#tm-what-to-expect {
  // main banner styles
  .banner {
    &.banner--main {
      .banner {
        &__teaser {
          // breakpoint needed to match the live site
          @include mq('320px', 'max-width') {
            height: 630px;
          }

          @include mq('medium') {
            background-position: 50% 65%; // value from live site
          }

          @include mq('x-large') {
            background-position: 50% 50%; // value from live site
          }

          // breakpoint needed to match the live site
          @include mq('1601px') {
            height: 755px;
          }

          // breakpoint needed to match the live site
          @include mq('2001px') {
            height: 900px;
          }

          .disclaimer-bottom-right .emu-teaser__disclaimer {
            @include mq('medium') {
              padding-right: var(--emu-common-spacing-none);
              padding-bottom: 15px;
            }

            @include mq('large') {
              padding-right: 50px;
              padding-left: 50px;
              padding-bottom: 20px;
            }

            @include mq('x-large') {
              padding-bottom: 10px;
            }
          }
        }

        &__content {
          padding-top: 80%; // value from live site

          // breakpoint needed to match the live site
          @include mq('350px') {
            padding-top: 90%; // value from live site
          }

          // breakpoint needed to match the live site
          @include mq('576px') {
            padding-top: 100%; // value from live site
          }

          @include mq('medium') {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            padding-bottom: 10px;
            padding-top: var(--emu-common-spacing-none);
            padding-left: var(--emu-common-spacing-none);
            max-width: var(--emu-semantic-sizing-breakpoints-large-plus);
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            top: 0;
          }

          @include mq('x-large') {
            padding-bottom: var(--emu-common-spacing-none);
          }

          > .container {
            @include mq('medium') {
              position: relative;
              top: 58%; // value from live site
            }

            @include mq('large') {
              top: 60%; // value from live site
            }

            @include mq('x-large') {
              top: 25%; // value from live site
            }

            // breakpoint needed to match the design
            @include mq('1600px') {
              top: 40%; // value from live site
            }
          }

          &-inner {
            @include mq('medium') {
              padding-left: 85px;
              padding-right: 52px;
            }

            @include mq('large') {
              padding-left: 50px;
              padding-right: 50px;
            }

            @include mq('x-large') {
              padding-left: var(--emu-common-spacing-none);
              padding-right: var(--emu-common-spacing-none);
              padding-top: var(--emu-common-spacing-medium);
            }
          }

          &-img {
            img {
              padding: var(--emu-common-spacing-none);
              max-width: 75%; // value from live site
              margin-left: 20px;

              // breakpoint needed to match the live site
              @include mq('350px') {
                max-width: 70%; // value from live site
              }

              // breakpoint needed to match the live site
              @media (min-width: 576px) and (max-width: 767px) {
                max-width: 65%; // value from live site
                margin-left: 15px;
              }

              @include mq('medium') {
                margin-left: -40px; // value from live site
                width: 560px; // value from live site
                max-width: none;
                height: auto;
              }

              @include mq('x-large') {
                width: auto;
                margin-left: var(--emu-common-spacing-none);
              }
            }
          }

          &-title {
            margin-top: -21px;

            // breakpoint needed to match the live site
            @include mq('350px') {
              padding-right: 5%; // value from live site
              padding-left: 30px;
              margin-top: -22px;
            }

            // breakpoint needed to match the live site
            @media (min-width: 576px) and (max-width: 767px) {
              margin-top: -7px;
              padding-left: 78px;
            }

            @include mq('medium') {
              padding: var(--emu-common-spacing-none);
              margin-top: -69px;
            }

            @include mq('large') {
              max-width: 660px; // value from live site
              margin-left: var(--emu-common-spacing-none);
            }

            @include mq('x-large') {
              padding-left: 78px;
              max-width: none;
              margin-top: -55px; // value from live site
            }

            .cmp-title__text {
              // breakpoint needed to match the live site
              @media (min-width: 576px) and (max-width: 767px) {
                font-size: 46px;
                line-height: 50px;
              }

              // breakpoint needed to match the live site
              @media (min-width: 1025px) and (max-width: 1199px) {
                font-size: 64px;
                line-height: 69px;
              }
            }
          }

          &-text {
            padding-right: 30px;

            // breakpoint needed to match the live site
            @include mq('350px') {
              padding-left: 30px;
              padding-right: 40px;
            }

            // breakpoint needed to match the live site
            @media (min-width: 576px) and (max-width: 767px) {
              font-size: 22px;
              line-height: 40px;
              padding-right: 35px;
              padding-left: 78px;
            }

            @include mq('medium') {
              padding: var(--emu-common-spacing-none);
              margin-top: 12px;
            }

            @include mq('large') {
              max-width: 635px;
              margin-left: var(--emu-common-spacing-none);
              margin-top: 28px;
            }

            // breakpoint needed to match the live site
            @media (min-width: 1025px) and (max-width: 1199px) {
              font-size: 28px;
              line-height: 42px;
            }

            @include mq('x-large') {
              padding-left: 78px;
              max-width: 720px;
              margin-top: 12px;
            }
          }
        }
      }
    }
  }

  // treatment plan overrides
  .treatment-plan {
    &__wrapper {
      padding-bottom: 18px;

      // breakpoint needed to match the live site
      @include mq('321px') {
        padding-bottom: 17px;
      }

      @include mq('medium') {
        padding-top: 102px;
        padding-bottom: 15px;
      }

      @include mq('large') {
        padding-bottom: var(--emu-common-spacing-medium);
      }

      @include mq('x-large') {
        padding-top: 111px;
        padding-bottom: 98px;
      }

      &.relative-modal__wrapper--hide {
        @include mq('medium') {
          padding-bottom: 19px;
        }

        @include mq('x-large') {
          padding-bottom: 410px; // calculated value to match the live site
        }
      }
    }

    &__title {
      @include mq('medium') {
        margin-right: var(--emu-common-spacing-none);
        margin-bottom: 15px;
      }

      @include mq('x-large') {
        margin-right: 10px;
        margin-bottom: 33px;
      }

      &-container {
        @include mq('medium') {
          margin-bottom: 55px;
        }

        @include mq('large') {
          width: 53%;
          margin-bottom: 40px;
        }

        @include mq('x-large') {
          width: 100%;
          margin-bottom: 28px;
        }
      }
    }

    &__scroll {
      &-wrapper {
        @include mq('x-large') {
          max-height: 800px;
        }
      }

      &-content {
        &-text {
          @include mq('medium') {
            padding-right: 38px;
          }

          @include mq('x-large') {
            padding-right: var(--emu-common-spacing-none);
          }

          li {
            @include mq('medium') {
              line-height: 42.5px; // to match live site
            }

            @include mq('large') {
              line-height: 42px;
            }

            @include mq('x-large') {
              font-size: 24px;
              line-height: 31.5px; // to match live site
            }
          }
        }

        .button {
          @include mq('x-large') {
            margin-top: -15px;
          }
        }
      }

      &-menu-text ul li > a {
        &:not(.active) {
          opacity: 0.4;
        }
      }
    }

    &__accordion-wrapper {
      .aaaem-accordion {
        &__button {
          @include mq('large') {
            align-items: center;
          }
        }

        &__header {
          &.-ba-is-active {
            .aaaem-accordion__button.caret-icon .aaaem-accordion__icon {
              @include mq('large') {
                transform: rotate(-180deg) translateY(22px);
              }
            }
          }
        }

        &__icon {
          @include mq('large') {
            width: 47px;
            height: 47px;
            margin-top: 12px;
          }

          &::after,
          &::before {
            @include mq('large') {
              width: 4.5px;
            }
          }

          &::after {
            @include mq('large') {
              transform: rotate(-48deg) translateX(1px);
            }
          }

          &::before {
            @include mq('large') {
              transform: rotate(48deg) translateX(-1px);
            }
          }
        }
      }
    }
  }

  // redirection banner
  .banner {
    &.banner--redirection {
      .banner {
        &__teaser {
          height: 825px; // value from live site

          // breakpoint needed to match the live site
          @include mq('414px') {
            height: 990px; // value from live site
          }

          // breakpoint needed to match the live site
          @include mq('576px') {
            height: 755px; // value from live site
          }

          @include mq('medium') {
            height: 1566px; // value from live site
            background-position: center center;
          }

          @include mq('large') {
            height: 2130px; // value from live site
          }

          // breakpoint needed to match the live site
          @include mq('1025px') {
            background-position: initial;
          }

          @include mq('x-large') {
            height: 750px; // value from live site
          }

          // breakpoint needed to match the live site
          @include mq('1401px') {
            height: 900px; // value from live site
          }

          // breakpoint needed to match the live site
          @include mq('1601px') {
            height: 1175px; // value from live site
          }

          // breakpoint needed to match the live site
          @include mq('2001px') {
            height: 1600px; // value from live site
          }
        }

        &__content {
          top: 0;
          height: 100%;
          display: flex;
          align-items: center;
          padding-top: calc(
            19.5% - 22px
          ); // height of the container in live is 39%. Half of it minus half the value of disclaimer height + 3px buffer value for line heights

          // breakpoint needed to match the live site
          @include mq('375px') {
            padding-top: calc(
              19.5% - 10px
            ); // calculated value to match live site
          }

          // breakpoint needed to match the live site
          @include mq('375px') {
            padding-top: calc(
              17.25% + 8px
            ); // calculated value to match live site
          }

          // breakpoint needed to match the live site
          @include mq('576px') {
            padding-top: var(--emu-common-spacing-none);
            margin-top: -12px; // disclaimer height is about 24px
          }

          @include mq('medium') {
            padding-top: calc(
              11% - 78px
            ); // calculated value to match live site
            margin-top: var(--emu-common-spacing-none);
          }

          @include mq('large') {
            padding-top: var(--emu-common-spacing-none);
            margin-top: -11px;
          }

          @include mq('x-large') {
            margin-top: -12px;
          }

          // breakpoint needed to match the live site
          @include mq('2001px') {
            margin-top: -9px;
          }

          &-inner {
            @include mq('medium') {
              max-width: 674px; // value from live site
              margin-left: auto;
              margin-right: auto;
            }
          }

          &-title {
            max-width: none;

            .cmp-title__text {
              // breakpoint needed to match the live site
              @media (min-width: 576px) and (max-width: 767px) {
                font-size: 54px;
                line-height: 58px;
              }

              @include mq('large') {
                font-size: 54px;
                line-height: 58px;
              }
            }
          }

          &-text {
            margin-top: -2px;
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);

            // breakpoint needed to match the live site
            @media (min-width: 576px) and (max-width: 767px) {
              font-size: 24px;
              line-height: 31px;
            }

            @include mq('medium') {
              max-width: none;
              padding: var(--emu-common-spacing-none);
              margin-top: -3px;
            }

            @include mq('large') {
              margin-top: 12px;
            }

            @include mq('x-large') {
              font-size: 24px;
              line-height: 31px;
            }

            &-container {
              @include mq('medium') {
                padding-left: var(--emu-common-spacing-none);
                padding-right: var(--emu-common-spacing-none);
              }
            }

            br {
              display: block;
            }
          }

          &-cta-container {
            margin-top: 25px;

            @include mq('medium') {
              margin-top: 24px;
            }
          }

          &-cta {
            height: 63px; // to match live site

            // breakpoint needed to match the live site
            @media (min-width: 576px) and (max-width: 767px) {
              font-size: 24px;
            }

            @include mq('medium') {
              font-size: 24px;
            }
          }
        }
      }
    }
  }

  // Find a Provider banner
  .banner {
    &.banner--fap {
      .banner {
        &__content {
          @include mq('medium') {
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
            top: 41%; // value from live site
          }

          @include mq('large') {
            top: 42.5%; // value from live site
            padding-top: 7px;
            max-width: var(--emu-semantic-sizing-breakpoints-large-plus);
          }

          @include mq('x-large') {
            top: 15%; // value from live site
            padding-top: 5px;
          }

          // breakpoint needed to match the live site
          @include mq('1401px') {
            padding-top: 7px;
          }

          &-inner {
            @include mq('medium') {
              padding-top: var(--emu-common-spacing-none);
              padding-left: 30px;
              padding-right: 30px;
            }

            @include mq('large') {
              padding-left: var(--emu-common-spacing-none);
              padding-right: var(--emu-common-spacing-none);
            }

            // breakpoint needed to match the live site
            @include mq('1025px') {
              padding-top: 28%; // value from live site
            }

            @include mq('x-large') {
              padding-top: 14.7%; // value from live site
            }

            // breakpoint needed to match the live site
            @include mq('1401px') {
              padding-top: 20.6%; // value from live site
            }

            // breakpoint needed to match the live site
            @include mq('1601px') {
              padding-top: 28%; // value from live site
            }

            // breakpoint needed to match the live site
            @include mq('2001px') {
              padding-top: 35%; // value from live site
            }
          }

          &-text {
            @include mq('321px') {
              max-width: 85%; // value from live site
              padding-left: var(--emu-common-spacing-none);
              padding-right: var(--emu-common-spacing-none);
            }

            @include mq('414px') {
              max-width: 90%; // value from live site
            }

            @include mq('medium') {
              max-width: 675px; // value from live site
            }

            @include mq('large') {
              margin-top: 12px;
            }

            // breakpoint needed to match the live site
            @include mq('1025px') {
              max-width: 700px; // value from live site
            }
          }

          &-cta-container {
            @include mq('large') {
              margin-top: 23px;
            }
          }
        }
      }
    }
  }

  // modal styles
  .emu-modal.relative-modal.checklist-modal {
    @include mq('medium') {
      margin-top: -42px;
    }

    @include mq('large') {
      margin-top: -28px;
    }

    @include mq('x-large') {
      margin-top: -35px;
    }

    .checklist-content {
      @include mq('x-large') {
        padding-bottom: var(--emu-common-spacing-none);
      }

      &__title {
        @include mq('medium') {
          margin-top: 26px;
          margin-bottom: var(--emu-common-spacing-xs);
        }

        @include mq('x-large') {
          margin-top: -3px;
          margin-bottom: 6px;
        }

        .cmp-title__text {
          @include mq('medium') {
            line-height: 69px;
          }

          @include mq('x-large') {
            font-size: 44px;
            line-height: 48px;
          }
        }
      }

      &__description {
        @include mq('medium') {
          margin-bottom: 18px;
        }
      }

      &__list {
        @include mq('medium') {
          line-height: 40px;
          padding-bottom: 30px;
        }

        @include mq('x-large') {
          line-height: 20px;
          padding-bottom: 34px;
        }

        li {
          @include mq('medium') {
            margin-bottom: 29px;
          }

          @include mq('x-large') {
            margin-bottom: 15px; // calculated value from live site
          }
        }
      }
    }

    .modal-content {
      @include mq('medium') {
        padding: 38px;
      }

      @include mq('x-large') {
        padding: 20px;
      }

      .close {
        @include mq('medium') {
          width: var(--emu-common-sizing-medium);
          height: var(--emu-common-sizing-medium);
        }

        @include mq('x-large') {
          width: 24px;
          height: 27px;
        }
      }
    }
  }
}
