.alle-banner {
  &-container {
    background-position: center center;
  }

  &-content-wrapper {
    padding-top: 100px;
    padding-right: 30px;
    padding-bottom: 90px;
    padding-left: 30px;

    @include mq('medium') {
      padding-bottom: 88px;
      max-width: var(--emu-common-sizing-tablet);
    }

    @include mq('x-large') {
      max-width: var(--emu-common-sizing-large-tablet);
    }

    &__img {
      img {
        width: 135px;
        margin: auto;
        margin-bottom: var(--emu-common-spacing-large);

        // breakpoint needed to match the live site
        @include mq('321px') {
          width: 158px; // As per design
        }

        // breakpoint needed to match the live site
        @include mq('414px') {
          width: 174px; // As per design
          margin-bottom: 34px;
        }

        @include mq('medium') {
          width: 279px; // As per design
          margin-bottom: 35px;
        }
      }
    }

    &__title {
      .cmp-title__text {
        font-size: var(--emu-semantic-font-sizes-narrow-large);
        line-height: var(--emu-semantic-line-heights-narrow-large);
        max-width: 100%;
        text-align: center;
        margin-bottom: 17px;
        margin-left: auto;
        margin-right: auto;

        // breakpoint needed to match the live site
        @include mq('320px', 'max-width') {
          font-size: 28px;
          line-height: 34px;
        }

        // breakpoint needed to match the live site
        @include mq('414px') {
          font-size: 33px;
          line-height: var(--emu-common-line-heights-narrow-xl);
          margin-bottom: 18px;
        }

        @include mq('medium') {
          font-size: 64px;
          line-height: 70px;
          max-width: 620px; // As per design
          margin-bottom: var(--emu-common-spacing-medium);
        }

        @include mq('large') {
          margin-bottom: 12px;
        }

        // breakpoint needed to match the live site
        @include mq('1025px') {
          font-size: var(--emu-semantic-font-sizes-wide-xl);
          line-height: var(--emu-semantic-line-heights-wide-xl);
          margin-bottom: 12px;
          max-width: 70%; // As per design
        }
      }
    }

    &__text {
      margin-bottom: 24px;
      font-size: var(--emu-semantic-font-sizes-narrow-small);
      line-height: var(--emu-semantic-line-heights-narrow-small);

      // breakpoint needed to match the live site
      @include mq('414px') {
        font-size: var(--emu-semantic-font-sizes-narrow-medium);
        line-height: 29px;
      }

      @include mq('medium') {
        font-size: 28px;
        line-height: 42px;
        padding-left: 15px;
        padding-right: 15px;
        max-width: 700px; // As per design
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
      }

      // breakpoint needed to match the live site
      @include mq('1025px') {
        font-size: var(--emu-semantic-font-sizes-wide-small);
        line-height: var(--emu-semantic-line-heights-wide-small);
        padding-left: 110px;
        padding-right: 110px;
        max-width: 100%;
      }

      * {
        font-size: inherit;
        line-height: inherit;
      }

      sup {
        font-size: 60%;
        top: 2px;
        line-height: 0;
      }
    }

    .button {
      text-align: center;

      .emu-button {
        padding-right: 49px;
        padding-left: 49px;

        // breakpoint needed to match the live site
        @include mq('348px') {
          padding-left: 74px;
          padding-right: 74px;
        }
      }
    }
  }
}
