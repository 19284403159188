.pre-footer {
  border-top-width: 13px;
  border-top-style: solid;
  border-top-color: var(--emu-semantic-colors-primary-100);
  margin-top: 63px;
  margin-bottom: var(--emu-common-spacing-none);
  margin-left: 15px;
  margin-right: 15px;
  padding-top: var(--emu-common-spacing-none);
  padding-bottom: var(--emu-common-spacing-none);

  @include mq('medium') {
    margin-right: 40px;
    margin-left: 40px;
    margin-bottom: 5px;
  }

  @include mq('large') {
    border-top-width: 15px;
    padding-top: 39px;
    padding-bottom: 59px;
    margin-bottom: 10px;
  }

  // breakpoint needed to match the design
  @include mq('1140px') {
    margin-top: 50px;
    padding-bottom: 45px;
  }

  @include mq('x-large') {
    max-width: 1092px; // As per live site.
    margin-left: auto;
    margin-right: auto;
    margin-bottom: var(--emu-common-spacing-none);
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: auto;

    @include mq('large') {
      height: 40px; // to match the live site.
      flex-direction: row;
    }
  }

  &__logo {
    padding-top: 35px;

    @include mq('large') {
      padding-top: 9px;
    }
    img {
      min-height: 50px; // to match the live site.

      @include mq('large') {
        min-height: 40px;
      }
    }
  }

  &__social {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: 46px; // to match the live site.
    margin-top: 25px;
    margin-bottom: 15px;
    margin-left: 15px;

    @include mq('large') {
      justify-content: right;
      align-items: center;
      margin: var(--emu-common-spacing-none);
      gap: 51px;
    }

    .cmp-text {
      a {
        font-size: var(--emu-semantic-font-sizes-narrow-small);
        line-height: 33px;
        font-family: var(--emu-semantic-font-families-heading);
        text-decoration: none;
        letter-spacing: 0.84px; // to match the live site.

        &:hover {
          color: var(--emu-semantic-colors-primary-500);
        }
      }
    }
  }

  &__icons {
    display: flex;

    [data-component='image'] {
      cursor: pointer;
      margin-right: 34.5px; // to match the live site.

      @include mq('large') {
        margin-left: 24.5px; // to match the live site.
        margin-right: var(--emu-common-spacing-none);
      }

      svg {
        fill: var(--emu-semantic-colors-secondary-700);
      }
    }

    > div:nth-child(1),
    > div:nth-child(2) {
      svg {
        width: 15.75px; // to match the live site.
      }
    }

    > div:nth-child(3) {
      svg {
        width: 20.25px; // to match the live site.
      }
    }
  }
}
