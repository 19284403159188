[data-component='modal'] {
  &.relative-modal {
    .modal {
      &-wrapper {
        position: relative;
        top: var(--emu-common-spacing-none);
        background-color: var(--emu-semantic-colors-none);
      }

      &-content {
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: 20px;
      }
    }
  }

  .modal {
    &-content {
      box-shadow: 8px 12px 14px rgba(0, 0, 0, 0.35);
      display: flex;
      flex-direction: column;

      @include mq('large') {
        // margin-left: 50px;
        // margin-right: 50px;
      }

      @include mq('x-large') {
        max-width: 1200px; // As per the live site
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
      }

      @include mq('xx-large') {
        width: 1200px; // As per the live site
      }

      > .container {
        padding-top: 20px;

        // breakpoint needed to match the live site
        @include mq('414px') {
          padding-top: var(--emu-common-spacing-none);
        }
      }
    }
  }

  .close {
    width: var(--emu-common-sizing-medium);
    height: var(--emu-common-sizing-medium);
    background-image: url('../../assets/images/close-icon.png');
    background-size: cover;
    background-repeat: no-repeat;
    position: static;
    align-self: flex-end;

    // breakpoint needed to match the live site
    @include mq('414px') {
      width: 28px;
      height: 28px;
    }

    @include mq('x-large') {
      width: 24px;
      height: 27px;
      background-position: center bottom;
      background-size: contain;
    }

    &,
    &:active,
    &:focus {
      opacity: 1;
    }

    span {
      display: none;
    }
  }
}
