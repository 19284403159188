
/**
 * ** 🚨 DO NOT EDIT THIS FILE DIRECTLY!
 * If you want to make edits make them in each appropriate token JSON file.
 */

$emu-semantic-colors-none: rgba(0,0,0,0) !default;
$emu-semantic-colors-surface-light: #F5ECEA !default;
$emu-semantic-colors-surface-dark: #492728 !default;
$emu-semantic-colors-surface-modal-backdrop: rgba(0, 0, 0, 0.4) !default;
$emu-semantic-colors-actions-primary-light: #0C2E77 !default;
$emu-semantic-colors-actions-primary-dark: #F2D9D0 !default;
$emu-semantic-colors-actions-primary-default-light: #0C2E77 !default;
$emu-semantic-colors-actions-primary-default-dark: #F2D9D0 !default;
$emu-semantic-colors-actions-primary-hover-light: #011A66 !default;
$emu-semantic-colors-actions-primary-hover-dark: #F7E8E3 !default;
$emu-semantic-colors-actions-primary-active-light: #011A66 !default;
$emu-semantic-colors-actions-primary-active-dark: #F7E8E3 !default;
$emu-semantic-colors-actions-primary-focus-light: #011A66 !default;
$emu-semantic-colors-actions-primary-focus-dark: #F7E8E3 !default;
$emu-semantic-colors-actions-primary-disabled-light: #B9B4B4 !default;
$emu-semantic-colors-actions-primary-disabled-dark: #D8D5D5 !default;
$emu-semantic-colors-actions-primary-error-light: #FF1D15 !default;
$emu-semantic-colors-actions-primary-error-dark: #FF7F7A !default;
$emu-semantic-colors-actions-on-primary-light: #FFFFFF !default;
$emu-semantic-colors-actions-on-primary-dark: #000000 !default;
$emu-semantic-colors-actions-secondary-light: #616568 !default;
$emu-semantic-colors-actions-secondary-dark: #DDC1CC !default;
$emu-semantic-colors-actions-secondary-default-light: #616568 !default;
$emu-semantic-colors-actions-secondary-default-dark: #DDC1CC !default;
$emu-semantic-colors-actions-secondary-hover-light: #393A3B !default;
$emu-semantic-colors-actions-secondary-hover-dark: #C08CA1 !default;
$emu-semantic-colors-actions-secondary-active-light: #393A3B !default;
$emu-semantic-colors-actions-secondary-active-dark: #C08CA1 !default;
$emu-semantic-colors-actions-secondary-focus-light: #393A3B !default;
$emu-semantic-colors-actions-secondary-focus-dark: #C08CA1 !default;
$emu-semantic-colors-actions-secondary-disabled-light: #D8D5D5 !default;
$emu-semantic-colors-actions-secondary-disabled-dark: #E6E5E5 !default;
$emu-semantic-colors-actions-on-secondary-light: #FFFFFF !default;
$emu-semantic-colors-actions-on-secondary-dark: #000000 !default;
$emu-semantic-colors-text-light: #000000 !default;
$emu-semantic-colors-text-dark: #FFFFFF !default;
$emu-semantic-colors-foreground-light: #C29A94 !default;
$emu-semantic-colors-foreground-dark: #F2D9D0 !default;
$emu-semantic-colors-primary-0: #FFFFFF !default;
$emu-semantic-colors-primary-50: #6BC2E6 !default;
$emu-semantic-colors-primary-100: #005CB9 !default;
$emu-semantic-colors-primary-200: #005EB8 !default;
$emu-semantic-colors-primary-300: #0C5BAC !default;
$emu-semantic-colors-primary-400: #074480 !default;
$emu-semantic-colors-primary-500: #0C2E77 !default;
$emu-semantic-colors-primary-600: #011A66 !default;
$emu-semantic-colors-primary-700: #0f2a64 !default;
$emu-semantic-colors-primary-800: #142d7b !default;
$emu-semantic-colors-primary-900: #05103A !default;
$emu-semantic-colors-primary-950: #002554 !default;
$emu-semantic-colors-primary-light: #0C2E77 !default;
$emu-semantic-colors-primary-dark: #005EB8 !default;
$emu-semantic-colors-secondary-50: #F3F3F3 !default;
$emu-semantic-colors-secondary-100: #ECECEC !default;
$emu-semantic-colors-secondary-200: #9DA6AE !default;
$emu-semantic-colors-secondary-300: #7F878F !default;
$emu-semantic-colors-secondary-400: #616468 !default;
$emu-semantic-colors-secondary-500: #616568 !default;
$emu-semantic-colors-secondary-600: #393A3B !default;
$emu-semantic-colors-secondary-700: #6B6966 !default;
$emu-semantic-colors-secondary-800: #EBEFF5 !default;
$emu-semantic-colors-secondary-900: #351D26 !default;
$emu-semantic-colors-secondary-950: #1A0E13 !default;
$emu-semantic-colors-secondary-light: #616568 !default;
$emu-semantic-colors-secondary-dark: #9DA6AE !default;
$emu-semantic-colors-tertiary-50: #E3EBFC !default;
$emu-semantic-colors-tertiary-100: #BED1F9 !default;
$emu-semantic-colors-tertiary-200: #6A97F1 !default;
$emu-semantic-colors-tertiary-300: #1B60E9 !default;
$emu-semantic-colors-tertiary-400: #0F3D9A !default;
$emu-semantic-colors-tertiary-500: #071D49 !default;
$emu-semantic-colors-tertiary-600: #06183C !default;
$emu-semantic-colors-tertiary-700: #04112A !default;
$emu-semantic-colors-tertiary-800: #030B1C !default;
$emu-semantic-colors-tertiary-900: #01060E !default;
$emu-semantic-colors-tertiary-950: #010409 !default;
$emu-semantic-colors-tertiary-light: #071D49 !default;
$emu-semantic-colors-tertiary-dark: #6A97F1 !default;
$emu-semantic-colors-error-50: #FFF5F5 !default;
$emu-semantic-colors-error-100: #FFDCDB !default;
$emu-semantic-colors-error-200: #FFABA8 !default;
$emu-semantic-colors-error-300: #FF7F7A !default;
$emu-semantic-colors-error-400: #FF4E47 !default;
$emu-semantic-colors-error-500: #FF1D15 !default;
$emu-semantic-colors-error-600: #DB0700 !default;
$emu-semantic-colors-error-700: #A30500 !default;
$emu-semantic-colors-error-800: #700400 !default;
$emu-semantic-colors-error-900: #380200 !default;
$emu-semantic-colors-error-950: #190100 !default;
$emu-semantic-colors-error-light: #FF1D15 !default;
$emu-semantic-colors-error-dark: #FFABA8 !default;
$emu-semantic-colors-background-light: #FAF6F5 !default;
$emu-semantic-colors-background-dark: #F2D9D0 !default;
$emu-semantic-font-weight-500: 500 !default;
$emu-semantic-font-weight-600: 600 !default;
$emu-semantic-font-weight-900: 900 !default;
$emu-semantic-font-weight-bold: 700 !default;
$emu-semantic-font-weight-light: 300 !default;
$emu-semantic-font-weight-black: 900 !default;
$emu-semantic-font-weight-regular: 400 !default;
$emu-semantic-font-families-body: 'AvenirNextRegular' , sans-serif !default;
$emu-semantic-font-families-heading: 'AvenirNextBold' , sans-serif !default;
$emu-semantic-font-families-mono: 'AvenirNextMedium' , sans-serif !default;
$emu-semantic-spacing-none: 0px !default;
$emu-semantic-spacing-one-line-height-wide: 31px !default;
$emu-semantic-spacing-one-line-height-narrow: 26px !default;
$emu-semantic-spacing-two-line-height-wide: calc(31px*2) !default;
$emu-semantic-spacing-two-line-height-narrow: calc(26px*2) !default;
$emu-semantic-spacing-three-line-height-wide: calc(31px*3) !default;
$emu-semantic-spacing-three-line-height-narrow: calc(26px*3) !default;
$emu-semantic-spacing-rem-horizontal: 0px !default;
$emu-semantic-spacing-horizontal-narrow: 0px !default;
$emu-semantic-spacing-horizontal-wide: 0px !default;
$emu-semantic-spacing-horizontal-onerem-narrow: 1rem !default;
$emu-semantic-spacing-horizontal-onerem-wide: 1rem !default;
$emu-semantic-spacing-horizontal-tworem-narrow: calc(1rem*2) !default;
$emu-semantic-spacing-horizontal-tworem-wide: calc(1rem*2) !default;
$emu-semantic-spacing-rem-vertical: 0px !default;
$emu-semantic-spacing-vertical-narrow: 0px !default;
$emu-semantic-spacing-vertical-wide: 0px !default;
$emu-semantic-spacing-half-line-height-wide: calc(31px*.5) !default;
$emu-semantic-spacing-half-line-height-narrow: calc(26px*.5) !default;
$emu-semantic-spacing-quarter-line-height-wide: calc(31px*.25) !default;
$emu-semantic-spacing-quarter-line-height-narrow: calc(26px*.25) !default;
$emu-semantic-font-sizes-narrow-medium: 16px !default;
$emu-semantic-font-sizes-narrow-large: 30px !default;
$emu-semantic-font-sizes-narrow-xl: 30px !default;
$emu-semantic-font-sizes-narrow-xxl: 40px !default;
$emu-semantic-font-sizes-narrow-xxxl: 48.83px !default;
$emu-semantic-font-sizes-narrow-small: 14px !default;
$emu-semantic-font-sizes-narrow-xs: 10px !default;
$emu-semantic-font-sizes-wide-medium: 22px !default;
$emu-semantic-font-sizes-wide-large: 34px !default;
$emu-semantic-font-sizes-wide-xl: 54px !default;
$emu-semantic-font-sizes-wide-xxl: 58px !default;
$emu-semantic-font-sizes-wide-xxxl: 54.93px !default;
$emu-semantic-font-sizes-wide-small: 24px !default;
$emu-semantic-font-sizes-wide-xs: 10px !default;
$emu-semantic-line-heights-narrow-medium: 17px !default;
$emu-semantic-line-heights-narrow-large: 35px !default;
$emu-semantic-line-heights-narrow-xl: 35px !default;
$emu-semantic-line-heights-narrow-xxl: 54px !default;
$emu-semantic-line-heights-narrow-xxxl: calc(26px*2.5) !default;
$emu-semantic-line-heights-narrow-small: 26px !default;
$emu-semantic-line-heights-narrow-xs: 14px !default;
$emu-semantic-line-heights-wide-medium: 30px !default;
$emu-semantic-line-heights-wide-large: 41px !default;
$emu-semantic-line-heights-wide-xl: 58px !default;
$emu-semantic-line-heights-wide-xxl: 80px !default;
$emu-semantic-line-heights-wide-xxxl: calc(31px*3) !default;
$emu-semantic-line-heights-wide-small: 31px !default;
$emu-semantic-line-heights-wide-xs: 14px !default;
$emu-semantic-border-radius-xs: 4px !default;
$emu-semantic-border-radius-small: 8px !default;
$emu-semantic-border-radius-medium: 16px !default;
$emu-semantic-border-radius-large: 32px !default;
$emu-semantic-border-radius-none: 0px !default;
$emu-semantic-border-width-thin: 1px !default;
$emu-semantic-border-width-medium: 2px !default;
$emu-semantic-border-width-thick: 4px !default;
$emu-semantic-border-width-none: 0px !default;
$emu-semantic-outline-width-thin: thin !default;
$emu-semantic-outline-width-medium: medium !default;
$emu-semantic-outline-width-thick: thick !default;
$emu-semantic-outline-width-unset: unset !default;
$emu-semantic-sizing-none: 0px !default;
$emu-semantic-sizing-xxs: 1px !default;
$emu-semantic-sizing-xs: 8px !default;
$emu-semantic-sizing-small: 16px !default;
$emu-semantic-sizing-medium: 32px !default;
$emu-semantic-sizing-large: 64px !default;
$emu-semantic-sizing-xl: 120px !default;
$emu-semantic-sizing-xxl: 256px !default;
$emu-semantic-sizing-breakpoints-small: 320px !default;
$emu-semantic-sizing-breakpoints-medium: 768px !default;
$emu-semantic-sizing-breakpoints-large: 1024px !default;
$emu-semantic-sizing-breakpoints-large-plus: 1140px !default;
$emu-semantic-sizing-breakpoints-x-large: 1200px !default;
$emu-semantic-sizing-breakpoints-xx-large: 1440px !default;
$emu-semantic-sizing-one-line-height-narrow: 20px !default;
$emu-semantic-sizing-one-line-height-wide: 20px !default;
$emu-semantic-sizing-two-line-height-narrow: calc(20px*2) !default;
$emu-semantic-sizing-two-line-height-wide: calc(20px*2) !default;
$emu-semantic-sizing-three-line-height-narrow: calc(20px*3) !default;
$emu-semantic-sizing-three-line-height-wide: calc(20px*3) !default;
$emu-semantic-sizing-max-width-text-narrow: 40em !default;
$emu-semantic-sizing-max-width-text-wide: 55em !default;
$emu-semantic-typography-narrow-headings-xxxl: 400 48.83px/calc(26px*2.5) 'AvenirNextBold' , sans-serif !default;
$emu-semantic-typography-narrow-headings-xxl: 500 40px/54px 'AvenirNextRegular' , sans-serif !default;
$emu-semantic-typography-narrow-headings-xl: 900 30px/35px 'AvenirNextBold' , sans-serif !default;
$emu-semantic-typography-narrow-headings-large: 900 30px/35px 'AvenirNextBold' , sans-serif !default;
$emu-semantic-typography-narrow-headings-medium: 900 16px/17px 'AvenirNextBold' , sans-serif !default;
$emu-semantic-typography-narrow-body-regular: 400 14px/26px 'AvenirNextRegular' , sans-serif !default;
$emu-semantic-typography-wide-headings-xxxl: 400 54.93px/calc(31px*3) 'AvenirNextBold' , sans-serif !default;
$emu-semantic-typography-wide-headings-xxl: 500 58px/80px 'AvenirNextRegular' , sans-serif !default;
$emu-semantic-typography-wide-headings-xl: 900 54px/58px 'AvenirNextBold' , sans-serif !default;
$emu-semantic-typography-wide-headings-large: 900 34px/41px 'AvenirNextBold' , sans-serif !default;
$emu-semantic-typography-wide-headings-medium: 900 22px/30px 'AvenirNextBold' , sans-serif !default;
$emu-semantic-typography-wide-body-regular: 400 24px/31px 'AvenirNextRegular' , sans-serif !default;

$semantic: (
  'semantic': (
    'colors': (
      'none': $emu-semantic-colors-none,
      'surface': (
        'light': $emu-semantic-colors-surface-light,
        'dark': $emu-semantic-colors-surface-dark,
        'modal-backdrop': $emu-semantic-colors-surface-modal-backdrop
      ),
      'actions': (
        'primary': (
          'light': $emu-semantic-colors-actions-primary-light,
          'dark': $emu-semantic-colors-actions-primary-dark,
          'default': (
            'light': $emu-semantic-colors-actions-primary-default-light,
            'dark': $emu-semantic-colors-actions-primary-default-dark
          ),
          'hover': (
            'light': $emu-semantic-colors-actions-primary-hover-light,
            'dark': $emu-semantic-colors-actions-primary-hover-dark
          ),
          'active': (
            'light': $emu-semantic-colors-actions-primary-active-light,
            'dark': $emu-semantic-colors-actions-primary-active-dark
          ),
          'focus': (
            'light': $emu-semantic-colors-actions-primary-focus-light,
            'dark': $emu-semantic-colors-actions-primary-focus-dark
          ),
          'disabled': (
            'light': $emu-semantic-colors-actions-primary-disabled-light,
            'dark': $emu-semantic-colors-actions-primary-disabled-dark
          ),
          'error': (
            'light': $emu-semantic-colors-actions-primary-error-light,
            'dark': $emu-semantic-colors-actions-primary-error-dark
          )
        ),
        'on-primary': (
          'light': $emu-semantic-colors-actions-on-primary-light,
          'dark': $emu-semantic-colors-actions-on-primary-dark
        ),
        'secondary': (
          'light': $emu-semantic-colors-actions-secondary-light,
          'dark': $emu-semantic-colors-actions-secondary-dark,
          'default': (
            'light': $emu-semantic-colors-actions-secondary-default-light,
            'dark': $emu-semantic-colors-actions-secondary-default-dark
          ),
          'hover': (
            'light': $emu-semantic-colors-actions-secondary-hover-light,
            'dark': $emu-semantic-colors-actions-secondary-hover-dark
          ),
          'active': (
            'light': $emu-semantic-colors-actions-secondary-active-light,
            'dark': $emu-semantic-colors-actions-secondary-active-dark
          ),
          'focus': (
            'light': $emu-semantic-colors-actions-secondary-focus-light,
            'dark': $emu-semantic-colors-actions-secondary-focus-dark
          ),
          'disabled': (
            'light': $emu-semantic-colors-actions-secondary-disabled-light,
            'dark': $emu-semantic-colors-actions-secondary-disabled-dark
          )
        ),
        'on-secondary': (
          'light': $emu-semantic-colors-actions-on-secondary-light,
          'dark': $emu-semantic-colors-actions-on-secondary-dark
        )
      ),
      'text': (
        'light': $emu-semantic-colors-text-light,
        'dark': $emu-semantic-colors-text-dark
      ),
      'foreground': (
        'light': $emu-semantic-colors-foreground-light,
        'dark': $emu-semantic-colors-foreground-dark
      ),
      'primary': (
        '0': $emu-semantic-colors-primary-0,
        '50': $emu-semantic-colors-primary-50,
        '100': $emu-semantic-colors-primary-100,
        '200': $emu-semantic-colors-primary-200,
        '300': $emu-semantic-colors-primary-300,
        '400': $emu-semantic-colors-primary-400,
        '500': $emu-semantic-colors-primary-500,
        '600': $emu-semantic-colors-primary-600,
        '700': $emu-semantic-colors-primary-700,
        '800': $emu-semantic-colors-primary-800,
        '900': $emu-semantic-colors-primary-900,
        '950': $emu-semantic-colors-primary-950,
        'light': $emu-semantic-colors-primary-light,
        'dark': $emu-semantic-colors-primary-dark
      ),
      'secondary': (
        '50': $emu-semantic-colors-secondary-50,
        '100': $emu-semantic-colors-secondary-100,
        '200': $emu-semantic-colors-secondary-200,
        '300': $emu-semantic-colors-secondary-300,
        '400': $emu-semantic-colors-secondary-400,
        '500': $emu-semantic-colors-secondary-500,
        '600': $emu-semantic-colors-secondary-600,
        '700': $emu-semantic-colors-secondary-700,
        '800': $emu-semantic-colors-secondary-800,
        '900': $emu-semantic-colors-secondary-900,
        '950': $emu-semantic-colors-secondary-950,
        'light': $emu-semantic-colors-secondary-light,
        'dark': $emu-semantic-colors-secondary-dark
      ),
      'tertiary': (
        '50': $emu-semantic-colors-tertiary-50,
        '100': $emu-semantic-colors-tertiary-100,
        '200': $emu-semantic-colors-tertiary-200,
        '300': $emu-semantic-colors-tertiary-300,
        '400': $emu-semantic-colors-tertiary-400,
        '500': $emu-semantic-colors-tertiary-500,
        '600': $emu-semantic-colors-tertiary-600,
        '700': $emu-semantic-colors-tertiary-700,
        '800': $emu-semantic-colors-tertiary-800,
        '900': $emu-semantic-colors-tertiary-900,
        '950': $emu-semantic-colors-tertiary-950,
        'light': $emu-semantic-colors-tertiary-light,
        'dark': $emu-semantic-colors-tertiary-dark
      ),
      'error': (
        '50': $emu-semantic-colors-error-50,
        '100': $emu-semantic-colors-error-100,
        '200': $emu-semantic-colors-error-200,
        '300': $emu-semantic-colors-error-300,
        '400': $emu-semantic-colors-error-400,
        '500': $emu-semantic-colors-error-500,
        '600': $emu-semantic-colors-error-600,
        '700': $emu-semantic-colors-error-700,
        '800': $emu-semantic-colors-error-800,
        '900': $emu-semantic-colors-error-900,
        '950': $emu-semantic-colors-error-950,
        'light': $emu-semantic-colors-error-light,
        'dark': $emu-semantic-colors-error-dark
      ),
      'background': (
        'light': $emu-semantic-colors-background-light,
        'dark': $emu-semantic-colors-background-dark
      )
    ),
    'fontWeight': (
      '500': $emu-semantic-font-weight-500,
      '600': $emu-semantic-font-weight-600,
      '900': $emu-semantic-font-weight-900,
      'bold': $emu-semantic-font-weight-bold,
      'light': $emu-semantic-font-weight-light,
      'black': $emu-semantic-font-weight-black,
      'regular': $emu-semantic-font-weight-regular
    ),
    'fontFamilies': (
      'body': $emu-semantic-font-families-body,
      'heading': $emu-semantic-font-families-heading,
      'mono': $emu-semantic-font-families-mono
    ),
    'spacing': (
      'none': $emu-semantic-spacing-none,
      'oneLineHeight': (
        'wide': $emu-semantic-spacing-one-line-height-wide,
        'narrow': $emu-semantic-spacing-one-line-height-narrow
      ),
      'twoLineHeight': (
        'wide': $emu-semantic-spacing-two-line-height-wide,
        'narrow': $emu-semantic-spacing-two-line-height-narrow
      ),
      'threeLineHeight': (
        'wide': $emu-semantic-spacing-three-line-height-wide,
        'narrow': $emu-semantic-spacing-three-line-height-narrow
      ),
      '_rem_horizontal': $emu-semantic-spacing-rem-horizontal,
      'horizontal': (
        'narrow': $emu-semantic-spacing-horizontal-narrow,
        'wide': $emu-semantic-spacing-horizontal-wide,
        'onerem': (
          'narrow': $emu-semantic-spacing-horizontal-onerem-narrow,
          'wide': $emu-semantic-spacing-horizontal-onerem-wide
        ),
        'tworem': (
          'narrow': $emu-semantic-spacing-horizontal-tworem-narrow,
          'wide': $emu-semantic-spacing-horizontal-tworem-wide
        )
      ),
      '_rem_vertical': $emu-semantic-spacing-rem-vertical,
      'vertical': (
        'narrow': $emu-semantic-spacing-vertical-narrow,
        'wide': $emu-semantic-spacing-vertical-wide
      ),
      'halfLineHeight': (
        'wide': $emu-semantic-spacing-half-line-height-wide,
        'narrow': $emu-semantic-spacing-half-line-height-narrow
      ),
      'quarterLineHeight': (
        'wide': $emu-semantic-spacing-quarter-line-height-wide,
        'narrow': $emu-semantic-spacing-quarter-line-height-narrow
      )
    ),
    'fontSizes': (
      'narrow': (
        'medium': $emu-semantic-font-sizes-narrow-medium,
        'large': $emu-semantic-font-sizes-narrow-large,
        'xl': $emu-semantic-font-sizes-narrow-xl,
        'xxl': $emu-semantic-font-sizes-narrow-xxl,
        'xxxl': $emu-semantic-font-sizes-narrow-xxxl,
        'small': $emu-semantic-font-sizes-narrow-small,
        'xs': $emu-semantic-font-sizes-narrow-xs
      ),
      'wide': (
        'medium': $emu-semantic-font-sizes-wide-medium,
        'large': $emu-semantic-font-sizes-wide-large,
        'xl': $emu-semantic-font-sizes-wide-xl,
        'xxl': $emu-semantic-font-sizes-wide-xxl,
        'xxxl': $emu-semantic-font-sizes-wide-xxxl,
        'small': $emu-semantic-font-sizes-wide-small,
        'xs': $emu-semantic-font-sizes-wide-xs
      )
    ),
    'lineHeights': (
      'narrow': (
        'medium': $emu-semantic-line-heights-narrow-medium,
        'large': $emu-semantic-line-heights-narrow-large,
        'xl': $emu-semantic-line-heights-narrow-xl,
        'xxl': $emu-semantic-line-heights-narrow-xxl,
        'xxxl': $emu-semantic-line-heights-narrow-xxxl,
        'small': $emu-semantic-line-heights-narrow-small,
        'xs': $emu-semantic-line-heights-narrow-xs
      ),
      'wide': (
        'medium': $emu-semantic-line-heights-wide-medium,
        'large': $emu-semantic-line-heights-wide-large,
        'xl': $emu-semantic-line-heights-wide-xl,
        'xxl': $emu-semantic-line-heights-wide-xxl,
        'xxxl': $emu-semantic-line-heights-wide-xxxl,
        'small': $emu-semantic-line-heights-wide-small,
        'xs': $emu-semantic-line-heights-wide-xs
      )
    ),
    'borderRadius': (
      'xs': $emu-semantic-border-radius-xs,
      'small': $emu-semantic-border-radius-small,
      'medium': $emu-semantic-border-radius-medium,
      'large': $emu-semantic-border-radius-large,
      'none': $emu-semantic-border-radius-none
    ),
    'borderWidth': (
      'thin': $emu-semantic-border-width-thin,
      'medium': $emu-semantic-border-width-medium,
      'thick': $emu-semantic-border-width-thick,
      'none': $emu-semantic-border-width-none
    ),
    'outlineWidth': (
      'thin': $emu-semantic-outline-width-thin,
      'medium': $emu-semantic-outline-width-medium,
      'thick': $emu-semantic-outline-width-thick,
      'unset': $emu-semantic-outline-width-unset
    ),
    'sizing': (
      'none': $emu-semantic-sizing-none,
      'xxs': $emu-semantic-sizing-xxs,
      'xs': $emu-semantic-sizing-xs,
      'small': $emu-semantic-sizing-small,
      'medium': $emu-semantic-sizing-medium,
      'large': $emu-semantic-sizing-large,
      'xl': $emu-semantic-sizing-xl,
      'xxl': $emu-semantic-sizing-xxl,
      'breakpoints': (
        'small': $emu-semantic-sizing-breakpoints-small,
        'medium': $emu-semantic-sizing-breakpoints-medium,
        'large': $emu-semantic-sizing-breakpoints-large,
        'large-plus': $emu-semantic-sizing-breakpoints-large-plus,
        'x-large': $emu-semantic-sizing-breakpoints-x-large,
        'xx-large': $emu-semantic-sizing-breakpoints-xx-large
      ),
      'oneLineHeight': (
        'narrow': $emu-semantic-sizing-one-line-height-narrow,
        'wide': $emu-semantic-sizing-one-line-height-wide
      ),
      'twoLineHeight': (
        'narrow': $emu-semantic-sizing-two-line-height-narrow,
        'wide': $emu-semantic-sizing-two-line-height-wide
      ),
      'threeLineHeight': (
        'narrow': $emu-semantic-sizing-three-line-height-narrow,
        'wide': $emu-semantic-sizing-three-line-height-wide
      ),
      'maxWidthText': (
        'narrow': $emu-semantic-sizing-max-width-text-narrow,
        'wide': $emu-semantic-sizing-max-width-text-wide
      )
    ),
    'typography': (
      'narrow': (
        'headings': (
          'xxxl': $emu-semantic-typography-narrow-headings-xxxl,
          'xxl': $emu-semantic-typography-narrow-headings-xxl,
          'xl': $emu-semantic-typography-narrow-headings-xl,
          'large': $emu-semantic-typography-narrow-headings-large,
          'medium': $emu-semantic-typography-narrow-headings-medium
        ),
        'body': (
          'regular': $emu-semantic-typography-narrow-body-regular
        )
      ),
      'wide': (
        'headings': (
          'xxxl': $emu-semantic-typography-wide-headings-xxxl,
          'xxl': $emu-semantic-typography-wide-headings-xxl,
          'xl': $emu-semantic-typography-wide-headings-xl,
          'large': $emu-semantic-typography-wide-headings-large,
          'medium': $emu-semantic-typography-wide-headings-medium
        ),
        'body': (
          'regular': $emu-semantic-typography-wide-body-regular
        )
      )
    )
  )
);
