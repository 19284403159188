.button .emu-button-v2.emu-button {
  &.emu-button {
    &__primary-filled:not(.u-no-gradient-btn) {
      @media (pointer: fine) {
        &:hover {
          .cmp-button__text {
            background: linear-gradient(
                270.32deg,
                rgba(130, 212, 248, 0.6) 21.95%,
                rgba(5, 50, 93, 0) 68.41%,
                rgba(9, 64, 102, 0.6) 99.32%
              ),
              linear-gradient(
                0deg,
                var(--emu-semantic-colors-primary-200),
                var(--emu-semantic-colors-primary-200)
              ); // value from live site
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }

    &__primary-filled,
    &__secondary-filled {
      justify-content: center;
      align-items: center;

      .cmp-button__text {
        position: relative;
        top: 2px;
      }

      &:focus {
        .cmp-button__text {
          text-decoration: underline;
        }
      }
    }

    &__primary-filled {
      &:focus {
        outline: var(--emu-common-border-width-medium) solid
          var(--emu-semantic-colors-primary-100);
        outline-offset: -4px;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); // value from live site

        .cmp-button__text {
          text-decoration-color: var(--emu-semantic-colors-primary-100);
        }
      }
    }

    &__secondary-filled {
      &:focus {
        .cmp-button__text {
          text-decoration-color: var(--emu-common-colors-white);
        }
      }
    }
  }
}
