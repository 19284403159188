html {
  -webkit-text-size-adjust: 100%;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a,
span,
button {
  color: inherit;
  margin: var(--emu-common-spacing-none);
  padding: var(--emu-common-spacing-none);
}

html,
body {
  -webkit-tap-highlight-color: var(--emu-semantic-colors-none);
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  // Adobe adds a negative margin, need to overwrite. Do not remove.
  .xf-content-height {
    margin: inherit;
  }

  .experiencefragment {
    padding: var(--emu-common-spacing-none);
  }
}

body {
  -webkit-overflow-scrolling: touch;
}

* {
  outline: var(--emu-semantic-colors-surface-dark);
}

*,
::before,
::after {
  box-sizing: border-box;
}

.cmp-image__image,
image {
  max-width: 100%;
  width: auto;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
}

sup {
  font-size: 60%;
  line-height: 0;
  position: relative;
  top: 0.1em;
  vertical-align: super;
}

// style from live site
::selection {
  background: #444; // used only once
  color: var(--emu-common-colors-white);
  text-shadow: none;
}

li::marker {
  line-height: 0;
  font-size: 1.3em;
}
