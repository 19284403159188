// set visibility by device/screen size
.desktop-view {
  display: none;

  @include mq('large') {
    display: block;
  }

  @include aem-editor-view {
    display: block;
  }
}

// visible on tablet size & larger
.tablet-desktop-view {
  display: none;

  @include mq('medium') {
    display: block;
  }

  @include aem-editor-view {
    display: block;
  }
}

// visible on tablet size & larger
.tablet-view {
  display: none;

  @include mq('medium') {
    display: block;
  }

  @include mq('large') {
    display: none;
  }

  @include aem-editor-view {
    display: block;
  }
}

// visible on tablet size & smaller
.tablet-mobile-view {
  display: block;

  @include mq('large') {
    display: none;
  }

  @include aem-editor-view {
    display: block;
  }
}

.mobile-view {
  display: block;

  @include mq('medium') {
    display: none;
  }

  @include aem-editor-view {
    display: block;
  }
}

// padding adjustments
.no-pad {
  padding: 0;
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

// content related util classes. Used to center buttons mostly.
.u-content--centered {
  text-align: center;
}

// Utility classes for container max widths.
[class*='u-container--'] {
  margin-left: auto;
  margin-right: auto;
}

.u-container--default {
  max-width: var(--emu-semantic-sizing-breakpoints-large-plus);
  padding-left: 15px;
  padding-right: 15px;
}

.u-container--large {
  max-width: var(--emu-semantic-sizing-breakpoints-x-large);
}

// Utility classes for background colors
.u-bg--white {
  background-color: var(--emu-common-colors-white);
}

.u-bg--black {
  background-color: var(--emu-common-colors-black);
}

.u-bg--light-blue-100 {
  background-color: var(--emu-semantic-colors-primary-100);
}

.u-bg--dark-blue-500 {
  background-color: var(--emu-semantic-colors-primary-500);
}

.u-bg--dark-blue-600 {
  background-color: var(--emu-semantic-colors-primary-600);
}

.u-bg--light-grey-50 {
  background-color: var(--emu-semantic-colors-secondary-50);
}

.u-bg--gradient-blue {
  background: linear-gradient(
    180deg,
    var(--emu-semantic-colors-primary-700) 8.29%,
    var(--emu-semantic-colors-primary-500) 25.17%,
    var(--emu-semantic-colors-primary-100) 78.12%
  ); // value from live site
}

// Utility classes for text colors
[class*='u-text--'] {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  a,
  span,
  button:not(.emu-dropdown-menu__button, .emu-dropdown-menu__item) {
    color: inherit;
  }
}

.u-text--white {
  color: var(--emu-common-colors-white);
}

.u-text--black {
  color: var(--emu-common-colors-black);
}

.u-text--dark-blue-100 {
  color: var(--emu-semantic-colors-primary-100);
}

.u-text--dark-blue-200 {
  color: var(--emu-semantic-colors-primary-200);
}

.u-text--blue-gradient {
  background: -webkit-linear-gradient(
    var(--emu-semantic-colors-primary-400),
    var(--emu-semantic-colors-primary-300)
  );
  color: var(--emu-semantic-colors-primary-100);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  sup {
    background: -webkit-linear-gradient(
      var(--emu-semantic-colors-primary-400),
      var(--emu-semantic-colors-primary-300)
    );
    color: var(--emu-semantic-colors-primary-100);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.u-text--light-grey-300 {
  color: var(--emu-semantic-colors-secondary-300);
}

// Utility classes for specific fonts
[class*='u-body-font'] {
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  li,
  a,
  span,
  button {
    font: inherit;
    color: inherit;
    letter-spacing: inherit;
  }
}

.u-body-font--disclaimer {
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  line-height: var(--emu-semantic-line-heights-narrow-xs);
}

.u-hide-overflow-in-mobile {
  @include mq('1023px', 'max-width') {
    overflow: hidden;
    height: 100vh;
  }
}

.u-hide {
  &:not(.cq-Editable-dom--container &) {
    display: none !important;
  }
}

// only author visible message
.u-author-only {
  padding: var(--emu-common-spacing-large);
  background-color: var(--emu-common-colors-grey-600);
  color: var(--emu-common-colors-white);
  display: none;

  @include aem-editor-view {
    display: block;
  }

  p {
    font-size: var(--emu-semantic-font-sizes-narrow-xxl);
    line-height: 1;

    i {
      font-size: 20px;
    }
  }
}

// common title present across the sites
.u-common-title {
  .cmp-title__text {
    font-size: 28px;
    line-height: 34px;

    // breakpoint needed to match the live site
    @include mq('321px') {
      font-size: var(--emu-semantic-font-sizes-narrow-large);
      line-height: var(--emu-semantic-line-heights-narrow-large);
    }

    // breakpoint needed to match the live site
    @include mq('414px') {
      font-size: 33px;
      line-height: var(--emu-common-line-heights-narrow-xl);
    }

    @include mq('medium') {
      font-size: 64px;
      line-height: 69px; // live site has 69.44. But to match the output more closely this value is being used
    }

    @include mq('large') {
      line-height: 69.7px; // live site has 69.44. But to match the output more closely this value is being used
    }

    @include mq('x-large') {
      font-size: 54px;
      line-height: 58px;
    }
  }
}

.u-common-text {
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  line-height: var(--emu-semantic-line-heights-narrow-small);

  // breakpoint needed to match the live site
  @include mq('414px') {
    font-size: var(--emu-semantic-font-sizes-narrow-medium);
    line-height: 29px;
  }

  @include mq('medium') {
    font-size: 28px;
    line-height: 42px;
  }

  // breakpoint needed to match the live site
  @include mq('1025px') {
    font-size: var(--emu-semantic-font-sizes-wide-small);
    line-height: var(--emu-semantic-line-heights-wide-small);
  }

  p,
  li {
    font-size: inherit;
    line-height: inherit;
  }
}

// used in conjunction with u-common-text, where between 576 and 767 a different font size needs to be added
.u-common-text--lg-in-lg-mobile {
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 22px;
    line-height: 40px;
  }
}
