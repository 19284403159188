#tm-see-results {
  // main banner styles
  .banner {
    &.banner--main {
      .banner {
        &__teaser {
          background-position: center top;
          top: 1px;

          // breakpoint needed to match the live site
          @include mq('321px', 'max-width') {
            height: 104vh;
          }

          // breakpoint needed to match the live site
          @media (min-width: 321px) and (max-width: 575px) {
            height: 105vh;
          }

          // breakpoint needed to match the live site
          @media (min-width: 576px) and (max-width: 767px) {
            height: 99vh;
          }

          @include mq('medium') {
            background-position: center center;
          }

          .emu-teaser__disclaimer {
            padding-left: 30px;
            padding-right: 30px;

            // breakpoint needed to match the live site
            @include mq('321px') {
              padding-bottom: 20px;
            }

            // breakpoint needed to match the live site
            @include mq('576px') {
              padding-left: 40px;
              padding-right: 40px;
            }

            @include mq('medium') {
              padding-left: 90px;
              padding-right: 90px;
            }

            @include mq('large') {
              padding-left: 84px;
              padding-right: 84px;
            }

            @include mq('x-large') {
              padding-left: var(--emu-common-spacing-none);
              padding-right: var(--emu-common-spacing-none);
            }

            sup {
              top: 0.5em;
            }
          }
        }

        &__content {
          height: calc(100% - 121px); // 121 is the space for the disclaimer
          padding-top: 10px;
          padding-right: 37px;
          padding-bottom: 12px;
          padding-left: 30px;
          display: flex;
          align-items: flex-end;

          // breakpoint needed to match the live site
          @include mq('321px') {
            top: 3px;
          }

          // breakpoint needed to match the live site
          @include mq('414px') {
            padding-top: 20px;
            padding-right: 30px;
            padding-bottom: 22px;
            top: 13px;
          }

          // breakpoint needed to match the live site
          @include mq('576px') {
            padding-left: 40px;
            padding-right: 40px;
            top: 0;
            height: calc(100% - 134px);
          }

          @include mq('medium') {
            padding-left: 85px;
            padding-right: 85px;
            padding-bottom: var(--emu-common-spacing-none);
            display: block;
            height: 90%; // calculated as per live site
          }

          @include mq('large') {
            padding-left: 84px;
            padding-right: 84px;
            top: 0;
          }

          @include mq('x-large') {
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
            top: -3px;
          }

          > .container {
            @include mq('medium') {
              display: flex;
              justify-content: center;
              flex-direction: column;
              padding-top: 55%;
              height: 100%;
            }

            @include mq('large') {
              padding-top: 25%;
            }

            // breakpoint needed to match the live site
            @include mq('1199px') {
              padding-left: 108px;
              padding-right: 108px;
              max-width: 1200px;
              margin-left: auto;
              margin-right: auto;
            }

            @include mq('x-large') {
              padding-top: var(--emu-common-spacing-none);
            }
          }

          &-inner {
            @include mq('medium') {
              padding-left: var(--emu-common-spacing-none);
              padding-right: var(--emu-common-spacing-none);
            }

            @include mq('large') {
              margin: var(--emu-common-spacing-none);
            }
          }

          &-img {
            img {
              padding: var(--emu-common-spacing-none);
              max-width: none;
              height: 105px; // value from live site
              width: auto;
              position: relative;
              left: -4%; // value from live site

              // breakpoint needed to match the live site
              @include mq('321px') {
                height: 110px;
                left: -3%;
              }

              // breakpoint needed to match the live site
              @include mq('414px') {
                height: 120px;
              }

              // breakpoint needed to match the live site
              @include mq('576px') {
                height: 179px;
                left: -20px;
              }

              @include mq('medium') {
                height: 215px;
                left: -45px;
                margin-left: var(--emu-common-spacing-none);
                margin-top: 70px;
              }

              @include mq('large') {
                height: 270px;
              }

              @include mq('x-large') {
                height: 240px;
                left: -100px;
                margin-top: var(--emu-common-spacing-none);
              }
            }
          }

          &-title {
            padding: var(--emu-common-spacing-none);
            margin-top: -13px;
            position: relative;

            // breakpoint needed to match the live site
            @include mq('321px') {
              margin-top: -12px;
            }

            // breakpoint needed to match the live site
            @include mq('576px') {
              margin-top: 14px;
            }

            @include mq('medium') {
              margin-top: -36px;
            }

            @include mq('large') {
              margin-top: -49px;
            }

            @include mq('x-large') {
              margin-top: -30px;
            }

            .cmp-title__text {
              // breakpoint needed to match the live site
              @include mq('321px') {
                font-size: var(--emu-semantic-font-sizes-narrow-large);
                line-height: var(--emu-semantic-line-heights-narrow-large);
              }

              // breakpoint needed to match the live site
              @include mq('414px') {
                font-size: 33px;
                line-height: var(--emu-common-line-heights-narrow-xl);
              }

              // breakpoint needed to match the live site
              @include mq('576px') {
                font-size: 46px;
                line-height: 50px;
              }

              @include mq('medium') {
                font-size: 64px;
                line-height: 69px;
              }

              @include mq('x-large') {
                font-size: 54px;
                line-height: 58px;
              }
            }
          }

          &-text {
            margin-top: 12px;
            padding: var(--emu-common-spacing-none);

            // breakpoint needed to match the live site
            @include mq('321px') {
              margin-top: 17px;
              width: 95%;
              margin-left: var(--emu-common-spacing-none);
            }

            // breakpoint needed to match the live site
            @include mq('414px') {
              width: 90%;
              font-size: 16px;
              line-height: 29px;
              margin-top: 7px;
            }

            // breakpoint needed to match the live site
            @include mq('576px') {
              font-size: 22px;
              line-height: var(--emu-semantic-font-sizes-narrow-xxl);
              width: 78%;
              margin-top: 21px;
            }

            @include mq('medium') {
              margin-top: 22px;
              margin-bottom: var(--emu-common-spacing-medium);
              font-size: 28px;
              line-height: 42px;
              width: 84%;
            }

            @include mq('large') {
              margin-top: 22px;
              margin-bottom: var(--emu-common-spacing-none);
              width: 570px;
            }

            @include mq('x-large') {
              font-size: 24px;
              line-height: 31px;
              width: 45%;
              margin-top: 17px;
            }

            &-container {
              @include mq('1199px') {
                padding-right: var(--emu-common-spacing-none);
              }
            }

            sup {
              top: 0.2em;
            }
          }
        }
      }
    }
  }

  // Find a Provider banner
  .banner {
    &.banner--fap {
      .banner {
        &__teaser {
          // breakpoint needed to match the design
          @include mq('320px', 'max-width') {
            height: 715px; // value from live site
          }

          // breakpoint needed to match the design
          @media (min-width: 321px) and (max-width: 413px) {
            height: 825px; // value from live site
          }

          // breakpoint needed to match the design
          @media (min-width: 414px) and (max-width: 767px) {
            height: 900px; // value from live site
          }

          .emu-teaser__disclaimer {
            font-size: 12px;
            padding-left: calc(15px + 4%); // calculated value from live site
            padding-right: calc(15px + 4%); // calculated value from live site

            @include mq('large') {
              // padding-left: 70px;
              // padding-right: 70px;
            }
          }
        }

        &__content {
          @include mq('large') {
            top: 0;
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
            padding-top: 6px;
            margin-left: -15px;
            margin-right: -15px;
            width: auto;
          }

          // breakpoint needed to match the live site
          @include mq('1025px') {
            top: 15%; // value from live site
            max-width: var(--emu-common-sizing-large-tablet);
            margin-left: auto;
            margin-right: auto;
          }

          // breakpoint needed to match the live site
          @include mq('1401px') {
            padding-top: 10px;
          }

          // breakpoint needed to match the live site
          @include mq('1601px') {
            padding-top: 7px;
          }

          // breakpoint needed to match the live site
          @include mq('2001px') {
            padding-top: var(--emu-common-spacing-small);
          }

          &-inner {
            @include mq('medium') {
              padding-top: 90%;
            }

            @include mq('large') {
              padding-top: 85%;
            }

            @include mq('x-large') {
              padding-top: 17.7%; // value from live site
            }

            // breakpoint needed to match the live site
            @include mq('1401px') {
              padding-top: 23.57%; // value from live site
            }

            // breakpoint needed to match the live site
            @include mq('1601px') {
              padding-top: 28%; // value from live site
            }
          }

          &-title {
            .cmp-title__text {
              // breakpoint needed to match the live site
              @include mq('1025px') {
                font-size: 64px;
                line-height: 69px;
              }

              @include mq('x-large') {
                font-size: var(--emu-semantic-font-sizes-wide-xl);
                line-height: var(--emu-semantic-line-heights-wide-xl);
              }
            }
          }

          &-text {
            @include mq('large') {
              margin-top: 12px;
              max-width: 640px;
            }

            // breakpoint needed to match the live site
            @include mq('1025px') {
              font-size: 28px;
              line-height: 42px;
            }

            @include mq('x-large') {
              font-size: 24px;
              line-height: 31px;
              max-width: 700px;
              margin-top: 11px;
            }

            // breakpoint needed to match the live site
            @include mq('1601px') {
              margin-top: 12px;
            }
          }

          &-cta-container {
            @include mq('medium') {
              margin-top: 23px;
            }
          }
        }
      }
    }
  }
}
