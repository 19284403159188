// modal styles
.emu-modal.relative-modal.checklist-modal {
  margin-top: -3px;

  @include mq('medium') {
    margin-top: -5px;
  }

  .checklist-content {
    @include mq('x-large') {
      display: flex;
      padding-top: 19px;
      padding-right: 26px;
      padding-bottom: 33px;
    }

    > .container {
      @include mq('x-large') {
        width: 50%;
      }
    }

    &__section {
      &--left {
        @include mq('x-large') {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }

    &__title {
      margin-top: 3px;
      margin-bottom: 7px;
      color: #265bb3; // no variable available for this

      // breakpoint needed to match the live site
      @include mq('414px') {
        margin-bottom: 5px;
      }

      @include mq('medium') {
        margin-top: 6px;
        margin-bottom: 9px;
      }

      @include mq('x-large') {
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-xs);
      }

      .cmp-title__text {
        // breakpoint needed to match the live site
        @include mq('413px', 'max-width') {
          font-size: 35px;
          line-height: 38px;
        }

        @include mq('medium') {
          line-height: 1;
        }

        @include mq('x-large') {
          line-height: var(--emu-semantic-line-heights-wide-xl);
        }
      }
    }

    &__description {
      font-size: 13px;
      line-height: 26px;
      color: #7e7e7e; // no variable for this
      margin-bottom: 15px;

      // breakpoint needed to match the live site
      @include mq('414px') {
        font-size: 12px;
        line-height: 22px;
      }

      @include mq('medium') {
        font-size: 20px;
        line-height: 34.6px; // value from live site
        margin-bottom: 43px;
        padding-right: 40%;
        font-weight: var(--emu-common-font-weight-bold);
      }

      @include mq('x-large') {
        font-weight: var(--emu-common-font-weight-regular);
        font-size: 15px;
        line-height: 33px;
        padding-right: var(--emu-common-spacing-none);
      }

      br {
        display: none;

        @include mq('medium') {
          display: block;
        }

        @include mq('x-large') {
          display: none;
        }
      }

      p,
      li {
        font-size: inherit;
        line-height: inherit;
        color: inherit;
        font-weight: inherit;
      }

      a {
        &,
        &:focus,
        &:active,
        &:hover {
          text-decoration: none;
          color: var(--emu-semantic-colors-primary-950);
        }
      }
    }

    &__list {
      font-size: 13px;
      line-height: 18px;
      color: var(--emu-semantic-colors-secondary-600);

      // breakpoint needed to match the live site
      @include mq('414px') {
        font-size: 12px;
        line-height: 22px;
      }

      @include mq('medium') {
        font-size: 24px;
        line-height: 48.36px; // value from live site
        font-weight: var(--emu-common-font-weight-bold);
      }

      @include mq('x-large') {
        font-size: 15px;
        line-height: 21px; // value from live site
        font-weight: var(--emu-common-font-weight-regular);
        margin-top: var(--emu-common-spacing-xxs);
        letter-spacing: 0.085px; // to match the live site more closer
      }

      sup {
        @include mq('x-large') {
          top: 3px;
        }
      }

      li,
      p {
        font-size: inherit;
        line-height: inherit;
        color: inherit;
        font-weight: inherit;
        letter-spacing: inherit;
      }

      ul {
        padding: var(--emu-common-spacing-none);
        margin: var(--emu-common-spacing-none);
        list-style: none;
      }

      li {
        margin-bottom: 29px;
        padding-left: 48px;
        position: relative;

        @include mq('medium') {
          padding-left: 60px;
          margin-bottom: 20px;
        }

        @include mq('x-large') {
          padding-left: 48px;
          margin-bottom: 14.4px; // calculated value to match the live site
        }

        &::before {
          content: '';
          width: 28px;
          height: 24px;
          position: absolute;
          left: 0;
          top: -1px;
          background-image: url('../../assets/images/checked-icon.png');
          background-size: contain;
          background-repeat: no-repeat;

          @include mq('medium') {
            width: 40px;
            height: 35px;
            top: 50%;
            transform: translateY(-50%);
          }

          @include mq('x-large') {
            width: 28px;
            height: 24px;
            top: -1px;
            transform: none;
          }
        }
      }
    }
  }
}
