.banner {
  &.banner--main {
    overflow: hidden;

    .banner {
      &__teaser {
        height: 565px;
        background-position: center;

        // breakpoint needed to match the live site
        @include mq('321px') {
          height: 700px;
        }

        // breakpoint needed to match the live site
        @include mq('414px') {
          height: 775px;
        }

        @include mq('medium') {
          height: 1432px;
          background-position: 50% 0;
        }

        @include mq('large') {
          height: 1910px;
        }

        @include mq('x-large') {
          height: 656px;
          background-position: center;
        }

        // breakpoint needed to match the live site
        @include mq('1401px') {
          height: 660px;
        }

        // breakpoint needed to match the live site
        @include mq('1601px') {
          height: 750px;
        }

        // breakpoint needed to match the live site
        @include mq('2001px') {
          height: 900px;
        }

        .disclaimer-bottom-right {
          .emu-teaser__disclaimer {
            @include mq('medium') {
              padding-bottom: 20px;
            }

            // breakpoint needed to match the live site
            @include mq('1025px') {
              padding-bottom: 10px;
            }
          }
        }
      }

      &__content {
        padding-top: 42%; // value from live site
        top: 0;

        // breakpoint needed to match with live site
        @include mq('350px') {
          padding-top: 70%; // value from live site
        }

        @include mq('medium') {
          padding-top: 48%; // value from live site
        }

        @include mq('large') {
          padding-top: 72%; // value from live site
        }

        // breakpoint needed to match with live site
        @include mq('1025px') {
          padding-left: 15px;
          padding-top: var(--emu-common-spacing-medium);
          position: absolute;
          top: 5%; // value from live site
          left: 0;
          width: 100%;
        }

        @include mq('x-large') {
          padding-left: var(--emu-common-spacing-none);
        }

        &-inner {
          @include mq('medium') {
            padding-left: 90px;
            padding-right: 90px;
          }

          // breakpoint needed to match with live site
          @include mq('1025px') {
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
            max-width: var(--emu-common-sizing-desktop);
            margin-left: auto;
            margin-right: auto;
          }

          @include mq('x-large') {
            padding-left: 73px;
            padding-right: 73px;
          }
        }

        &-img {
          &-container {
            padding-top: 15px;
            padding-right: 10px;
            padding-bottom: 15px;
            padding-left: 10px;

            @include mq('576px') {
              padding-bottom: var(--emu-common-spacing-none);
              padding-top: var(--emu-common-spacing-none);
            }

            @include mq('medium') {
              display: flex;
              align-items: center;
              justify-content: center;
              padding-left: 10%; // value from live site
              padding-right: 10%; // value from live site
            }

            > .image {
              display: inline-block;
              vertical-align: middle;
            }
          }

          img {
            max-width: 65%; // value from live site
            padding-right: 15px;
            padding-left: 30px;

            // breakpoint needed to match the live site
            @include mq('350px') {
              padding-left: 45px;
              padding-right: var(--emu-common-spacing-none);
              max-width: 60%; // value from live site
            }

            // breakpoint needed to match the live site
            @include mq('414px') {
              max-width: 65%; // value from live site
            }

            @include mq('medium') {
              padding-left: var(--emu-common-spacing-none);
              padding-right: var(--emu-common-spacing-none);
              height: 296px;
              width: auto;
              margin-left: -25px;
              max-width: none;
            }

            // breakpoint needed to match the live site
            @include mq('1025px') {
              margin-left: var(--emu-common-spacing-none);
              height: auto;
              max-width: 600px; // nominal value to make sure that things are not breaking
            }
          }
        }

        &-title {
          padding-right: 10px;
          margin-top: -28px;
          padding-left: 30px;
          max-width: none;

          // breakpoint needed to match the live site
          @include mq('350px') {
            padding-left: 45px;
            margin-top: -23px;
          }

          // breakpoint needed to match the live site
          @include mq('414px') {
            margin-top: -26px;
          }

          @include mq('medium') {
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
            margin-top: -18px;
          }

          // breakpoint needed to match the live site
          @include mq('1025px') {
            padding-left: 35px;
            margin-top: -95px;
          }

          @include aem-editor-view {
            margin-top: var(--emu-common-spacing-none) !important;
          }

          .cmp-title__text {
            // breakpoint needed to match the design
            @media (min-width: 321px) and (max-width: 349px) {
              font-size: 28px;
              line-height: 34px;
            }

            @include mq('medium') {
              margin-top: var(--emu-common-spacing-none);
            }
          }

          br {
            &:nth-child(2) {
              // breakpoint needed to match the design
              @include mq('767px') {
                display: none;
              }
            }
          }
        }

        &-text {
          padding-left: 30px;
          padding-right: 15px;
          margin-top: 7px;
          max-width: none;

          // breakpoint needed to match the live site
          @include mq('350px') {
            padding-left: 45px;
          }

          @include mq('medium') {
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
            margin-top: 13px;
          }

          // breakpoint needed to match the live site
          @include mq('1025px') {
            padding-left: 35px;
          }

          &-container {
            padding-left: var(--emu-common-spacing-none);
          }

          br {
            display: none;

            @include mq('large') {
              display: block;
            }
          }

          sup {
            top: 2px;
          }
        }

        &-cta-container {
          padding-left: 35px;
          justify-content: flex-start;
          margin-top: 15px;

          // media query needed to match design
          @include mq('321px') {
            padding-left: 45px;
          }

          @include mq('medium') {
            padding-left: var(--emu-common-spacing-none);
            margin-top: 22px;
          }

          // breakpoint needed to match the live site
          @include mq('1025px') {
            padding-left: 35px;
            margin-top: 13px;
          }
        }

        &-cta.emu-button-v2.emu-button.emu-button-v2.emu-button {
          font-size: 114%; // value from live site
          letter-spacing: 0.1px;
          padding-top: var(--emu-common-spacing-none);
          padding-bottom: var(--emu-common-spacing-none);
          padding-left: 39px;
          padding-right: 39px;
          height: 59px;
          line-height: 59px;

          // media query needed to match design
          @include mq('321px') {
            height: 54px;
            line-height: var(--emu-common-line-heights-narrow-xxxl);
          }

          // media query needed to match design
          @include mq('414px') {
            height: 59px;
            line-height: 59px;
          }

          @include mq('medium') {
            font-size: 24px;
            padding-left: 43px;
            padding-right: 43px;
          }

          .cmp-button__text {
            top: 0;
          }
        }
      }
    }
  }
}
