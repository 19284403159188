.banner {
  position: relative;
  z-index: var(--emu-common-other-z-index-layer);

  &--has-additional-img {
    &.banner--has-sm-img {
      .banner__teaser {
        &:not(.cq-Editable-dom--container &) {
          // breakpoint needed to match the live site
          @media (min-width: 768px) and (max-width: 1023px) {
            background-image: none !important;
          }
        }
      }
    }

    &.banner--has-md-img {
      .banner__teaser {
        &:not(.cq-Editable-dom--container &) {
          // breakpoint needed to match the live site
          @media (min-width: 1024px) and (max-width: 1199px) {
            background-image: none !important;
          }
        }
      }
    }

    &.banner--has-1401-img {
      .banner__teaser {
        &:not(.cq-Editable-dom--container &) {
          // breakpoint needed to match the live site
          @include mq('1401px') {
            background-image: none !important;
          }
        }
      }
    }

    .banner__img {
      &.banner__img--1401 {
        img {
          // breakpoint needed to match the live site
          @include mq('1401px') {
            display: block;
            object-position: center center;
          }
        }
      }

      &.banner__img--sm {
        img {
          // breakpoint needed to match the live site
          @media (min-width: 768px) and (max-width: 1023px) {
            display: block;
          }
        }
      }

      &.banner__img--md {
        img {
          // breakpoint needed to match the live site
          @media (min-width: 1024px) and (max-width: 1199px) {
            display: block;
          }
        }
      }

      img {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;

        // authoring styles
        @include aem-editor-view {
          position: static;
          display: block;
          max-height: 600px !important;
          object-fit: contain !important;
          object-position: center !important;
        }
      }
    }
  }

  &__teaser {
    background-size: cover;
    background-repeat: no-repeat;
    height: 650px;

    // authoring styles
    @include aem-editor-view {
      background-position: center !important;
    }

    // breakpoint needed to match the live site
    @include mq('321px') {
      height: 765px;
    }

    // breakpoint needed to match the live site
    @include mq('414px') {
      height: 845px;
    }

    @include mq('medium') {
      height: 1565px;
      background-position: bottom;
    }

    @include mq('large') {
      height: 2080px;
    }

    @include mq('x-large') {
      height: 800px;
      background-position: initial;
    }

    // breakpoint needed to match the live site
    @include mq('1401px') {
      height: 850px;
    }

    // breakpoint needed to match the live site
    @include mq('1601px') {
      height: 1100px;
    }

    // breakpoint needed to match the live site
    @include mq('2001px') {
      height: 1400px;
    }

    .content-container {
      position: static;
    }

    .disclaimer-bottom-right {
      .emu-teaser__disclaimer {
        text-align: center;
        font-size: 10px;
        font-weight: var(--emu-semantic-font-weight-500);
        line-height: 135%; // value from live site
        text-shadow: -1px 2px 9px rgba(0, 0, 0, 0.8);
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        letter-spacing: 0.025px; // to match the live site more closely

        p {
          font-size: inherit;
          line-height: inherit;
          text-shadow: inherit;
          letter-spacing: inherit;

          sup {
            font-size: 60%;
            top: 1px;

            @include mq('x-large') {
              top: 2px;
            }
          }
        }
      }
    }
  }

  &__content {
    position: absolute;
    top: 40%;
    left: 0;
    width: 100%;
    padding-top: 3px;

    // authoring styles
    @include aem-editor-view {
      position: static !important;
      padding-top: var(--emu-common-spacing-none) !important;
    }

    // breakpoint needed to match the live site
    @include mq('414px') {
      padding-top: var(--emu-common-spacing-xs);
    }

    @include mq('medium') {
      padding-top: 6px;
    }

    // breakpoint needed to match the live site
    @include mq('1025px') {
      top: 35%; // value from live site
    }

    @include mq('large') {
      padding-top: 5px;
    }

    @include aem-editor-view {
      position: static;
      background-color: var(--emu-semantic-colors-primary-100);
    }

    > .container {
      width: 100%;

      // authoring styles
      @include aem-editor-view {
        position: static !important;
        padding-top: var(--emu-common-spacing-none) !important;
      }
    }

    &-inner {
      // authoring styles
      @include aem-editor-view {
        position: static !important;
        padding-top: var(--emu-common-spacing-none) !important;
      }
    }

    &-title {
      max-width: 475px;
      margin-left: auto;
      margin-right: auto;

      @include mq('medium') {
        max-width: 550px;
      }

      .cmp-title__text {
        font-size: 28px;
        line-height: 34px;
        letter-spacing: 0;
        font-weight: var(--emu-common-font-weight-black);

        // breakpoint needed to match the live site
        @include mq('321px') {
          font-size: var(--emu-semantic-font-sizes-narrow-large);
          line-height: var(--emu-semantic-line-heights-narrow-large);
        }

        // breakpoint needed to match the live site
        @include mq('414px') {
          font-size: 33px;
          line-height: var(--emu-common-line-heights-narrow-xl);
        }

        @include mq('medium') {
          font-size: 64px;
          line-height: 69px;
        }

        // breakpoint needed to match the live site
        @include mq('1025px') {
          font-size: var(--emu-semantic-font-sizes-wide-xl);
          line-height: var(--emu-semantic-font-sizes-wide-xxl);
        }
      }
    }

    &-text {
      font-size: var(--emu-semantic-font-sizes-narrow-small);
      line-height: var(--emu-semantic-line-heights-narrow-small);
      padding-left: 25px;
      padding-right: 25px;
      margin-top: 12px;
      margin-left: auto;
      margin-right: auto;
      max-width: 575px;

      @include mq('medium') {
        max-width: 730px;
      }

      // breakpoint needed to match the live site
      @include mq('414px') {
        font-size: var(--emu-semantic-font-sizes-narrow-medium);
        line-height: 29px;
      }

      @include mq('medium') {
        font-size: 28px;
        line-height: 42px;
        margin-top: 15px;
      }

      // breakpoint needed to match the live site
      @include mq('1025px') {
        font-size: var(--emu-semantic-font-sizes-wide-small);
        line-height: var(--emu-semantic-line-heights-wide-small);
      }

      > *:not(sup) {
        font-size: inherit;
        line-height: inherit;
      }

      br {
        display: none;

        @include mq('medium') {
          display: block;
        }
      }

      sup {
        font-size: 60%;
        top: 1px;

        @include mq('medium') {
          top: 2px;
        }
      }

      &-container {
        @include mq('large') {
          padding-left: 15px;
        }

        // breakpoint needed to match the live site
        @include mq('1199px') {
          padding-right: 73px;
        }

        // breakpoint needed to match the live site
        @include mq('x-large') {
          padding-left: 73px;
        }
      }
    }

    &-cta {
      &-container {
        display: flex;
        justify-content: center;
        margin-top: 14px;

        // authoring styles
        @include aem-editor-view {
          display: block !important;
        }

        > .button {
          // authoring styles
          @include aem-editor-view {
            width: fit-content;
            margin-left: auto;
            margin-right: auto;
          }
        }

        // breakpoint needed to match the live site
        @include mq('414px') {
          margin-top: 13px;
        }

        @include mq('medium') {
          margin-top: 25px;
        }

        @include mq('1025px') {
          margin-top: 10px;
        }
      }
    }
  }
}
