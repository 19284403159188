header.experiencefragment {
  position: relative;
  z-index: var(--emu-common-other-z-index-header);

  div.header {
    @include mq('large') {
      padding-top: 11px;
    }

    @include mq('x-large') {
      max-width: var(--emu-semantic-sizing-breakpoints-large-plus);
      margin-left: auto;
      margin-right: auto;
    }

    .skip-menu {
      @include mq('large') {
        display: none;
      }
    }

    .header {
      &__content {
        padding-top: 10px;
        padding-right: 15px;
        padding-bottom: 6px;
        padding-left: 15px;

        // breakpoint needed to match the live site
        @include mq('414px') {
          padding-bottom: 7px;
        }

        @include mq('medium') {
          padding-bottom: 17px;
        }

        @include mq('large') {
          padding-bottom: var(--emu-common-spacing-none);
        }

        @include mq('x-large') {
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
        }
      }

      &__menu-mobile {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @include mq('large') {
          display: none;
        }

        > .image {
          max-width: 60%;
        }
      }

      &__eyebrow-text {
        p {
          color: var(--emu-semantic-colors-secondary-500);
          font-size: 7px;
          line-height: 8px;
          margin-top: 21px;
          font-family: var(--emu-semantic-font-families-heading);
          font-weight: var(--emu-semantic-font-weight-regular);

          // breakpoint needed to match the live site
          @include mq('414px') {
            font-size: 8px;
          }

          @include mq('large') {
            margin-top: var(--emu-common-spacing-none);
            font-size: 8px;
            line-height: 9px;
            padding-bottom: var(--emu-common-spacing-small);
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
          }

          .emphasis {
            letter-spacing: -0.7px; // to match live site.

            // breakpoint needed to match the live site
            @include mq('414px') {
              letter-spacing: -0.8px; // to match live site.
            }

            @include mq('medium') {
              letter-spacing: 0.16px; // to match live site.
            }

            &:first-child {
              margin-right: 5px;

              @include mq('medium') {
                margin-right: 10px;
              }
            }

            &:last-child {
              margin: var(--emu-common-spacing-none);
              margin-left: var(--emu-common-spacing-xs);

              @include mq('medium') {
                margin-left: 10px;
              }
            }
          }
        }
      }

      .header__menu-trigger {
        padding: var(--emu-common-spacing-none);
        background-color: var(--emu-common-colors-transparent);
        padding-top: var(--emu-common-spacing-small);
        padding-bottom: var(--emu-common-spacing-small);
        border: var(--emu-common-border-width-none);
        outline: var(--emu-common-outline-width-unset);
        position: relative;
        left: 1px;

        &:hover,
        &:focus,
        &:active {
          background-color: var(--emu-common-colors-transparent);
        }

        &:active {
          outline: var(--emu-common-outline-width-unset);
        }

        span {
          background: var(--emu-semantic-colors-secondary-300);
          border-radius: var(--emu-common-border-radius-xxs);
          width: 28px; // to match live site.
          height: 2px; // to match live site.

          &::before,
          &::after {
            content: '';
            border-radius: 1px; // to match live site.
            background: inherit;
            height: inherit;
            width: inherit;
            position: absolute;
            right: 0;
          }

          &::before {
            top: 1px;
          }

          &::after {
            bottom: 1px;
          }
        }

        .cmp-button__text {
          display: none;
        }
      }

      .aaaem-navigation {
        display: none;

        &__global-navigation-close-button {
          .header__menu-close {
            padding: var(--emu-common-spacing-none);
            border-width: var(--emu-common-border-width-none);
            background-color: var(--emu-common-colors-transparent);

            &:hover,
            &:focus,
            &:active {
              border-width: var(--emu-common-border-width-none);
              background-color: var(--emu-common-colors-transparent);
              outline: var(--emu-common-outline-width-unset);
            }

            span {
              width: 35px; // to match live site.
              height: 35px; // to match live site.
              display: block;
              position: relative;

              &::before,
              &::after {
                content: '';
                background-color: var(--emu-common-colors-white);
                width: 80%;
                height: var(--emu-common-border-width-medium);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(45deg);
              }

              &::after {
                transform: translate(-50%, -50%) rotate(-45deg);
              }
            }

            .cmp-button__text {
              display: none;
            }
          }
        }

        &__content-wrapper {
          @include mq('large') {
            display: block;
          }

          &.js-is-open {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: var(--emu-common-other-z-index-layer);
            background-color: var(--emu-semantic-colors-primary-900);
            overflow-y: auto;

            @include mq('large') {
              position: relative;
              background-color: var(--emu-semantic-colors-none);
              overflow: visible;
            }

            .header {
              &__eyebrow-text {
                p {
                  color: var(--emu-common-colors-white);
                }
              }

              &__menu-mobile-dropdown {
                .aaaem-image img {
                  filter: brightness(0) invert(1); // As per the live site.

                  @include mq('large') {
                    filter: none;
                  }
                }
              }
            }

            .aaaem-navigation__global-navigation-close-button {
              .aaaem-button {
                position: absolute;
                top: 9px;
                right: 9px;
              }
            }
          }
        }

        &__global-navigation-close-button {
          @include mq('large') {
            display: none;
          }
        }

        &__prenav-content {
          padding-top: 10px;
          padding-right: 15px;
          padding-left: 15px;

          @include mq('large') {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-top: var(--emu-common-spacing-none);
          }

          @include mq('x-large') {
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
          }

          > .container {
            @include mq('large') {
              width: 16.667%; // value from live site
              padding-right: 25px;
            }

            @include mq('x-large') {
              width: 25%;
              padding-right: 25px;
              margin-top: -2px; // needed to match live site
            }
          }

          .header__eyebrow-text {
            @include mq('large') {
              display: none;
            }
          }
        }
      }
    }
  }
}
