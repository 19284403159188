// block of title and text present in How CS Works page beneath the main banner
// Also a variation of it is present with blue background in the same page beneath the FDA Tabs and in

.intro-block {
  &__inner-container {
    padding-top: 103px;
    padding-left: 30px;
    padding-bottom: 95px;
    padding-right: 30px;
    max-width: var(--emu-common-sizing-tablet);
    margin-left: auto;
    margin-right: auto;

    // breakpoint needed to match the live site
    @include mq('414px') {
      padding-top: 105px;
      padding-bottom: 93px;
    }

    @include mq('medium') {
      padding-top: 150px;
      padding-right: 65px;
      padding-bottom: 143px;
      padding-left: 65px;
    }

    // breakpoint needed to match the live site
    @include mq('1025px') {
      padding-top: 102px;
      padding-right: 30px;
      padding-bottom: 93px;
      padding-left: 30px;
    }

    @include mq('x-large') {
      max-width: var(--emu-common-sizing-large-tablet);
    }
  }

  &__title {
    margin-bottom: 17px;

    @include mq('medium') {
      margin-bottom: var(--emu-common-spacing-xxs);
    }

    // breakpoint needed to match the live site
    @include mq('1025px') {
      margin-bottom: 12px;
    }

    .cmp-title__text {
      font-size: 28px;
      line-height: 34px;
      font-weight: var(--emu-common-font-weight-black);
      font-family: var(--emu-semantic-font-families-heading);

      // breakpoint needed to match the live site
      @include mq('321px') {
        font-size: var(--emu-semantic-font-sizes-narrow-large);
        line-height: var(--emu-semantic-line-heights-narrow-large);
      }

      // breakpoint needed to match the live site
      @include mq('414px') {
        font-size: 33px;
        line-height: var(--emu-common-line-heights-narrow-xl);
      }

      // breakpoint needed to match the live site
      @include mq('576px') {
        font-size: 46px;
        line-height: 50px;
      }

      @include mq('medium') {
        font-size: 64px;
        line-height: 77px;
      }

      // breakpoint needed to match the live site
      @include mq('1025px') {
        font-size: var(--emu-semantic-font-sizes-wide-xl);
        line-height: 65px;
        text-align: center;
      }

      br {
        @include mq('x-large') {
          display: none;
        }
      }
    }
  }

  &__text {
    max-width: 670px;

    // breakpoint needed to match the live site
    @include mq('414px') {
      font-size: var(--emu-semantic-font-sizes-narrow-medium);
      line-height: 29px;
    }

    // breakpoint needed to match the live site
    @include mq('576px') {
      font-size: 22px;
      line-height: 40px;
    }

    @include mq('medium') {
      font-size: 28px;
      line-height: 42px;
    }

    // breakpoint needed to match the live site
    @include mq('1025px') {
      font-size: 24px;
      line-height: 31px;
      text-align: center;
      max-width: none;
      padding-left: 65px;
      padding-right: 65px;
    }

    * {
      font-size: inherit;
      line-height: inherit;
    }

    sup {
      font-size: 60%;
      line-height: 0;
    }
  }
}

//
.intro-block {
  &.intro-block--blue-bg {
    .intro-block {
      &__inner-container {
        padding-top: 16.5%; // needed to match live site
        padding-right: 10%; // value from live site
        padding-bottom: 16.5%; // needed to match live site
        padding-left: 10%; // value from live site
        margin-left: -25px;
        margin-right: -25px;

        // breakpoint needed to match the live site
        @include mq('321px') {
          margin-left: -10px;
          margin-right: -10px;
          padding-top: 15%; // value from live site
          padding-bottom: 15%; // value from live site
        }

        @include mq('medium') {
          margin-left: auto;
          margin-right: auto;
          padding-top: 105px;
          padding-right: 50px;
          padding-bottom: 97px;
          padding-left: 50px;
        }

        // breakpoint needed to match the live site
        @include mq('1025px') {
          padding-top: 93px;
          padding-bottom: 89px;
        }

        @include mq('x-large') {
          padding-top: 93px;
          padding-right: 80px;
          padding-bottom: 89px;
          padding-left: 80px;
        }
      }

      &__title {
        margin-bottom: 43px;
        margin-top: 1px;
        // breakpoint needed to match the live site
        @include mq('321px') {
          margin-top: var(--emu-common-spacing-xxs);
        }

        // breakpoint needed to match the live site
        @include mq('575px') {
          margin-bottom: 44px;
        }
        @include mq('medium') {
          max-width: 640px;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 18px;
          margin-top: var(--emu-common-spacing-none);
        }

        // breakpoint needed to match the live site
        @include mq('1025px') {
          max-width: 750px;
        }

        @include mq('x-large') {
          max-width: none;
        }

        .cmp-title__text {
          font-family: var(--emu-semantic-font-families-body);
          font-weight: var(--emu-common-font-weight-regular);

          // breakpoint needed to match the live site
          @media (min-width: 575px) and (max-width: 767px) {
            font-size: 46px;
            line-height: 50px;
          }
        }
      }

      &__text {
        // breakpoint needed to match the live site
        @media (min-width: 575px) and (max-width: 767px) {
          font-size: 22px;
          line-height: 40px;
        }

        @include mq('medium') {
          max-width: 650px;
          margin-left: auto;
          margin-right: auto;
        }

        // breakpoint needed to match the live site
        @include mq('1025px') {
          max-width: 750px;
          line-height: 38px;
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
        }

        @include mq('x-large') {
          max-width: none;
        }
      }
    }
  }
}
