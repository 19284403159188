#get-savings {
  // main banner styles
  .banner {
    &.banner--main {
      .banner {
        &__teaser {
          background-position: initial;

          @include mq('medium') {
            background-position: 0 30%;
          }

          @include mq('x-large') {
            background-position: initial;
          }
        }

        &__img.banner__img--1401 img {
          object-position: center left;

          @include mq('1601px') {
            object-position: center top;
          }
        }

        &__content {
          padding-top: var(--emu-common-spacing-none);
          display: flex;
          align-items: flex-end;
          padding-top: 10px;
          padding-bottom: 10px;
          padding-left: 8.8px; // value from live site
          padding-right: 8.8px; // value from live site
          height: 100%;
          top: 0;

          @include mq('321px') {
            padding-left: 6.3px; // value from live site
            padding-right: 6.3px; // value from live site
          }

          @include mq('414px') {
            padding-top: 20px;
            padding-bottom: 18px;
            padding-left: var(--emu-common-spacing-xs);
            padding-right: var(--emu-common-spacing-xs);
          }

          // breakpoint needed to match the live site
          @include mq('576px') {
            padding-top: 30px;
            padding-right: 20px;
            padding-bottom: 28px;
            padding-left: 20px;
          }

          @include mq('medium') {
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
            justify-content: center;
            align-items: center;
            padding-top: 26%; // value from live site
            padding-bottom: 95px;
          }

          @include mq('large') {
            padding-top: 16%; // value from live site
          }

          @include mq('x-large') {
            padding-top: var(--emu-common-spacing-none);
            padding-bottom: 79px;
            align-items: flex-end;
          }

          &-inner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @include mq('medium') {
              padding-left: 10%; // value from live site
              padding-right: 10%; // value from live site
            }

            @include mq('x-large') {
              padding-left: var(--emu-common-spacing-none);
              padding-right: var(--emu-common-spacing-none);
            }
          }

          &-img {
            margin: var(--emu-common-spacing-none);

            @include mq('medium') {
              position: relative;
              left: -40px;
            }

            img {
              position: relative;
              left: 0;
              width: auto;
              padding: var(--emu-common-spacing-none);
              margin: var(--emu-common-spacing-none);
              max-width: none;
            }

            &--join {
              img {
                height: 80px;

                // breakpoint needed to match the live site
                @include mq('321px') {
                  height: 100px;
                  top: -1px;
                }

                // breakpoint needed to match the live site
                @include mq('414px') {
                  height: 110px;
                  top: -5px;
                }

                // breakpoint needed to match the live site
                @include mq('576px') {
                  height: 140px;
                  top: -2px;
                }

                @include mq('medium') {
                  height: auto;
                  width: auto;
                  top: -1px;
                }

                @include mq('x-large') {
                  top: 2px;
                  left: -3px;
                }

                // breakpoint needed to match the live site
                @include mq('1401px') {
                  left: -2px;
                }
              }
            }

            &--alle {
              img {
                height: 60px;
                left: 10px;

                // breakpoint needed to match the live site
                @include mq('321px') {
                  height: 75px;
                  top: -2px;
                }

                // breakpoint needed to match the live site
                @include mq('414px') {
                  top: -6px;
                  left: 15px;
                }

                // breakpoint needed to match the live site
                @include mq('576px') {
                  height: 120px;
                  top: -2px;
                }

                @include mq('medium') {
                  height: auto;
                  width: auto;
                  top: -1px;
                }

                @include mq('x-large') {
                  top: 2px;
                  left: 17px;
                }
              }
            }
          }

          &-title {
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
            margin-top: var(--emu-common-spacing-xxs);

            // breakpoint needed to match the live site
            @include mq('414px') {
              margin-top: var(--emu-common-spacing-xs);
            }

            // breakpoint needed to match the live site
            @include mq('576px') {
              margin-top: var(--emu-common-spacing-xxs);
            }

            @include mq('medium') {
              margin-top: 35px;
            }

            @include mq('x-large') {
              padding-left: 20%; // value from live site
              padding-right: 20%; // value from live site
              margin-top: 37px;
            }

            br {
              &:last-child {
                display: none;

                @include mq('x-large') {
                  display: block;
                }
              }

              &:first-child {
                @include mq('x-large') {
                  display: none;
                }
              }
            }

            .cmp-title__text {
              // breakpoint needed to match the live site
              @media (min-width: 321px) and (max-width: 413px) {
                font-size: var(--emu-semantic-font-sizes-narrow-large);
                line-height: var(--emu-semantic-line-heights-narrow-large);
              }

              // breakpoint needed to match the live site
              @media (min-width: 576px) and (max-width: 767px) {
                font-size: 46px;
                line-height: 50px;
              }

              // breakpoint needed to match the live site
              @include mq('1025px') {
                font-size: 64px;
                line-height: 69px;
              }

              @include mq('x-large') {
                font-size: var(--emu-semantic-font-sizes-wide-xl);
                line-height: var(--emu-semantic-line-heights-wide-xl);
              }
            }
          }

          &-text {
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
            padding-bottom: 40px;
            margin-top: 12px;

            // breakpoint needed to match the live site
            @media (min-width: 576px) and (max-width: 767px) {
              font-size: 22px;
              line-height: 40px;
            }

            // breakpoint needed to match the live site
            @include mq('1025px') {
              font-size: 28px;
              line-height: 42px;
            }

            @include mq('x-large') {
              padding-left: 10.5%; // value from live site
              padding-right: 10.5%; // value from live site
              padding-bottom: 15px;
              font-size: var(--emu-semantic-font-sizes-wide-small);
              line-height: var(--emu-semantic-line-heights-wide-small);
            }

            &-container {
              // breakpoint needed to match the live site
              @include mq('321px') {
                padding-left: 30px;
                padding-right: 30px;
              }

              @include mq('medium') {
                padding-left: var(--emu-common-spacing-none);
                padding-right: var(--emu-common-spacing-none);
              }
            }
          }
        }
      }
    }
  }

  // savings grid
  .product-grid-container {
    padding-top: 98px;
    padding-bottom: 105px;
    padding-left: 20px;
    padding-right: 20px;
    max-width: none;

    // breakpoint needed to match the live site
    @include mq('576px') {
      padding-top: 100px;
    }

    @include mq('medium') {
      padding-top: 140px;
      padding-bottom: 100px;
      padding-left: 60px;
      padding-right: 60px;
    }

    @include mq('x-large') {
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
    }

    &__title {
      margin-bottom: 63px;
      max-width: 280px; // to match live site

      @include mq('medium') {
        max-width: 550px;
      }

      @include mq('x-large') {
        max-width: none;
        text-align: center; // to match live site
        margin-bottom: 121px;
      }

      .cmp-title__text {
        // breakpoint needed to match the live site
        @include mq('320px', 'max-width') {
          font-size: 28px;
          line-height: 34px;
        }

        // breakpoint needed to match the live site
        @include mq('414px') {
          font-size: 33px;
          line-height: 39px;
        }

        // breakpoint needed to match the live site
        @include mq('576px') {
          font-size: 46px;
          line-height: 50px;
        }

        @include mq('medium') {
          font-size: 64px;
          line-height: 70px;
        }

        @include mq('large') {
          font-size: 64px;
          line-height: 69px;
        }

        @include mq('x-large') {
          font-size: var(--emu-semantic-font-sizes-wide-xl);
          line-height: 64px;
        }

        br {
          display: none;

          &:last-child {
            display: block;
          }

          // breakpoint needed to match the live site
          @include mq('576px') {
            display: block;
          }

          @include mq('medium') {
            display: none;

            &:last-child {
              display: block;
            }
          }

          @include mq('x-large') {
            display: none;

            &:nth-last-child(2) {
              display: block;
            }

            &:last-child {
              display: none;
            }
          }
        }
      }
    }

    &__items-wrapper {
      display: flex;
      flex-wrap: wrap;

      @include mq('x-large') {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        max-width: var(--emu-common-sizing-desktop);
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
      }

      > .container {
        position: relative;
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 65px;

          @include mq('medium') {
            margin-bottom: 77px;
          }

          @include mq('x-large') {
            margin-bottom: 104px;
          }
        }

        &:nth-child(odd) {
          @include mq('x-large') {
            padding-left: 90px;
            padding-right: 20px;
          }
        }

        &:nth-child(3) {
          @include mq('x-large') {
            margin-bottom: var(--emu-common-spacing-none);
            padding-left: 90px;
            padding-right: 50px;
          }
        }

        &:nth-child(even) {
          @include mq('x-large') {
            padding-left: 90px;
            padding-right: 60px;
          }
        }
      }
    }

    &__item {
      &-title p {
        color: var(--emu-semantic-colors-primary-100);
        font-size: var(--emu-semantic-font-sizes-narrow-xxl);
        line-height: var(--emu-common-line-heights-narrow-xxxl);
        font-weight: var(--emu-semantic-font-weight-600);

        @include mq('medium') {
          font-size: var(--emu-semantic-font-sizes-wide-xxl);
          line-height: var(--emu-semantic-line-heights-wide-xxl);
        }

        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-wide-xxl);
          line-height: var(--emu-semantic-line-heights-wide-xxl);
          margin-top: var(--emu-common-spacing-xs);
        }
      }

      &-subtitle p {
        font-size: var(--emu-semantic-font-sizes-narrow-large);
        font-family: var(--emu-semantic-font-families-heading);
        line-height: var(--emu-semantic-line-heights-narrow-large);
        color: var(--emu-semantic-colors-secondary-600);
        margin-bottom: var(--emu-common-spacing-small);

        // breakpoint needed to match the design
        @include mq('320px', 'max-width') {
          font-size: 28px;
          line-height: 34px;
        }

        // breakpoint needed to match the live site
        @include mq('414px') {
          font-size: 33px;
          line-height: 39px;
          margin-bottom: 6px;
        }

        // breakpoint needed to match the live site
        @include mq('576px') {
          font-size: 46px;
          line-height: 50px;
          margin-top: 1px;
          margin-bottom: 7px;
        }

        @include mq('medium') {
          font-size: 64px; // to match live site.
          line-height: 70px; // to match live site.
          margin-bottom: 17px;
        }

        @include mq('large') {
          font-size: 64px;
          line-height: 69px;
          margin-top: 1px;
          margin-bottom: var(--emu-common-spacing-medium);
        }

        @include mq('x-large') {
          font-size: 44px;
          line-height: var(--emu-common-line-heights-wide-xl);
          margin-bottom: 18px;
        }
      }

      &-text p {
        font-size: var(--emu-semantic-font-sizes-narrow-small);
        line-height: var(--emu-semantic-line-heights-narrow-small);
        color: var(--emu-semantic-colors-secondary-600);

        // breakpoint needed to match the live site
        @include mq('414px') {
          font-size: var(--emu-semantic-font-sizes-narrow-medium);
          line-height: 29px;
        }

        // breakpoint needed to match the live site
        @include mq('576px') {
          font-size: 22px;
          line-height: 40px;
        }

        @include mq('medium') {
          font-size: 28px;
          line-height: 42px;
        }

        @include mq('x-large') {
          font-size: var(--emu-semantic-font-sizes-wide-small);
          line-height: var(--emu-semantic-line-heights-wide-small);
        }
      }
    }

    .button {
      text-align: center;
      margin-top: 35px;

      @include mq('medium') {
        margin-top: 48px;
      }

      @include mq('large') {
        margin-top: 48px;
      }

      @include mq('x-large') {
        margin-top: 54px;
      }
    }

    &__btn {
      width: 218px; // to match the design
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);

      @include mq('medium') {
        width: 258px; // As per design
        font-size: 28px;
        padding-top: 18px;
        padding-bottom: 18px;
      }

      @include mq('x-large') {
        width: 311px;
        font-size: 24px;
      }
    }
  }

  // find a provider styles
  .banner {
    &.banner--fap {
      .banner {
        &__teaser {
          background-position: center center;
          height: 715px;

          // breakpoint needed to match the live site
          @include mq('321px') {
            height: 825px;
          }

          // breakpoint needed to match the live site
          @include mq('414px') {
            height: 900px;
          }

          @include mq('medium') {
            height: 1560px;
          }

          @include mq('large') {
            height: 2130px;
          }

          @include mq('x-large') {
            height: 755px;
          }

          // breakpoint needed to match the live site
          @include mq('1401px') {
            height: 1000px;
          }

          // breakpoint needed to match the live site
          @include mq('1601px') {
            height: 1250px;
          }

          // breakpoint needed to match the live site
          @include mq('2001px') {
            height: 1500px;
          }
        }

        &__content {
          @include mq('large') {
            top: 22%; // value from live site
            padding-left: var(--emu-common-spacing-none);
            padding-top: 7px;
            padding-right: var(--emu-common-spacing-none);
            max-width: var(--emu-common-sizing-large-tablet);
            left: 0;
            right: 0;
          }

          // breakpoint needed to match the live site
          @include mq('1401px') {
            top: 25%; // value from live site
          }

          // breakpoint needed to match the live site
          @include mq('1601px') {
            top: 22%; // value from live site
          }

          &-inner {
            padding-top: 90%;

            @include mq('medium') {
              padding-top: 87%; // value from live site
            }

            @include mq('large') {
              padding-top: 37%; // value from live site
            }

            @include mq('x-large') {
              padding-top: 10%;
            }

            // breakpoint needed to match the live site
            @include mq('1401px') {
              padding-top: 12%; // value from live site
            }

            // breakpoint needed to match the live site
            @include mq('1601px') {
              padding-top: 28%; // value from live site
            }
          }

          &-title {
            .cmp-title__text {
              // breakpoint needed to match the live site
              @media (min-width: 576px) and (max-width: 767px) {
                font-size: 46px;
                line-height: 50px;
              }
            }
          }

          &-text {
            // breakpoint needed to match the live site
            @media (min-width: 1025px) and (max-width: 1199px) {
              font-size: 28px;
              line-height: 42px;
            }
          }
        }
      }
    }
  }
}
