.fda-tabs {
  &-wrapper {
    // breakpoint needed to match the design
    @include mq('1025px') {
      max-width: var(--emu-common-sizing-tablet);
      margin-left: auto;
      margin-right: auto;
      padding-left: 25px;
      padding-right: 25px;
    }

    // breakpoint needed to match the design
    @include mq('x-large') {
      max-width: var(--emu-common-sizing-large-tablet);
    }
  }

  &-container {
    background-color: var(--emu-semantic-colors-secondary-50);
    padding-top: 53px;
    padding-bottom: 26px;
    overflow: hidden;
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);

    @include mq('medium') {
      padding-top: 85px;
    }

    // breakpoint needed ot match the live site
    @include mq('1025px') {
      padding-top: 54px;
    }

    &__inner {
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);

      @include mq('medium') {
        padding-left: 50px;
        padding-right: 50px;
      }

      // breakpoint needed ot match the live site
      @include mq('1025px') {
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
      }
    }
  }

  &__title p {
    font-size: var(--emu-semantic-font-sizes-narrow-large);
    line-height: var(--emu-semantic-line-heights-narrow-large);
    font-family: var(--emu-semantic-font-families-heading);
    color: var(--emu-semantic-colors-primary-100);
    font-weight: var(--emu-common-font-weight-black);
    margin-bottom: 12px;
    padding-left: 25px;
    padding-right: 25px;

    // breakpoint needed to match the live site
    @include mq('320px', 'max-width') {
      font-size: 28px;
      line-height: 34px;
      padding-left: 10px;
      padding-right: 10px;
    }

    // breakpoint needed to match the live site
    @media (min-width: 414px) and (max-width: 575px) {
      font-size: 33px;
      line-height: var(--emu-common-line-heights-narrow-xl);
    }

    // breakpoint needed to match the live site
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 46px;
      line-height: 50px;
    }

    @include mq('medium') {
      font-size: 64px;
      line-height: 69px;
      margin-bottom: var(--emu-common-spacing-medium);
    }

    @include mq('large') {
      margin-bottom: 17px;
    }

    // breakpoint needed to match the live site
    @include mq('1025px') {
      font-size: var(--emu-semantic-font-sizes-wide-xl);
      line-height: var(--emu-semantic-line-heights-wide-xl);
      margin-bottom: 12px;
    }

    br {
      display: block;

      @include mq('x-large') {
        display: none;
      }
    }
  }

  &__text {
    // breakpoint needed ot match the live site
    @include mq('1025px') {
      width: 82%; // value from live site
      margin-left: auto;
      margin-right: auto;
    }

    p {
      font-size: var(--emu-semantic-font-sizes-narrow-small);
      line-height: var(--emu-semantic-line-heights-narrow-small);
      color: var(--emu-semantic-colors-secondary-600);
      margin: auto;
      margin-bottom: 35px;
      padding-left: 25px;
      padding-right: 25px;

      // breakpoint needed to match the live site
      @include mq('320px', 'max-width') {
        padding-left: 10px;
        padding-right: 10px;
      }

      @media (min-width: 414px) and (max-width: 575px) {
        font-size: var(--emu-semantic-font-sizes-narrow-medium);
        line-height: 29px;
      }

      // breakpoint needed to match the live site
      @media (min-width: 576px) and (max-width: 767px) {
        font-size: 22px;
        line-height: 40px;
      }

      @include mq('medium') {
        font-size: 28px;
        line-height: 42px;
        max-width: 620px;
        margin-left: auto;
        margin-right: auto;
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
        margin-bottom: 33px;
      }

      // breakpoint needed to match the live site
      @include mq('1025px') {
        width: 85%; // value from live site
        max-width: none;
        margin-left: auto;
        margin-right: auto;
        font-size: var(--emu-semantic-font-sizes-wide-small);
        line-height: 38px;
      }

      @include mq('large') {
        max-width: 90%; // As per design
      }
    }

    &--gray {
      p {
        @include mq('large') {
          max-width: 620px; // As per design
        }

        // breakpoint needed to match the live site
        @include mq('1025px') {
          line-height: 38px;
          max-width: none; // As per design
          width: 85%; // value from live site
        }
      }
    }
  }

  &__item {
    .aaaem-tabs__tablist {
      justify-content: center;
      margin-bottom: 70px;
    }

    li.aaaem-tabs__tab {
      width: 140px;

      // breakpoint needed to match the design
      @include mq('321px') {
        min-width: 40%; // value from live site
        width: auto;
      }

      @include mq('medium') {
        font-size: var(--emu-semantic-font-sizes-wide-small);
        min-width: 245px; // As per design
        padding: var(--emu-common-spacing-none);
      }

      &:not(:last-child) {
        margin-right: 10px;

        // breakpoint needed to match the live site
        @include mq('1025px') {
          margin-right: 20px;
        }
      }

      a {
        font-family: var(--emu-semantic-font-families-heading);
        border-radius: 58px;
        border: var(--emu-common-border-width-medium) solid
          var(--emu-semantic-colors-primary-200);
        background: var(--emu-common-colors-transparent);
        padding: 10px;
        vertical-align: middle;
        font-size: var(--emu-semantic-font-sizes-narrow-small);
        line-height: 21px;
        font-weight: var(--emu-common-font-weight-black);
        text-decoration: none;
        color: var(--emu-semantic-colors-primary-200);
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: center;

        // breakpoint needed to match the design
        @include mq('321px') {
          padding-left: 35px;
          padding-right: 35px;
        }

        @include mq('medium') {
          font-size: var(--emu-semantic-font-sizes-wide-small);
          padding-top: 15px;
          padding-bottom: 15px;
        }

        span {
          @include mq('medium') {
            position: relative;
            top: 2px;
          }
        }

        &[aria-selected='true'],
        &[aria-selected='true']:hover,
        &[aria-selected='true']:active,
        &[aria-selected='true']:focus {
          background: var(--emu-semantic-colors-primary-200);
          color: var(--emu-common-colors-white);
        }
      }
    }

    &--mobile {
      display: flex;
      flex-direction: column-reverse;

      // breakpoint needed to match the live site
      @include mq('1025px') {
        display: none;
      }

      @include aem-editor-view {
        display: flex !important;
      }

      .aaaem-tabs__tablist {
        margin-bottom: var(--emu-common-spacing-none);
        column-gap: var(--emu-common-spacing-none);

        li.aaaem-tabs__tab {
          width: auto;
          margin-right: 5px;
          min-width: 0;

          a {
            background-color: var(--emu-common-colors-transparent);
            color: var(--emu-semantic-colors-secondary-600);
            opacity: 0.4;
            border-radius: var(--emu-common-border-radius-xxs);
            font-size: 13px;
            line-height: 13px;
            font-weight: var(--emu-semantic-font-weight-600);
            border: none;
            padding-top: 13px;
            padding-right: 25px;
            padding-bottom: 13px;
            padding-left: 25px;
            letter-spacing: 1px;

            &[aria-selected='true'],
            &[aria-selected='true']:focus {
              color: var(--emu-semantic-colors-primary-100);
              background: var(--emu-common-colors-transparent);
              opacity: 1;
            }

            &[aria-selected='true']:focus {
              box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); // As per live site.
            }
          }
        }
      }

      .aaaem-tabs__tabpanels {
        // media query needed to match the live site
        @include mq('576px') {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
  }

  &__img {
    &--mobile {
      img {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        height: 417px;

        // breakpoint needed to match the live site
        @include mq('320px', 'max-width') {
          height: 408px;
          width: 100%;
        }

        // breakpoint needed to match the live site
        @include mq('321px') {
          margin-bottom: 20px;
        }

        // breakpoint needed to match the live site
        @media (min-width: 414px) and (max-width: 575px) {
          height: 486px;
          margin-bottom: var(--emu-common-spacing-none);
        }

        // breakpoint needed to match the live site
        @media (min-width: 576px) and (max-width: 767px) {
          height: auto;
          width: 100%;
        }

        @include mq('medium') {
          max-width: 100%;
          height: 899px;
          margin-bottom: var(--emu-common-spacing-none);
        }
      }
    }

    &--desktop {
      display: none;

      @include aem-editor-view {
        display: block !important;
      }

      // breakpoint needed to match the live site
      @include mq('1025px') {
        display: block;
        height: 450px;
        width: 950px;
        margin: auto;
      }

      // breakpoint needed to match the live site
      @include mq('1366px') {
        height: auto;
      }

      img {
        // breakpoint needed to match the live site
        @include mq('1025px') {
          height: 433px;
          max-width: none;
          margin: auto;
        }

        // breakpoint needed to match the live site
        @include mq('1366px') {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
