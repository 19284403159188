.treatment-plan {
  &__wrapper {
    padding-top: 68px;
    padding-bottom: 20px;
    position: relative;

    @include mq('medium') {
      padding-top: 73px;
      padding-bottom: 105px;
    }

    @include mq('x-large') {
      padding-top: 90px;
      padding-bottom: 21px;
    }

    @include mq('x-large') {
      padding-top: 111px;
      padding-bottom: 100px;
    }

    &.relative-modal__wrapper--hide {
      @include mq('medium') {
        padding-bottom: 20px;
      }

      @include mq('x-large') {
        padding-top: 80px;
        padding-bottom: 411px; // to match the live site
      }

      .treatment-plan__scroll-wrapper,
      .treatment-plan__accordion-wrapper {
        display: none;
      }

      .treatment-plan__title-container {
        @include mq('x-large') {
          display: none;
        }
      }
    }
  }

  &__title-container {
    width: 82%; // value from live site
    margin: auto;
    margin-bottom: 53px;

    // breakpoint needed to match live site
    @include mq('321px') {
      width: 75%; // value from live site
      margin-bottom: 73px;
    }

    // breakpoint needed to match live site
    @include mq('414px') {
      width: 70%; // value from live site
    }

    @include mq('medium') {
      margin-bottom: 18px;
    }

    @include mq('x-large') {
      width: 100%;
      padding-bottom: 150px;
    }
  }

  &__title {
    margin-bottom: 7px;
    margin-top: -4px;

    @include mq('medium') {
      margin-bottom: 33px;
      margin-right: 10px;
      margin-top: -6px;
    }

    @include mq('large') {
      margin-bottom: var(--emu-common-spacing-large);
    }

    @include mq('x-large') {
      margin-bottom: 33px;
      margin-right: 10px;
    }
  }

  &__sub-title {
    // breakpoint needed to match the live site
    @media (min-width: 1025px) and (max-width: 1199px) {
      font-size: 28px;
      line-height: 42px;
    }

    br {
      display: block;

      @include mq('medium') {
        display: none;
      }

      @include mq('x-large') {
        display: block;
      }
    }
  }

  &__accordion {
    &-wrapper {
      display: block;

      @include mq('x-large') {
        display: none;
      }

      @include aem-editor-view {
        display: block;
      }
    }
  }

  &__scroll {
    &-wrapper {
      display: none;

      @include mq('x-large') {
        display: flex;
        justify-content: center;
        margin-top: 35px;
        padding-left: 63px;
        padding-right: 63px;
        max-height: 700px; //to match live site
        overflow: auto;
        margin-bottom: -15px; //to match live site

        @include aem-editor-view {
          max-height: none !important;
        }

        &::-webkit-scrollbar {
          display: none; //to hide scroll bar
        }

        > div:first-child {
          overflow: hidden;
          position: sticky;
          top: 0;
        }
      }

      @include aem-editor-view {
        display: flex;
        max-height: none;
      }
    }

    &-menu {
      display: flex;
      justify-content: flex-end;
      width: 100%; // to match live site
      height: 100%; // to match live site
      max-width: 580px;

      @include mq('1300px') {
        max-width: 600px;
      }

      &-text {
        display: flex;
        height: 100%; //to match live site
        z-index: var(--emu-common-other-z-index-base);
        border-right-color: var(--emu-common-colors-white);
        border-right-width: var(--emu-common-border-width-thin);
        border-right-style: solid;
        position: relative;
        margin-right: 30px;
        padding-right: 30px;

        &::before,
        &::after {
          content: '';
          z-index: var(--emu-common-other-z-index-layer);
          position: absolute;
          width: 12px;
          height: 12px;
          background-color: var(--emu-semantic-colors-primary-50);
          border-radius: 50%;
          transform: translateY(-50%);
          right: -6px;
        }

        &::before {
          top: 6px;
        }

        &::after {
          bottom: -6px;
        }

        ul {
          list-style: none; //to hide default list style
          padding-left: 10px;
          padding-right: 10px;
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: var(--emu-common-spacing-none);

          li {
            position: relative;
            font-size: var(--emu-semantic-font-sizes-wide-xl);
            line-height: var(--emu-semantic-line-heights-wide-xl);
            counter-increment: section; /* Increment the counter */
            display: flex;
            padding-left: 56px;
            padding-top: 5px;
            padding-bottom: 68px;
            font-family: var(--emu-semantic-font-families-heading);

            .os-windows & {
              padding-top: 13px;
              padding-bottom: 61px;
            }

            > a {
              text-decoration: none;
              opacity: 0.1;
              transition: all 0.3s ease-out 0s;

              &,
              &:active,
              &:visited {
                color: #0077bf; // no css variable for this
              }

              &:hover {
                color: var(--emu-common-colors-white);
                opacity: 1;
              }

              &::after {
                content: counter(section); /* Display the counter */
                font-family: var(--emu-semantic-font-families-heading);
                font-size: 28px;
                line-height: 15px;
                color: var(--emu-semantic-colors-primary-100);
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                left: 0;
                top: 8px;
                width: 41px;
                height: 41px;
                border-radius: 50%;
                background-color: var(--emu-common-colors-white);
              }

              .os-windows & {
                &::after {
                  padding-top: 5px;
                  top: 10px;
                }
              }

              &.active {
                opacity: 1;
                color: var(--emu-common-colors-white);
              }
            }
          }
        }
      }
    }

    &-content {
      @include mq(x-large) {
        padding-top: 60px;
        padding-bottom: 60px;
        scroll-margin-top: 150px; //to show some gap above container when scrolled by click event

        &-wrapper {
          max-width: 580px; //to match live site

          // breakpoint needed to match the live site
          @include mq('1300px') {
            width: 580px;
          }

          > div:nth-child(1) {
            margin-top: -60px; //to match live site
            margin-bottom: 170px; //to match live site
          }
        }
      }

      &-text {
        @include mq('medium') {
          padding-left: 38px;
          padding-right: 30px;
        }

        @include mq('x-large') {
          padding: var(--emu-common-spacing-none);
        }

        ul {
          margin: var(--emu-common-spacing-none);
          padding-left: 35px;
          padding-right: 20px;

          @include mq('x-large') {
            padding-left: 25px;
            padding-right: var(--emu-common-spacing-none);
          }

          li {
            margin-bottom: 19px;
            font-size: var(--emu-semantic-font-sizes-narrow-medium);
            line-height: 21px;

            // breakpoint needed to match live site
            @include mq('414px') {
              font-size: 15px;
              line-height: 28px;
            }

            @include mq('medium') {
              margin-bottom: 29px;
              font-size: 28px;
              line-height: 42px;
            }

            @include mq('large') {
              margin-bottom: 31px;
              font-size: 28px;
              line-height: 42px;
            }

            @include mq('x-large') {
              margin-bottom: 30px;
              font-size: 23px;
              line-height: 35px;
            }

            b {
              font-family: var(--emu-semantic-font-families-heading);

              a {
                text-decoration: none;
              }
            }
          }
        }
      }

      &-cta {
        width: 100%; //to match live site
        max-width: 619px; //to match live site
        justify-content: center;
        align-items: center;
        height: 51px;

        // breakpoint needed to match live site
        @include mq('414px') {
          height: 66px;
        }

        @include mq('medium') {
          height: 72px; //to match live site
          margin-left: auto;
          margin-right: auto;
        }

        @include mq('large') {
          height: 82px;
        }

        @include mq('x-large') {
          height: 59px;
          width: fit-content; //to match live site
          margin-left: 25px;
        }
      }

      .button {
        display: flex;

        @include mq('medium') {
          margin-top: 20px;
        }

        @include mq('large') {
          justify-content: center;
          margin-top: var(--emu-common-spacing-medium);
        }

        @include mq('x-large') {
          justify-content: flex-start;
          margin-top: -10px;
        }

        .emu-button-v2.emu-button {
          font-size: 18px;
          line-height: 33px;

          @include mq('medium') {
            font-size: var(--emu-semantic-font-sizes-wide-small);
          }

          @include mq('large') {
            font-size: 28px;
          }

          @include mq('x-large') {
            font-size: var(--emu-semantic-font-sizes-wide-small);
          }
        }
      }

      &-disclaimer {
        p {
          font-size: var(--emu-common-font-sizes-narrow-medium);
          line-height: var(--emu-common-line-heights-narrow-medium);
        }
      }
    }
  }
}
