.timeline-item {
  margin-top: 40px;
  margin-bottom: 40px;

  // breakpoint needed to match the live site
  @include mq('767px') {
    padding-left: 45px;
    padding-right: 45px;
  }

  @include mq('medium') {
    margin-top: 88px;
    margin-bottom: 88px;
  }

  @include mq('x-large') {
    margin-top: 3%;
    margin-bottom: 55px;
    padding-right: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
    padding-left: var(--emu-common-spacing-none);
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  &--no-title {
    margin-top: 70px;

    // breakpoint needed for matching the live site
    @include mq('414px') {
      margin-top: 72px;
    }

    @include mq('medium') {
      margin-top: 118px;
    }

    @include mq('x-large') {
      justify-content: flex-end;
      margin-top: 3%;
    }
  }

  // main title of the timeline section
  > .title {
    @include mq('x-large') {
      width: 42%;
    }
  }

  // year and content container of the timeline section
  > .container {
    @include mq('x-large') {
      width: 42%;
      justify-self: flex-end;
    }
  }

  // main title
  &__title {
    margin-bottom: 36px;
    padding-top: 3px;

    // breakpoint needed to match the live site
    @include mq('414px') {
      padding-top: 5px;
    }

    // breakpoint needed to match the live site
    @include mq('767px') {
      margin-bottom: 100px;
    }

    @include mq('large') {
      padding-right: 20%;
    }

    @include mq('x-large') {
      padding-right: var(--emu-common-spacing-none);
      margin-bottom: var(--emu-common-spacing-none);
    }

    &--mb-lg {
      margin-bottom: 42px;

      // breakpoint needed to match the live site
      @include mq('767px') {
        margin-bottom: 74px;
      }

      @include mq('x-large') {
        margin-bottom: var(--emu-common-spacing-none);
      }
    }

    &--pr-lg {
      @include mq('large') {
        padding-right: 40%;
      }

      @include mq('x-large') {
        padding-right: var(--emu-common-spacing-none);
      }
    }

    br {
      display: none;

      &:nth-child(2) {
        display: block;
      }

      @include mq('medium') {
        &:nth-child(2) {
          display: none;
        }

        &:nth-child(1),
        &:nth-child(3) {
          display: block;
        }
      }

      @include mq('x-large') {
        &:nth-child(1),
        &:nth-child(3) {
          display: none;
        }

        &:nth-child(2) {
          display: block;
        }
      }
    }
  }

  // year and content
  &__content {
    display: flex;
    justify-content: space-between;
    position: relative;

    @include mq('x-large') {
      position: static;
    }

    // container with year
    > .container {
      width: 22%; // value from live site

      // breakpoint needed to match the live site
      @include mq('321px') {
        width: 18%; // value from live site
      }

      // breakpoint needed to match the live site
      @include mq('414px') {
        width: 20%; // value from live site
        margin-top: -1px;
      }

      @include mq('x-large') {
        position: absolute;
        margin: var(--emu-common-spacing-none);
        width: 7%;
        left: 43%;
        height: calc(100% - 2px);
        top: 2px;
      }

      @include aem-editor-view {
        position: static;
        width: 20%;
      }
    }

    // text of the timeline component
    > .text {
      width: 70%; // value from live site

      // breakpoint needed to match the live site
      @include mq('321px') {
        width: 75%; // value from live site
      }

      @include mq('x-large') {
        width: 100%;
      }
    }

    &-separator {
      width: 2px;
      height: 100%;
      background-color: var(--emu-common-colors-white);
      position: relative;

      p,
      ul {
        display: none;
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background-color: var(--emu-semantic-colors-primary-50);
        height: 12px;
        width: 12px;
        border-radius: 100%;
        z-index: var(--emu-common-other-z-index-layer);
        transform: translateX(-50%) translateX(1px);
      }

      &::after {
        top: auto;
        bottom: 0;
      }
    }

    &-date {
      &-container {
        display: flex;
        justify-content: space-between;
        height: 100%;

        @include mq('medium') {
          margin-top: -1px;
        }

        @include mq('large') {
          margin-top: -2px;
        }
      }

      .cmp-title__text {
        font-size: 15px;
        line-height: var(--emu-semantic-line-heights-narrow-small);
        margin-top: var(--emu-common-spacing-xxs);

        // breakpoint needed to match the live site
        @include mq('321px') {
          margin-top: 18px;
        }

        // breakpoint needed to match the live site
        @include mq('414px') {
          font-size: 20px;
        }

        // breakpoint needed to match the live site
        @include mq('576px') {
          font-size: 28px;
          margin-top: 5px;
        }

        @include mq('x-large') {
          font-size: 18px;
          margin-top: 17px;
        }
      }
    }

    &-desc {
      // breakpoint needed to match the live site
      @include mq('414px') {
        font-size: 16px;
        line-height: 29px;
      }

      // breakpoint needed to match the live site
      @include mq('576px') {
        font-size: 22px;
        line-height: 40px;
      }

      @include mq('medium') {
        font-size: 28px;
        line-height: 42px;
      }

      @include mq('x-large') {
        font-size: 24px;
        line-height: 31px;
        padding-top: var(--emu-common-spacing-xxs);
      }

      p,
      li {
        font-size: inherit;
        line-height: inherit;
      }

      p {
        margin-bottom: 15px;

        // breakpoint needed to match the live site
        @include mq('414px') {
          margin-bottom: 13px;
        }

        @include mq('medium') {
          margin-bottom: 15px;
        }

        @include mq('x-large') {
          margin-bottom: 14px;
        }
      }

      sup {
        top: 0.1em;
      }

      h4 {
        font-size: 15px;
        line-height: 26px;
        font-weight: var(--emu-common-font-weight-bold);
        margin-top: var(--emu-common-spacing-xxs);
        margin-bottom: 7px;

        // breakpoint needed to match the live site
        @include mq('414px') {
          font-size: 17px;
          line-height: 135%; // value from live site
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: 9px;
        }

        // breakpoint needed to match the live site
        @include mq('576px') {
          font-size: 24px;
          line-height: 32.78px; // value from live site
          margin-top: 1px;
          margin-bottom: var(--emu-common-spacing-small);
        }

        // breakpoint needed to match the live site
        @include mq('767px') {
          font-size: 30px;
          line-height: 40.98px; // value from live site
          margin-top: var(--emu-common-spacing-none);
        }

        // breakpoint needed to match the live site
        @include mq('x-large') {
          font-size: var(--emu-semantic-font-sizes-wide-small);
          line-height: 33px;
        }
      }
    }
  }
}
