#esf-what-to-expect {
  // main banner styles
  .banner {
    &.banner--main {
      .banner {
        &__teaser {
          @include mq('large') {
            background-position: center;
          }

          .emu-teaser__disclaimer {
            padding-bottom: 10px;
          }
        }

        &__img {
          img {
            // breakpoint needed to match the live site
            @include mq('1401px') {
              object-position: center right;
            }

            // breakpoint needed to match the live site
            @include mq('1601px') {
              object-position: center center;
            }
          }
        }

        &__content {
          padding-top: 65%;

          // media query needed to match the live site
          @include mq('321px') {
            padding-top: 90%;
          }

          @include mq('medium') {
            padding-top: 68%;
          }

          @include mq('large') {
            top: 23%;
            padding-top: var(--emu-common-spacing-none);
            padding-left: 15px;
          }

          // media query needed to match the live site
          @include mq('1199px') {
            padding-right: 73px;
          }

          @include mq('x-large') {
            padding-left: 73px;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            max-width: var(--emu-common-sizing-desktop);
          }

          // breakpoint needed to match the live site
          @include mq('1600px') {
            top: 28%;
          }

          &-inner {
            @include mq('medium') {
              padding: var(--emu-common-spacing-none);
            }

            @include mq('large') {
              padding-top: 22%;
            }

            // breakpoint needed to match the live site
            @include mq('1025px') {
              padding-right: 30px;
            }

            @include mq('x-large') {
              padding-top: var(--emu-common-spacing-medium);
            }
          }

          &-img {
            img {
              max-width: 80%;
              padding-right: 15px;
              padding-left: 30px;

              // breakpoint needed to match the live site
              @include mq('321px') {
                padding-right: var(--emu-common-spacing-none);
                width: 100%;
              }

              @include mq('medium') {
                height: auto;
                padding-right: 1%; // value from live site
                margin-left: var(--emu-common-spacing-none);
                margin-top: 14px;
              }

              @include mq('large') {
                padding-left: 10px;
                padding-right: 20%; // value from live site
                margin-top: 66px;
              }

              @include mq('x-large') {
                width: auto;
                height: auto;
                padding: var(--emu-common-spacing-none);
                max-width: 600px; // nominal value to make sure this does not break
                margin-top: var(--emu-common-spacing-none);
              }
            }
          }

          &-title {
            margin-top: -7px;

            // breakpoint needed to match the live site
            @include mq('321px') {
              padding-right: 5%; // value from live site
              padding-left: 40px;
            }

            // breakpoint needed to match the live site
            @include mq('414px') {
              margin-top: -6px;
            }

            @include mq('medium') {
              padding-left: 85px;
              padding-right: 25px;
              margin-top: -68px;
            }

            @include mq('large') {
              padding-right: 40%; // value from live site
              margin-top: -69px;
            }

            @include mq('x-large') {
              padding-right: var(--emu-common-spacing-none);
              padding-left: 35px;
              margin-top: -55px;
            }

            .cmp-title__text {
              // breakpoint needed to match the live site
              @media (min-width: 321px) and (max-width: 413px) and (orientation: portrait) {
                font-size: 30px;
                line-height: 35px;
              }

              // breakpoint needed to match the live site
              @media (min-width: 1025px) and (max-width: 1199px) {
                font-size: 64px;
                line-height: 69px;
              }
            }

            br {
              display: none;

              @include mq('large') {
                display: block;
              }
            }
          }

          &-text {
            padding-right: var(--emu-common-spacing-none);

            // breakpoint needed to match the live site
            @include mq('321px') {
              padding-left: 40px;
              padding-right: 15px;
            }

            // breakpoint needed to match the live site
            @include mq('414px') {
              padding-right: 25px;
              max-width: 725px;
              margin-left: var(--emu-common-spacing-none);
            }

            @include mq('medium') {
              padding-left: 85px;
              padding-right: 15px;
              margin-top: var(--emu-common-spacing-small);
            }

            @include mq('large') {
              margin-top: 22px;
              padding-right: 25%; // value from live site
              max-width: none;
            }

            // breakpoint needed to match the live site
            @media (min-width: 1024px) and (max-width: 1199px) {
              font-size: 28px;
              line-height: 42px;
            }

            @include mq('x-large') {
              padding-right: var(--emu-common-spacing-none);
              padding-left: 35px;
              max-width: 725px;
              margin-top: 13px;
            }

            &-container {
              // breakpoint needed to match the live site
              @include mq('1199px') {
                padding-right: var(--emu-common-spacing-none);
              }
            }
          }
        }
      }
    }
  }

  // redirection banner
  .banner {
    &.banner--redirection {
      .banner {
        &__teaser {
          height: 825px;
          background-position: center top;

          // breakpoint needed to match the live site
          @include mq('414px') {
            height: 990px;
          }

          @include mq('medium') {
            height: 1566px;
          }

          @include mq('large') {
            height: 2130px;
          }

          @include mq('x-large') {
            height: 750px;
          }

          // breakpoint needed to match the live site
          @include mq('1401px') {
            height: 900px;
          }

          // breakpoint needed to match the live site
          @include mq('1601px') {
            height: 1175px;
          }

          // breakpoint needed to match the live site
          @include mq('2001px') {
            height: 1600px;
          }

          .emu-teaser__disclaimer {
            line-height: 23px;
          }
        }

        &__content {
          top: 0;
          height: 100%;
          display: flex;
          align-items: center;
          padding-top: var(--emu-common-spacing-none);

          &-inner {
            transform: translateY(49px);

            @include mq('medium') {
              max-width: 674px;
              margin-left: auto;
              margin-right: auto;
              transform: translateY(-15px);
            }

            @include mq('large') {
              transform: translateY(-56px);
            }

            @include mq('x-large') {
              transform: none;
            }

            // breakpoint needed to match the live site
            @include mq('1401px') {
              transform: translateY(-35px);
            }

            // breakpoint needed to match the live site
            @include mq('1601px') {
              transform: translateY(-15px);
            }

            // breakpoint needed to match the live site
            @include mq('2001px') {
              transform: translateY(-10px);
            }
          }

          &-title {
            max-width: none;

            .cmp-title__text {
              @include mq('large') {
                font-size: 64px;
                line-height: 69px;
              }

              @include mq('x-large') {
                font-size: 54px;
                line-height: 58px;
              }
            }
          }

          &-text {
            margin-top: var(--emu-common-spacing-none);
            max-width: 72%;
            padding-left: 15px;
            padding-right: 15px;
            margin-top: -3px;

            @include mq('medium') {
              max-width: none;
              padding: var(--emu-common-spacing-none);
              margin-top: 6px;
            }

            @include mq('large') {
              margin-top: 21px;
              font-size: 28px;
              line-height: 42px;
            }

            @include mq('x-large') {
              font-size: 24px;
              line-height: 31px;
              margin-top: 12px;
            }

            &-container {
              @include mq('medium') {
                padding-left: var(--emu-common-spacing-none);
                padding-right: var(--emu-common-spacing-none);
              }
            }
          }

          &-cta-container {
            margin-top: 10px;

            @include mq('medium') {
              margin-top: 18px;
            }

            @include mq('x-large') {
              margin-top: 24px;
            }
          }

          &-cta {
            height: 63px; // to match live site

            @include mq('medium') {
              height: 72px; // to match live site
              font-size: 28px;
            }

            @include mq('x-large') {
              height: 63px; // to match live site
              font-size: 24px;
            }
          }
        }
      }
    }
  }

  // Find a Provider banner
  .banner {
    &.banner--fap {
      .banner {
        &__teaser {
          .emu-teaser__disclaimer {
            p {
              font-size: 12px;
            }
          }
        }

        &__content {
          @include mq('medium') {
            padding-top: var(--emu-common-spacing-none);
          }

          @include mq('large') {
            padding-left: var(--emu-common-spacing-none);
            top: 0;
            margin-left: -15px;
            margin-right: -15px;
            max-width: none;
            width: auto;
            padding-top: 7px;
          }

          // breakpoint needed to match the live site
          @include mq('1025px') {
            top: 15%; // value from live site
            margin-left: var(--emu-common-spacing-none);
            margin-right: var(--emu-common-spacing-none);
            padding-top: 6px;
            padding-right: var(--emu-common-spacing-none);
            max-width: var(--emu-common-sizing-large-tablet);
            margin-left: auto;
            margin-right: auto;
          }

          // breakpoint needed to match the live site
          @include mq('1401px') {
            padding-top: var(--emu-common-spacing-small);
          }

          &-inner {
            @include mq('large') {
              padding-top: 80%; // value from live site
            }

            @include mq('x-large') {
              padding-top: 14.7%; // value from live site
            }

            // breakpoint needed to match the live site
            @include mq('1401px') {
              padding-top: 20.75%; // value from live site
            }

            // breakpoint needed to match the live site
            @include mq('1601px') {
              padding-top: 28%; // value from live site
            }

            // breakpoint needed to match the live site
            @include mq('2001px') {
              padding-top: 35%; // value from live site
            }
          }

          &-title {
            .cmp-title__text {
              // breakpoint needed to match the live site
              @include mq('1025px') {
                font-size: 64px;
                line-height: 69px;
              }

              @include mq('x-large') {
                font-size: 54px;
                line-height: 58px;
              }
            }
          }

          &-text {
            // breakpoint needed to match the live site
            @include mq('1025px') {
              font-size: 28px;
              line-height: 42px;
              margin-top: 11px;
            }

            @include mq('x-large') {
              font-size: 24px;
              line-height: 31px;
            }
          }

          &-cta {
            &-container {
              // breakpoint needed to match the live site
              @include mq('1025px') {
                margin-top: 23px;
              }
            }
          }
        }
      }
    }
  }
}
