.page-title-banner {
  &__title {
    padding: var(--emu-common-spacing-none);

    .emu-title__text {
      font-size: 33px;
      line-height: var(--emu-common-line-heights-narrow-xl);

      @include mq('medium') {
        font-size: var(--emu-semantic-font-sizes-wide-xl);
        line-height: var(--emu-semantic-line-heights-wide-xl);
      }
    }

    &-wrapper {
      background-color: var(--emu-semantic-colors-secondary-800);
      padding-top: 29px;
      padding-right: 30px;
      padding-bottom: 21px;
      padding-left: 30px;
      margin-bottom: var(--emu-common-spacing-large);

      @include mq('medium') {
        margin-bottom: 33px;
      }

      @include mq('x-large') {
        padding-left: 60px;
      }

      @include mq('xx-large') {
        padding-left: 90px;
      }
    }
  }
}
