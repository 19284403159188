#error-page {
  .error {
    &__sub-title {
      p {
        font-size: var(--emu-semantic-font-sizes-narrow-large);
        line-height: var(--emu-semantic-line-heights-narrow-large);
        margin-bottom: 10px;
      }
    }

    &__text p,
    .button .emu-button-v2 {
      font-size: var(--emu-semantic-font-sizes-narrow-medium);
      line-height: var(--emu-common-line-heights-wide-medium);
      font-weight: var(--emu-semantic-font-weight-light);
      font-family: var(--emu-semantic-font-families-body);
      font-weight: var(--emu-semantic-font-weight-light);
      padding: var(--emu-common-spacing-none);
    }

    &__text {
      color: var(--emu-semantic-colors-primary-950);
      margin-bottom: 36px;

      @include mq('large') {
        margin-bottom: 30px;
      }
    }

    &__content {
      padding-right: var(--emu-common-spacing-xs);
      margin-bottom: 48px;
      padding-left: 34px;

      @include mq('medium') {
        padding-left: 45px;
      }

      @include mq('x-large') {
        padding-right: 20px;
      }

      .button .emu-button-v2 {
        &:hover {
          .cmp-button__text {
            background: none;
            -webkit-text-fill-color: var(--emu-semantic-colors-primary-100);
            text-decoration: underline;
          }
        }
      }
    }
  }
}
