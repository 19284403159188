#discover-our-story {
  // main banner styles
  .banner {
    &.banner--main {
      .banner {
        &__teaser {
          // breakpoint needed to match the live site
          @media (min-width: 414px) and (max-width: 575px) {
            height: 825px;
          }

          // breakpoint needed to match the live site
          @media (min-width: 576px) and (max-width: 766px) {
            height: 1225px;
          }

          // breakpoint needed to match the live site
          @media (min-width: 767px) and (max-width: 1023px) {
            height: 1432px;
          }

          .disclaimer-bottom-right .emu-teaser__disclaimer {
            @include mq('medium') {
              padding-bottom: 10px;
            }
          }
        }

        &__content {
          padding-top: 70%; // value from live site

          // breakpoint needed to match the live site
          @include mq('321px') {
            padding-top: 90%; // value from live site
          }

          @include mq('medium') {
            padding-top: 70%; // value from live site
          }

          @include mq('large') {
            padding-top: var(--emu-common-spacing-none);
            top: 27%; // value from live site
            padding-left: 15px;
          }

          // breakpoint needed to match the live site
          @include mq('1199px') {
            padding-right: var(--emu-common-spacing-xs);
          }

          @include mq('x-large') {
            padding-left: var(--emu-common-spacing-xs);
            margin-top: -34px;
            max-width: var(--emu-common-sizing-desktop);
            margin-left: auto;
            margin-right: auto;
            right: 0;
          }

          // breakpoint needed to match the live site
          @include mq('1601px') {
            margin-top: -35px;
          }

          &-inner {
            @include mq('medium') {
              padding-left: var(--emu-common-spacing-none);
              padding-right: var(--emu-common-spacing-none);
            }

            @include mq('large') {
              padding-top: 33%; // value from live site
            }

            // breakpoint needed to match the live site
            @include mq('1025px') {
              padding-right: 30px;
            }

            @include mq('x-large') {
              padding-right: 30px;
              padding-top: 16px;
            }
          }

          &-img {
            img {
              max-width: 80%;
              padding-left: var(--emu-common-spacing-none);
              padding-right: 15px;

              // breakpoint needed to match the live site
              @include mq('321px') {
                padding-left: 5px;
                padding-right: var(--emu-common-spacing-none);
              }

              // breakpoint needed to match the live site
              @include mq('414px') {
                padding-left: var(--emu-common-spacing-none);
              }

              // breakpoint needed to match the live site
              @include mq('767px') {
                padding-right: 14%; // value from live site
              }

              @include mq('medium') {
                max-width: 100%;
                width: 100%;
                margin-left: var(--emu-common-spacing-none);
                height: auto;
              }

              @include mq('large') {
                padding-right: 35%; // value from live site
              }

              @include mq('x-large') {
                width: auto;
                height: auto;
              }
            }
          }

          &-title {
            padding-left: 25px;
            padding-right: 25px;
            margin-top: -19px;

            // breakpoint needed to match the live site
            @include mq('321px') {
              padding-left: 40px;
              margin-top: -22px;
            }

            // breakpoint needed to match the live site
            @include mq('414px') {
              margin-top: -21px;
            }

            @include mq('medium') {
              padding-left: 85px;
              padding-right: 53px;
              margin-top: -46px;
            }

            @include mq('x-large') {
              margin-top: -25px;
              padding-left: 104px;
              padding-right: var(--emu-common-spacing-none);
            }

            .cmp-title__text {
              // breakpoint needed to match the live site
              @media (min-width: 321px) and (max-width: 413px) {
                font-size: var(--emu-semantic-font-sizes-narrow-large);
                line-height: var(--emu-semantic-line-heights-narrow-large);
              }

              // breakpoint needed to match the live site
              @media (min-width: 1025px) and (max-width: 1199px) {
                font-size: 64px;
                line-height: 69px;
              }
            }
          }

          &-text {
            padding-left: 25px;
            padding-right: 40px;

            // breakpoint needed to match the live site
            @include mq('321px') {
              padding-left: 40px;
              margin-top: var(--emu-common-spacing-small);
            }

            // breakpoint needed to match the live site
            @media (min-width: 321px) and (max-width: 413px) {
              line-height: 21px;
            }

            // breakpoint needed to match the live site
            @include mq('414px') {
              margin-top: 7px;
            }

            // breakpoint needed to match the live site
            @include mq('767px') {
              margin-top: var(--emu-common-spacing-small);
            }

            @include mq('medium') {
              padding-left: 85px;
              padding-right: 53px;
              margin-top: 22px;
            }

            // breakpoint needed to match the live site
            @media (min-width: 1025px) and (max-width: 1199px) {
              font-size: 28px;
              line-height: 42px;
            }

            @include mq('x-large') {
              padding-left: 104px;
              padding-right: var(--emu-common-spacing-none);
              margin-top: 13px;
            }

            &-container {
              // breakpoint needed to match the live site
              @include mq('1199px') {
                padding-right: var(--emu-common-spacing-none);
              }
            }

            br {
              @include mq('large') {
                display: none;
              }

              @include mq('x-large') {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  // timeline section styles
  .timeline {
    padding-top: 49px;
    padding-right: 20px;
    padding-bottom: 45px;
    padding-left: 20px;

    // breakpoint needed to match the live site
    @include mq('321px') {
      padding-left: 30px;
      padding-right: 30px;
    }

    // breakpoint needed to match the live site
    @include mq('576px') {
      padding-left: 41px;
      padding-right: 41px;
    }

    @include mq('medium') {
      padding-left: 39px;
      padding-right: 39px;
      padding-top: 2px;
    }

    @include mq('x-large') {
      padding-top: 83px;
      padding-right: 82px;
      padding-bottom: 82px;
      padding-left: 82px;
    }

    &__inner {
      max-width: var(--emu-common-sizing-desktop);
      margin-left: auto;
      margin-right: auto;
    }

    // section with title and gif image
    &__gif-section {
      position: relative;

      &-title {
        padding-top: 53px;
        margin-bottom: 46px;

        @include mq('large') {
          margin-bottom: 44px;
        }

        @include mq('x-large') {
          padding-top: 103px;
          margin-bottom: 73px;
        }
      }

      &-img {
        margin-bottom: 50px;
        position: relative;

        // breakpoint needed to match the live site
        @include mq('414px') {
          margin-bottom: 49px;
        }

        // breakpoint needed to match the live site
        @include mq('767px') {
          width: 95%;
          margin-left: auto;
          margin-right: auto;
        }

        @include mq('x-large') {
          width: 100%;
          margin-bottom: 150px;
        }

        .cmp-image__title {
          font-weight: var(--emu-semantic-font-weight-500);
          font-size: 12px;
          line-height: 135%;
          bottom: -30px;
          position: absolute;
          color: var(--emu-common-colors-white);
        }

        img {
          height: 308px;
          object-fit: cover;

          // breakpoint needed to match the live site
          @include mq('321px') {
            height: 346px;
          }

          // breakpoint needed to match the live site
          @include mq('414px') {
            height: 389px;
          }

          // breakpoint needed to match the live site
          @include mq('576px') {
            height: 538px;
          }

          // breakpoint needed to match the live site
          @include mq('1439px') {
            height: 560px;
          }
        }
      }
    }

    // teaser section
    &__teaser {
      background-repeat: no-repeat;
      background-size: cover;
      height: 230px;
      margin-top: 73px;
      margin-bottom: 52px;

      // breakpoint needed to match the live site
      @include mq('321px') {
        height: 253px;
      }

      // breakpoint needed to match the live site
      @include mq('414px') {
        height: 285px;
        margin-bottom: 50px;
      }

      // breakpoint needed to match the live site
      @include mq('576px') {
        height: 402px;
      }

      // breakpoint needed to match the live site
      @include mq('767px') {
        width: 95%;
      }

      // breakpoint needed to match the live site
      @include mq('medium') {
        height: 540px;
        margin-left: auto;
        margin-right: auto;
        margin-top: -5px;
      }

      // breakpoint needed to match the live site
      @media (min-width: 820px) and (max-width: 1023px) and (orientation: portrait) {
        height: 580px;
      }

      @include mq('large') {
        height: 735px;
      }

      @include mq('x-large') {
        height: 572px;
        width: 100%;
        margin-top: 104px;
        margin-bottom: 135px;
      }

      // breakpoint needed to match the live site
      @include mq('1439px') {
        height: 674px;
      }

      .content-container {
        position: static;
      }

      .emu-teaser__disclaimer {
        position: absolute;
        bottom: 0;
        padding-bottom: 10px;
        font-weight: var(--emu-semantic-font-weight-500);
        text-shadow: -1px 1px 9px rgba(0, 0, 0, 0.75);
        text-align: center;
        padding-left: 22px;
        padding-right: 22px;
        width: 100%;
        left: 0;

        p {
          font-size: 10px;
          line-height: 135%;
        }
      }
    }

    // elite teaser
    &__elite-teaser {
      height: 173px;
      background-size: cover;
      background-repeat: no-repeat;

      // breakpoint needed to match the live site
      @include mq('321px') {
        height: 186px;
      }

      // breakpoint needed to match the live site
      @include mq('414px') {
        height: 211px;
      }

      // breakpoint needed to match the live site
      @include mq('576px') {
        height: 320px;
      }

      @include mq('medium') {
        height: 430px;
        margin-top: -5px;
      }

      // breakpoint needed to match the live site
      @media (min-width: 820px) and (max-width: 1023px) and (orientation: portrait) {
        height: 450px;
      }

      @include mq('large') {
        height: 590px;
        margin-top: -3px;
      }

      @include mq('x-large') {
        height: 563px;
        margin-top: 152px;
        margin-bottom: 39px;
      }

      // breakpoint needed to match the live site
      @include mq('1439px') {
        height: 661px;
      }

      .content-container {
        position: static;
      }

      .emu-teaser__disclaimer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding-bottom: 15px;
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);

        @include mq('medium') {
          padding-bottom: 13px;
        }

        sup {
          font-size: 60%;
          top: -0.1em;
        }

        p {
          font-size: 13px;
          line-height: 18px;
          font-family: var(--emu-semantic-font-families-heading);
          font-weight: var(--emu-common-font-weight-bold);
          text-align: center;

          // breakpoint needed to match the live site
          @include mq('414px') {
            font-size: 17px;
            line-height: 23.7px; // value from live site
          }

          // breakpoint needed to match the live site
          @include mq('576px') {
            font-size: 24px;
            line-height: 32.78px; // value from live site
          }

          // breakpoint needed to match the live site
          @include mq('767px') {
            font-size: 30px;
            line-height: 40.98px; // value from live site
          }

          @include mq('x-large') {
            font-size: 24px;
            line-height: 32.7px; // value from live site
          }
        }
      }
    }
  }

  // product switch section
  .product-switch {
    // intentionally left empty to override if needed
  }

  // Find a Provider Banner
  .banner {
    &.banner--fap {
      .banner {
        &__teaser {
          // breakpoint needed to match the live site
          @include mq('320px', 'max-width') {
            height: 715px;
          }

          // breakpoint needed to match the live site
          @media (min-width: 321px) and (max-width: 413px) {
            height: 825px;
          }

          // breakpoint needed to match the live site
          @media (min-width: 414px) and (max-width: 766px) {
            height: 900px;
          }

          @include mq('medium') {
            background-position: top;
          }

          @include mq('large') {
            background-position: center center;
          }
        }

        &__content {
          @include mq('large') {
            top: 0;
            padding-top: 6px;
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
            width: auto;
            margin-left: -15px;
            margin-right: -15px;
            max-width: none;
          }

          // breakpoint needed to match the live site
          @include mq('1025px') {
            top: 15%;
            margin-left: auto;
            margin-right: auto;
            max-width: var(--emu-common-sizing-large-tablet);
          }

          &-inner {
            @include mq('medium') {
              padding-top: 87%; // value from live site
            }

            @include mq('large') {
              padding-top: 81%; // value from live site
            }

            @include mq('x-large') {
              padding-top: 14.7%; // value from live site
            }

            // breakpoint needed to match the live site
            @include mq('1401px') {
              padding-top: 20.62%; // value from live site
            }

            // breakpoint needed to match the live site
            @include mq('1601px') {
              padding-top: 28%; // value from live site
            }
          }

          &-title {
            .cmp-title__text {
              // breakpoint needed to match the live site
              @include mq('1025px') {
                font-size: 64px;
                line-height: 69px;
              }

              @include mq('x-large') {
                color: var(--emu-semantic-colors-secondary-600);
                font-size: 54px;
                line-height: 58px;
              }
            }
          }

          &-text {
            // breakpoint needed to match the live site
            @include mq('1025px') {
              font-size: 28px;
              line-height: 42px;
            }

            @include mq('x-large') {
              color: var(--emu-semantic-colors-secondary-600);
              font-size: 24px;
              line-height: 31px;
            }
          }
        }
      }
    }
  }
}
