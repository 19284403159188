.gallery {
  &.cs-loader__wrapper--show {
    .gallery__carousel {
      visibility: hidden;
    }
  }

  &--tabs-ready {
    &.gallery {
      .gallery__filters-img-tabs {
        display: block;
      }

      .gallery__label-container {
        display: flex;

        @include mq('x-large') {
          display: none;
        }
      }
    }
  }

  &--has-error {
    .gallery__carousel-wrapper {
      display: none;
    }
  }

  // outer container
  &__inner {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 76px;
    padding-right: 13px;
    padding-bottom: 71px;
    padding-left: 13px;

    // breakpoint needed to match the live site
    @include mq('414px') {
      padding-left: 30px;
      padding-right: 30px;
    }

    // breakpoint needed to match the live site
    @include mq('576px') {
      padding-left: 13px;
      padding-right: 13px;
    }

    @include mq('medium') {
      padding-top: 123px;
      padding-right: 84px;
      padding-bottom: 126px;
      padding-left: 84px;
    }

    @include mq('large') {
      padding-bottom: 114px;
    }

    @include mq('x-large') {
      padding-top: 90px;
      padding-right: 80px;
      padding-bottom: var(--emu-common-spacing-large);
      padding-left: 80px;
    }
  }

  // main title
  &__title {
    padding-left: 30px;
    padding-right: 30px;
    margin-right: 10px;
    margin-top: 3px;
    margin-bottom: 6px;

    // breakpoint needed to match the live site
    @include mq('321px') {
      padding-left: 20px;
      padding-right: 20px;
    }

    // breakpoint needed to match the live site
    @include mq('414px') {
      margin-top: var(--emu-common-spacing-xs);
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
    }

    @include mq('medium') {
      margin-top: 6px;
      margin-bottom: 5px;
    }

    @include mq('x-large') {
      margin-top: 5px;
      margin-bottom: 6px;
    }

    .cmp-title__text {
      // breakpoint needed to match the live site
      @media (min-width: 576px) and (max-width: 767px) {
        font-size: 46px;
        line-height: 50px;
      }

      @include mq('x-large') {
        text-align: center;
      }
    }
  }

  // main description
  &__desc {
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 50px;

    // breakpoint needed to match the live site
    @include mq('321px') {
      padding-left: 20px;
      padding-right: 20px;
    }

    // breakpoint needed to match the live site
    @include mq('414px') {
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
    }

    // breakpoint needed to match the live site
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 22px;
      line-height: 40px;
    }

    @include mq('medium') {
      margin-bottom: 76px;
    }

    // breakpoint needed to match the live site
    @include mq('1025px') {
      font-size: 28px;
      line-height: 42px;
    }

    @include mq('x-large') {
      text-align: center;
      padding-left: 18%; // value from live site
      padding-right: 18%; // value from live site
      font-size: var(--emu-semantic-font-sizes-wide-small);
      line-height: var(--emu-semantic-line-heights-wide-small);
      margin-bottom: 54px;
    }
  }

  // filters section with dropdowns and image thumbnails
  &__filters {
    @include mq('x-large') {
      display: flex;
      align-items: center;
      margin-bottom: 70px;

      @include aem-editor-view {
        display: block;
      }
    }

    // container of the left section with buttons and dropdown
    > .container:first-child {
      @include mq('x-large') {
        width: 70%;
      }

      @include aem-editor-view {
        width: 100%;
      }
    }

    // container of the right section with images
    > .container:last-child {
      @include mq('x-large') {
        width: 180px;
        justify-self: flex-end;
        margin-left: auto;
      }

      @include aem-editor-view {
        width: 100%;
      }
    }

    &-section {
      &--left {
        @include mq('x-large') {
          display: flex;
        }

        @include aem-editor-view {
          display: block;
        }
      }
    }

    &-cta {
      // needed to increase specificity
      .emu-button-v2.emu-button.emu-button__primary-filled.emu-button-v2.emu-button.emu-button__primary-filled {
        .cmp-button__text {
          top: 1px;
        }
      }

      // needed to increase specificity
      &.emu-button.emu-button-v2.emu-button-v2 {
        border-radius: var(--emu-common-border-radius-none);
        height: 40px; // value from live site
        font-size: 12px;
        line-height: 40px;
        padding: var(--emu-common-spacing-none);
        width: 100%;

        @include mq('medium') {
          font-size: 28px;
          height: var(--emu-common-sizing-large); // value from live site
          line-height: 64px;
        }

        @include mq('x-large') {
          font-size: 18px;
          height: 50px;
          line-height: 50px;
        }
      }

      &--inactive {
        opacity: 0.25;
      }

      &-container {
        border-radius: 12px;
        overflow: hidden;
        display: flex;
        margin-bottom: 25px;

        @include mq('medium') {
          margin-bottom: 45px;
        }

        @include mq('x-large') {
          margin-right: 20px;
          margin-left: 20px;
          margin-bottom: 22px;
        }

        .button {
          width: 50%;

          @include mq('x-large') {
            width: 194px;
          }
        }
      }
    }

    &-dropdown {
      width: 90%;

      // breakpoint needed to match the live site
      @include mq('576px') {
        width: auto;
      }

      @include mq('medium') {
        width: 270px; // value from live site
      }

      @include mq('large') {
        width: 385px; // value from live site
      }

      &--gender {
        [aria-selected='true'] {
          display: none;
        }
      }

      &-container {
        display: flex;
        justify-content: center;
        margin-bottom: 70px;

        @include mq('medium') {
          margin-bottom: 90px;
        }

        @include mq('x-large') {
          margin-bottom: var(--emu-common-spacing-none);
        }

        > .options {
          width: 50%;
          display: flex;

          @include mq('x-large') {
            margin-right: 20px;
            margin-left: 20px;
            margin-bottom: 22px;
            width: 172px;
          }

          &:last-child {
            justify-content: flex-end;
          }
        }
      }
    }

    &-img {
      &-tabs {
        margin-bottom: 52px;
        min-height: 410px; // value from live site. Needed to make sure that the space is present when tabs are shifted
        display: none;

        @include mq('medium') {
          min-height: 710px;
        }

        @include mq('x-large') {
          min-height: 170px;
          margin-bottom: var(--emu-common-spacing-none);
        }

        @include aem-editor-view {
          display: none;
        }

        .aaaem-tabs__tablist {
          display: none;

          @include aem-editor-view {
            display: block;
          }
        }

        img {
          width: 100%;
          height: 410px; // value from live site
          aspect-ratio: 165 / 410;
          width: 165px;
          object-fit: contain;

          @include mq('medium') {
            width: 286px;
            height: 710px; // value from live site
          }

          @include mq('x-large') {
            width: 68px;
            height: 170px;
          }

          @include aem-editor-view {
            height: 400px;
          }
        }
      }

      &-container {
        &.aaaem-container {
          display: flex;
          justify-content: center;

          @include mq('x-large') {
            justify-content: flex-end;
            margin-right: 20px;
          }
        }

        > .image {
          &:not(:last-child) {
            @include mq('x-large') {
              margin-right: var(--emu-common-spacing-xs);
            }
          }
        }
      }
    }
  }

  // back-side and front-side text labels beneath image thumbnails in mobile
  &__label {
    padding-top: var(--emu-common-spacing-small);
    padding-bottom: var(--emu-common-spacing-small);

    // breakpoint needed to match the live site
    @include mq('576px') {
      padding-top: 12px;
    }

    @include mq('medium') {
      padding-top: var(--emu-common-spacing-xs);
    }

    @include mq('large') {
      padding-top: var(--emu-common-spacing-none);
    }

    .cmp-title__text {
      font-size: 11px;
      letter-spacing: 0.65px;

      // breakpoint needed to match the live site
      @include mq('414px') {
        font-size: 13px;
      }

      // breakpoint needed to match the live site
      @include mq('576px') {
        font-size: 19px;
      }

      @include mq('medium') {
        font-size: 27px;
      }
    }

    &-container {
      display: none;
      justify-content: center;
      margin-bottom: 60px;

      // breakpoint needed to match the live site
      @include mq('414px') {
        margin-bottom: 63px;
      }

      // breakpoint needed to match the live site
      @include mq('576px') {
        margin-bottom: 72px;
      }

      @include mq('medium') {
        margin-bottom: 66px;
      }

      @include mq('large') {
        margin-bottom: 57px;
      }

      @include mq('x-large') {
        display: none;
      }

      @include aem-editor-view {
        display: flex !important;
      }

      > .title {
        width: 50%;

        @include mq('large') {
          max-width: 288px; // value from live site
        }
      }
    }
  }

  // carousel
  &__carousel {
    // label that shows all, female-abdomen etc
    &-label {
      display: none;
      margin-bottom: 22px;

      @include mq('medium') {
        margin-bottom: 25px;
      }

      @include mq('x-large') {
        margin-bottom: 19px;
      }

      @include aem-editor-view {
        display: block;
      }

      &--show {
        display: block;
      }

      .cmp-title__text {
        font-size: 14px;
        line-height: 1;

        @include mq('medium') {
          font-size: 30px;
        }

        @include mq('x-large') {
          font-size: 16px;
          padding-left: 10px;
        }
      }
    }

    .aaaem-carousel {
      &__action {
        top: 50%;
        transform: translateY(-50%) translateY(-73px);
        height: 41px;

        @include mq('medium') {
          transform: translateY(-50%) translateY(-79px);
        }

        @include mq('x-large') {
          transform: translateY(-50%) translateY(-81px);
        }

        @include mq('x-large') {
          top: 54%;
        }

        &::before {
          background-image: url('../../assets/images/gallery-next-arrow.png');
          width: 18px;
          height: 25px;
        }

        &-next {
          right: -6px;

          @include mq('medium') {
            right: -26px;
          }
        }

        &-prev {
          left: -6px;

          @include mq('medium') {
            left: -26px;
          }
        }
      }
    }

    &-slide {
      &-main {
        margin-left: 20px;
        margin-right: 20px;

        @include mq('x-large') {
          display: flex;
          justify-content: center;
        }

        // if there are only two elements in container
        &:has(.container:nth-child(2):last-child) {
          @include mq('x-large') {
            justify-content: space-between;
          }
        }

        > .container {
          @include mq('x-large') {
            flex: 0 0 320px;
          }

          &:not(:last-child) {
            @include mq('x-large') {
              margin-right: 10px;
            }
          }
        }
      }
    }

    &-ba {
      &-container {
        margin-bottom: 23px;

        @include mq('medium') {
          margin-bottom: 39px;
        }

        @include mq('x-large') {
          margin-bottom: var(--emu-common-spacing-none);
        }
      }

      &-img {
        &,
        img {
          aspect-ratio: 419 / 290;
        }

        img {
          margin-left: auto;
          margin-right: auto;
          width: 100%;

          @include mq('medium') {
            width: 100%;
          }

          @include mq('large') {
            max-width: 90%;
          }

          @include mq('x-large') {
            max-width: 100%;
          }

          &.cmp-image__image--is-loading {
            max-height: var(--emu-common-sizing-none);
          }
        }

        .cmp-image__title {
          margin-top: 27px;
          display: block;
          text-align: center; // needed as this is not a part of title/text component
          font-family: var(--emu-semantic-font-families-heading);
          font-size: 13px;
          line-height: 1;

          @include mq('414px') {
            font-size: 14px;
          }

          @include mq('medium') {
            font-size: 30px;
            margin-top: 20px;
          }

          @include mq('x-large') {
            font-size: 14px;
            margin-top: 27px;
          }
        }
      }

      &-disc {
        margin-top: var(--emu-common-spacing-xxs);
        font-size: 12px;
        letter-spacing: 0.1px;

        @include mq('medium') {
          margin-top: 53px;
          font-size: 20px;
          font-family: var(--emu-semantic-font-families-heading);
        }

        @include mq('large') {
          margin-top: 50px;
        }

        @include mq('x-large') {
          margin-top: -2px;
          font-size: 11px;
          font-family: var(--emu-semantic-font-families-body);
        }

        sup {
          @include mq('medium') {
            top: -0.1em;
            font-size: 72%;
          }

          @include mq('large') {
            top: 0.15em;
          }

          @include mq('x-large') {
            top: 0em;
          }
        }

        p,
        li {
          font-size: inherit;
          letter-spacing: inherit;
          font-family: inherit;
        }
      }
    }

    &-disclaimer {
      font-size: 12px;
      margin-top: 23px;

      @include mq('medium') {
        font-size: 20px;
        margin-top: -25px;
      }

      @include mq('large') {
        margin-top: -30px;
      }

      @include mq('x-large') {
        font-size: 12px;
        margin-top: 25px;
      }

      h5 {
        @include mq('medium') {
          line-height: 1.5;
        }

        .emphasis {
          display: inline-block;
        }
      }

      p {
        &:has(.emphasis) {
          margin-top: var(--emu-common-spacing-xxs);

          @include mq('medium') {
            margin-top: var(--emu-common-spacing-medium);
          }

          @include mq('large') {
            margin-top: 12px;
          }

          @include mq('x-large') {
            margin-top: -4px;
          }
        }
      }

      p,
      li,
      h5 {
        font-size: inherit;
        line-height: inherit;
      }
    }
  }

  // error messages
  &__error {
    display: none;
    font-size: 16px;
    line-height: 24px;
    font-family: var(--emu-semantic-font-families-heading);
    font-weight: 600;
    color: #ff0000; // As there is only one occurrence of this color
    padding-left: 10px;
    margin-top: -11px;
    margin-bottom: 20px;

    @include mq('medium') {
      margin-top: -7px;
      margin-bottom: 13px;
    }

    @include mq('large') {
      margin-top: -6px;
      margin-bottom: 24px;
    }

    @include mq('x-large') {
      margin-top: -4px;
      margin-bottom: 23px;
    }

    @include aem-editor-view {
      display: block;
    }

    &--show {
      display: block;
    }

    p {
      font-size: inherit;
      line-height: inherit;
      color: inherit;
      font-family: inherit;
    }
  }
}
