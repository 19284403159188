#esf-see-results {
  // gallery specific overrides
  .gallery {
    .emu-mapping-marker {
      &[aria-label*='female-thighs'] {
        min-width: 87px;
        min-height: 87px;

        @include mq('medium') {
          min-width: 150px;
          min-height: 140px;
        }
      }

      &[aria-label*='-male-thighs'] {
        min-width: 83px;
        min-height: 78px;

        @include mq('medium') {
          min-width: 147px;
          min-height: 138px;
        }
      }
    }

    .gallery__filter-img--back {
      .emu-mapping-marker {
        &[aria-label*='female-flanks'] {
          min-width: 75px;
          min-height: var(--emu-common-sizing-medium);

          @include mq('medium') {
            min-width: 124px;
            min-height: 59px;
          }
        }

        &[aria-label*='female-thighs'] {
          min-width: 45px;
          min-height: 28px;

          @include mq('medium') {
            min-width: 55px;
            min-height: 49px;
          }
        }

        &[aria-label*='-male-flanks'] {
          min-width: 73px;
          min-height: 40px;

          @include mq('medium') {
            min-width: 128px;
            min-height: 67px;
          }
        }

        &[aria-label*='-male-thighs'] {
          min-width: 34px;
          min-height: 25px;

          @include mq('medium') {
            min-width: 40px;
            min-height: 44px;
          }
        }
      }
    }
  }
}
