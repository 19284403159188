@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(1turn);
  }
}

.cs-loader {
  display: none;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: var(--emu-common-other-z-index-layer);

  path {
    stroke: var(--emu-common-colors-white);
  }

  &__wrapper {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: var(--emu-common-colors-transparent);
      display: none;
    }

    &--show {
      pointer-events: none;
      .cs-loader,
      &::after {
        display: block;
      }
    }
  }
}
