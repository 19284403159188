.product-switch {
  &__wrapper {
    position: relative;
    background-position: top center;
  }

  &__title {
    .cmp-title__text {
      font-family: var(--emu-semantic-font-families-heading);
      font-weight: var(--emu-common-font-weight-black);
      margin-top: 38px;
      padding-right: 10px;
      margin-bottom: 28px;
      padding-left: 10px;

      // breakpoint needed to match the live site
      @include mq('349px', 'max-width') {
        font-size: 28px;
        line-height: 41px;
        margin-top: 40px;
        margin-bottom: 27px;
      }

      // breakpoint needed to match the live site
      @include mq('414px') {
        font-size: 33px;
        line-height: var(--emu-common-line-heights-narrow-xl);
        margin-bottom: 26px;
        margin-top: 40px;
      }

      @include mq('medium') {
        font-size: 64px;
        line-height: 70px;
        margin-bottom: 50px;
        padding-left: 50px;
        padding-right: 50px;
      }

      // breakpoint needed to match the live site
      @include mq('1025px') {
        font-size: var(--emu-semantic-font-sizes-wide-xl);
        line-height: var(--emu-semantic-line-heights-wide-xl);
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
      }

      @include mq('x-large') {
        margin-top: 45px;
        margin-bottom: 35px;
      }
    }
  }

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include mq('large') {
      max-width: var(--emu-common-sizing-tablet);
      margin-left: auto;
      margin-right: auto;
    }

    @include mq('x-large') {
      max-width: var(--emu-semantic-sizing-breakpoints-large-plus);
    }

    > .container {
      align-self: stretch;
      flex-basis: 40%; // value from live site
      flex-shrink: 0;
    }

    &-inner {
      position: relative;
      padding-top: var(--emu-common-spacing-none);
      padding-bottom: var(--emu-common-spacing-none);

      @include mq('x-large') {
        display: flex;
        justify-content: center;
        gap: 30px;
      }

      > .container {
        @include mq('x-large') {
          width: 540px;
        }
      }
    }
  }

  &__img-container {
    position: relative;

    > .button {
      position: static;
    }
  }

  &__image {
    position: relative;

    &--desktop {
      display: none;

      // breakpoint needed to match the design
      @include mq('x-large') {
        display: block;
      }
    }

    &--mobile {
      // breakpoint needed to match the design
      @include mq('x-large') {
        display: none;
      }
    }

    > img {
      width: 100%; //to match with design
      height: 100%;
      margin: auto;
      object-fit: cover;
      height: 350px;

      // breakpoint needed to match the live site
      @include mq('321px') {
        height: 400px;
      }

      // breakpoint needed to match the live site
      @include mq('414px') {
        height: 440px;
      }

      @include mq('medium') {
        height: 850px;
      }

      @include mq('large') {
        height: 1080px;
      }

      @include mq('x-large') {
        height: 561px;
      }
    }

    // needed to increase the specificity
    &-button.emu-button.emu-button-v2.emu-button-v2 {
      position: absolute;
      bottom: 31px;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
      padding-left: 34px;
      padding-right: 34px;
      min-width: 250px;

      @include mq('medium') {
        bottom: 25px;
      }

      @include mq('large') {
        bottom: 26px;
      }

      span.cmp-button__text {
        top: 1px;
      }
    }
  }

  &__description {
    margin-bottom: 75px;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 10px;

    * {
      font-size: var(--emu-semantic-font-sizes-narrow-xs);
      line-height: 15px;
    }

    // breakpoint needed to match the live site
    @include mq('414px') {
      margin-bottom: 85px;
    }

    @include mq('medium') {
      margin-top: 45px;
      margin-bottom: 92px;
    }

    @include mq('x-large') {
      margin-top: 36px;
      margin-bottom: 40px;
      max-width: 540px;
    }

    sup {
      font-size: 6px;
      top: 1px;
    }
  }
}
