body {
  font-family: var(--emu-semantic-font-families-body);
  font-weight: var(--emu-semantic-font-weight-regular);
}

h1 {
  font: var(--emu-component-ingredients-title-h1-typography-narrow);
}

@media (min-width: 1024px) {
  h1 {
    font: var(--emu-component-ingredients-title-h1-typography-wide);
  }
}

h2 {
  font: var(--emu-component-ingredients-title-h2-typography-narrow);
}

@media (min-width: 1024px) {
  h2 {
    font: var(--emu-component-ingredients-title-h2-typography-wide);
  }
}

h3 {
  font: var(--emu-component-ingredients-title-h3-typography-narrow);
}

@media (min-width: 1024px) {
  h3 {
    font: var(--emu-component-ingredients-title-h3-typography-wide);
  }
}

h4 {
  font: var(--emu-component-ingredients-title-h4-typography-narrow);
}

@media (min-width: 1024px) {
  h4 {
    font: var(--emu-component-ingredients-title-h4-typography-wide);
  }
}

h5 {
  font: var(--emu-component-ingredients-title-h5-typography-narrow);
}

@media (min-width: 1024px) {
  h5 {
    font: var(--emu-component-ingredients-title-h5-typography-wide);
  }
}

b {
  font-family: var(--emu-semantic-font-families-heading);
  font-weight: var(--emu-common-font-weight-regular);
}

:root {
  --emu-common-spacing-xs: 4px;
  --emu-common-spacing-xxs: 2px;
  --emu-common-spacing-small: 8px;
  --emu-common-spacing-medium: 16px;
  --emu-common-spacing-large: 32px;
  --emu-common-spacing-xl: 64px;
  --emu-common-spacing-none: 0px;
  --emu-common-spacing-unset: unset;
  --emu-common-colors-white: #fff;
  --emu-common-colors-black: #000;
  --emu-common-colors-red-50: #fff5f5;
  --emu-common-colors-red-100: #ffdcdb;
  --emu-common-colors-red-200: #ffaba8;
  --emu-common-colors-red-300: #ff7f7a;
  --emu-common-colors-red-400: #ff4e47;
  --emu-common-colors-red-500: #ff1d15;
  --emu-common-colors-red-600: #db0700;
  --emu-common-colors-red-700: #a30500;
  --emu-common-colors-red-800: #700400;
  --emu-common-colors-red-900: #380200;
  --emu-common-colors-red-950: #190100;
  --emu-common-colors-grey-100: #f4f5f5;
  --emu-common-colors-grey-200: #d4d7d8;
  --emu-common-colors-grey-300: #b4b8bb;
  --emu-common-colors-grey-400: #949a9e;
  --emu-common-colors-grey-500: #7e868b;
  --emu-common-colors-grey-600: #61676b;
  --emu-common-colors-grey-700: #44484b;
  --emu-common-colors-grey-800: #303436;
  --emu-common-colors-grey-900: #1d1f20;
  --emu-common-colors-transparent: #0000;
  --emu-common-colors-teal-50: #e0fbff;
  --emu-common-colors-teal-100: #c2f7ff;
  --emu-common-colors-teal-200: #85efff;
  --emu-common-colors-teal-300: #42e6ff;
  --emu-common-colors-teal-400: #05deff;
  --emu-common-colors-teal-500: #00abc7;
  --emu-common-colors-teal-600: #00899e;
  --emu-common-colors-teal-700: #006675;
  --emu-common-colors-teal-800: #004752;
  --emu-common-colors-teal-900: #002329;
  --emu-common-colors-teal-950: #001214;
  --emu-common-colors-beige-50: #fefcfb;
  --emu-common-colors-beige-100: #fdf9f7;
  --emu-common-colors-beige-200: #f9efeb;
  --emu-common-colors-beige-300: #f7e8e3;
  --emu-common-colors-beige-400: #f5e2db;
  --emu-common-colors-beige-500: #f2d9d0;
  --emu-common-colors-beige-600: #dfa18b;
  --emu-common-colors-beige-700: #cb6743;
  --emu-common-colors-beige-800: #8c4227;
  --emu-common-colors-beige-900: #482214;
  --emu-common-colors-beige-950: #24110a;
  --emu-common-colors-light-brown-50: #faf6f5;
  --emu-common-colors-light-brown-100: #f5ecea;
  --emu-common-colors-light-brown-200: #ead7d2;
  --emu-common-colors-light-brown-300: #e0c4bd;
  --emu-common-colors-light-brown-400: #d5aea5;
  --emu-common-colors-light-brown-500: #ca9a8e;
  --emu-common-colors-light-brown-600: #b4705f;
  --emu-common-colors-light-brown-700: #8e5243;
  --emu-common-colors-light-brown-800: #5e362c;
  --emu-common-colors-light-brown-900: #311c17;
  --emu-common-colors-light-brown-950: #180e0b;
  --emu-common-colors-medium-brown-50: #f6f0ef;
  --emu-common-colors-medium-brown-100: #ebdddb;
  --emu-common-colors-medium-brown-200: #d6bcb8;
  --emu-common-colors-medium-brown-300: #c29a94;
  --emu-common-colors-medium-brown-400: #ad7971;
  --emu-common-colors-medium-brown-500: #925c54;
  --emu-common-colors-medium-brown-600: #754a43;
  --emu-common-colors-medium-brown-700: #573732;
  --emu-common-colors-medium-brown-800: #3a2522;
  --emu-common-colors-medium-brown-900: #1d1211;
  --emu-common-colors-medium-brown-950: #100a09;
  --emu-common-colors-dark-brown-50: #f1e4e5;
  --emu-common-colors-dark-brown-100: #e3cacb;
  --emu-common-colors-dark-brown-200: #c8989a;
  --emu-common-colors-dark-brown-300: #ac6365;
  --emu-common-colors-dark-brown-400: #7e4345;
  --emu-common-colors-dark-brown-500: #492728;
  --emu-common-colors-dark-brown-600: #3c2021;
  --emu-common-colors-dark-brown-700: #2b1718;
  --emu-common-colors-dark-brown-800: #1e1010;
  --emu-common-colors-dark-brown-900: #0d0707;
  --emu-common-colors-dark-brown-950: #070404;
  --emu-common-colors-light-grey-50: #fff;
  --emu-common-colors-light-grey-100: #fdfcfc;
  --emu-common-colors-light-grey-200: #fdfcfc;
  --emu-common-colors-light-grey-300: #fcf9f8;
  --emu-common-colors-light-grey-400: #fcf9f8;
  --emu-common-colors-light-grey-500: #faf6f5;
  --emu-common-colors-light-grey-600: #dabcb4;
  --emu-common-colors-light-grey-700: #b87f70;
  --emu-common-colors-light-grey-800: #855042;
  --emu-common-colors-light-grey-900: #221411;
  --emu-common-colors-medium-grey-50: #fff;
  --emu-common-colors-medium-grey-100: #f8f7f7;
  --emu-common-colors-medium-grey-200: #e6e5e5;
  --emu-common-colors-medium-grey-300: #d8d5d5;
  --emu-common-colors-medium-grey-400: #c9c5c5;
  --emu-common-colors-medium-grey-500: #b9b4b4;
  --emu-common-colors-medium-grey-600: #958e8e;
  --emu-common-colors-medium-grey-700: #716a6a;
  --emu-common-colors-medium-grey-800: #4c4747;
  --emu-common-colors-medium-grey-900: #252222;
  --emu-common-colors-medium-grey-950: #121111;
  --emu-common-colors-dark-grey-50: #f7f7f7;
  --emu-common-colors-dark-grey-100: #e8e8e8;
  --emu-common-colors-dark-grey-200: #c9c9ca;
  --emu-common-colors-dark-grey-300: #adadae;
  --emu-common-colors-dark-grey-400: #8e8f90;
  --emu-common-colors-dark-grey-500: #6f7071;
  --emu-common-colors-dark-grey-600: #58595a;
  --emu-common-colors-dark-grey-700: #424243;
  --emu-common-colors-dark-grey-800: #2d2e2e;
  --emu-common-colors-dark-grey-900: #171717;
  --emu-common-colors-dark-grey-950: #0a0a0a;
  --emu-common-colors-blush-50: #fff;
  --emu-common-colors-blush-100: #fdfcfc;
  --emu-common-colors-blush-200: #f4ebef;
  --emu-common-colors-blush-300: #eddee4;
  --emu-common-colors-blush-400: #e6d1d9;
  --emu-common-colors-blush-500: #ddc1cc;
  --emu-common-colors-blush-600: #c08ca1;
  --emu-common-colors-blush-700: #a15876;
  --emu-common-colors-blush-800: #693a4d;
  --emu-common-colors-blush-900: #351d26;
  --emu-common-colors-blush-950: #1a0e13;
  --emu-common-colors-abbvie-blue-50: #e3ebfc;
  --emu-common-colors-abbvie-blue-100: #bed1f9;
  --emu-common-colors-abbvie-blue-200: #6a97f1;
  --emu-common-colors-abbvie-blue-300: #1b60e9;
  --emu-common-colors-abbvie-blue-400: #0f3d9a;
  --emu-common-colors-abbvie-blue-500: #071d49;
  --emu-common-colors-abbvie-blue-600: #06183c;
  --emu-common-colors-abbvie-blue-700: #04112a;
  --emu-common-colors-abbvie-blue-800: #030b1c;
  --emu-common-colors-abbvie-blue-900: #01060e;
  --emu-common-colors-abbvie-blue-950: #010409;
  --emu-common-font-families-sans: Arial, sans-serif;
  --emu-common-font-families-serif: Times New Roman, serif;
  --emu-common-font-families-mono: Courier, monospace;
  --emu-common-font-weight-bold: 700;
  --emu-common-font-weight-light: 300;
  --emu-common-font-weight-black: 900;
  --emu-common-font-weight-regular: 400;
  --emu-common-font-sizes-narrow-medium: 12px;
  --emu-common-font-sizes-narrow-large: 16.97px;
  --emu-common-font-sizes-narrow-xl: 23.99px;
  --emu-common-font-sizes-narrow-xxl: 33.93px;
  --emu-common-font-sizes-narrow-xxxl: 47.97px;
  --emu-common-font-sizes-wide-medium: 16px;
  --emu-common-font-sizes-wide-large: 22.62px;
  --emu-common-font-sizes-wide-xl: 31.99px;
  --emu-common-font-sizes-wide-xxl: 45.23px;
  --emu-common-font-sizes-wide-xxxl: 63.96px;
  --emu-common-line-heights-narrow-large: 18px;
  --emu-common-line-heights-narrow-medium: 18px;
  --emu-common-line-heights-narrow-xl: 36px;
  --emu-common-line-heights-narrow-xxl: 36px;
  --emu-common-line-heights-narrow-xxxl: 54px;
  --emu-common-line-heights-wide-large: 24px;
  --emu-common-line-heights-wide-medium: 24px;
  --emu-common-line-heights-wide-xl: 48px;
  --emu-common-line-heights-wide-xxl: 48px;
  --emu-common-line-heights-wide-xxxl: 72px;
  --emu-common-border-radius-xxs: 2px;
  --emu-common-border-radius-xs: 4px;
  --emu-common-border-radius-small: 8px;
  --emu-common-border-radius-medium: 16px;
  --emu-common-border-radius-large: 32px;
  --emu-common-border-radius-none: 0px;
  --emu-common-border-width-thin: 1px;
  --emu-common-border-width-medium: 2px;
  --emu-common-border-width-thick: 4px;
  --emu-common-border-width-none: 0px;
  --emu-common-outline-width-thin: thin;
  --emu-common-outline-width-medium: medium;
  --emu-common-outline-width-thick: thick;
  --emu-common-outline-width-unset: unset;
  --emu-common-sizing-none: 0px;
  --emu-common-sizing-xxs: 1px;
  --emu-common-sizing-xs: 8px;
  --emu-common-sizing-small: 16px;
  --emu-common-sizing-medium: 32px;
  --emu-common-sizing-large: 64px;
  --emu-common-sizing-xl: 120px;
  --emu-common-sizing-xxl: 256px;
  --emu-common-sizing-tablet: 960px;
  --emu-common-sizing-large-tablet: 1140px;
  --emu-common-sizing-desktop: 1200px;
  --emu-common-sizing-container: 1520px;
  --emu-common-other-time-transition-short: .2s;
  --emu-common-other-time-transition-base: .4s;
  --emu-common-other-time-transition-long: .6s;
  --emu-common-other-time-transition-xl: 1s;
  --emu-common-other-time-duration-instant: .4s;
  --emu-common-other-time-duration-short: 2s;
  --emu-common-other-time-duration-base: 4s;
  --emu-common-other-time-duration-long: 6s;
  --emu-common-other-time-delay-none: 0s;
  --emu-common-other-time-delay-short: 50ms;
  --emu-common-other-time-delay-base: .1s;
  --emu-common-other-time-delay-long: .2s;
  --emu-common-other-z-index-cookie-banner: 700;
  --emu-common-other-z-index-modal: 100;
  --emu-common-other-z-index-header: 500;
  --emu-common-other-z-index-isi: 400;
  --emu-common-other-z-index-overlay: 300;
  --emu-common-other-z-index-layer: 100;
  --emu-common-other-z-index-base: 0;
  --emu-common-other-z-index-behind: -1;
  --emu-common-box-shadow-soft-light: 0 0 6 0 var(--emu-common-colors-grey-700);
  --emu-semantic-colors-none: var(--emu-common-colors-transparent);
  --emu-semantic-colors-surface-light: var(--emu-common-colors-light-brown-100);
  --emu-semantic-colors-surface-dark: var(--emu-common-colors-dark-brown-500);
  --emu-semantic-colors-surface-modal-backdrop: #0006;
  --emu-semantic-colors-actions-primary-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-semantic-colors-actions-primary-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-semantic-colors-actions-primary-default-light: var(--emu-semantic-colors-primary-500);
  --emu-semantic-colors-actions-primary-default-dark: var(--emu-common-colors-beige-500);
  --emu-semantic-colors-actions-primary-hover-light: var(--emu-semantic-colors-primary-600);
  --emu-semantic-colors-actions-primary-hover-dark: var(--emu-common-colors-beige-300);
  --emu-semantic-colors-actions-primary-active-light: var(--emu-semantic-colors-primary-600);
  --emu-semantic-colors-actions-primary-active-dark: var(--emu-common-colors-beige-300);
  --emu-semantic-colors-actions-primary-focus-light: var(--emu-semantic-colors-primary-600);
  --emu-semantic-colors-actions-primary-focus-dark: var(--emu-common-colors-beige-300);
  --emu-semantic-colors-actions-primary-disabled-light: var(--emu-common-colors-medium-grey-500);
  --emu-semantic-colors-actions-primary-disabled-dark: var(--emu-common-colors-medium-grey-300);
  --emu-semantic-colors-actions-primary-error-light: var(--emu-semantic-colors-error-500);
  --emu-semantic-colors-actions-primary-error-dark: var(--emu-semantic-colors-error-300);
  --emu-semantic-colors-actions-on-primary-light: var(--emu-common-colors-white);
  --emu-semantic-colors-actions-on-primary-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-actions-secondary-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-semantic-colors-actions-secondary-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-semantic-colors-actions-secondary-default-light: var(--emu-semantic-colors-secondary-500);
  --emu-semantic-colors-actions-secondary-default-dark: var(--emu-common-colors-blush-500);
  --emu-semantic-colors-actions-secondary-hover-light: var(--emu-semantic-colors-secondary-600);
  --emu-semantic-colors-actions-secondary-hover-dark: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-actions-secondary-active-light: var(--emu-semantic-colors-secondary-600);
  --emu-semantic-colors-actions-secondary-active-dark: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-actions-secondary-focus-light: var(--emu-semantic-colors-secondary-600);
  --emu-semantic-colors-actions-secondary-focus-dark: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-actions-secondary-disabled-light: var(--emu-common-colors-medium-grey-300);
  --emu-semantic-colors-actions-secondary-disabled-dark: var(--emu-common-colors-medium-grey-200);
  --emu-semantic-colors-actions-on-secondary-light: var(--emu-common-colors-white);
  --emu-semantic-colors-actions-on-secondary-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-text-light: var(--emu-common-colors-black);
  --emu-semantic-colors-text-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-foreground-light: var(--emu-common-colors-medium-brown-300);
  --emu-semantic-colors-foreground-dark: var(--emu-common-colors-beige-500);
  --emu-semantic-colors-primary-0: var(--emu-common-colors-white);
  --emu-semantic-colors-primary-50: #6bc2e6;
  --emu-semantic-colors-primary-100: #005cb9;
  --emu-semantic-colors-primary-200: #005eb8;
  --emu-semantic-colors-primary-300: #0c5bac;
  --emu-semantic-colors-primary-400: #074480;
  --emu-semantic-colors-primary-500: #0c2e77;
  --emu-semantic-colors-primary-600: #011a66;
  --emu-semantic-colors-primary-700: #0f2a64;
  --emu-semantic-colors-primary-800: #142d7b;
  --emu-semantic-colors-primary-900: #05103a;
  --emu-semantic-colors-primary-950: #002554;
  --emu-semantic-colors-primary-light: var(--emu-semantic-colors-primary-500);
  --emu-semantic-colors-primary-dark: var(--emu-semantic-colors-primary-200);
  --emu-semantic-colors-secondary-50: #f3f3f3;
  --emu-semantic-colors-secondary-100: #ececec;
  --emu-semantic-colors-secondary-200: #9da6ae;
  --emu-semantic-colors-secondary-300: #7f878f;
  --emu-semantic-colors-secondary-400: #616468;
  --emu-semantic-colors-secondary-500: #616568;
  --emu-semantic-colors-secondary-600: #393a3b;
  --emu-semantic-colors-secondary-700: #6b6966;
  --emu-semantic-colors-secondary-800: #ebeff5;
  --emu-semantic-colors-secondary-900: var(--emu-common-colors-blush-900);
  --emu-semantic-colors-secondary-950: var(--emu-common-colors-blush-950);
  --emu-semantic-colors-secondary-light: var(--emu-semantic-colors-secondary-500);
  --emu-semantic-colors-secondary-dark: var(--emu-semantic-colors-secondary-200);
  --emu-semantic-colors-tertiary-50: var(--emu-common-colors-abbvie-blue-50);
  --emu-semantic-colors-tertiary-100: var(--emu-common-colors-abbvie-blue-100);
  --emu-semantic-colors-tertiary-200: var(--emu-common-colors-abbvie-blue-200);
  --emu-semantic-colors-tertiary-300: var(--emu-common-colors-abbvie-blue-300);
  --emu-semantic-colors-tertiary-400: var(--emu-common-colors-abbvie-blue-400);
  --emu-semantic-colors-tertiary-500: var(--emu-common-colors-abbvie-blue-500);
  --emu-semantic-colors-tertiary-600: var(--emu-common-colors-abbvie-blue-600);
  --emu-semantic-colors-tertiary-700: var(--emu-common-colors-abbvie-blue-700);
  --emu-semantic-colors-tertiary-800: var(--emu-common-colors-abbvie-blue-800);
  --emu-semantic-colors-tertiary-900: var(--emu-common-colors-abbvie-blue-900);
  --emu-semantic-colors-tertiary-950: var(--emu-common-colors-abbvie-blue-950);
  --emu-semantic-colors-tertiary-light: var(--emu-semantic-colors-tertiary-500);
  --emu-semantic-colors-tertiary-dark: var(--emu-semantic-colors-tertiary-200);
  --emu-semantic-colors-error-50: var(--emu-common-colors-red-50);
  --emu-semantic-colors-error-100: var(--emu-common-colors-red-100);
  --emu-semantic-colors-error-200: var(--emu-common-colors-red-200);
  --emu-semantic-colors-error-300: var(--emu-common-colors-red-300);
  --emu-semantic-colors-error-400: var(--emu-common-colors-red-400);
  --emu-semantic-colors-error-500: var(--emu-common-colors-red-500);
  --emu-semantic-colors-error-600: var(--emu-common-colors-red-600);
  --emu-semantic-colors-error-700: var(--emu-common-colors-red-700);
  --emu-semantic-colors-error-800: var(--emu-common-colors-red-800);
  --emu-semantic-colors-error-900: var(--emu-common-colors-red-900);
  --emu-semantic-colors-error-950: var(--emu-common-colors-red-950);
  --emu-semantic-colors-error-light: var(--emu-semantic-colors-error-500);
  --emu-semantic-colors-error-dark: var(--emu-semantic-colors-error-200);
  --emu-semantic-colors-background-light: var(--emu-common-colors-light-brown-50);
  --emu-semantic-colors-background-dark: var(--emu-common-colors-beige-500);
  --emu-semantic-font-weight-500: 500;
  --emu-semantic-font-weight-600: 600;
  --emu-semantic-font-weight-900: 900;
  --emu-semantic-font-weight-bold: var(--emu-common-font-weight-bold);
  --emu-semantic-font-weight-light: var(--emu-common-font-weight-light);
  --emu-semantic-font-weight-black: var(--emu-common-font-weight-black);
  --emu-semantic-font-weight-regular: var(--emu-common-font-weight-regular);
  --emu-semantic-font-families-body: "AvenirNextRegular", sans-serif;
  --emu-semantic-font-families-heading: "AvenirNextBold", sans-serif;
  --emu-semantic-font-families-mono: "AvenirNextMedium", sans-serif;
  --emu-semantic-spacing-none: var(--emu-common-spacing-none);
  --emu-semantic-spacing-one-line-height-wide: var(--emu-semantic-line-heights-wide-small);
  --emu-semantic-spacing-one-line-height-narrow: var(--emu-semantic-line-heights-narrow-small);
  --emu-semantic-spacing-two-line-height-wide: calc(var(--emu-semantic-spacing-one-line-height-wide) * 2);
  --emu-semantic-spacing-two-line-height-narrow: calc(var(--emu-semantic-spacing-one-line-height-narrow) * 2);
  --emu-semantic-spacing-three-line-height-wide: calc(var(--emu-semantic-spacing-one-line-height-wide) * 3);
  --emu-semantic-spacing-three-line-height-narrow: calc(var(--emu-semantic-spacing-one-line-height-narrow) * 3);
  --emu-semantic-spacing-rem-horizontal: var(--emu-common-spacing-none);
  --emu-semantic-spacing-horizontal-narrow: var(--emu-common-spacing-none);
  --emu-semantic-spacing-horizontal-wide: var(--emu-common-spacing-none);
  --emu-semantic-spacing-horizontal-onerem-narrow: 1rem;
  --emu-semantic-spacing-horizontal-onerem-wide: 1rem;
  --emu-semantic-spacing-horizontal-tworem-narrow: calc(var(--emu-semantic-spacing-horizontal-onerem-narrow) * 2);
  --emu-semantic-spacing-horizontal-tworem-wide: calc(var(--emu-semantic-spacing-horizontal-onerem-wide) * 2);
  --emu-semantic-spacing-rem-vertical: var(--emu-common-spacing-none);
  --emu-semantic-spacing-vertical-narrow: var(--emu-common-spacing-none);
  --emu-semantic-spacing-vertical-wide: var(--emu-common-spacing-none);
  --emu-semantic-spacing-half-line-height-wide: calc(var(--emu-semantic-line-heights-wide-small) * .5);
  --emu-semantic-spacing-half-line-height-narrow: calc(var(--emu-semantic-line-heights-narrow-small) * .5);
  --emu-semantic-spacing-quarter-line-height-wide: calc(var(--emu-semantic-line-heights-wide-small) * .25);
  --emu-semantic-spacing-quarter-line-height-narrow: calc(var(--emu-semantic-line-heights-narrow-small) * .25);
  --emu-semantic-font-sizes-narrow-medium: 16px;
  --emu-semantic-font-sizes-narrow-large: 30px;
  --emu-semantic-font-sizes-narrow-xl: 30px;
  --emu-semantic-font-sizes-narrow-xxl: 40px;
  --emu-semantic-font-sizes-narrow-xxxl: 48.83px;
  --emu-semantic-font-sizes-narrow-small: 14px;
  --emu-semantic-font-sizes-narrow-xs: 10px;
  --emu-semantic-font-sizes-wide-medium: 22px;
  --emu-semantic-font-sizes-wide-large: 34px;
  --emu-semantic-font-sizes-wide-xl: 54px;
  --emu-semantic-font-sizes-wide-xxl: 58px;
  --emu-semantic-font-sizes-wide-xxxl: 54.93px;
  --emu-semantic-font-sizes-wide-small: 24px;
  --emu-semantic-font-sizes-wide-xs: 10px;
  --emu-semantic-line-heights-narrow-medium: 17px;
  --emu-semantic-line-heights-narrow-large: 35px;
  --emu-semantic-line-heights-narrow-xl: 35px;
  --emu-semantic-line-heights-narrow-xxl: 54px;
  --emu-semantic-line-heights-narrow-xxxl: calc(var(--emu-semantic-line-heights-narrow-small) * 2.5);
  --emu-semantic-line-heights-narrow-small: 26px;
  --emu-semantic-line-heights-narrow-xs: 14px;
  --emu-semantic-line-heights-wide-medium: 30px;
  --emu-semantic-line-heights-wide-large: 41px;
  --emu-semantic-line-heights-wide-xl: 58px;
  --emu-semantic-line-heights-wide-xxl: 80px;
  --emu-semantic-line-heights-wide-xxxl: calc(var(--emu-semantic-line-heights-wide-small) * 3);
  --emu-semantic-line-heights-wide-small: 31px;
  --emu-semantic-line-heights-wide-xs: 14px;
  --emu-semantic-border-radius-xs: var(--emu-common-border-radius-xs);
  --emu-semantic-border-radius-small: var(--emu-common-border-radius-small);
  --emu-semantic-border-radius-medium: var(--emu-common-border-radius-medium);
  --emu-semantic-border-radius-large: var(--emu-common-border-radius-large);
  --emu-semantic-border-radius-none: var(--emu-common-border-radius-none);
  --emu-semantic-border-width-thin: var(--emu-common-border-width-thin);
  --emu-semantic-border-width-medium: var(--emu-common-border-width-medium);
  --emu-semantic-border-width-thick: var(--emu-common-border-width-thick);
  --emu-semantic-border-width-none: 0px;
  --emu-semantic-outline-width-thin: var(--emu-common-outline-width-thin);
  --emu-semantic-outline-width-medium: var(--emu-common-outline-width-medium);
  --emu-semantic-outline-width-thick: var(--emu-common-outline-width-thick);
  --emu-semantic-outline-width-unset: unset;
  --emu-semantic-sizing-none: var(--emu-common-sizing-none);
  --emu-semantic-sizing-xxs: var(--emu-common-sizing-xxs);
  --emu-semantic-sizing-xs: var(--emu-common-sizing-xs);
  --emu-semantic-sizing-small: var(--emu-common-sizing-small);
  --emu-semantic-sizing-medium: var(--emu-common-sizing-medium);
  --emu-semantic-sizing-large: var(--emu-common-sizing-large);
  --emu-semantic-sizing-xl: var(--emu-common-sizing-xl);
  --emu-semantic-sizing-xxl: var(--emu-common-sizing-xxl);
  --emu-semantic-sizing-breakpoints-small: 320px;
  --emu-semantic-sizing-breakpoints-medium: 768px;
  --emu-semantic-sizing-breakpoints-large: 1024px;
  --emu-semantic-sizing-breakpoints-large-plus: 1140px;
  --emu-semantic-sizing-breakpoints-x-large: 1200px;
  --emu-semantic-sizing-breakpoints-xx-large: 1440px;
  --emu-semantic-sizing-one-line-height-narrow: 20px;
  --emu-semantic-sizing-one-line-height-wide: 20px;
  --emu-semantic-sizing-two-line-height-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) * 2);
  --emu-semantic-sizing-two-line-height-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) * 2);
  --emu-semantic-sizing-three-line-height-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) * 3);
  --emu-semantic-sizing-three-line-height-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) * 3);
  --emu-semantic-sizing-max-width-text-narrow: 40em;
  --emu-semantic-sizing-max-width-text-wide: 55em;
  --emu-semantic-typography-narrow-headings-xxxl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xxxl) / var(--emu-semantic-line-heights-narrow-xxxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-xxl: var(--emu-semantic-font-weight-500) var(--emu-semantic-font-sizes-narrow-xxl) / var(--emu-semantic-line-heights-narrow-xxl) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-narrow-headings-xl: var(--emu-semantic-font-weight-900) var(--emu-semantic-font-sizes-narrow-xl) / var(--emu-semantic-line-heights-narrow-xl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-large: var(--emu-semantic-font-weight-900) var(--emu-semantic-font-sizes-narrow-large) / var(--emu-semantic-line-heights-narrow-large) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-medium: var(--emu-semantic-font-weight-900) var(--emu-semantic-font-sizes-narrow-medium) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-body-regular: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-small) / var(--emu-semantic-line-heights-narrow-small) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-headings-xxxl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-xxxl) / var(--emu-semantic-line-heights-wide-xxxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-xxl: var(--emu-semantic-font-weight-500) var(--emu-semantic-font-sizes-wide-xxl) / var(--emu-semantic-line-heights-wide-xxl) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-headings-xl: var(--emu-semantic-font-weight-900) var(--emu-semantic-font-sizes-wide-xl) / var(--emu-semantic-line-heights-wide-xl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-large: var(--emu-semantic-font-weight-900) var(--emu-semantic-font-sizes-wide-large) / var(--emu-semantic-line-heights-wide-large) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-medium: var(--emu-semantic-font-weight-900) var(--emu-semantic-font-sizes-wide-medium) / var(--emu-semantic-line-heights-wide-medium) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-body-regular: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-small) / var(--emu-semantic-line-heights-wide-small) var(--emu-semantic-font-families-body);
  --emu-component-cards-card-margin-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-button-text-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-size-height: 30px;
  --emu-component-cards-card-header-button-icon-size-width: 30px;
  --emu-component-cards-card-header-button-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-cards-card-header-button-icon-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-cards-card-header-button-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-body-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-body-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-teaser-padding-top-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-top-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-right-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-right-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-bottom-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-bottom-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-left-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-left-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-narrow: var(--emu-semantic-spacing-two-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-cards-teaser-padding-wide: var(--emu-semantic-spacing-two-line-height-wide) var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-text-color-light: var(--emu-common-colors-white);
  --emu-component-cards-teaser-text-color-dark: var(--emu-common-colors-black);
  --emu-component-cards-teaser-title-max-width: 66.6667%;
  --emu-component-cards-teaser-title-margin-top-narrow: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-title-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-title-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-title-margin-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-title-margin-narrow: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-title-margin-wide: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-description-margin-top-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-description-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-bottom-wide: var(--emu-common-spacing-small);
  --emu-component-cards-teaser-description-padding-narrow: var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-description-padding-wide: var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-subtext-padding-narrow: var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-subtext-padding-wide: var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-actions-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-cards-teaser-actions-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-cards-teaser-actions-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-actions-padding-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-disclaimer-padding-narrow: 0px 50px 10px 50px;
  --emu-component-cards-teaser-disclaimer-padding-wide: 0px 50px 10px 50px;
  --emu-component-cards-content-fragment-card-margin-narrow: var(--emu-semantic-spacing-horizontal-onerem-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-horizontal-onerem-narrow) var(--emu-semantic-spacing-none);
  --emu-component-cards-content-fragment-card-margin-wide: var(--emu-semantic-spacing-horizontal-onerem-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-horizontal-onerem-wide) var(--emu-semantic-spacing-none);
  --emu-component-cards-content-fragment-card-text-typography-name-narrow: var(--emu-semantic-typography-narrow-headings-xl);
  --emu-component-cards-content-fragment-card-text-typography-name-wide: var(--emu-semantic-typography-wide-headings-xl);
  --emu-component-cards-content-fragment-card-text-typography-title-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-cards-content-fragment-card-text-typography-title-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-cards-content-fragment-card-text-typography-bio-narrow: var(--emu-component-ingredients-text-typography-narrow);
  --emu-component-cards-content-fragment-card-text-typography-bio-wide: var(--emu-component-ingredients-text-typography-wide);
  --emu-component-cards-content-fragment-card-text-margin-narrow: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-none);
  --emu-component-cards-content-fragment-card-text-margin-wide: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-none);
  --emu-component-cards-content-fragment-card-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-cards-content-fragment-card-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-cards-content-fragment-card-padding-narrow: var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-horizontal-onerem-narrow) var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-horizontal-onerem-narrow);
  --emu-component-cards-content-fragment-card-padding-wide: var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-horizontal-onerem-wide) var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-horizontal-onerem-wide);
  --emu-component-cards-content-fragment-card-color-background-dark: var(--emu-semantic-colors-primary-950);
  --emu-component-cards-content-fragment-card-color-background-light: var(--emu-semantic-colors-primary-0);
  --emu-component-cards-content-fragment-card-border-radius-narrow: var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small);
  --emu-component-cards-content-fragment-card-border-radius-wide: var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small);
  --emu-component-cards-content-fragment-card-author-image-sizing-height-narrow: var(--emu-semantic-sizing-xl);
  --emu-component-cards-content-fragment-card-author-image-sizing-height-wide: var(--emu-semantic-sizing-xxl);
  --emu-component-cards-content-fragment-card-author-image-sizing-width-narrow: var(--emu-semantic-sizing-xl);
  --emu-component-cards-content-fragment-card-author-image-sizing-width-wide: var(--emu-semantic-sizing-xxl);
  --emu-component-cards-content-fragment-card-author-image-border-radius-narrow: var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium);
  --emu-component-cards-content-fragment-card-author-image-border-radius-wide: var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium);
  --emu-component-lists-accordion-color-background-light: var(--emu-component-lists-accordion-item-body-color-background-light);
  --emu-component-lists-accordion-color-background-dark: var(--emu-component-lists-accordion-item-body-color-background-dark);
  --emu-component-lists-accordion-color-text-light: var(--emu-common-colors-white);
  --emu-component-lists-accordion-color-text-dark: var(--emu-component-lists-accordion-item-header-text-color-dark);
  --emu-component-lists-accordion-color-border-light: var(--emu-component-lists-accordion-item-header-color-border-bottom-open-light);
  --emu-component-lists-accordion-color-border-dark: var(--emu-component-lists-accordion-item-header-color-border-bottom-open-dark);
  --emu-component-lists-accordion-margin-narrow: var(--emu-common-sizing-none);
  --emu-component-lists-accordion-margin-wide: var(--emu-common-sizing-none);
  --emu-component-lists-accordion-margin-top-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-top-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-right-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-right-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-bottom-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-bottom-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-left-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-left-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-color-border-bottom-closed-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-lists-accordion-item-color-border-bottom-closed-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-lists-accordion-item-color-border-bottom-open-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-color-border-bottom-open-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-body-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-lists-accordion-item-body-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-accordion-item-body-color-border-open-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-lists-accordion-item-body-color-border-open-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-lists-accordion-item-body-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-lists-accordion-item-body-padding-narrow: var(--emu-component-lists-accordion-item-body-padding-top-narrow) var(--emu-component-lists-accordion-item-body-padding-right-narrow) var(--emu-component-lists-accordion-item-body-padding-bottom-narrow) var(--emu-component-lists-accordion-item-body-padding-left-narrow);
  --emu-component-lists-accordion-item-body-padding-wide: var(--emu-component-lists-accordion-item-body-padding-top-wide) var(--emu-component-lists-accordion-item-body-padding-right-wide) var(--emu-component-lists-accordion-item-body-padding-bottom-wide) var(--emu-component-lists-accordion-item-body-padding-left-wide);
  --emu-component-lists-accordion-item-body-padding-top-narrow: 8px;
  --emu-component-lists-accordion-item-body-padding-top-wide: 8px;
  --emu-component-lists-accordion-item-body-padding-right-narrow: var(--emu-common-spacing-none);
  --emu-component-lists-accordion-item-body-padding-right-wide: var(--emu-common-spacing-none);
  --emu-component-lists-accordion-item-body-padding-bottom-narrow: 64px;
  --emu-component-lists-accordion-item-body-padding-bottom-wide: 64px;
  --emu-component-lists-accordion-item-body-padding-left-narrow: var(--emu-common-spacing-none);
  --emu-component-lists-accordion-item-body-padding-left-wide: var(--emu-common-spacing-none);
  --emu-component-lists-accordion-item-body-border-width-bottom-open: var(--emu-semantic-border-width-thin);
  --emu-component-lists-accordion-item-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-lists-accordion-item-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-lists-accordion-item-header-text-color-light: var(--emu-common-colors-white);
  --emu-component-lists-accordion-item-header-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-accordion-item-header-padding-narrow: var(--emu-component-lists-accordion-item-header-padding-top-narrow) var(--emu-component-lists-accordion-item-header-padding-right-narrow) var(--emu-component-lists-accordion-item-header-padding-bottom-narrow) var(--emu-component-lists-accordion-item-header-padding-left-narrow);
  --emu-component-lists-accordion-item-header-padding-wide: var(--emu-component-lists-accordion-item-header-padding-top-wide) var(--emu-component-lists-accordion-item-header-padding-right-wide) var(--emu-component-lists-accordion-item-header-padding-bottom-wide) var(--emu-component-lists-accordion-item-header-padding-left-wide);
  --emu-component-lists-accordion-item-header-padding-top-narrow: var(--emu-common-spacing-none);
  --emu-component-lists-accordion-item-header-padding-top-wide: var(--emu-common-spacing-none);
  --emu-component-lists-accordion-item-header-padding-right-narrow: var(--emu-common-spacing-none);
  --emu-component-lists-accordion-item-header-padding-right-wide: var(--emu-common-spacing-none);
  --emu-component-lists-accordion-item-header-padding-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-lists-accordion-item-header-padding-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-lists-accordion-item-header-padding-left-narrow: var(--emu-common-spacing-none);
  --emu-component-lists-accordion-item-header-padding-left-wide: var(--emu-common-spacing-none);
  --emu-component-lists-accordion-item-header-icon-margin-narrow: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-item-header-icon-margin-wide: var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-component-lists-accordion-item-header-icon-spacing);
  --emu-component-lists-accordion-item-header-icon-spacing: var(--emu-common-spacing-small);
  --emu-component-lists-accordion-item-header-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-lists-accordion-item-header-icon-border-radius: var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small);
  --emu-component-lists-accordion-item-header-icon-transform-open: rotate(0);
  --emu-component-lists-accordion-item-header-icon-transform-closed: rotate(180deg);
  --emu-component-lists-accordion-item-header-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-light: var(--emu-common-colors-white);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-dark: var(--emu-semantic-colors-actions-primary-dark);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-light: var(--emu-common-colors-white);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-dark: var(--emu-semantic-colors-actions-primary-dark);
  --emu-component-lists-accordion-item-header-icon-size-height-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-header-icon-size-height-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-header-icon-size-width-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-header-icon-size-width-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-header-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-lists-accordion-item-header-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-accordion-item-header-color-border-bottom-open-light: var(--emu-semantic-colors-surface-light);
  --emu-component-lists-accordion-item-header-color-border-bottom-open-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-accordion-item-header-color-border-bottom-closed-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-lists-accordion-item-header-color-border-bottom-closed-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-lists-accordion-item-border-width-bottom-open: unsetpx;
  --emu-component-lists-accordion-border-radius-narrow: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-radius-wide: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-width-narrow: var(--emu-common-border-width-none);
  --emu-component-lists-accordion-border-width-wide: var(--emu-common-border-width-none);
  --emu-component-lists-navigation-banner-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-banner-color-background-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-background-default-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-item-color-background-default-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-background-hover-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-banner-item-color-background-hover-dark: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-color-border-default-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-item-color-border-default-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-border-hover-light: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-color-border-hover-dark: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-text-color-default-light: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-banner-item-text-color-default-dark: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-banner-item-text-color-hover-light: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-banner-item-text-color-hover-dark: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-banner-item-border-width: var(--emu-common-border-width-none);
  --emu-component-lists-navigation-banner-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-dropdown-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-dropdown-color-background-dark: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-color-border-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-color-border-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-border-width: var(--emu-common-border-width-none);
  --emu-component-lists-navigation-dropdown-item-color-background-default-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-item-color-background-default-dark: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-dark: var(--emu-common-colors-grey-100);
  --emu-component-lists-navigation-dropdown-item-text-color-default-light: var(--emu-semantic-colors-secondary-200);
  --emu-component-lists-navigation-dropdown-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-light: var(--emu-semantic-colors-primary-100);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-z-index: var(--emu-common-other-z-index-base);
  --emu-component-lists-navigation-text-padding-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-lists-navigation-text-padding-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-lists-navigation-text-padding-right-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-lists-navigation-text-padding-right-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-lists-navigation-text-padding-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-lists-navigation-text-padding-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-lists-navigation-text-padding-left-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-lists-navigation-text-padding-left-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-default-light: var(--emu-component-ingredients-select-input-option-text-color-filled-default-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-default-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-default-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-hover-light: var(--emu-component-ingredients-select-input-option-text-color-filled-hover-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-hover-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-hover-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-active-light: var(--emu-component-ingredients-select-input-option-text-color-filled-active-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-active-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-active-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-focus-light: var(--emu-component-ingredients-select-input-option-text-color-filled-focus-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-focus-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-focus-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-default-light: var(--emu-component-ingredients-select-input-option-text-color-outline-default-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-default-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-default-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-hover-light: var(--emu-component-ingredients-select-input-option-text-color-outline-hover-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-hover-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-hover-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-active-light: var(--emu-component-ingredients-select-input-option-text-color-outline-active-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-active-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-active-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-focus-light: var(--emu-component-ingredients-select-input-option-text-color-outline-focus-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-focus-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-focus-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-filled-light: var(--emu-component-ingredients-select-input-option-color-background-default-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-default-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-outline-light: var(--emu-component-ingredients-select-input-option-color-background-default-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-default-outline-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-filled-light: var(--emu-component-ingredients-select-input-option-color-background-hover-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-hover-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-outline-light: var(--emu-component-ingredients-select-input-option-color-background-hover-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-hover-outline-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-filled-light: var(--emu-component-ingredients-select-input-option-color-background-active-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-active-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-outline-light: var(--emu-component-ingredients-select-input-option-color-background-active-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-active-outline-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-filled-light: var(--emu-component-ingredients-select-input-option-color-background-focus-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-focus-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-outline-light: var(--emu-component-ingredients-select-input-option-color-background-focus-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-focus-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-default-filled-light: var(--emu-component-ingredients-select-input-color-border-default-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-default-filled-dark: var(--emu-component-ingredients-select-input-color-border-default-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-default-outline-light: var(--emu-component-ingredients-select-input-color-border-default-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-default-outline-dark: var(--emu-component-ingredients-select-input-color-border-default-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-filled-light: var(--emu-component-ingredients-select-input-color-border-hover-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-filled-dark: var(--emu-component-ingredients-select-input-color-border-hover-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-outline-light: var(--emu-component-ingredients-select-input-color-border-hover-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-outline-dark: var(--emu-component-ingredients-select-input-color-border-hover-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-active-filled-light: var(--emu-component-ingredients-select-input-color-border-active-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-active-filled-dark: var(--emu-component-ingredients-select-input-color-border-active-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-active-outline-light: var(--emu-component-ingredients-select-input-color-border-active-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-active-outline-dark: var(--emu-component-ingredients-select-input-color-border-active-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-filled-light: var(--emu-component-ingredients-select-input-color-border-focus-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-filled-dark: var(--emu-component-ingredients-select-input-color-border-focus-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-outline-light: var(--emu-component-ingredients-select-input-color-border-focus-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-outline-dark: var(--emu-component-ingredients-select-input-color-border-focus-outline-dark);
  --emu-component-lists-language-navigation-dropdown-border-radius-narrow: var(--emu-component-ingredients-select-input-border-radius-narrow);
  --emu-component-lists-language-navigation-dropdown-border-radius-wide: var(--emu-component-ingredients-select-input-border-radius-wide);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-default-light: var(--emu-component-ingredients-select-input-icon-color-fill-filled-default-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-default-dark: var(--emu-component-ingredients-select-input-icon-color-fill-filled-default-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-hover-light: var(--emu-component-ingredients-select-input-icon-color-fill-filled-hover-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-hover-dark: var(--emu-component-ingredients-select-input-icon-color-fill-filled-hover-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-active-light: var(--emu-component-ingredients-select-input-icon-color-fill-filled-active-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-active-dark: var(--emu-component-ingredients-select-input-icon-color-fill-filled-active-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-default-light: var(--emu-component-ingredients-select-input-icon-color-fill-outline-default-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-default-dark: var(--emu-component-ingredients-select-input-icon-color-fill-outline-default-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-hover-light: var(--emu-component-ingredients-select-input-icon-color-fill-outline-hover-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-hover-dark: var(--emu-component-ingredients-select-input-icon-color-fill-outline-hover-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-active-light: var(--emu-component-ingredients-select-input-icon-color-fill-outline-active-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-active-dark: var(--emu-component-ingredients-select-input-icon-color-fill-outline-active-dark);
  --emu-component-lists-language-navigation-dropdown-padding-narrow: var(--emu-component-ingredients-select-input-padding-narrow);
  --emu-component-lists-language-navigation-dropdown-padding-wide: var(--emu-component-ingredients-select-input-padding-wide);
  --emu-component-lists-language-navigation-dropdown-gap-narrow: var(--emu-component-ingredients-select-input-gap-narrow);
  --emu-component-lists-language-navigation-dropdown-gap-wide: var(--emu-component-ingredients-select-input-gap-wide);
  --emu-component-lists-language-navigation-dropdown-border-width-filled-narrow: var(--emu-component-ingredients-select-input-border-width-filled-narrow);
  --emu-component-lists-language-navigation-dropdown-border-width-filled-wide: var(--emu-component-ingredients-select-input-border-width-filled-wide);
  --emu-component-lists-language-navigation-dropdown-border-width-outline-narrow: var(--emu-component-ingredients-select-input-border-width-outline-narrow);
  --emu-component-lists-language-navigation-dropdown-border-width-outline-wide: var(--emu-component-ingredients-select-input-border-width-outline-wide);
  --emu-component-lists-language-navigation-pipes-gap-narrow: var(--emu-common-spacing-small);
  --emu-component-lists-language-navigation-pipes-gap-wide: var(--emu-common-spacing-small);
  --emu-component-lists-language-navigation-pipes-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-lists-language-navigation-pipes-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-lists-language-navigation-pipes-color-border-light: var(--emu-semantic-colors-surface-light);
  --emu-component-lists-language-navigation-pipes-color-border-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-language-navigation-pipes-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-language-navigation-pipes-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-language-navigation-pipes-item-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-language-navigation-pipes-item-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-language-navigation-pipes-item-text-color-active-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-language-navigation-pipes-item-text-color-active-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-feeds-instagram-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-feeds-instagram-padding-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-feeds-instagram-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-post-gap-narrow: 40px;
  --emu-component-feeds-instagram-post-gap-wide: 40px;
  --emu-component-ingredients-icon-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-icon-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-icon-size-height-narrow: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-height-wide: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-width-narrow: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-width-wide: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-separator-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-separator-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-separator-size-height: var(--emu-semantic-sizing-xxs);
  --emu-component-ingredients-title-h1-typography-narrow: var(--emu-semantic-typography-narrow-headings-xxxl);
  --emu-component-ingredients-title-h1-typography-wide: var(--emu-semantic-typography-wide-headings-xxxl);
  --emu-component-ingredients-title-h2-typography-narrow: var(--emu-semantic-typography-narrow-headings-xxl);
  --emu-component-ingredients-title-h2-typography-wide: var(--emu-semantic-typography-wide-headings-xxl);
  --emu-component-ingredients-title-h3-typography-narrow: var(--emu-semantic-typography-narrow-headings-xl);
  --emu-component-ingredients-title-h3-typography-wide: var(--emu-semantic-typography-wide-headings-xl);
  --emu-component-ingredients-title-h4-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-ingredients-title-h4-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-ingredients-title-h5-typography-narrow: var(--emu-semantic-typography-narrow-headings-medium);
  --emu-component-ingredients-title-h5-typography-wide: var(--emu-semantic-typography-wide-headings-medium);
  --emu-component-ingredients-title-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-title-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-text-color-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-text-text-color-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-text-text-typography-narrow: var(--emu-component-ingredients-text-typography-narrow);
  --emu-component-ingredients-text-text-typography-wide: var(--emu-component-ingredients-text-typography-wide);
  --emu-component-ingredients-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-typography-narrow: var(--emu-semantic-typography-narrow-body-regular);
  --emu-component-ingredients-text-typography-wide: var(--emu-semantic-typography-wide-body-regular);
  --emu-component-ingredients-loader-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-loader-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-image-map-buttons-size-height: 2px;
  --emu-component-ingredients-image-map-buttons-size-width: 2px;
  --emu-component-ingredients-image-map-buttons-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-image-map-buttons-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-image-map-buttons-color-icon-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-ingredients-image-map-buttons-color-icon-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-ingredients-image-map-buttons-border-radius-top-left: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-top-right: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-bottom-right: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-bottom-left: 50%;
  --emu-component-ingredients-image-map-buttons-shadow: 0 0 2 5 #0009;
  --emu-component-ingredients-google-map-color-background-light: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-ingredients-google-map-color-background-dark: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-ingredients-google-map-margin-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-right-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-google-map-margin-right-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-google-map-margin-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-left-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-google-map-margin-left-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-image-caption-padding-narrow: calc(var(--emu-semantic-spacing-vertical-narrow) * .5);
  --emu-component-ingredients-label-margin-narrow: var(--emu-semantic-spacing-half-line-height-narrow) 0px;
  --emu-component-ingredients-label-margin-wide: var(--emu-semantic-spacing-half-line-height-wide) 0px;
  --emu-component-ingredients-label-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-label-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-label-text-color-default-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-label-text-color-default-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-label-text-color-checked-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-label-text-color-checked-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-label-text-color-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-label-text-color-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-label-text-color-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-label-text-color-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-legend-margin-narrow: var(--emu-semantic-spacing-none);
  --emu-component-ingredients-legend-margin-wide: var(--emu-semantic-spacing-none);
  --emu-component-ingredients-legend-text-color-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-legend-text-color-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-field-message-margin-narrow: var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-field-message-margin-wide: var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-field-message-text-color-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-field-message-text-color-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-error-message-margin-narrow: var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-error-message-margin-wide: var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-error-message-text-color-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-error-message-text-color-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-default-light: var(--emu-semantic-colors-primary-100);
  --emu-component-ingredients-select-input-option-text-color-filled-default-dark: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-option-text-color-filled-hover-light: var(--emu-semantic-colors-primary-100);
  --emu-component-ingredients-select-input-option-text-color-filled-hover-dark: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-option-text-color-filled-active-light: var(--emu-semantic-colors-primary-100);
  --emu-component-ingredients-select-input-option-text-color-filled-active-dark: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-option-text-color-filled-focus-light: var(--emu-semantic-colors-primary-100);
  --emu-component-ingredients-select-input-option-text-color-filled-focus-dark: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-option-text-color-filled-disabled-light: var(--emu-semantic-colors-primary-100);
  --emu-component-ingredients-select-input-option-text-color-filled-disabled-dark: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-option-text-color-filled-error-light: var(--emu-semantic-colors-primary-100);
  --emu-component-ingredients-select-input-option-text-color-filled-error-dark: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-option-text-color-outline-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-active-light: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-option-text-color-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-option-text-color-outline-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-option-color-background-default-filled-light: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-option-color-background-default-filled-dark: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-option-color-background-default-outline-light: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-default-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-hover-filled-light: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-option-color-background-hover-filled-dark: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-option-color-background-hover-outline-light: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-hover-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-active-filled-light: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-option-color-background-active-filled-dark: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-option-color-background-active-outline-light: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-active-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-focus-filled-light: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-option-color-background-focus-filled-dark: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-option-color-background-focus-outline-light: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-focus-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-disabled-filled-light: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-option-color-background-disabled-filled-dark: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-option-color-background-disabled-outline-light: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-disabled-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-error-filled-light: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-option-color-background-error-filled-dark: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-option-color-background-error-outline-light: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-error-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-color-border-default-filled-light: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-color-border-default-filled-dark: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-color-border-default-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-default-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-hover-filled-light: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-color-border-hover-filled-dark: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-color-border-hover-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-hover-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-active-filled-light: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-color-border-active-filled-dark: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-color-border-active-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-active-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-focus-filled-light: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-color-border-focus-filled-dark: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-color-border-focus-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-focus-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-disabled-filled-light: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-color-border-disabled-filled-dark: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-color-border-disabled-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-disabled-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-error-filled-light: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-color-border-error-filled-dark: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-color-border-error-outline-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-color-border-error-outline-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-border-radius-narrow: 12px;
  --emu-component-ingredients-select-input-border-radius-wide: 12px;
  --emu-component-ingredients-select-input-icon-color-fill-filled-default-light: var(--emu-semantic-colors-primary-100);
  --emu-component-ingredients-select-input-icon-color-fill-filled-default-dark: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-icon-color-fill-filled-hover-light: var(--emu-semantic-colors-primary-100);
  --emu-component-ingredients-select-input-icon-color-fill-filled-hover-dark: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-icon-color-fill-filled-active-light: var(--emu-semantic-colors-primary-100);
  --emu-component-ingredients-select-input-icon-color-fill-filled-active-dark: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-icon-color-fill-filled-focus-light: var(--emu-semantic-colors-primary-100);
  --emu-component-ingredients-select-input-icon-color-fill-filled-focus-dark: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-icon-color-fill-filled-disabled-light: var(--emu-semantic-colors-primary-100);
  --emu-component-ingredients-select-input-icon-color-fill-filled-disabled-dark: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-icon-color-fill-filled-error-light: var(--emu-semantic-colors-primary-100);
  --emu-component-ingredients-select-input-icon-color-fill-filled-error-dark: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-icon-color-fill-outline-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-hover-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-hover-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-padding-narrow: 9px 8px 9px 8px;
  --emu-component-ingredients-select-input-padding-wide: 0px 20px 0px 20px;
  --emu-component-ingredients-select-input-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-select-input-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-select-input-border-width-filled-narrow: var(--emu-common-border-width-none);
  --emu-component-ingredients-select-input-border-width-filled-wide: var(--emu-common-border-width-none);
  --emu-component-ingredients-select-input-border-width-outline-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-select-input-border-width-outline-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-select-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-select-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-select-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-select-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-select-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-select-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-select-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-select-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-select-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-select-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-select-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-select-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-select-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-select-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-select-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-select-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-select-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-select-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-text-input-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-input-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-input-text-color-empty-light: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-text-input-text-color-empty-dark: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-text-input-text-color-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-input-text-color-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-input-text-color-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-text-input-text-color-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-text-input-text-color-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-text-input-text-color-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-text-input-text-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-text-input-text-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-text-input-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-text-input-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-text-input-color-border-empty-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-text-input-color-border-empty-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-text-input-color-border-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-text-input-color-border-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-text-input-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-text-input-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-text-input-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-text-input-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-text-input-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-color-background-empty-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-empty-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-color-background-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-focus-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-color-background-disabled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-disabled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-color-background-error-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-error-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-text-input-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-text-input-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-text-input-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-text-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-text-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-text-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-text-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-text-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-text-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-text-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-text-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-text-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-text-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-text-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-text-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-text-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-text-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-text-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-text-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-text-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-text-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-radio-input-control-size-all-narrow: var(--emu-semantic-sizing-one-line-height-narrow);
  --emu-component-ingredients-radio-input-control-size-all-wide: var(--emu-semantic-sizing-one-line-height-wide);
  --emu-component-ingredients-radio-input-control-border-radius-narrow: var(--emu-semantic-border-radius-medium);
  --emu-component-ingredients-radio-input-control-border-radius-wide: var(--emu-semantic-border-radius-medium);
  --emu-component-ingredients-radio-input-control-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-radio-input-control-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-radio-input-control-color-background-focus-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-focus-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-checked-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-checked-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-disabled-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-disabled-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-error-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-error-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-active-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-active-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-hover-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-hover-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-border-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-ingredients-radio-input-control-color-border-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-ingredients-radio-input-control-color-border-focus-light: var(--emu-semantic-colors-actions-primary-focus-light);
  --emu-component-ingredients-radio-input-control-color-border-focus-dark: var(--emu-semantic-colors-actions-primary-focus-dark);
  --emu-component-ingredients-radio-input-control-color-border-checked-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-ingredients-radio-input-control-color-border-checked-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-ingredients-radio-input-control-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-radio-input-control-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-radio-input-control-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-radio-input-control-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-radio-input-control-color-border-active-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-ingredients-radio-input-control-color-border-active-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-ingredients-radio-input-control-color-border-hover-light: var(--emu-semantic-colors-actions-primary-focus-light);
  --emu-component-ingredients-radio-input-control-color-border-hover-dark: var(--emu-semantic-colors-actions-primary-focus-dark);
  --emu-component-ingredients-radio-input-control-border-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-ingredients-radio-input-control-border-width-wide: var(--emu-semantic-border-width-thin);
  --emu-component-ingredients-radio-input-icon-color-fill-checked-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-ingredients-radio-input-icon-color-fill-checked-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-ingredients-radio-input-icon-color-fill-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-radio-input-icon-color-fill-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-radio-input-icon-color-fill-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-radio-input-icon-color-fill-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-radio-input-icon-size-all-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow)  - 4px);
  --emu-component-ingredients-radio-input-icon-size-all-wide: calc(var(--emu-semantic-sizing-one-line-height-wide)  - 4px);
  --emu-component-ingredients-radio-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-radio-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-radio-input-label-gap-narrow: var(--emu-component-ingredients-label-gap-narrow);
  --emu-component-ingredients-radio-input-label-gap-wide: var(--emu-component-ingredients-label-gap-wide);
  --emu-component-ingredients-radio-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-radio-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-radio-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-radio-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-radio-input-label-text-color-checked-light: var(--emu-component-ingredients-label-text-color-checked-light);
  --emu-component-ingredients-radio-input-label-text-color-checked-dark: var(--emu-component-ingredients-label-text-color-checked-dark);
  --emu-component-ingredients-radio-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-radio-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-radio-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-radio-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-radio-input-legend-margin-narrow: var(--emu-component-ingredients-legend-margin-narrow);
  --emu-component-ingredients-radio-input-legend-margin-wide: var(--emu-component-ingredients-legend-margin-wide);
  --emu-component-ingredients-radio-input-legend-text-color-light: var(--emu-component-ingredients-legend-text-color-light);
  --emu-component-ingredients-radio-input-legend-text-color-dark: var(--emu-component-ingredients-legend-text-color-dark);
  --emu-component-ingredients-radio-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-radio-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-radio-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-radio-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-radio-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-radio-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-radio-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-radio-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-checkbox-input-control-size-all-narrow: var(--emu-semantic-sizing-one-line-height-narrow);
  --emu-component-ingredients-checkbox-input-control-size-all-wide: var(--emu-semantic-sizing-one-line-height-wide);
  --emu-component-ingredients-checkbox-input-control-border-radius-narrow: var(--emu-common-border-radius-xxs);
  --emu-component-ingredients-checkbox-input-control-border-radius-wide: var(--emu-common-border-radius-xxs);
  --emu-component-ingredients-checkbox-input-control-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-checkbox-input-control-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-focus-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-focus-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-checked-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-checked-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-disabled-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-disabled-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-error-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-error-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-hover-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-hover-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-active-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-active-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-checkbox-input-control-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-focus-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-focus-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-checked-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-checked-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-checkbox-input-control-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-checkbox-input-control-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-active-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-active-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-hover-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-hover-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-checkbox-input-control-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-checkbox-input-icon-color-fill-checked-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-checkbox-input-icon-color-fill-checked-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-checkbox-input-icon-color-fill-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-checkbox-input-icon-color-fill-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-checkbox-input-icon-color-fill-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-checkbox-input-icon-color-fill-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-checkbox-input-icon-size-all-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow)  - 4px);
  --emu-component-ingredients-checkbox-input-icon-size-all-wide: calc(var(--emu-semantic-sizing-one-line-height-wide)  - 4px);
  --emu-component-ingredients-checkbox-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-checkbox-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-checkbox-input-label-gap-narrow: var(--emu-component-ingredients-label-gap-narrow);
  --emu-component-ingredients-checkbox-input-label-gap-wide: var(--emu-component-ingredients-label-gap-wide);
  --emu-component-ingredients-checkbox-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-checkbox-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-checkbox-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-checked-light: var(--emu-component-ingredients-label-text-color-checked-light);
  --emu-component-ingredients-checkbox-input-label-text-color-checked-dark: var(--emu-component-ingredients-label-text-color-checked-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-checkbox-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-checkbox-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-checkbox-input-legend-margin-narrow: var(--emu-component-ingredients-legend-margin-narrow);
  --emu-component-ingredients-checkbox-input-legend-margin-wide: var(--emu-component-ingredients-legend-margin-wide);
  --emu-component-ingredients-checkbox-input-legend-text-color-light: var(--emu-component-ingredients-legend-text-color-light);
  --emu-component-ingredients-checkbox-input-legend-text-color-dark: var(--emu-component-ingredients-legend-text-color-dark);
  --emu-component-ingredients-checkbox-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-checkbox-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-checkbox-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-checkbox-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-checkbox-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-checkbox-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-checkbox-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-checkbox-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-textarea-input-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-textarea-input-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-textarea-input-text-color-empty-light: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-textarea-input-text-color-empty-dark: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-textarea-input-text-color-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-textarea-input-text-color-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-textarea-input-text-color-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-textarea-input-text-color-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-textarea-input-text-color-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-textarea-input-text-color-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-textarea-input-text-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-textarea-input-text-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-textarea-input-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-textarea-input-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-textarea-input-color-border-empty-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-textarea-input-color-border-empty-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-textarea-input-color-border-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-textarea-input-color-border-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-textarea-input-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-textarea-input-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-textarea-input-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-textarea-input-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-textarea-input-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-color-background-empty-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-empty-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-color-background-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-focus-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-color-background-disabled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-disabled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-color-background-error-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-error-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-textarea-input-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-textarea-input-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-textarea-input-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-textarea-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-textarea-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-textarea-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-textarea-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-textarea-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-textarea-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-textarea-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-textarea-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-textarea-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-textarea-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-textarea-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-textarea-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-textarea-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-textarea-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-textarea-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-textarea-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-textarea-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-textarea-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-actions-button-text-typography-narrow: var(--emu-semantic-font-weight-900) 20px / 22px var(--emu-semantic-font-families-heading);
  --emu-component-actions-button-text-typography-wide: var(--emu-semantic-font-weight-900) 24px / 27px var(--emu-semantic-font-families-heading);
  --emu-component-actions-button-text-color-link-light: var(--emu-component-actions-button-text-color-link-default-light);
  --emu-component-actions-button-text-color-link-dark: var(--emu-component-actions-button-text-color-link-default-dark);
  --emu-component-actions-button-text-color-link-hover-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-actions-button-text-color-link-hover-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-actions-button-text-color-link-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-button-text-color-link-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-button-text-color-link-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-actions-button-text-color-link-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-actions-button-text-color-link-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-actions-button-text-color-link-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-actions-button-text-color-primary-filled-light: var(--emu-component-actions-button-text-color-primary-filled-default-light);
  --emu-component-actions-button-text-color-primary-filled-dark: var(--emu-component-actions-button-text-color-primary-filled-default-dark);
  --emu-component-actions-button-text-color-primary-filled-focus-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-text-color-primary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-hover-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-text-color-primary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-active-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-text-color-primary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-default-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-text-color-primary-filled-default-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-disabled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-light: var(--emu-component-actions-button-text-color-primary-outline-default-light);
  --emu-component-actions-button-text-color-primary-outline-dark: var(--emu-component-actions-button-text-color-primary-outline-default-dark);
  --emu-component-actions-button-text-color-primary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-default-light: var(--emu-semantic-colors-primary-200);
  --emu-component-actions-button-text-color-primary-outline-default-dark: var(--emu-semantic-colors-actions-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-text-color-primary-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-text-color-secondary-filled-light: var(--emu-component-actions-button-text-color-secondary-filled-default-light);
  --emu-component-actions-button-text-color-secondary-filled-dark: var(--emu-component-actions-button-text-color-secondary-filled-default-dark);
  --emu-component-actions-button-text-color-secondary-filled-focus-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-filled-hover-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-filled-active-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-filled-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-filled-default-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-filled-default-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-light: var(--emu-component-actions-button-text-color-secondary-outline-default-light);
  --emu-component-actions-button-text-color-secondary-outline-dark: var(--emu-component-actions-button-text-color-secondary-outline-default-dark);
  --emu-component-actions-button-text-color-secondary-outline-focus-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-hover-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-active-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-outline-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-default-light: var(--emu-semantic-colors-actions-secondary-light);
  --emu-component-actions-button-text-color-secondary-outline-default-dark: var(--emu-semantic-colors-actions-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-text-color-secondary-outline-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-border-width-primary-filled: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-primary-outline: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-secondary-filled: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-secondary-outline: var(--emu-common-border-width-thin);
  --emu-component-actions-button-outline-width-primary-filled: var(--emu-semantic-outline-width-thin);
  --emu-component-actions-button-outline-width-primary-outline: var(--emu-semantic-outline-width-thin);
  --emu-component-actions-button-outline-width-secondary-filled: var(--emu-semantic-outline-width-thin);
  --emu-component-actions-button-outline-width-secondary-outline: var(--emu-semantic-outline-width-thin);
  --emu-component-actions-button-color-border-primary-filled-light: var(--emu-component-actions-button-color-border-primary-filled-default-light);
  --emu-component-actions-button-color-border-primary-filled-dark: var(--emu-component-actions-button-color-border-primary-filled-default-dark);
  --emu-component-actions-button-color-border-primary-filled-focus-light: var(--emu-common-colors-white);
  --emu-component-actions-button-color-border-primary-filled-focus-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-hover-light: var(--emu-common-colors-white);
  --emu-component-actions-button-color-border-primary-filled-hover-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-active-light: var(--emu-common-colors-white);
  --emu-component-actions-button-color-border-primary-filled-active-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-default-light: var(--emu-common-colors-white);
  --emu-component-actions-button-color-border-primary-filled-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-color-border-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-color-border-primary-outline-light: var(--emu-component-actions-button-color-border-primary-outline-default-light);
  --emu-component-actions-button-color-border-primary-outline-dark: var(--emu-component-actions-button-color-border-primary-filled-default-dark);
  --emu-component-actions-button-color-border-primary-outline-focus-light: var(--emu-semantic-colors-primary-200);
  --emu-component-actions-button-color-border-primary-outline-focus-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-outline-hover-light: var(--emu-semantic-colors-primary-200);
  --emu-component-actions-button-color-border-primary-outline-hover-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-outline-active-light: var(--emu-semantic-colors-primary-200);
  --emu-component-actions-button-color-border-primary-outline-active-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-outline-default-light: var(--emu-semantic-colors-primary-200);
  --emu-component-actions-button-color-border-primary-outline-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-color-border-primary-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-color-border-secondary-filled-light: var(--emu-component-actions-button-color-border-secondary-filled-default-light);
  --emu-component-actions-button-color-border-secondary-filled-dark: var(--emu-component-actions-button-color-border-secondary-filled-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-focus-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-border-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-hover-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-border-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-active-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-border-secondary-filled-active-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-default-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-border-secondary-filled-default-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-color-border-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-color-border-secondary-outline-light: var(--emu-component-actions-button-color-border-secondary-outline-default-light);
  --emu-component-actions-button-color-border-secondary-outline-dark: var(--emu-component-actions-button-color-border-secondary-outline-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-focus-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-hover-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-active-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-outline-active-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-default-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-outline-default-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-color-border-secondary-outline-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-color-background-primary-filled-light: var(--emu-component-actions-button-color-background-primary-filled-default-light);
  --emu-component-actions-button-color-background-primary-filled-dark: var(--emu-component-actions-button-color-background-primary-filled-default-dark);
  --emu-component-actions-button-color-background-primary-filled-focus-light: var(--emu-common-colors-white);
  --emu-component-actions-button-color-background-primary-filled-focus-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-button-color-background-primary-filled-hover-light: var(--emu-common-colors-white);
  --emu-component-actions-button-color-background-primary-filled-hover-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-button-color-background-primary-filled-active-light: var(--emu-common-colors-white);
  --emu-component-actions-button-color-background-primary-filled-active-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-button-color-background-primary-filled-default-light: var(--emu-common-colors-white);
  --emu-component-actions-button-color-background-primary-filled-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-background-primary-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-color-background-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-color-background-primary-outline-light: var(--emu-component-actions-button-color-background-primary-outline-default-light);
  --emu-component-actions-button-color-background-primary-outline-dark: var(--emu-component-actions-button-color-background-primary-outline-default-dark);
  --emu-component-actions-button-color-background-primary-outline-focus-light: var(--emu-semantic-colors-primary-200);
  --emu-component-actions-button-color-background-primary-outline-focus-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-background-primary-outline-hover-light: var(--emu-semantic-colors-primary-200);
  --emu-component-actions-button-color-background-primary-outline-hover-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-background-primary-outline-active-light: var(--emu-semantic-colors-primary-200);
  --emu-component-actions-button-color-background-primary-outline-active-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-background-primary-outline-default-light: var(--emu-common-colors-white);
  --emu-component-actions-button-color-background-primary-outline-default-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-disabled-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-disabled-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-filled-light: var(--emu-component-actions-button-color-background-secondary-filled-default-light);
  --emu-component-actions-button-color-background-secondary-filled-dark: var(--emu-component-actions-button-color-background-secondary-filled-disabled-dark);
  --emu-component-actions-button-color-background-secondary-filled-focus-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-background-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-secondary-focus-dark);
  --emu-component-actions-button-color-background-secondary-filled-hover-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-background-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-secondary-hover-dark);
  --emu-component-actions-button-color-background-secondary-filled-active-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-background-secondary-filled-active-dark: var(--emu-semantic-colors-actions-secondary-active-dark);
  --emu-component-actions-button-color-background-secondary-filled-default-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-background-secondary-filled-default-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-background-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-color-background-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-color-background-secondary-outline-light: var(--emu-component-actions-button-color-background-secondary-outline-default-light);
  --emu-component-actions-button-color-background-secondary-outline-dark: var(--emu-component-actions-button-color-background-secondary-outline-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-focus-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-background-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-hover-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-background-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-active-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-background-secondary-outline-active-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-default-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-default-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-disabled-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-disabled-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-outline-color-primary-filled-light: var(--emu-component-actions-button-color-outline-color-primary-filled-default-light);
  --emu-component-actions-button-color-outline-color-primary-filled-dark: var(--emu-component-actions-button-color-outline-color-primary-filled-default-dark);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-active-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-filled-default-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-default-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-color-outline-color-primary-filled-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-color-outline-color-primary-outline-light: var(--emu-component-actions-button-color-outline-color-primary-outline-default-light);
  --emu-component-actions-button-color-outline-color-primary-outline-dark: var(--emu-component-actions-button-color-outline-color-primary-outline-default-dark);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-active-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-outline-default-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-default-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-disabled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-light: var(--emu-component-actions-button-color-outline-color-secondary-filled-default-light);
  --emu-component-actions-button-color-outline-color-secondary-filled-dark: var(--emu-component-actions-button-color-outline-color-secondary-filled-default-dark);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-default-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-default-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-disabled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-light: var(--emu-component-actions-button-color-outline-color-secondary-outline-default-light);
  --emu-component-actions-button-color-outline-color-secondary-outline-dark: var(--emu-component-actions-button-color-outline-color-secondary-outline-default-dark);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-default-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-default-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-disabled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-group-horizontal-gap: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-horizontal-padding-left: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-horizontal-padding-right: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-horizontal-padding-top: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-group-horizontal-padding-bottom: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-group-vertical-gap: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-vertical-padding-left: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-vertical-padding-right: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-vertical-padding-top: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-group-vertical-padding-bottom: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-actions-button-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-actions-button-icon-transform-open: rotate(0);
  --emu-component-actions-button-icon-transform-closed: rotate(180deg);
  --emu-component-actions-button-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-actions-button-icon-color-primary-filled-light: var(--emu-component-actions-button-icon-color-primary-filled-default-light);
  --emu-component-actions-button-icon-color-primary-filled-dark: var(--emu-component-actions-button-icon-color-primary-filled-default-dark);
  --emu-component-actions-button-icon-color-primary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-default-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-default-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-disabled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-light: var(--emu-component-actions-button-icon-color-primary-outline-default-light);
  --emu-component-actions-button-icon-color-primary-outline-dark: var(--emu-component-actions-button-icon-color-primary-outline-default-dark);
  --emu-component-actions-button-icon-color-primary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-default-light: var(--emu-semantic-colors-actions-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-default-dark: var(--emu-semantic-colors-actions-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-icon-color-primary-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-icon-color-secondary-filled-focus-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-hover-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-active-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-default-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-default-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-light: var(--emu-component-actions-button-icon-color-secondary-filled-default-light);
  --emu-component-actions-button-icon-color-secondary-filled-dark: var(--emu-component-actions-button-icon-color-secondary-filled-default-dark);
  --emu-component-actions-button-icon-color-secondary-outline-hover-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-focus-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-active-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-default-light: var(--emu-semantic-colors-actions-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-default-dark: var(--emu-semantic-colors-actions-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-icon-color-secondary-outline-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-icon-color-secondary-outline-light: var(--emu-component-actions-button-icon-color-secondary-outline-default-light);
  --emu-component-actions-button-icon-color-secondary-outline-dark: var(--emu-component-actions-button-icon-color-secondary-outline-default-dark);
  --emu-component-actions-button-icon-size-height-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) * .75);
  --emu-component-actions-button-icon-size-height-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) * .75);
  --emu-component-actions-button-icon-size-width-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) * .75);
  --emu-component-actions-button-icon-size-width-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) * .75);
  --emu-component-actions-button-icon-border-radius: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-padding-top-narrow: 15px;
  --emu-component-actions-button-padding-top-wide: 15px;
  --emu-component-actions-button-padding-right-narrow: 74px;
  --emu-component-actions-button-padding-right-wide: 74px;
  --emu-component-actions-button-padding-bottom-narrow: 15px;
  --emu-component-actions-button-padding-bottom-wide: 15px;
  --emu-component-actions-button-padding-left-narrow: 74px;
  --emu-component-actions-button-padding-left-wide: 74px;
  --emu-component-actions-button-padding-narrow: var(--emu-component-actions-button-padding-top-narrow) var(--emu-component-actions-button-padding-right-narrow) var(--emu-component-actions-button-padding-bottom-narrow) var(--emu-component-actions-button-padding-left-narrow);
  --emu-component-actions-button-padding-wide: var(--emu-component-actions-button-padding-top-wide) var(--emu-component-actions-button-padding-right-wide) var(--emu-component-actions-button-padding-bottom-wide) var(--emu-component-actions-button-padding-left-wide);
  --emu-component-actions-button-border-radius-primary-filled: 100px;
  --emu-component-actions-button-border-radius-primary-outline: 58px;
  --emu-component-actions-button-border-radius-secondary-filled: 100px;
  --emu-component-actions-button-border-radius-secondary-outline: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-back-to-top-z-index: var(--emu-common-other-z-index-modal);
  --emu-component-actions-back-to-top-icon-size-height: var(--emu-common-sizing-medium);
  --emu-component-actions-back-to-top-icon-size-width: var(--emu-common-sizing-medium);
  --emu-component-actions-back-to-top-position-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-left-narrow: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-left-wide: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-right-narrow: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-right-wide: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-top-narrow: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-top-wide: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-margin-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-left-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-left-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-right-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-right-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-add-to-calendar-button-text-color-filled-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-active-light: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-actions-add-to-calendar-button-color-background-default-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-default-outline-light: var(--emu-common-colors-transparent);
  --emu-component-actions-add-to-calendar-button-color-background-default-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-add-to-calendar-button-color-background-hover-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-hover-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-hover-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-active-filled-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-actions-add-to-calendar-button-color-background-active-filled-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-add-to-calendar-button-color-background-active-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-active-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-focus-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-focus-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-focus-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-disabled-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-disabled-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-disabled-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-disabled-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-error-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-error-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-error-outline-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-actions-add-to-calendar-button-color-background-error-outline-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-actions-add-to-calendar-button-border-width-filled-narrow: var(--emu-common-border-width-none);
  --emu-component-actions-add-to-calendar-button-border-width-filled-wide: var(--emu-common-border-width-none);
  --emu-component-actions-add-to-calendar-button-border-width-outline-narrow: var(--emu-common-border-width-thin);
  --emu-component-actions-add-to-calendar-button-border-width-outline-wide: var(--emu-common-border-width-thin);
  --emu-component-actions-add-to-calendar-button-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-add-to-calendar-button-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-active-light: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-default-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-default-outline-light: var(--emu-common-colors-transparent);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-default-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-hover-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-hover-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-hover-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-active-filled-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-active-filled-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-active-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-active-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-focus-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-focus-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-focus-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-disabled-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-disabled-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-disabled-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-disabled-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-error-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-error-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-error-outline-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-error-outline-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-width: 165px;
  --emu-component-actions-add-to-calendar-dropdown-border-width-filled-narrow: var(--emu-common-border-width-none);
  --emu-component-actions-add-to-calendar-dropdown-border-width-filled-wide: var(--emu-common-border-width-none);
  --emu-component-actions-add-to-calendar-dropdown-border-width-outline-narrow: var(--emu-common-border-width-thin);
  --emu-component-actions-add-to-calendar-dropdown-border-width-outline-wide: var(--emu-common-border-width-thin);
  --emu-component-actions-add-to-calendar-dropdown-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-component-actions-add-to-calendar-dropdown-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-component-actions-add-to-calendar-dropdown-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-add-to-calendar-dropdown-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-actions-add-to-calendar-dropdown-icon-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-actions-add-to-calendar-dropdown-icon-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-actions-add-to-calendar-dropdown-icon-size-height-narrow: var(--emu-semantic-sizing-small);
  --emu-component-actions-add-to-calendar-dropdown-icon-size-height-wide: 24px;
  --emu-component-actions-add-to-calendar-dropdown-icon-size-width-narrow: var(--emu-semantic-sizing-small);
  --emu-component-actions-add-to-calendar-dropdown-icon-size-width-wide: 24px;
  --emu-component-actions-pagination-icon-narrow: var(--emu-semantic-font-sizes-narrow-small);
  --emu-component-actions-pagination-icon-wide: var(--emu-semantic-font-sizes-wide-small);
  --emu-component-actions-pagination-text-color-hover-light: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-hover-dark: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-default-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-actions-pagination-text-color-default-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-actions-pagination-text-color-active-light: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-active-dark: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-focus-light: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-focus-dark: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-typography-narrow: var(--emu-component-ingredients-text-typography-narrow);
  --emu-component-actions-pagination-text-typography-wide: var(--emu-component-ingredients-text-typography-wide);
  --emu-component-actions-pagination-gap-narrow: var(--emu-semantic-spacing-horizontal-onerem-narrow);
  --emu-component-actions-pagination-gap-wide: var(--emu-semantic-spacing-horizontal-onerem-wide);
  --emu-component-actions-pagination-margin-narrow: var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-pagination-margin-wide: var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-scroll-reveal-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-containers-scroll-reveal-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-containers-scroll-reveal-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-padding-top-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-padding-top-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-padding-bottom-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-padding-bottom-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-progress-bar-color-bar-light: var(--emu-common-colors-grey-300);
  --emu-component-containers-scroll-reveal-progress-bar-color-bar-dark: var(--emu-common-colors-grey-300);
  --emu-component-containers-scroll-reveal-static-section-margin-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-right-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-scroll-reveal-static-section-margin-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-scroll-reveal-cards-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-medium);
  --emu-component-containers-scroll-reveal-cards-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-medium);
  --emu-component-containers-scroll-reveal-cards-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-cards-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-cards-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-containers-scroll-reveal-cards-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-containers-scroll-reveal-cards-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-cards-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-comparison-slider-divider-color-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-comparison-slider-divider-color-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-comparison-slider-divider-size-width-narrow: 2px;
  --emu-component-containers-comparison-slider-divider-size-width-wide: 2px;
  --emu-component-containers-comparison-slider-handle-size-width-narrow: var(--emu-semantic-sizing-medium);
  --emu-component-containers-comparison-slider-handle-size-width-wide: var(--emu-semantic-sizing-large);
  --emu-component-containers-comparison-slider-custom-handle-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-arrow-size-width-narrow: var(--emu-common-sizing-small);
  --emu-component-containers-comparison-slider-custom-handle-arrow-size-width-wide: var(--emu-common-sizing-small);
  --emu-component-containers-comparison-slider-custom-handle-arrow-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-comparison-slider-custom-handle-arrow-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-comparison-slider-custom-handle-arrow-thickness-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-custom-handle-arrow-thickness-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-custom-handle-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-comparison-slider-custom-handle-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-comparison-slider-custom-handle-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-comparison-slider-custom-handle-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-comparison-slider-custom-handle-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-line-height-narrow: var(--emu-semantic-line-heights-narrow-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-line-height-wide: var(--emu-semantic-line-heights-wide-medium);
  --emu-component-containers-tabs-gap-horizontal-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-gap-horizontal-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-gap-vertical-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-gap-vertical-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-padding-top-narrow: var(--emu-component-containers-container-padding-top-narrow);
  --emu-component-containers-tabs-navigation-item-padding-top-wide: var(--emu-component-containers-container-padding-top-wide);
  --emu-component-containers-tabs-navigation-item-padding-right-narrow: var(--emu-component-containers-container-padding-right-narrow);
  --emu-component-containers-tabs-navigation-item-padding-right-wide: var(--emu-component-containers-container-padding-right-wide);
  --emu-component-containers-tabs-navigation-item-padding-bottom-narrow: var(--emu-component-containers-container-padding-bottom-narrow);
  --emu-component-containers-tabs-navigation-item-padding-bottom-wide: var(--emu-component-containers-container-padding-bottom-wide);
  --emu-component-containers-tabs-navigation-item-padding-left-narrow: var(--emu-component-containers-container-padding-left-narrow);
  --emu-component-containers-tabs-navigation-item-padding-left-wide: var(--emu-component-containers-container-padding-left-wide);
  --emu-component-containers-tabs-navigation-item-gap-horizontal-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-horizontal-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-vertical-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-vertical-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-border-width-top-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-top-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-right-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-right-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-active: var(--emu-semantic-border-width-thin);
  --emu-component-containers-tabs-navigation-item-border-width-left-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-left-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-color-border-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-border-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-border-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-border-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-background-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-background-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-tabs-navigation-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-navigation-item-text-color-active-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-tabs-navigation-item-text-color-active-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-content-padding-top-narrow: var(--emu-component-containers-container-padding-top-narrow);
  --emu-component-containers-tabs-content-padding-top-wide: var(--emu-component-containers-container-padding-top-wide);
  --emu-component-containers-tabs-content-padding-right-narrow: var(--emu-component-containers-container-padding-right-narrow);
  --emu-component-containers-tabs-content-padding-right-wide: var(--emu-component-containers-container-padding-right-wide);
  --emu-component-containers-tabs-content-padding-bottom-narrow: var(--emu-component-containers-container-padding-bottom-narrow);
  --emu-component-containers-tabs-content-padding-bottom-wide: var(--emu-component-containers-container-padding-bottom-wide);
  --emu-component-containers-tabs-content-padding-left-narrow: var(--emu-component-containers-container-padding-left-narrow);
  --emu-component-containers-tabs-content-padding-left-wide: var(--emu-component-containers-container-padding-left-wide);
  --emu-component-containers-tabs-content-gap-horizontal-narrow: var(--emu-component-containers-container-gap-horizontal-narrow);
  --emu-component-containers-tabs-content-gap-horizontal-wide: var(--emu-component-containers-container-gap-horizontal-wide);
  --emu-component-containers-tabs-content-gap-vertical-narrow: var(--emu-component-containers-container-gap-vertical-narrow);
  --emu-component-containers-tabs-content-gap-vertical-wide: var(--emu-component-containers-container-gap-vertical-wide);
  --emu-component-containers-tabs-content-color-background-light: var(--emu-component-containers-container-color-background-light);
  --emu-component-containers-tabs-content-color-background-dark: var(--emu-component-containers-container-color-background-dark);
  --emu-component-containers-isi-header-padding-top-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-isi-header-padding-top-wide: var(--emu-common-spacing-none);
  --emu-component-containers-isi-header-padding-right-narrow: 25px;
  --emu-component-containers-isi-header-padding-right-wide: 25px;
  --emu-component-containers-isi-header-padding-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-isi-header-padding-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-containers-isi-header-padding-left-narrow: 25px;
  --emu-component-containers-isi-header-padding-left-wide: 25px;
  --emu-component-containers-isi-header-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-header-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-header-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-header-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-header-border-width-top: var(--emu-semantic-border-width-thin);
  --emu-component-containers-isi-body-padding-top-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-top-wide: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-right-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-right-wide: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-left-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-left-wide: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-background-light: var(--emu-common-colors-white);
  --emu-component-containers-isi-body-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-body-border-width-top: var(--emu-semantic-border-width-thin);
  --emu-component-containers-isi-open-content-size: 30vh;
  --emu-component-containers-isi-z-index: var(--emu-common-other-z-index-isi);
  --emu-component-containers-container-max-width: 100%;
  --emu-component-containers-container-padding-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-container-padding-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-container-padding-right-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-container-padding-right-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-container-padding-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-container-padding-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-container-padding-left-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-container-padding-left-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-container-padding-narrow: var(--emu-component-containers-container-padding-top-narrow) var(--emu-component-containers-container-padding-right-narrow) var(--emu-component-containers-container-padding-bottom-narrow) var(--emu-component-containers-container-padding-left-narrow);
  --emu-component-containers-container-padding-wide: var(--emu-component-containers-container-padding-top-wide) var(--emu-component-containers-container-padding-right-wide) var(--emu-component-containers-container-padding-bottom-wide) var(--emu-component-containers-container-padding-left-wide);
  --emu-component-containers-container-gap-horizontal-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-container-gap-horizontal-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-container-gap-vertical-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-container-gap-vertical-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-container-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-container-color-background-dark: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-gap-horizontal-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-carousel-gap-horizontal-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-carousel-gap-vertical-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-carousel-gap-vertical-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-carousel-indicators-margin-top-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-carousel-indicators-margin-top-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-carousel-indicators-margin-bottom-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-carousel-indicators-margin-bottom-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-carousel-indicators-gap-horizontal-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-carousel-indicators-gap-horizontal-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-carousel-indicators-dots-color-background-default-light: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-dots-color-background-default-dark: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-dots-color-background-active-light: var(--emu-common-colors-grey-300);
  --emu-component-containers-carousel-indicators-dots-color-background-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-containers-carousel-indicators-dots-color-border-default-light: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-dots-color-border-default-dark: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-dots-color-border-active-light: var(--emu-common-colors-grey-300);
  --emu-component-containers-carousel-indicators-dots-color-border-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-containers-carousel-indicators-dots-border-width-all: var(--emu-common-border-width-thin);
  --emu-component-containers-carousel-indicators-dots-size-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-dots-size-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-image-container-size-narrow: 160px;
  --emu-component-containers-carousel-indicators-image-container-size-wide: 160px;
  --emu-component-containers-carousel-indicators-image-color-border-default-light: var(--emu-common-colors-grey-100);
  --emu-component-containers-carousel-indicators-image-color-border-default-dark: var(--emu-common-colors-grey-100);
  --emu-component-containers-carousel-indicators-image-color-border-active-light: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-image-color-border-active-dark: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-image-border-width-all: var(--emu-common-border-width-thick);
  --emu-component-containers-carousel-indicators-image-size-narrow: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-indicators-image-size-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-indicators-text-color-background-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-text-color-background-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-text-color-text-light: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-text-color-text-dark: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-text-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-component-containers-carousel-indicators-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-component-containers-carousel-controls-padding-top-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-carousel-controls-padding-top-wide: var(--emu-common-spacing-none);
  --emu-component-containers-carousel-controls-padding-right-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-carousel-controls-padding-right-wide: var(--emu-common-spacing-none);
  --emu-component-containers-carousel-controls-padding-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-carousel-controls-padding-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-containers-carousel-controls-padding-left-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-carousel-controls-padding-left-wide: var(--emu-common-spacing-none);
  --emu-component-containers-carousel-controls-color-background-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-controls-color-background-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-controls-color-border-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-controls-color-border-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-controls-border-width-all: var(--emu-common-border-width-thin);
  --emu-component-containers-carousel-controls-z-index: var(--emu-common-other-z-index-layer);
  --emu-component-containers-carousel-controls-icon-color-fill-light: var(--emu-component-ingredients-icon-color-fill-light);
  --emu-component-containers-carousel-controls-icon-color-fill-dark: var(--emu-component-ingredients-icon-color-fill-dark);
  --emu-component-containers-carousel-controls-icon-size-narrow: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-controls-icon-size-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-embed-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-embed-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-embed-player-vimeo-global-color-light: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-color-dark: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-base: 15px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small: 13px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-large: 18px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-xlarge: 21px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-regular: 400;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-bold: 600;
  --emu-component-containers-embed-player-vimeo-global-text-typography-line-height: 1.7;
  --emu-component-containers-embed-player-vimeo-color-background-light: #000;
  --emu-component-containers-embed-player-vimeo-color-background-dark: #000;
  --emu-component-containers-embed-player-vimeo-outline-color-focus-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-outline-color-focus-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-badge-border-radius-all: 2px;
  --emu-component-containers-embed-player-vimeo-badge-color-background-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-color-background-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-text-typography-font-size: 9px;
  --emu-component-containers-embed-player-vimeo-badge-text-color-light: #fff;
  --emu-component-containers-embed-player-vimeo-badge-text-color-dark: #fff;
  --emu-component-containers-embed-player-vimeo-menu-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-menu-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-menu-color-border-light: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-color-border-dark: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-light: #728197;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-dark: #728197;
  --emu-component-containers-embed-player-vimeo-menu-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-vimeo-menu-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-border-radius-all: 4px;
  --emu-component-containers-embed-player-vimeo-menu-arrow-size: 6px;
  --emu-component-containers-embed-player-vimeo-controls-global-spacing: 10px;
  --emu-component-containers-embed-player-vimeo-controls-global-icon-sizing: 18px;
  --emu-component-containers-embed-player-vimeo-controls-global-padding: 7px;
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-global-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-vimeo-tooltip-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-tooltip-padding: 5px;
  --emu-component-containers-embed-player-vimeo-tooltip-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-light: #23282f99;
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-dark: #23282f99;
  --emu-component-containers-embed-player-vimeo-scrubber-sizing-height: 5px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-sizing-height: 13px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-light: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-dark: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-one: 0 1 1 0 #d71a1226;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-two: 0 0 0 1 #d71a1233;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-width-active: 3px;
  --emu-component-containers-embed-player-vimeo-scrubber-loading-size: 25px;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-light: #c1c8d199;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-dark: #c1c8d199;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-light: #d71a121a;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-dark: #d71a121a;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-light: #ffffff80;
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-dark: #ffffff80;
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-captions-text-color-light: #fff;
  --emu-component-containers-embed-player-vimeo-captions-text-color-dark: #fff;
  --emu-component-containers-embed-player-vimeo-captions-color-background-light: #000c;
  --emu-component-containers-embed-player-vimeo-captions-color-background-dark: #000c;
  --emu-component-containers-embed-player-vimeo-time-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-youtube-global-color-light: #00b3ff;
  --emu-component-containers-embed-player-youtube-global-color-dark: #00b3ff;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-base: 15px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-small: 13px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-large: 18px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-xlarge: 21px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-weight-regular: 400;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-weight-bold: 600;
  --emu-component-containers-embed-player-youtube-global-text-typography-line-height: 1.7;
  --emu-component-containers-embed-player-youtube-color-background-light: #000;
  --emu-component-containers-embed-player-youtube-color-background-dark: #000;
  --emu-component-containers-embed-player-youtube-outline-color-focus-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-outline-color-focus-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-badge-border-radius-all: 2px;
  --emu-component-containers-embed-player-youtube-badge-color-background-light: #4a5464;
  --emu-component-containers-embed-player-youtube-badge-color-background-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-badge-text-typography-font-size: 9px;
  --emu-component-containers-embed-player-youtube-badge-text-color-light: #fff;
  --emu-component-containers-embed-player-youtube-badge-text-color-dark: #fff;
  --emu-component-containers-embed-player-youtube-menu-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-youtube-menu-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-youtube-menu-color-border-light: #dcdfe5;
  --emu-component-containers-embed-player-youtube-menu-color-border-dark: #dcdfe5;
  --emu-component-containers-embed-player-youtube-menu-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-size: 4px;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-color-light: #728197;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-color-dark: #728197;
  --emu-component-containers-embed-player-youtube-menu-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-youtube-menu-text-color-light: #4a5464;
  --emu-component-containers-embed-player-youtube-menu-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-menu-border-radius-all: 4px;
  --emu-component-containers-embed-player-youtube-menu-arrow-size: 6px;
  --emu-component-containers-embed-player-youtube-controls-global-spacing: 10px;
  --emu-component-containers-embed-player-youtube-controls-global-icon-sizing: 18px;
  --emu-component-containers-embed-player-youtube-controls-global-padding: 7px;
  --emu-component-containers-embed-player-youtube-controls-global-toggle-color-background-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-global-toggle-color-background-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-global-border-radius-all: 3px;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-video-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-light: #4a5464;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-youtube-tooltip-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-youtube-tooltip-text-color-light: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-youtube-tooltip-border-radius-all: 3px;
  --emu-component-containers-embed-player-youtube-tooltip-padding: 5px;
  --emu-component-containers-embed-player-youtube-tooltip-arrow-size: 4px;
  --emu-component-containers-embed-player-youtube-scrubber-color-background-active-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-scrubber-color-background-active-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-scrubber-color-background-loading-light: #23282f99;
  --emu-component-containers-embed-player-youtube-scrubber-color-background-loading-dark: #23282f99;
  --emu-component-containers-embed-player-youtube-scrubber-sizing-height: 5px;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-sizing-height: 13px;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-color-background-light: #fff;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-color-background-dark: #fff;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-one: 0 1 1 0 #d71a1226;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-two: 0 0 0 1 #d71a1233;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-width-active: 3px;
  --emu-component-containers-embed-player-youtube-scrubber-loading-size: 25px;
  --emu-component-containers-embed-player-youtube-scrubber-audio-progress-color-background-buffered-light: #c1c8d199;
  --emu-component-containers-embed-player-youtube-scrubber-audio-progress-color-background-buffered-dark: #c1c8d199;
  --emu-component-containers-embed-player-youtube-scrubber-audio-thumb-shadow-active-light: #d71a121a;
  --emu-component-containers-embed-player-youtube-scrubber-audio-thumb-shadow-active-dark: #d71a121a;
  --emu-component-containers-embed-player-youtube-scrubber-audio-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-audio-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-progress-color-background-buffered-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-progress-color-background-buffered-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-thumb-shadow-active-light: #ffffff80;
  --emu-component-containers-embed-player-youtube-scrubber-video-thumb-shadow-active-dark: #ffffff80;
  --emu-component-containers-embed-player-youtube-scrubber-video-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-captions-text-color-light: #fff;
  --emu-component-containers-embed-player-youtube-captions-text-color-dark: #fff;
  --emu-component-containers-embed-player-youtube-captions-color-background-light: #000c;
  --emu-component-containers-embed-player-youtube-captions-color-background-dark: #000c;
  --emu-component-containers-embed-player-youtube-time-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-gallery-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-border-width: var(--emu-semantic-border-width-thin);
  --emu-component-containers-gallery-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-separator-content-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-sizing-width-narrow: 100%;
  --emu-component-containers-gallery-item-sizing-width-wide: calc(33.33% - 8px);
  --emu-component-containers-gallery-item-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-border-width: var(--emu-semantic-border-width-thin);
  --emu-component-containers-gallery-item-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-stage-container-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-gap-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-preview-container-gap-wide: var(--emu-common-sizing-xs);
  --emu-component-layers-modal-z-index: var(--emu-common-other-z-index-modal);
  --emu-component-layers-modal-scrim-color-background: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-layers-modal-body-sizing-width: 90%;
  --emu-component-layers-modal-body-sizing-max-width: 1080px;
  --emu-component-layers-modal-body-padding-top-narrow: 20px;
  --emu-component-layers-modal-body-padding-top-wide: 52px;
  --emu-component-layers-modal-body-padding-right-narrow: 20px;
  --emu-component-layers-modal-body-padding-right-wide: 36px;
  --emu-component-layers-modal-body-padding-bottom-narrow: 60px;
  --emu-component-layers-modal-body-padding-bottom-wide: 102px;
  --emu-component-layers-modal-body-padding-left-narrow: 20px;
  --emu-component-layers-modal-body-padding-left-wide: 36px;
  --emu-component-layers-modal-body-color-background-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-body-color-background-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-body-color-border-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-body-color-border-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-body-border-width-all: var(--emu-semantic-border-width-thin);
  --emu-component-layers-modal-actions-close-padding-top: var(--emu-common-spacing-none);
  --emu-component-layers-modal-actions-close-padding-right: var(--emu-common-spacing-none);
  --emu-component-layers-modal-actions-close-padding-bottom: var(--emu-common-spacing-none);
  --emu-component-layers-modal-actions-close-padding-left: var(--emu-common-spacing-none);
  --emu-component-layers-modal-actions-close-text-typography: var(--emu-semantic-typography-narrow-headings-xxl);
  --emu-component-layers-modal-actions-close-text-color-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-actions-close-text-color-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-actions-prompt-button-group-gap-narrow: var(--emu-common-spacing-small);
  --emu-component-layers-modal-actions-prompt-button-group-gap-wide: var(--emu-common-spacing-small);
  --emu-component-layers-modal-actions-prompt-button-group-margin-top: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-layers-cookie-consent-banner-z-index: var(--emu-common-other-z-index-cookie-banner);
  --emu-component-layers-cookie-consent-banner-max-width: var(--emu-component-containers-container-max-width) px;
  --emu-component-layers-cookie-consent-banner-text-color-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-text-color-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-color-background-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-color-background-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-shadow: 4px 4px 20px 4px #0003;
  --emu-component-layers-cookie-consent-banner-padding-top-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-padding-top-wide: 24px;
  --emu-component-layers-cookie-consent-banner-padding-right-narrow: 32px;
  --emu-component-layers-cookie-consent-banner-padding-right-wide: 32px;
  --emu-component-layers-cookie-consent-banner-padding-bottom-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-padding-bottom-wide: 24px;
  --emu-component-layers-cookie-consent-banner-padding-left-narrow: 32px;
  --emu-component-layers-cookie-consent-banner-padding-left-wide: 32px;
  --emu-component-layers-cookie-consent-banner-settings-button-text-typography-narrow: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-settings-button-text-typography-wide: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-active-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-active-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-padding-top-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-top-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-right-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-right-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-left-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-left-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-top-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-top-wide: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-right-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-right-wide: 0px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-wide: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-left-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-left-wide: 56px;
  --emu-component-layers-cookie-consent-banner-settings-button-border-width: 1px;
  --emu-component-layers-cookie-consent-banner-settings-button-outline-width: 1px;
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-active-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-active-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-dark: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-light: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-dark: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-active-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-active-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-light: var(--emu-common-colors-grey-300);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-dark: var(--emu-common-colors-grey-300);
}

html {
  -webkit-text-size-adjust: 100%;
}

html, body, h1, h2, h3, h4, h5, h6, p, li, a, span, button {
  color: inherit;
  margin: var(--emu-common-spacing-none);
  padding: var(--emu-common-spacing-none);
}

html, body {
  -webkit-tap-highlight-color: var(--emu-semantic-colors-none);
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
}

html .xf-content-height, body .xf-content-height {
  margin: inherit;
}

html .experiencefragment, body .experiencefragment {
  padding: var(--emu-common-spacing-none);
}

body {
  -webkit-overflow-scrolling: touch;
}

* {
  outline: var(--emu-semantic-colors-surface-dark);
}

*, :before, :after {
  box-sizing: border-box;
}

.cmp-image__image, image {
  width: auto;
  max-width: 100%;
}

button, input, optgroup, select, textarea {
  font: inherit;
}

sup {
  vertical-align: super;
  font-size: 60%;
  line-height: 0;
  position: relative;
  top: .1em;
}

::selection {
  color: var(--emu-common-colors-white);
  text-shadow: none;
  background: #444;
}

li::marker {
  font-size: 1.3em;
  line-height: 0;
}

.desktop-view {
  display: none;
}

@media (min-width: 1024px) {
  .desktop-view {
    display: block;
  }
}

.cq-Editable-dom--container .desktop-view {
  display: block;
}

.tablet-desktop-view {
  display: none;
}

@media (min-width: 768px) {
  .tablet-desktop-view {
    display: block;
  }
}

.cq-Editable-dom--container .tablet-desktop-view {
  display: block;
}

.tablet-view {
  display: none;
}

@media (min-width: 768px) {
  .tablet-view {
    display: block;
  }
}

@media (min-width: 1024px) {
  .tablet-view {
    display: none;
  }
}

.cq-Editable-dom--container .tablet-view, .tablet-mobile-view {
  display: block;
}

@media (min-width: 1024px) {
  .tablet-mobile-view {
    display: none;
  }
}

.cq-Editable-dom--container .tablet-mobile-view, .mobile-view {
  display: block;
}

@media (min-width: 768px) {
  .mobile-view {
    display: none;
  }
}

.cq-Editable-dom--container .mobile-view {
  display: block;
}

.no-pad {
  padding: 0;
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

.u-content--centered {
  text-align: center;
}

[class*="u-container--"] {
  margin-left: auto;
  margin-right: auto;
}

.u-container--default {
  max-width: var(--emu-semantic-sizing-breakpoints-large-plus);
  padding-left: 15px;
  padding-right: 15px;
}

.u-container--large {
  max-width: var(--emu-semantic-sizing-breakpoints-x-large);
}

.u-bg--white {
  background-color: var(--emu-common-colors-white);
}

.u-bg--black {
  background-color: var(--emu-common-colors-black);
}

.u-bg--light-blue-100 {
  background-color: var(--emu-semantic-colors-primary-100);
}

.u-bg--dark-blue-500 {
  background-color: var(--emu-semantic-colors-primary-500);
}

.u-bg--dark-blue-600 {
  background-color: var(--emu-semantic-colors-primary-600);
}

.u-bg--light-grey-50 {
  background-color: var(--emu-semantic-colors-secondary-50);
}

.u-bg--gradient-blue {
  background: linear-gradient(180deg, var(--emu-semantic-colors-primary-700) 8.29%, var(--emu-semantic-colors-primary-500) 25.17%, var(--emu-semantic-colors-primary-100) 78.12%);
}

[class*="u-text--"] h1, [class*="u-text--"] h2, [class*="u-text--"] h3, [class*="u-text--"] h4, [class*="u-text--"] h5, [class*="u-text--"] h6, [class*="u-text--"] p, [class*="u-text--"] li, [class*="u-text--"] a, [class*="u-text--"] span, [class*="u-text--"] button:not(.emu-dropdown-menu__button, .emu-dropdown-menu__item) {
  color: inherit;
}

.u-text--white {
  color: var(--emu-common-colors-white);
}

.u-text--black {
  color: var(--emu-common-colors-black);
}

.u-text--dark-blue-100 {
  color: var(--emu-semantic-colors-primary-100);
}

.u-text--dark-blue-200 {
  color: var(--emu-semantic-colors-primary-200);
}

.u-text--blue-gradient, .u-text--blue-gradient sup {
  background: -webkit-linear-gradient(var(--emu-semantic-colors-primary-400), var(--emu-semantic-colors-primary-300));
  color: var(--emu-semantic-colors-primary-100);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.u-text--light-grey-300 {
  color: var(--emu-semantic-colors-secondary-300);
}

[class*="u-body-font"] h1, [class*="u-body-font"] h2, [class*="u-body-font"] h3, [class*="u-body-font"] h4, [class*="u-body-font"] h5, [class*="u-body-font"] p, [class*="u-body-font"] li, [class*="u-body-font"] a, [class*="u-body-font"] span, [class*="u-body-font"] button {
  font: inherit;
  color: inherit;
  letter-spacing: inherit;
}

.u-body-font--disclaimer {
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  line-height: var(--emu-semantic-line-heights-narrow-xs);
}

@media (max-width: 1023px) {
  .u-hide-overflow-in-mobile {
    height: 100vh;
    overflow: hidden;
  }
}

.u-hide:not(.cq-Editable-dom--container .u-hide) {
  display: none !important;
}

.u-author-only {
  padding: var(--emu-common-spacing-large);
  background-color: var(--emu-common-colors-grey-600);
  color: var(--emu-common-colors-white);
  display: none;
}

.cq-Editable-dom--container .u-author-only {
  display: block;
}

.u-author-only p {
  font-size: var(--emu-semantic-font-sizes-narrow-xxl);
  line-height: 1;
}

.u-author-only p i {
  font-size: 20px;
}

.u-common-title .cmp-title__text {
  font-size: 28px;
  line-height: 34px;
}

@media (min-width: 321px) {
  .u-common-title .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-narrow-large);
    line-height: var(--emu-semantic-line-heights-narrow-large);
  }
}

@media (min-width: 414px) {
  .u-common-title .cmp-title__text {
    font-size: 33px;
    line-height: var(--emu-common-line-heights-narrow-xl);
  }
}

@media (min-width: 768px) {
  .u-common-title .cmp-title__text {
    font-size: 64px;
    line-height: 69px;
  }
}

@media (min-width: 1024px) {
  .u-common-title .cmp-title__text {
    line-height: 69.7px;
  }
}

@media (min-width: 1200px) {
  .u-common-title .cmp-title__text {
    font-size: 54px;
    line-height: 58px;
  }
}

.u-common-text {
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  line-height: var(--emu-semantic-line-heights-narrow-small);
}

@media (min-width: 414px) {
  .u-common-text {
    font-size: var(--emu-semantic-font-sizes-narrow-medium);
    line-height: 29px;
  }
}

@media (min-width: 768px) {
  .u-common-text {
    font-size: 28px;
    line-height: 42px;
  }
}

@media (min-width: 1025px) {
  .u-common-text {
    font-size: var(--emu-semantic-font-sizes-wide-small);
    line-height: var(--emu-semantic-line-heights-wide-small);
  }
}

.u-common-text p, .u-common-text li {
  font-size: inherit;
  line-height: inherit;
}

@media (min-width: 576px) and (max-width: 767px) {
  .u-common-text--lg-in-lg-mobile {
    font-size: 22px;
    line-height: 40px;
  }
}

.coolmonth-banner-wrapper {
  max-width: unset;
  position: relative;
  overflow: hidden;
}

.coolmonth-banner-wrapper.hidden {
  display: none;
}

.coolmonth-banner-wrapper > .container {
  display: flex;
}

.coolmonth-banner-wrapper .background-image img {
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  inset: 50% 0 0 50%;
  transform: translate(-50%, -50%);
}

.coolmonth-banner {
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

@media (min-width: 768px) {
  .coolmonth-banner {
    flex-direction: row;
  }
}

.coolmonth-banner > div {
  align-items: center;
  display: flex;
}

.coolmonth-banner .logo {
  width: 150px;
  margin-top: 32px;
  margin-bottom: -16px;
  position: relative;
}

@media (min-width: 768px) {
  .coolmonth-banner .logo {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 32px;
  }
}

.coolmonth-banner .text {
  position: relative;
}

.coolmonth-banner p, .coolmonth-banner a {
  color: #fff;
  text-align: center;
  font-family: Arial, sans-serif;
  font-size: 20px;
}

.coolmonth-banner p {
  padding: 32px;
}

#error-page .error__sub-title p {
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  line-height: var(--emu-semantic-line-heights-narrow-large);
  margin-bottom: 10px;
}

#error-page .error__text p, #error-page .error .button .emu-button-v2 {
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  line-height: var(--emu-common-line-heights-wide-medium);
  font-weight: var(--emu-semantic-font-weight-light);
  font-family: var(--emu-semantic-font-families-body);
  font-weight: var(--emu-semantic-font-weight-light);
  padding: var(--emu-common-spacing-none);
}

#error-page .error__text {
  color: var(--emu-semantic-colors-primary-950);
  margin-bottom: 36px;
}

@media (min-width: 1024px) {
  #error-page .error__text {
    margin-bottom: 30px;
  }
}

#error-page .error__content {
  padding-right: var(--emu-common-spacing-xs);
  margin-bottom: 48px;
  padding-left: 34px;
}

@media (min-width: 768px) {
  #error-page .error__content {
    padding-left: 45px;
  }
}

@media (min-width: 1200px) {
  #error-page .error__content {
    padding-right: 20px;
  }
}

#error-page .error__content .button .emu-button-v2:hover .cmp-button__text {
  -webkit-text-fill-color: var(--emu-semantic-colors-primary-100);
  background: none;
  text-decoration: underline;
}

@media (min-width: 414px) and (max-width: 575px) {
  #discover-our-story .banner.banner--main .banner__teaser {
    height: 825px;
  }
}

@media (min-width: 576px) and (max-width: 766px) {
  #discover-our-story .banner.banner--main .banner__teaser {
    height: 1225px;
  }
}

@media (min-width: 767px) and (max-width: 1023px) {
  #discover-our-story .banner.banner--main .banner__teaser {
    height: 1432px;
  }
}

@media (min-width: 768px) {
  #discover-our-story .banner.banner--main .banner__teaser .disclaimer-bottom-right .emu-teaser__disclaimer {
    padding-bottom: 10px;
  }
}

#discover-our-story .banner.banner--main .banner__content {
  padding-top: 70%;
}

@media (min-width: 321px) {
  #discover-our-story .banner.banner--main .banner__content {
    padding-top: 90%;
  }
}

@media (min-width: 768px) {
  #discover-our-story .banner.banner--main .banner__content {
    padding-top: 70%;
  }
}

@media (min-width: 1024px) {
  #discover-our-story .banner.banner--main .banner__content {
    padding-top: var(--emu-common-spacing-none);
    padding-left: 15px;
    top: 27%;
  }
}

@media (min-width: 1199px) {
  #discover-our-story .banner.banner--main .banner__content {
    padding-right: var(--emu-common-spacing-xs);
  }
}

@media (min-width: 1200px) {
  #discover-our-story .banner.banner--main .banner__content {
    padding-left: var(--emu-common-spacing-xs);
    max-width: var(--emu-common-sizing-desktop);
    margin-top: -34px;
    margin-left: auto;
    margin-right: auto;
    right: 0;
  }
}

@media (min-width: 1601px) {
  #discover-our-story .banner.banner--main .banner__content {
    margin-top: -35px;
  }
}

@media (min-width: 768px) {
  #discover-our-story .banner.banner--main .banner__content-inner {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1024px) {
  #discover-our-story .banner.banner--main .banner__content-inner {
    padding-top: 33%;
  }
}

@media (min-width: 1025px) {
  #discover-our-story .banner.banner--main .banner__content-inner {
    padding-right: 30px;
  }
}

@media (min-width: 1200px) {
  #discover-our-story .banner.banner--main .banner__content-inner {
    padding-top: 16px;
    padding-right: 30px;
  }
}

#discover-our-story .banner.banner--main .banner__content-img img {
  padding-left: var(--emu-common-spacing-none);
  max-width: 80%;
  padding-right: 15px;
}

@media (min-width: 321px) {
  #discover-our-story .banner.banner--main .banner__content-img img {
    padding-left: 5px;
    padding-right: var(--emu-common-spacing-none);
  }
}

@media (min-width: 414px) {
  #discover-our-story .banner.banner--main .banner__content-img img {
    padding-left: var(--emu-common-spacing-none);
  }
}

@media (min-width: 767px) {
  #discover-our-story .banner.banner--main .banner__content-img img {
    padding-right: 14%;
  }
}

@media (min-width: 768px) {
  #discover-our-story .banner.banner--main .banner__content-img img {
    margin-left: var(--emu-common-spacing-none);
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}

@media (min-width: 1024px) {
  #discover-our-story .banner.banner--main .banner__content-img img {
    padding-right: 35%;
  }
}

@media (min-width: 1200px) {
  #discover-our-story .banner.banner--main .banner__content-img img {
    width: auto;
    height: auto;
  }
}

#discover-our-story .banner.banner--main .banner__content-title {
  margin-top: -19px;
  padding-left: 25px;
  padding-right: 25px;
}

@media (min-width: 321px) {
  #discover-our-story .banner.banner--main .banner__content-title {
    margin-top: -22px;
    padding-left: 40px;
  }
}

@media (min-width: 414px) {
  #discover-our-story .banner.banner--main .banner__content-title {
    margin-top: -21px;
  }
}

@media (min-width: 768px) {
  #discover-our-story .banner.banner--main .banner__content-title {
    margin-top: -46px;
    padding-left: 85px;
    padding-right: 53px;
  }
}

@media (min-width: 1200px) {
  #discover-our-story .banner.banner--main .banner__content-title {
    padding-left: 104px;
    padding-right: var(--emu-common-spacing-none);
    margin-top: -25px;
  }
}

@media (min-width: 321px) and (max-width: 413px) {
  #discover-our-story .banner.banner--main .banner__content-title .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-narrow-large);
    line-height: var(--emu-semantic-line-heights-narrow-large);
  }
}

@media (min-width: 1025px) and (max-width: 1199px) {
  #discover-our-story .banner.banner--main .banner__content-title .cmp-title__text {
    font-size: 64px;
    line-height: 69px;
  }
}

#discover-our-story .banner.banner--main .banner__content-text {
  padding-left: 25px;
  padding-right: 40px;
}

@media (min-width: 321px) {
  #discover-our-story .banner.banner--main .banner__content-text {
    margin-top: var(--emu-common-spacing-small);
    padding-left: 40px;
  }
}

@media (min-width: 321px) and (max-width: 413px) {
  #discover-our-story .banner.banner--main .banner__content-text {
    line-height: 21px;
  }
}

@media (min-width: 414px) {
  #discover-our-story .banner.banner--main .banner__content-text {
    margin-top: 7px;
  }
}

@media (min-width: 767px) {
  #discover-our-story .banner.banner--main .banner__content-text {
    margin-top: var(--emu-common-spacing-small);
  }
}

@media (min-width: 768px) {
  #discover-our-story .banner.banner--main .banner__content-text {
    margin-top: 22px;
    padding-left: 85px;
    padding-right: 53px;
  }
}

@media (min-width: 1025px) and (max-width: 1199px) {
  #discover-our-story .banner.banner--main .banner__content-text {
    font-size: 28px;
    line-height: 42px;
  }
}

@media (min-width: 1200px) {
  #discover-our-story .banner.banner--main .banner__content-text {
    padding-left: 104px;
    padding-right: var(--emu-common-spacing-none);
    margin-top: 13px;
  }
}

@media (min-width: 1199px) {
  #discover-our-story .banner.banner--main .banner__content-text-container {
    padding-right: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1024px) {
  #discover-our-story .banner.banner--main .banner__content-text br {
    display: none;
  }
}

@media (min-width: 1200px) {
  #discover-our-story .banner.banner--main .banner__content-text br {
    display: block;
  }
}

#discover-our-story .timeline {
  padding: 49px 20px 45px;
}

@media (min-width: 321px) {
  #discover-our-story .timeline {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (min-width: 576px) {
  #discover-our-story .timeline {
    padding-left: 41px;
    padding-right: 41px;
  }
}

@media (min-width: 768px) {
  #discover-our-story .timeline {
    padding-top: 2px;
    padding-left: 39px;
    padding-right: 39px;
  }
}

@media (min-width: 1200px) {
  #discover-our-story .timeline {
    padding: 83px 82px 82px;
  }
}

#discover-our-story .timeline__inner {
  max-width: var(--emu-common-sizing-desktop);
  margin-left: auto;
  margin-right: auto;
}

#discover-our-story .timeline__gif-section {
  position: relative;
}

#discover-our-story .timeline__gif-section-title {
  margin-bottom: 46px;
  padding-top: 53px;
}

@media (min-width: 1024px) {
  #discover-our-story .timeline__gif-section-title {
    margin-bottom: 44px;
  }
}

@media (min-width: 1200px) {
  #discover-our-story .timeline__gif-section-title {
    margin-bottom: 73px;
    padding-top: 103px;
  }
}

#discover-our-story .timeline__gif-section-img {
  margin-bottom: 50px;
  position: relative;
}

@media (min-width: 414px) {
  #discover-our-story .timeline__gif-section-img {
    margin-bottom: 49px;
  }
}

@media (min-width: 767px) {
  #discover-our-story .timeline__gif-section-img {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
  #discover-our-story .timeline__gif-section-img {
    width: 100%;
    margin-bottom: 150px;
  }
}

#discover-our-story .timeline__gif-section-img .cmp-image__title {
  font-weight: var(--emu-semantic-font-weight-500);
  color: var(--emu-common-colors-white);
  font-size: 12px;
  line-height: 135%;
  position: absolute;
  bottom: -30px;
}

#discover-our-story .timeline__gif-section-img img {
  object-fit: cover;
  height: 308px;
}

@media (min-width: 321px) {
  #discover-our-story .timeline__gif-section-img img {
    height: 346px;
  }
}

@media (min-width: 414px) {
  #discover-our-story .timeline__gif-section-img img {
    height: 389px;
  }
}

@media (min-width: 576px) {
  #discover-our-story .timeline__gif-section-img img {
    height: 538px;
  }
}

@media (min-width: 1439px) {
  #discover-our-story .timeline__gif-section-img img {
    height: 560px;
  }
}

#discover-our-story .timeline__teaser {
  background-repeat: no-repeat;
  background-size: cover;
  height: 230px;
  margin-top: 73px;
  margin-bottom: 52px;
}

@media (min-width: 321px) {
  #discover-our-story .timeline__teaser {
    height: 253px;
  }
}

@media (min-width: 414px) {
  #discover-our-story .timeline__teaser {
    height: 285px;
    margin-bottom: 50px;
  }
}

@media (min-width: 576px) {
  #discover-our-story .timeline__teaser {
    height: 402px;
  }
}

@media (min-width: 767px) {
  #discover-our-story .timeline__teaser {
    width: 95%;
  }
}

@media (min-width: 768px) {
  #discover-our-story .timeline__teaser {
    height: 540px;
    margin-top: -5px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 820px) and (max-width: 1023px) and (orientation: portrait) {
  #discover-our-story .timeline__teaser {
    height: 580px;
  }
}

@media (min-width: 1024px) {
  #discover-our-story .timeline__teaser {
    height: 735px;
  }
}

@media (min-width: 1200px) {
  #discover-our-story .timeline__teaser {
    width: 100%;
    height: 572px;
    margin-top: 104px;
    margin-bottom: 135px;
  }
}

@media (min-width: 1439px) {
  #discover-our-story .timeline__teaser {
    height: 674px;
  }
}

#discover-our-story .timeline__teaser .content-container {
  position: static;
}

#discover-our-story .timeline__teaser .emu-teaser__disclaimer {
  font-weight: var(--emu-semantic-font-weight-500);
  text-shadow: -1px 1px 9px #000000bf;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  padding-left: 22px;
  padding-right: 22px;
  position: absolute;
  bottom: 0;
  left: 0;
}

#discover-our-story .timeline__teaser .emu-teaser__disclaimer p {
  font-size: 10px;
  line-height: 135%;
}

#discover-our-story .timeline__elite-teaser {
  background-repeat: no-repeat;
  background-size: cover;
  height: 173px;
}

@media (min-width: 321px) {
  #discover-our-story .timeline__elite-teaser {
    height: 186px;
  }
}

@media (min-width: 414px) {
  #discover-our-story .timeline__elite-teaser {
    height: 211px;
  }
}

@media (min-width: 576px) {
  #discover-our-story .timeline__elite-teaser {
    height: 320px;
  }
}

@media (min-width: 768px) {
  #discover-our-story .timeline__elite-teaser {
    height: 430px;
    margin-top: -5px;
  }
}

@media (min-width: 820px) and (max-width: 1023px) and (orientation: portrait) {
  #discover-our-story .timeline__elite-teaser {
    height: 450px;
  }
}

@media (min-width: 1024px) {
  #discover-our-story .timeline__elite-teaser {
    height: 590px;
    margin-top: -3px;
  }
}

@media (min-width: 1200px) {
  #discover-our-story .timeline__elite-teaser {
    height: 563px;
    margin-top: 152px;
    margin-bottom: 39px;
  }
}

@media (min-width: 1439px) {
  #discover-our-story .timeline__elite-teaser {
    height: 661px;
  }
}

#discover-our-story .timeline__elite-teaser .content-container {
  position: static;
}

#discover-our-story .timeline__elite-teaser .emu-teaser__disclaimer {
  padding-bottom: 15px;
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (min-width: 768px) {
  #discover-our-story .timeline__elite-teaser .emu-teaser__disclaimer {
    padding-bottom: 13px;
  }
}

#discover-our-story .timeline__elite-teaser .emu-teaser__disclaimer sup {
  font-size: 60%;
  top: -.1em;
}

#discover-our-story .timeline__elite-teaser .emu-teaser__disclaimer p {
  font-size: 13px;
  line-height: 18px;
  font-family: var(--emu-semantic-font-families-heading);
  font-weight: var(--emu-common-font-weight-bold);
  text-align: center;
}

@media (min-width: 414px) {
  #discover-our-story .timeline__elite-teaser .emu-teaser__disclaimer p {
    font-size: 17px;
    line-height: 23.7px;
  }
}

@media (min-width: 576px) {
  #discover-our-story .timeline__elite-teaser .emu-teaser__disclaimer p {
    font-size: 24px;
    line-height: 32.78px;
  }
}

@media (min-width: 767px) {
  #discover-our-story .timeline__elite-teaser .emu-teaser__disclaimer p {
    font-size: 30px;
    line-height: 40.98px;
  }
}

@media (min-width: 1200px) {
  #discover-our-story .timeline__elite-teaser .emu-teaser__disclaimer p {
    font-size: 24px;
    line-height: 32.7px;
  }
}

@media (max-width: 320px) {
  #discover-our-story .banner.banner--fap .banner__teaser {
    height: 715px;
  }
}

@media (min-width: 321px) and (max-width: 413px) {
  #discover-our-story .banner.banner--fap .banner__teaser {
    height: 825px;
  }
}

@media (min-width: 414px) and (max-width: 766px) {
  #discover-our-story .banner.banner--fap .banner__teaser {
    height: 900px;
  }
}

@media (min-width: 768px) {
  #discover-our-story .banner.banner--fap .banner__teaser {
    background-position: top;
  }
}

@media (min-width: 1024px) {
  #discover-our-story .banner.banner--fap .banner__teaser {
    background-position: center;
  }

  #discover-our-story .banner.banner--fap .banner__content {
    padding-top: 6px;
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    width: auto;
    max-width: none;
    margin-left: -15px;
    margin-right: -15px;
    top: 0;
  }
}

@media (min-width: 1025px) {
  #discover-our-story .banner.banner--fap .banner__content {
    max-width: var(--emu-common-sizing-large-tablet);
    margin-left: auto;
    margin-right: auto;
    top: 15%;
  }
}

@media (min-width: 768px) {
  #discover-our-story .banner.banner--fap .banner__content-inner {
    padding-top: 87%;
  }
}

@media (min-width: 1024px) {
  #discover-our-story .banner.banner--fap .banner__content-inner {
    padding-top: 81%;
  }
}

@media (min-width: 1200px) {
  #discover-our-story .banner.banner--fap .banner__content-inner {
    padding-top: 14.7%;
  }
}

@media (min-width: 1401px) {
  #discover-our-story .banner.banner--fap .banner__content-inner {
    padding-top: 20.62%;
  }
}

@media (min-width: 1601px) {
  #discover-our-story .banner.banner--fap .banner__content-inner {
    padding-top: 28%;
  }
}

@media (min-width: 1025px) {
  #discover-our-story .banner.banner--fap .banner__content-title .cmp-title__text {
    font-size: 64px;
    line-height: 69px;
  }
}

@media (min-width: 1200px) {
  #discover-our-story .banner.banner--fap .banner__content-title .cmp-title__text {
    color: var(--emu-semantic-colors-secondary-600);
    font-size: 54px;
    line-height: 58px;
  }
}

@media (min-width: 1025px) {
  #discover-our-story .banner.banner--fap .banner__content-text {
    font-size: 28px;
    line-height: 42px;
  }
}

@media (min-width: 1200px) {
  #discover-our-story .banner.banner--fap .banner__content-text {
    color: var(--emu-semantic-colors-secondary-600);
    font-size: 24px;
    line-height: 31px;
  }
}

#esf-see-results .banner.banner--main .banner__teaser {
  background-position: top;
  top: 1px;
}

@media (max-width: 349px) {
  #esf-see-results .banner.banner--main .banner__teaser {
    height: 104vh;
  }
}

@media (min-width: 350px) and (max-width: 575px) {
  #esf-see-results .banner.banner--main .banner__teaser {
    height: 105vh;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  #esf-see-results .banner.banner--main .banner__teaser {
    height: 99vh;
  }
}

@media (min-width: 1200px) {
  #esf-see-results .banner.banner--main .banner__teaser {
    background-position: center;
  }
}

#esf-see-results .banner.banner--main .banner__teaser .emu-teaser__disclaimer {
  padding-left: 30px;
  padding-right: 30px;
}

@media (min-width: 350px) {
  #esf-see-results .banner.banner--main .banner__teaser .emu-teaser__disclaimer {
    padding-bottom: 20px;
  }
}

@media (min-width: 576px) {
  #esf-see-results .banner.banner--main .banner__teaser .emu-teaser__disclaimer {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 768px) {
  #esf-see-results .banner.banner--main .banner__teaser .emu-teaser__disclaimer {
    padding-left: 90px;
    padding-right: 90px;
  }
}

@media (min-width: 1024px) {
  #esf-see-results .banner.banner--main .banner__teaser .emu-teaser__disclaimer {
    padding-left: 84px;
    padding-right: 84px;
  }
}

@media (min-width: 1200px) {
  #esf-see-results .banner.banner--main .banner__teaser .emu-teaser__disclaimer {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#esf-see-results .banner.banner--main .banner__teaser .emu-teaser__disclaimer sup {
  top: .5em;
}

#esf-see-results .banner.banner--main .banner__content {
  align-items: flex-end;
  height: calc(100% - 121px);
  padding: 10px 37px 12px 30px;
  display: flex;
}

@media (min-width: 350px) {
  #esf-see-results .banner.banner--main .banner__content {
    top: 3px;
  }
}

@media (min-width: 414px) {
  #esf-see-results .banner.banner--main .banner__content {
    padding-top: 20px;
    padding-bottom: 22px;
    padding-right: 30px;
    top: 13px;
  }
}

@media (min-width: 576px) {
  #esf-see-results .banner.banner--main .banner__content {
    padding-left: 40px;
    padding-right: 40px;
    top: 19px;
  }
}

@media (min-width: 768px) {
  #esf-see-results .banner.banner--main .banner__content {
    height: 90%;
    padding-bottom: 20px;
    padding-left: 90px;
    padding-right: 90px;
    display: block;
  }
}

@media (min-width: 1024px) {
  #esf-see-results .banner.banner--main .banner__content {
    padding-left: 84px;
    padding-right: 84px;
    padding-bottom: var(--emu-common-spacing-none);
    top: 4px;
  }
}

@media (min-width: 1200px) {
  #esf-see-results .banner.banner--main .banner__content {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    top: -3px;
  }
}

@media (min-width: 768px) {
  #esf-see-results .banner.banner--main .banner__content > .container {
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-top: 58%;
    display: flex;
  }
}

@media (min-width: 1024px) {
  #esf-see-results .banner.banner--main .banner__content > .container {
    padding-top: 31%;
  }
}

@media (min-width: 1200px) {
  #esf-see-results .banner.banner--main .banner__content > .container {
    padding-top: var(--emu-common-spacing-none);
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 108px;
    padding-right: 108px;
  }
}

@media (min-width: 768px) {
  #esf-see-results .banner.banner--main .banner__content-inner {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1024px) {
  #esf-see-results .banner.banner--main .banner__content-inner {
    margin: var(--emu-common-spacing-none);
  }
}

#esf-see-results .banner.banner--main .banner__content-img img {
  padding: var(--emu-common-spacing-none);
  width: auto;
  max-width: none;
  height: 105px;
  position: relative;
  left: -4%;
}

@media (min-width: 350px) {
  #esf-see-results .banner.banner--main .banner__content-img img {
    height: 110px;
    left: -3%;
  }
}

@media (min-width: 414px) {
  #esf-see-results .banner.banner--main .banner__content-img img {
    height: 120px;
  }
}

@media (min-width: 576px) {
  #esf-see-results .banner.banner--main .banner__content-img img {
    height: 179px;
    left: -20px;
  }
}

@media (min-width: 768px) {
  #esf-see-results .banner.banner--main .banner__content-img img {
    margin-left: var(--emu-common-spacing-none);
    height: 230px;
    left: -35px;
  }
}

@media (min-width: 1200px) {
  #esf-see-results .banner.banner--main .banner__content-img img {
    height: 240px;
    left: -100px;
  }
}

#esf-see-results .banner.banner--main .banner__content-title {
  padding: var(--emu-common-spacing-none);
  margin-top: -13px;
  position: relative;
}

@media (min-width: 350px) {
  #esf-see-results .banner.banner--main .banner__content-title {
    margin-top: -12px;
  }
}

@media (min-width: 576px) {
  #esf-see-results .banner.banner--main .banner__content-title {
    margin-top: 14px;
  }
}

@media (min-width: 768px) {
  #esf-see-results .banner.banner--main .banner__content-title {
    margin-top: -24px;
  }
}

@media (min-width: 1024px) {
  #esf-see-results .banner.banner--main .banner__content-title {
    margin-top: -33px;
  }
}

@media (min-width: 1200px) {
  #esf-see-results .banner.banner--main .banner__content-title {
    margin-top: -30px;
  }
}

@media (min-width: 576px) {
  #esf-see-results .banner.banner--main .banner__content-title .cmp-title__text {
    font-size: 46px;
    line-height: 50px;
  }
}

@media (min-width: 768px) {
  #esf-see-results .banner.banner--main .banner__content-title .cmp-title__text {
    font-size: 64px;
    line-height: 69px;
  }
}

@media (min-width: 1200px) {
  #esf-see-results .banner.banner--main .banner__content-title .cmp-title__text {
    font-size: 54px;
    line-height: 58px;
  }
}

#esf-see-results .banner.banner--main .banner__content-text {
  padding: var(--emu-common-spacing-none);
  margin-top: 12px;
}

@media (min-width: 350px) {
  #esf-see-results .banner.banner--main .banner__content-text {
    margin-top: 17px;
  }
}

@media (min-width: 414px) {
  #esf-see-results .banner.banner--main .banner__content-text {
    margin-left: var(--emu-common-spacing-none);
    width: 90%;
    margin-top: 7px;
    font-size: 16px;
    line-height: 29px;
  }
}

@media (min-width: 576px) {
  #esf-see-results .banner.banner--main .banner__content-text {
    font-size: 22px;
    line-height: var(--emu-semantic-font-sizes-narrow-xxl);
    width: 84%;
    margin-top: 21px;
  }
}

@media (min-width: 768px) {
  #esf-see-results .banner.banner--main .banner__content-text {
    margin-top: 22px;
    margin-bottom: var(--emu-common-spacing-medium);
    width: 570px;
    font-size: 28px;
    line-height: 42px;
  }
}

@media (min-width: 1024px) {
  #esf-see-results .banner.banner--main .banner__content-text {
    margin-top: 21px;
    margin-bottom: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1025px) {
  #esf-see-results .banner.banner--main .banner__content-text {
    width: 45%;
    font-size: 24px;
    line-height: 31px;
  }
}

@media (min-width: 1200px) {
  #esf-see-results .banner.banner--main .banner__content-text {
    margin-top: 17px;
  }
}

@media (min-width: 1199px) {
  #esf-see-results .banner.banner--main .banner__content-text-container {
    padding-right: var(--emu-common-spacing-none);
  }
}

#esf-see-results .banner.banner--main .banner__content-text sup {
  top: .2em;
}

@media (max-width: 320px) {
  #esf-see-results .banner.banner--fap .banner__teaser {
    height: 715px;
  }
}

@media (min-width: 321px) and (max-width: 413px) {
  #esf-see-results .banner.banner--fap .banner__teaser {
    height: 825px;
  }
}

@media (min-width: 414px) and (max-width: 767px) {
  #esf-see-results .banner.banner--fap .banner__teaser {
    height: 900px;
  }
}

@media (min-width: 1024px) {
  #esf-see-results .banner.banner--fap .banner__teaser .emu-teaser__disclaimer {
    padding-left: 70px;
    padding-right: 70px;
  }
}

@media (min-width: 768px) {
  #esf-see-results .banner.banner--fap .banner__content {
    margin-top: -35px;
    padding-top: 7px;
    top: 42%;
  }
}

@media (min-width: 1024px) {
  #esf-see-results .banner.banner--fap .banner__content {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    padding-top: 5px;
    top: 43%;
  }
}

@media (min-width: 1200px) {
  #esf-see-results .banner.banner--fap .banner__content {
    max-width: var(--emu-common-sizing-large-tablet);
    top: 15%;
  }
}

@media (min-width: 1401px) {
  #esf-see-results .banner.banner--fap .banner__content {
    padding-top: 7px;
  }
}

@media (min-width: 2001px) {
  #esf-see-results .banner.banner--fap .banner__content {
    padding-top: var(--emu-common-spacing-small);
  }
}

@media (min-width: 768px) {
  #esf-see-results .banner.banner--fap .banner__content-inner {
    padding-top: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1200px) {
  #esf-see-results .banner.banner--fap .banner__content-inner {
    padding-top: 17.7%;
  }
}

@media (min-width: 1401px) {
  #esf-see-results .banner.banner--fap .banner__content-inner {
    padding-top: 23.57%;
  }
}

@media (min-width: 1601px) {
  #esf-see-results .banner.banner--fap .banner__content-inner {
    padding-top: 28%;
  }
}

@media (min-width: 1024px) {
  #esf-see-results .banner.banner--fap .banner__content-text {
    margin-top: 12px;
  }
}

@media (min-width: 768px) {
  #esf-see-results .banner.banner--fap .banner__content-cta-container {
    margin-top: 23px;
  }
}

#esf-see-results .gallery__carousel-ba-img, #esf-see-results .gallery__carousel-ba-img img {
  aspect-ratio: 200 / 119;
}

@media (min-width: 1024px) {
  #esf-what-to-expect .banner.banner--main .banner__teaser {
    background-position: center;
  }
}

#esf-what-to-expect .banner.banner--main .banner__teaser .emu-teaser__disclaimer {
  padding-bottom: 10px;
}

@media (min-width: 1401px) {
  #esf-what-to-expect .banner.banner--main .banner__img img {
    object-position: center right;
  }
}

@media (min-width: 1601px) {
  #esf-what-to-expect .banner.banner--main .banner__img img {
    object-position: center center;
  }
}

#esf-what-to-expect .banner.banner--main .banner__content {
  padding-top: 65%;
}

@media (min-width: 321px) {
  #esf-what-to-expect .banner.banner--main .banner__content {
    padding-top: 90%;
  }
}

@media (min-width: 768px) {
  #esf-what-to-expect .banner.banner--main .banner__content {
    padding-top: 68%;
  }
}

@media (min-width: 1024px) {
  #esf-what-to-expect .banner.banner--main .banner__content {
    padding-top: var(--emu-common-spacing-none);
    padding-left: 15px;
    top: 23%;
  }
}

@media (min-width: 1199px) {
  #esf-what-to-expect .banner.banner--main .banner__content {
    padding-right: 73px;
  }
}

@media (min-width: 1200px) {
  #esf-what-to-expect .banner.banner--main .banner__content {
    max-width: var(--emu-common-sizing-desktop);
    margin-left: auto;
    margin-right: auto;
    padding-left: 73px;
    left: 0;
    right: 0;
  }
}

@media (min-width: 1600px) {
  #esf-what-to-expect .banner.banner--main .banner__content {
    top: 28%;
  }
}

@media (min-width: 768px) {
  #esf-what-to-expect .banner.banner--main .banner__content-inner {
    padding: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1024px) {
  #esf-what-to-expect .banner.banner--main .banner__content-inner {
    padding-top: 22%;
  }
}

@media (min-width: 1025px) {
  #esf-what-to-expect .banner.banner--main .banner__content-inner {
    padding-right: 30px;
  }
}

@media (min-width: 1200px) {
  #esf-what-to-expect .banner.banner--main .banner__content-inner {
    padding-top: var(--emu-common-spacing-medium);
  }
}

#esf-what-to-expect .banner.banner--main .banner__content-img img {
  max-width: 80%;
  padding-left: 30px;
  padding-right: 15px;
}

@media (min-width: 321px) {
  #esf-what-to-expect .banner.banner--main .banner__content-img img {
    padding-right: var(--emu-common-spacing-none);
    width: 100%;
  }
}

@media (min-width: 768px) {
  #esf-what-to-expect .banner.banner--main .banner__content-img img {
    margin-left: var(--emu-common-spacing-none);
    height: auto;
    margin-top: 14px;
    padding-right: 1%;
  }
}

@media (min-width: 1024px) {
  #esf-what-to-expect .banner.banner--main .banner__content-img img {
    margin-top: 66px;
    padding-left: 10px;
    padding-right: 20%;
  }
}

@media (min-width: 1200px) {
  #esf-what-to-expect .banner.banner--main .banner__content-img img {
    padding: var(--emu-common-spacing-none);
    margin-top: var(--emu-common-spacing-none);
    width: auto;
    max-width: 600px;
    height: auto;
  }
}

#esf-what-to-expect .banner.banner--main .banner__content-title {
  margin-top: -7px;
}

@media (min-width: 321px) {
  #esf-what-to-expect .banner.banner--main .banner__content-title {
    padding-left: 40px;
    padding-right: 5%;
  }
}

@media (min-width: 414px) {
  #esf-what-to-expect .banner.banner--main .banner__content-title {
    margin-top: -6px;
  }
}

@media (min-width: 768px) {
  #esf-what-to-expect .banner.banner--main .banner__content-title {
    margin-top: -68px;
    padding-left: 85px;
    padding-right: 25px;
  }
}

@media (min-width: 1024px) {
  #esf-what-to-expect .banner.banner--main .banner__content-title {
    margin-top: -69px;
    padding-right: 40%;
  }
}

@media (min-width: 1200px) {
  #esf-what-to-expect .banner.banner--main .banner__content-title {
    padding-right: var(--emu-common-spacing-none);
    margin-top: -55px;
    padding-left: 35px;
  }
}

@media (min-width: 321px) and (max-width: 413px) and (orientation: portrait) {
  #esf-what-to-expect .banner.banner--main .banner__content-title .cmp-title__text {
    font-size: 30px;
    line-height: 35px;
  }
}

@media (min-width: 1025px) and (max-width: 1199px) {
  #esf-what-to-expect .banner.banner--main .banner__content-title .cmp-title__text {
    font-size: 64px;
    line-height: 69px;
  }
}

#esf-what-to-expect .banner.banner--main .banner__content-title br {
  display: none;
}

@media (min-width: 1024px) {
  #esf-what-to-expect .banner.banner--main .banner__content-title br {
    display: block;
  }
}

#esf-what-to-expect .banner.banner--main .banner__content-text {
  padding-right: var(--emu-common-spacing-none);
}

@media (min-width: 321px) {
  #esf-what-to-expect .banner.banner--main .banner__content-text {
    padding-left: 40px;
    padding-right: 15px;
  }
}

@media (min-width: 414px) {
  #esf-what-to-expect .banner.banner--main .banner__content-text {
    margin-left: var(--emu-common-spacing-none);
    max-width: 725px;
    padding-right: 25px;
  }
}

@media (min-width: 768px) {
  #esf-what-to-expect .banner.banner--main .banner__content-text {
    margin-top: var(--emu-common-spacing-small);
    padding-left: 85px;
    padding-right: 15px;
  }
}

@media (min-width: 1024px) {
  #esf-what-to-expect .banner.banner--main .banner__content-text {
    max-width: none;
    margin-top: 22px;
    padding-right: 25%;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  #esf-what-to-expect .banner.banner--main .banner__content-text {
    font-size: 28px;
    line-height: 42px;
  }
}

@media (min-width: 1200px) {
  #esf-what-to-expect .banner.banner--main .banner__content-text {
    padding-right: var(--emu-common-spacing-none);
    max-width: 725px;
    margin-top: 13px;
    padding-left: 35px;
  }
}

@media (min-width: 1199px) {
  #esf-what-to-expect .banner.banner--main .banner__content-text-container {
    padding-right: var(--emu-common-spacing-none);
  }
}

#esf-what-to-expect .banner.banner--redirection .banner__teaser {
  background-position: top;
  height: 825px;
}

@media (min-width: 414px) {
  #esf-what-to-expect .banner.banner--redirection .banner__teaser {
    height: 990px;
  }
}

@media (min-width: 768px) {
  #esf-what-to-expect .banner.banner--redirection .banner__teaser {
    height: 1566px;
  }
}

@media (min-width: 1024px) {
  #esf-what-to-expect .banner.banner--redirection .banner__teaser {
    height: 2130px;
  }
}

@media (min-width: 1200px) {
  #esf-what-to-expect .banner.banner--redirection .banner__teaser {
    height: 750px;
  }
}

@media (min-width: 1401px) {
  #esf-what-to-expect .banner.banner--redirection .banner__teaser {
    height: 900px;
  }
}

@media (min-width: 1601px) {
  #esf-what-to-expect .banner.banner--redirection .banner__teaser {
    height: 1175px;
  }
}

@media (min-width: 2001px) {
  #esf-what-to-expect .banner.banner--redirection .banner__teaser {
    height: 1600px;
  }
}

#esf-what-to-expect .banner.banner--redirection .banner__teaser .emu-teaser__disclaimer {
  line-height: 23px;
}

#esf-what-to-expect .banner.banner--redirection .banner__content {
  padding-top: var(--emu-common-spacing-none);
  align-items: center;
  height: 100%;
  display: flex;
  top: 0;
}

#esf-what-to-expect .banner.banner--redirection .banner__content-inner {
  transform: translateY(49px);
}

@media (min-width: 768px) {
  #esf-what-to-expect .banner.banner--redirection .banner__content-inner {
    max-width: 674px;
    margin-left: auto;
    margin-right: auto;
    transform: translateY(-15px);
  }
}

@media (min-width: 1024px) {
  #esf-what-to-expect .banner.banner--redirection .banner__content-inner {
    transform: translateY(-56px);
  }
}

@media (min-width: 1200px) {
  #esf-what-to-expect .banner.banner--redirection .banner__content-inner {
    transform: none;
  }
}

@media (min-width: 1401px) {
  #esf-what-to-expect .banner.banner--redirection .banner__content-inner {
    transform: translateY(-35px);
  }
}

@media (min-width: 1601px) {
  #esf-what-to-expect .banner.banner--redirection .banner__content-inner {
    transform: translateY(-15px);
  }
}

@media (min-width: 2001px) {
  #esf-what-to-expect .banner.banner--redirection .banner__content-inner {
    transform: translateY(-10px);
  }
}

#esf-what-to-expect .banner.banner--redirection .banner__content-title {
  max-width: none;
}

@media (min-width: 1024px) {
  #esf-what-to-expect .banner.banner--redirection .banner__content-title .cmp-title__text {
    font-size: 64px;
    line-height: 69px;
  }
}

@media (min-width: 1200px) {
  #esf-what-to-expect .banner.banner--redirection .banner__content-title .cmp-title__text {
    font-size: 54px;
    line-height: 58px;
  }
}

#esf-what-to-expect .banner.banner--redirection .banner__content-text {
  margin-top: var(--emu-common-spacing-none);
  max-width: 72%;
  margin-top: -3px;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  #esf-what-to-expect .banner.banner--redirection .banner__content-text {
    padding: var(--emu-common-spacing-none);
    max-width: none;
    margin-top: 6px;
  }
}

@media (min-width: 1024px) {
  #esf-what-to-expect .banner.banner--redirection .banner__content-text {
    margin-top: 21px;
    font-size: 28px;
    line-height: 42px;
  }
}

@media (min-width: 1200px) {
  #esf-what-to-expect .banner.banner--redirection .banner__content-text {
    margin-top: 12px;
    font-size: 24px;
    line-height: 31px;
  }
}

@media (min-width: 768px) {
  #esf-what-to-expect .banner.banner--redirection .banner__content-text-container {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#esf-what-to-expect .banner.banner--redirection .banner__content-cta-container {
  margin-top: 10px;
}

@media (min-width: 768px) {
  #esf-what-to-expect .banner.banner--redirection .banner__content-cta-container {
    margin-top: 18px;
  }
}

@media (min-width: 1200px) {
  #esf-what-to-expect .banner.banner--redirection .banner__content-cta-container {
    margin-top: 24px;
  }
}

#esf-what-to-expect .banner.banner--redirection .banner__content-cta {
  height: 63px;
}

@media (min-width: 768px) {
  #esf-what-to-expect .banner.banner--redirection .banner__content-cta {
    height: 72px;
    font-size: 28px;
  }
}

@media (min-width: 1200px) {
  #esf-what-to-expect .banner.banner--redirection .banner__content-cta {
    height: 63px;
    font-size: 24px;
  }
}

#esf-what-to-expect .banner.banner--fap .banner__teaser .emu-teaser__disclaimer p {
  font-size: 12px;
}

@media (min-width: 768px) {
  #esf-what-to-expect .banner.banner--fap .banner__content {
    padding-top: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1024px) {
  #esf-what-to-expect .banner.banner--fap .banner__content {
    padding-left: var(--emu-common-spacing-none);
    width: auto;
    max-width: none;
    margin-left: -15px;
    margin-right: -15px;
    padding-top: 7px;
    top: 0;
  }
}

@media (min-width: 1025px) {
  #esf-what-to-expect .banner.banner--fap .banner__content {
    margin-left: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-none);
    padding-top: 6px;
    padding-right: var(--emu-common-spacing-none);
    max-width: var(--emu-common-sizing-large-tablet);
    margin-left: auto;
    margin-right: auto;
    top: 15%;
  }
}

@media (min-width: 1401px) {
  #esf-what-to-expect .banner.banner--fap .banner__content {
    padding-top: var(--emu-common-spacing-small);
  }
}

@media (min-width: 1024px) {
  #esf-what-to-expect .banner.banner--fap .banner__content-inner {
    padding-top: 80%;
  }
}

@media (min-width: 1200px) {
  #esf-what-to-expect .banner.banner--fap .banner__content-inner {
    padding-top: 14.7%;
  }
}

@media (min-width: 1401px) {
  #esf-what-to-expect .banner.banner--fap .banner__content-inner {
    padding-top: 20.75%;
  }
}

@media (min-width: 1601px) {
  #esf-what-to-expect .banner.banner--fap .banner__content-inner {
    padding-top: 28%;
  }
}

@media (min-width: 2001px) {
  #esf-what-to-expect .banner.banner--fap .banner__content-inner {
    padding-top: 35%;
  }
}

@media (min-width: 1025px) {
  #esf-what-to-expect .banner.banner--fap .banner__content-title .cmp-title__text {
    font-size: 64px;
    line-height: 69px;
  }
}

@media (min-width: 1200px) {
  #esf-what-to-expect .banner.banner--fap .banner__content-title .cmp-title__text {
    font-size: 54px;
    line-height: 58px;
  }
}

@media (min-width: 1025px) {
  #esf-what-to-expect .banner.banner--fap .banner__content-text {
    margin-top: 11px;
    font-size: 28px;
    line-height: 42px;
  }
}

@media (min-width: 1200px) {
  #esf-what-to-expect .banner.banner--fap .banner__content-text {
    font-size: 24px;
    line-height: 31px;
  }
}

@media (min-width: 1025px) {
  #esf-what-to-expect .banner.banner--fap .banner__content-cta-container {
    margin-top: 23px;
  }
}

#get-savings .banner.banner--main .banner__teaser {
  background-position: initial;
}

@media (min-width: 768px) {
  #get-savings .banner.banner--main .banner__teaser {
    background-position: 0 30%;
  }
}

@media (min-width: 1200px) {
  #get-savings .banner.banner--main .banner__teaser {
    background-position: initial;
  }
}

#get-savings .banner.banner--main .banner__img.banner__img--1401 img {
  object-position: center left;
}

@media (min-width: 1601px) {
  #get-savings .banner.banner--main .banner__img.banner__img--1401 img {
    object-position: center top;
  }
}

#get-savings .banner.banner--main .banner__content {
  padding-top: var(--emu-common-spacing-none);
  align-items: flex-end;
  height: 100%;
  padding: 10px 8.8px;
  display: flex;
  top: 0;
}

@media (min-width: 321px) {
  #get-savings .banner.banner--main .banner__content {
    padding-left: 6.3px;
    padding-right: 6.3px;
  }
}

@media (min-width: 414px) {
  #get-savings .banner.banner--main .banner__content {
    padding-top: 20px;
    padding-bottom: 18px;
    padding-left: var(--emu-common-spacing-xs);
    padding-right: var(--emu-common-spacing-xs);
  }
}

@media (min-width: 576px) {
  #get-savings .banner.banner--main .banner__content {
    padding: 30px 20px 28px;
  }
}

@media (min-width: 768px) {
  #get-savings .banner.banner--main .banner__content {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    justify-content: center;
    align-items: center;
    padding-top: 26%;
    padding-bottom: 95px;
  }
}

@media (min-width: 1024px) {
  #get-savings .banner.banner--main .banner__content {
    padding-top: 16%;
  }
}

@media (min-width: 1200px) {
  #get-savings .banner.banner--main .banner__content {
    padding-top: var(--emu-common-spacing-none);
    align-items: flex-end;
    padding-bottom: 79px;
  }
}

#get-savings .banner.banner--main .banner__content-inner {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (min-width: 768px) {
  #get-savings .banner.banner--main .banner__content-inner {
    padding-left: 10%;
    padding-right: 10%;
  }
}

@media (min-width: 1200px) {
  #get-savings .banner.banner--main .banner__content-inner {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#get-savings .banner.banner--main .banner__content-img {
  margin: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #get-savings .banner.banner--main .banner__content-img {
    position: relative;
    left: -40px;
  }
}

#get-savings .banner.banner--main .banner__content-img img {
  padding: var(--emu-common-spacing-none);
  margin: var(--emu-common-spacing-none);
  width: auto;
  max-width: none;
  position: relative;
  left: 0;
}

#get-savings .banner.banner--main .banner__content-img--join img {
  height: 80px;
}

@media (min-width: 321px) {
  #get-savings .banner.banner--main .banner__content-img--join img {
    height: 100px;
    top: -1px;
  }
}

@media (min-width: 414px) {
  #get-savings .banner.banner--main .banner__content-img--join img {
    height: 110px;
    top: -5px;
  }
}

@media (min-width: 576px) {
  #get-savings .banner.banner--main .banner__content-img--join img {
    height: 140px;
    top: -2px;
  }
}

@media (min-width: 768px) {
  #get-savings .banner.banner--main .banner__content-img--join img {
    width: auto;
    height: auto;
    top: -1px;
  }
}

@media (min-width: 1200px) {
  #get-savings .banner.banner--main .banner__content-img--join img {
    top: 2px;
    left: -3px;
  }
}

@media (min-width: 1401px) {
  #get-savings .banner.banner--main .banner__content-img--join img {
    left: -2px;
  }
}

#get-savings .banner.banner--main .banner__content-img--alle img {
  height: 60px;
  left: 10px;
}

@media (min-width: 321px) {
  #get-savings .banner.banner--main .banner__content-img--alle img {
    height: 75px;
    top: -2px;
  }
}

@media (min-width: 414px) {
  #get-savings .banner.banner--main .banner__content-img--alle img {
    top: -6px;
    left: 15px;
  }
}

@media (min-width: 576px) {
  #get-savings .banner.banner--main .banner__content-img--alle img {
    height: 120px;
    top: -2px;
  }
}

@media (min-width: 768px) {
  #get-savings .banner.banner--main .banner__content-img--alle img {
    width: auto;
    height: auto;
    top: -1px;
  }
}

@media (min-width: 1200px) {
  #get-savings .banner.banner--main .banner__content-img--alle img {
    top: 2px;
    left: 17px;
  }
}

#get-savings .banner.banner--main .banner__content-title {
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
  margin-top: var(--emu-common-spacing-xxs);
}

@media (min-width: 414px) {
  #get-savings .banner.banner--main .banner__content-title {
    margin-top: var(--emu-common-spacing-xs);
  }
}

@media (min-width: 576px) {
  #get-savings .banner.banner--main .banner__content-title {
    margin-top: var(--emu-common-spacing-xxs);
  }
}

@media (min-width: 768px) {
  #get-savings .banner.banner--main .banner__content-title {
    margin-top: 35px;
  }
}

@media (min-width: 1200px) {
  #get-savings .banner.banner--main .banner__content-title {
    margin-top: 37px;
    padding-left: 20%;
    padding-right: 20%;
  }
}

#get-savings .banner.banner--main .banner__content-title br:last-child {
  display: none;
}

@media (min-width: 1200px) {
  #get-savings .banner.banner--main .banner__content-title br:last-child {
    display: block;
  }

  #get-savings .banner.banner--main .banner__content-title br:first-child {
    display: none;
  }
}

@media (min-width: 321px) and (max-width: 413px) {
  #get-savings .banner.banner--main .banner__content-title .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-narrow-large);
    line-height: var(--emu-semantic-line-heights-narrow-large);
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  #get-savings .banner.banner--main .banner__content-title .cmp-title__text {
    font-size: 46px;
    line-height: 50px;
  }
}

@media (min-width: 1025px) {
  #get-savings .banner.banner--main .banner__content-title .cmp-title__text {
    font-size: 64px;
    line-height: 69px;
  }
}

@media (min-width: 1200px) {
  #get-savings .banner.banner--main .banner__content-title .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
    line-height: var(--emu-semantic-line-heights-wide-xl);
  }
}

#get-savings .banner.banner--main .banner__content-text {
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
  margin-top: 12px;
  padding-bottom: 40px;
}

@media (min-width: 576px) and (max-width: 767px) {
  #get-savings .banner.banner--main .banner__content-text {
    font-size: 22px;
    line-height: 40px;
  }
}

@media (min-width: 1025px) {
  #get-savings .banner.banner--main .banner__content-text {
    font-size: 28px;
    line-height: 42px;
  }
}

@media (min-width: 1200px) {
  #get-savings .banner.banner--main .banner__content-text {
    font-size: var(--emu-semantic-font-sizes-wide-small);
    line-height: var(--emu-semantic-line-heights-wide-small);
    padding-bottom: 15px;
    padding-left: 10.5%;
    padding-right: 10.5%;
  }
}

@media (min-width: 321px) {
  #get-savings .banner.banner--main .banner__content-text-container {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (min-width: 768px) {
  #get-savings .banner.banner--main .banner__content-text-container {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#get-savings .product-grid-container {
  max-width: none;
  padding: 98px 20px 105px;
}

@media (min-width: 576px) {
  #get-savings .product-grid-container {
    padding-top: 100px;
  }
}

@media (min-width: 768px) {
  #get-savings .product-grid-container {
    padding: 140px 60px 100px;
  }
}

@media (min-width: 1200px) {
  #get-savings .product-grid-container {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#get-savings .product-grid-container__title {
  max-width: 280px;
  margin-bottom: 63px;
}

@media (min-width: 768px) {
  #get-savings .product-grid-container__title {
    max-width: 550px;
  }
}

@media (min-width: 1200px) {
  #get-savings .product-grid-container__title {
    text-align: center;
    max-width: none;
    margin-bottom: 121px;
  }
}

@media (max-width: 320px) {
  #get-savings .product-grid-container__title .cmp-title__text {
    font-size: 28px;
    line-height: 34px;
  }
}

@media (min-width: 414px) {
  #get-savings .product-grid-container__title .cmp-title__text {
    font-size: 33px;
    line-height: 39px;
  }
}

@media (min-width: 576px) {
  #get-savings .product-grid-container__title .cmp-title__text {
    font-size: 46px;
    line-height: 50px;
  }
}

@media (min-width: 768px) {
  #get-savings .product-grid-container__title .cmp-title__text {
    font-size: 64px;
    line-height: 70px;
  }
}

@media (min-width: 1024px) {
  #get-savings .product-grid-container__title .cmp-title__text {
    font-size: 64px;
    line-height: 69px;
  }
}

@media (min-width: 1200px) {
  #get-savings .product-grid-container__title .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
    line-height: 64px;
  }
}

#get-savings .product-grid-container__title .cmp-title__text br {
  display: none;
}

#get-savings .product-grid-container__title .cmp-title__text br:last-child {
  display: block;
}

@media (min-width: 576px) {
  #get-savings .product-grid-container__title .cmp-title__text br {
    display: block;
  }
}

@media (min-width: 768px) {
  #get-savings .product-grid-container__title .cmp-title__text br {
    display: none;
  }

  #get-savings .product-grid-container__title .cmp-title__text br:last-child {
    display: block;
  }
}

@media (min-width: 1200px) {
  #get-savings .product-grid-container__title .cmp-title__text br {
    display: none;
  }

  #get-savings .product-grid-container__title .cmp-title__text br:nth-last-child(2) {
    display: block;
  }

  #get-savings .product-grid-container__title .cmp-title__text br:last-child {
    display: none;
  }
}

#get-savings .product-grid-container__items-wrapper {
  flex-wrap: wrap;
  display: flex;
}

@media (min-width: 1200px) {
  #get-savings .product-grid-container__items-wrapper {
    max-width: var(--emu-common-sizing-desktop);
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: grid;
  }
}

#get-savings .product-grid-container__items-wrapper > .container {
  width: 100%;
  position: relative;
}

#get-savings .product-grid-container__items-wrapper > .container:not(:last-child) {
  margin-bottom: 65px;
}

@media (min-width: 768px) {
  #get-savings .product-grid-container__items-wrapper > .container:not(:last-child) {
    margin-bottom: 77px;
  }
}

@media (min-width: 1200px) {
  #get-savings .product-grid-container__items-wrapper > .container:not(:last-child) {
    margin-bottom: 104px;
  }

  #get-savings .product-grid-container__items-wrapper > .container:nth-child(odd) {
    padding-left: 90px;
    padding-right: 20px;
  }

  #get-savings .product-grid-container__items-wrapper > .container:nth-child(3) {
    margin-bottom: var(--emu-common-spacing-none);
    padding-left: 90px;
    padding-right: 50px;
  }

  #get-savings .product-grid-container__items-wrapper > .container:nth-child(2n) {
    padding-left: 90px;
    padding-right: 60px;
  }
}

#get-savings .product-grid-container__item-title p {
  color: var(--emu-semantic-colors-primary-100);
  font-size: var(--emu-semantic-font-sizes-narrow-xxl);
  line-height: var(--emu-common-line-heights-narrow-xxxl);
  font-weight: var(--emu-semantic-font-weight-600);
}

@media (min-width: 768px) {
  #get-savings .product-grid-container__item-title p {
    font-size: var(--emu-semantic-font-sizes-wide-xxl);
    line-height: var(--emu-semantic-line-heights-wide-xxl);
  }
}

@media (min-width: 1024px) {
  #get-savings .product-grid-container__item-title p {
    font-size: var(--emu-semantic-font-sizes-wide-xxl);
    line-height: var(--emu-semantic-line-heights-wide-xxl);
    margin-top: var(--emu-common-spacing-xs);
  }
}

#get-savings .product-grid-container__item-subtitle p {
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  font-family: var(--emu-semantic-font-families-heading);
  line-height: var(--emu-semantic-line-heights-narrow-large);
  color: var(--emu-semantic-colors-secondary-600);
  margin-bottom: var(--emu-common-spacing-small);
}

@media (max-width: 320px) {
  #get-savings .product-grid-container__item-subtitle p {
    font-size: 28px;
    line-height: 34px;
  }
}

@media (min-width: 414px) {
  #get-savings .product-grid-container__item-subtitle p {
    margin-bottom: 6px;
    font-size: 33px;
    line-height: 39px;
  }
}

@media (min-width: 576px) {
  #get-savings .product-grid-container__item-subtitle p {
    margin-top: 1px;
    margin-bottom: 7px;
    font-size: 46px;
    line-height: 50px;
  }
}

@media (min-width: 768px) {
  #get-savings .product-grid-container__item-subtitle p {
    margin-bottom: 17px;
    font-size: 64px;
    line-height: 70px;
  }
}

@media (min-width: 1024px) {
  #get-savings .product-grid-container__item-subtitle p {
    margin-top: 1px;
    margin-bottom: var(--emu-common-spacing-medium);
    font-size: 64px;
    line-height: 69px;
  }
}

@media (min-width: 1200px) {
  #get-savings .product-grid-container__item-subtitle p {
    font-size: 44px;
    line-height: var(--emu-common-line-heights-wide-xl);
    margin-bottom: 18px;
  }
}

#get-savings .product-grid-container__item-text p {
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  line-height: var(--emu-semantic-line-heights-narrow-small);
  color: var(--emu-semantic-colors-secondary-600);
}

@media (min-width: 414px) {
  #get-savings .product-grid-container__item-text p {
    font-size: var(--emu-semantic-font-sizes-narrow-medium);
    line-height: 29px;
  }
}

@media (min-width: 576px) {
  #get-savings .product-grid-container__item-text p {
    font-size: 22px;
    line-height: 40px;
  }
}

@media (min-width: 768px) {
  #get-savings .product-grid-container__item-text p {
    font-size: 28px;
    line-height: 42px;
  }
}

@media (min-width: 1200px) {
  #get-savings .product-grid-container__item-text p {
    font-size: var(--emu-semantic-font-sizes-wide-small);
    line-height: var(--emu-semantic-line-heights-wide-small);
  }
}

#get-savings .product-grid-container .button {
  text-align: center;
  margin-top: 35px;
}

@media (min-width: 768px) {
  #get-savings .product-grid-container .button {
    margin-top: 48px;
  }
}

@media (min-width: 1024px) {
  #get-savings .product-grid-container .button {
    margin-top: 48px;
  }
}

@media (min-width: 1200px) {
  #get-savings .product-grid-container .button {
    margin-top: 54px;
  }
}

#get-savings .product-grid-container__btn {
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
  width: 218px;
}

@media (min-width: 768px) {
  #get-savings .product-grid-container__btn {
    width: 258px;
    padding-top: 18px;
    padding-bottom: 18px;
    font-size: 28px;
  }
}

@media (min-width: 1200px) {
  #get-savings .product-grid-container__btn {
    width: 311px;
    font-size: 24px;
  }
}

#get-savings .banner.banner--fap .banner__teaser {
  background-position: center;
  height: 715px;
}

@media (min-width: 321px) {
  #get-savings .banner.banner--fap .banner__teaser {
    height: 825px;
  }
}

@media (min-width: 414px) {
  #get-savings .banner.banner--fap .banner__teaser {
    height: 900px;
  }
}

@media (min-width: 768px) {
  #get-savings .banner.banner--fap .banner__teaser {
    height: 1560px;
  }
}

@media (min-width: 1024px) {
  #get-savings .banner.banner--fap .banner__teaser {
    height: 2130px;
  }
}

@media (min-width: 1200px) {
  #get-savings .banner.banner--fap .banner__teaser {
    height: 755px;
  }
}

@media (min-width: 1401px) {
  #get-savings .banner.banner--fap .banner__teaser {
    height: 1000px;
  }
}

@media (min-width: 1601px) {
  #get-savings .banner.banner--fap .banner__teaser {
    height: 1250px;
  }
}

@media (min-width: 2001px) {
  #get-savings .banner.banner--fap .banner__teaser {
    height: 1500px;
  }
}

@media (min-width: 1024px) {
  #get-savings .banner.banner--fap .banner__content {
    padding-left: var(--emu-common-spacing-none);
    padding-top: 7px;
    padding-right: var(--emu-common-spacing-none);
    max-width: var(--emu-common-sizing-large-tablet);
    top: 22%;
    left: 0;
    right: 0;
  }
}

@media (min-width: 1401px) {
  #get-savings .banner.banner--fap .banner__content {
    top: 25%;
  }
}

@media (min-width: 1601px) {
  #get-savings .banner.banner--fap .banner__content {
    top: 22%;
  }
}

#get-savings .banner.banner--fap .banner__content-inner {
  padding-top: 90%;
}

@media (min-width: 768px) {
  #get-savings .banner.banner--fap .banner__content-inner {
    padding-top: 87%;
  }
}

@media (min-width: 1024px) {
  #get-savings .banner.banner--fap .banner__content-inner {
    padding-top: 37%;
  }
}

@media (min-width: 1200px) {
  #get-savings .banner.banner--fap .banner__content-inner {
    padding-top: 10%;
  }
}

@media (min-width: 1401px) {
  #get-savings .banner.banner--fap .banner__content-inner {
    padding-top: 12%;
  }
}

@media (min-width: 1601px) {
  #get-savings .banner.banner--fap .banner__content-inner {
    padding-top: 28%;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  #get-savings .banner.banner--fap .banner__content-title .cmp-title__text {
    font-size: 46px;
    line-height: 50px;
  }
}

@media (min-width: 1025px) and (max-width: 1199px) {
  #get-savings .banner.banner--fap .banner__content-text {
    font-size: 28px;
    line-height: 42px;
  }
}

#how-cs-works .banner.banner--main .banner__teaser {
  height: 594px;
}

@media (min-width: 321px) {
  #how-cs-works .banner.banner--main .banner__teaser {
    height: 700px;
  }
}

@media (min-width: 414px) {
  #how-cs-works .banner.banner--main .banner__teaser {
    height: 775px;
  }
}

@media (min-width: 576px) {
  #how-cs-works .banner.banner--main .banner__teaser {
    height: 1075px;
  }
}

@media (min-width: 768px) {
  #how-cs-works .banner.banner--main .banner__teaser {
    height: 1432px;
  }
}

@media (min-width: 1024px) {
  #how-cs-works .banner.banner--main .banner__teaser {
    height: 1910px;
  }
}

@media (min-width: 1200px) {
  #how-cs-works .banner.banner--main .banner__teaser {
    height: 656px;
  }
}

@media (min-width: 1401px) {
  #how-cs-works .banner.banner--main .banner__teaser {
    height: 660px;
  }
}

@media (min-width: 1601px) {
  #how-cs-works .banner.banner--main .banner__teaser {
    height: 750px;
  }
}

@media (min-width: 2001px) {
  #how-cs-works .banner.banner--main .banner__teaser {
    height: 900px;
  }
}

#how-cs-works .banner.banner--main .banner__teaser .disclaimer-bottom-right .emu-teaser__disclaimer {
  padding-bottom: 15px;
}

@media (min-width: 768px) {
  #how-cs-works .banner.banner--main .banner__teaser .disclaimer-bottom-right .emu-teaser__disclaimer {
    padding-bottom: 25px;
  }
}

#how-cs-works .banner.banner--main .banner__content {
  padding-top: var(--emu-common-spacing-none);
  flex-direction: column;
  justify-content: flex-end;
  height: calc(100% - 62px);
  padding-bottom: 10%;
  display: flex;
}

@media (min-width: 451px) {
  #how-cs-works .banner.banner--main .banner__content {
    height: calc(100% - 48.5px);
    top: 1px;
  }
}

@media (min-width: 768px) {
  #how-cs-works .banner.banner--main .banner__content {
    max-width: var(--emu-common-sizing-tablet);
    padding-top: 105%;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: var(--emu-common-spacing-none);
    justify-content: flex-start;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (min-width: 1024px) {
  #how-cs-works .banner.banner--main .banner__content {
    padding-top: 115%;
  }
}

@media (min-width: 1025px) {
  #how-cs-works .banner.banner--main .banner__content {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    justify-content: center;
    max-width: none;
    height: calc(100% - 58.5px);
    left: 0;
    transform: none;
  }
}

@media (min-width: 1199px) {
  #how-cs-works .banner.banner--main .banner__content {
    max-width: var(--emu-common-sizing-desktop);
    padding-left: 38px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (min-width: 1200px) {
  #how-cs-works .banner.banner--main .banner__content {
    padding-top: var(--emu-common-spacing-none);
  }
}

@media (min-width: 768px) {
  #how-cs-works .banner.banner--main .banner__content-inner {
    padding-left: 75px;
    padding-right: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1025px) {
  #how-cs-works .banner.banner--main .banner__content-inner {
    padding-left: var(--emu-common-spacing-none);
  }
}

#how-cs-works .banner.banner--main .banner__content-img img {
  width: 100%;
  max-width: none;
  padding-left: 1px;
  padding-right: 31%;
}

@media (min-width: 321px) {
  #how-cs-works .banner.banner--main .banner__content-img img {
    padding-right: 38%;
    padding-left: var(--emu-common-spacing-none);
  }
}

@media (min-width: 414px) {
  #how-cs-works .banner.banner--main .banner__content-img img {
    padding-right: 38.5%;
  }
}

@media (min-width: 576px) {
  #how-cs-works .banner.banner--main .banner__content-img img {
    padding-left: 1px;
    padding-right: 41.5%;
  }
}

@media (min-width: 768px) {
  #how-cs-works .banner.banner--main .banner__content-img img {
    padding: var(--emu-common-spacing-none);
    width: 515px;
    height: auto;
    margin-left: -85px;
  }
}

@media (min-width: 1025px) {
  #how-cs-works .banner.banner--main .banner__content-img img {
    margin-left: var(--emu-common-spacing-none);
    width: 613px;
    padding-left: 2%;
  }
}

@media (min-width: 1200px) {
  #how-cs-works .banner.banner--main .banner__content-img img {
    width: 600px;
    padding-left: 3%;
  }
}

#how-cs-works .banner.banner--main .banner__content-title {
  margin-top: -26px;
  padding-left: 30px;
  padding-right: 5%;
}

@media (min-width: 321px) and (max-width: 413px) {
  #how-cs-works .banner.banner--main .banner__content-title {
    margin-top: -27px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  #how-cs-works .banner.banner--main .banner__content-title {
    margin-top: -41px;
  }
}

@media (min-width: 768px) {
  #how-cs-works .banner.banner--main .banner__content-title {
    padding: var(--emu-common-spacing-none);
    margin-top: -54px;
  }
}

@media (min-width: 1025px) {
  #how-cs-works .banner.banner--main .banner__content-title {
    margin-top: -45px;
    padding-left: 70px;
    padding-right: 40%;
  }
}

@media (min-width: 321px) and (max-width: 413px) {
  #how-cs-works .banner.banner--main .banner__content-title .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-narrow-large);
    line-height: var(--emu-semantic-line-heights-narrow-large);
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  #how-cs-works .banner.banner--main .banner__content-title .cmp-title__text {
    font-size: 46px;
    line-height: 50px;
  }
}

#how-cs-works .banner.banner--main .banner__content-text {
  padding-left: 30px;
}

@media (min-width: 576px) and (max-width: 767px) {
  #how-cs-works .banner.banner--main .banner__content-text {
    padding-left: 70px;
    padding-right: 30px;
    font-size: 22px;
    line-height: 40px;
  }
}

@media (min-width: 768px) {
  #how-cs-works .banner.banner--main .banner__content-text {
    padding-left: var(--emu-common-spacing-none);
    margin-top: 15px;
  }
}

@media (min-width: 1025px) {
  #how-cs-works .banner.banner--main .banner__content-text {
    margin-top: 12px;
    padding-left: 70px;
  }
}

#how-cs-works .banner.banner--main .banner__content-text br {
  display: none;
}

@media (min-width: 1200px) {
  #how-cs-works .banner.banner--main .banner__content-text br {
    display: block;
  }
}

#how-cs-works .freeze-section {
  position: relative;
  overflow: hidden;
}

@media (max-width: 320px) {
  #how-cs-works .freeze-section__title h3 {
    font-size: 28px;
    line-height: 34px;
  }
}

@media (min-width: 414px) and (max-width: 575px) {
  #how-cs-works .freeze-section__title h3 {
    font-size: 33px;
    line-height: var(--emu-common-line-heights-narrow-xl);
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  #how-cs-works .freeze-section__title h3 {
    font-size: 46px;
    line-height: 50px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  #how-cs-works .freeze-section__title h3 {
    font-size: 64px;
    line-height: 69px;
  }
}

#how-cs-works .freeze-section__bg {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 768px) {
  #how-cs-works .freeze-section__bg {
    background-position: center;
  }
}

#how-cs-works .freeze-section__container {
  z-index: var(--emu-common-other-z-index-layer);
  padding-top: 80px;
  position: relative;
}

@media (min-width: 321px) {
  #how-cs-works .freeze-section__intro {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 768px) {
  #how-cs-works .freeze-section__intro {
    padding-left: 50px;
    padding-right: 50px;
  }
}

#how-cs-works .freeze-section__intro-img {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  #how-cs-works .freeze-section__intro-img {
    width: 480px;
  }
}

@media (min-width: 1025px) {
  #how-cs-works .freeze-section__intro-img {
    width: 600px;
  }
}

#how-cs-works .freeze-section__intro-title {
  max-width: 100%;
  margin-top: -23px;
}

@media (min-width: 414px) {
  #how-cs-works .freeze-section__intro-title {
    margin-top: -21px;
  }
}

@media (min-width: 768px) {
  #how-cs-works .freeze-section__intro-title {
    margin-top: -74px;
  }
}

@media (min-width: 1025px) {
  #how-cs-works .freeze-section__intro-title {
    margin-top: -100px;
  }
}

#how-cs-works .freeze-section__intro-title .cmp-title__text br {
  display: none;
}

#how-cs-works .freeze-section__intro-title .cmp-title__text br:nth-of-type(2), #how-cs-works .freeze-section__intro-title .cmp-title__text br:nth-of-type(5) {
  display: block;
}

@media (min-width: 768px) {
  #how-cs-works .freeze-section__intro-title .cmp-title__text br:nth-of-type(2), #how-cs-works .freeze-section__intro-title .cmp-title__text br:nth-of-type(5) {
    display: none;
  }

  #how-cs-works .freeze-section__intro-title .cmp-title__text br:first-of-type, #how-cs-works .freeze-section__intro-title .cmp-title__text br:nth-of-type(3), #how-cs-works .freeze-section__intro-title .cmp-title__text br:nth-of-type(5) {
    display: block;
  }
}

@media (min-width: 1200px) {
  #how-cs-works .freeze-section__intro-title .cmp-title__text br:first-of-type, #how-cs-works .freeze-section__intro-title .cmp-title__text br:nth-of-type(3), #how-cs-works .freeze-section__intro-title .cmp-title__text br:nth-of-type(5) {
    display: none;
  }

  #how-cs-works .freeze-section__intro-title .cmp-title__text br:nth-of-type(4) {
    display: block;
  }
}

#how-cs-works .freeze-section__grid {
  padding-top: 72px;
  padding-bottom: 75px;
}

@media (min-width: 321px) {
  #how-cs-works .freeze-section__grid {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 414px) {
  #how-cs-works .freeze-section__grid {
    padding-top: 71px;
  }
}

@media (min-width: 768px) {
  #how-cs-works .freeze-section__grid {
    padding-top: 68px;
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (min-width: 1025px) {
  #how-cs-works .freeze-section__grid {
    max-width: var(--emu-common-sizing-tablet);
    margin-left: auto;
    margin-right: auto;
    padding-top: 70px;
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 1200px) {
  #how-cs-works .freeze-section__grid {
    max-width: var(--emu-common-sizing-large-tablet);
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    display: flex;
  }
}

@media (min-width: 768px) {
  #how-cs-works .freeze-section__grid > .container:last-child .freeze-section__grid-item-text {
    margin-bottom: 15px;
  }
}

@media (min-width: 1200px) {
  #how-cs-works .freeze-section__grid > .container {
    width: 33.33%;
    padding-left: 40px;
    padding-right: 40px;
  }
}

#how-cs-works .freeze-section__grid-item {
  padding-left: 23px;
  padding-right: 23px;
}

@media (min-width: 321px) {
  #how-cs-works .freeze-section__grid-item {
    padding-left: 26px;
    padding-right: 26px;
  }
}

@media (min-width: 768px) {
  #how-cs-works .freeze-section__grid-item {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    max-width: 570px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1025px) {
  #how-cs-works .freeze-section__grid-item {
    max-width: none;
  }
}

#how-cs-works .freeze-section__grid-item-img {
  justify-content: center;
  margin-bottom: 13px;
  display: flex;
}

@media (min-width: 414px) {
  #how-cs-works .freeze-section__grid-item-img {
    margin-bottom: 14px;
  }
}

@media (min-width: 768px) {
  #how-cs-works .freeze-section__grid-item-img {
    margin-bottom: 40px;
  }
}

@media (min-width: 1025px) {
  #how-cs-works .freeze-section__grid-item-img {
    margin-bottom: 14px;
  }
}

#how-cs-works .freeze-section__grid-item-img img {
  max-width: var(--emu-common-sizing-large);
}

@media (min-width: 768px) {
  #how-cs-works .freeze-section__grid-item-img img {
    width: 100px;
    max-width: none;
  }
}

@media (min-width: 1025px) {
  #how-cs-works .freeze-section__grid-item-img img {
    width: auto;
  }
}

#how-cs-works .freeze-section__grid-item-title {
  margin-bottom: 6px;
}

@media (min-width: 414px) {
  #how-cs-works .freeze-section__grid-item-title {
    margin-bottom: 5px;
  }
}

@media (min-width: 768px) {
  #how-cs-works .freeze-section__grid-item-title {
    margin-bottom: 7px;
  }
}

@media (min-width: 1025px) {
  #how-cs-works .freeze-section__grid-item-title .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-wide-large);
    line-height: 1.2;
  }
}

#how-cs-works .freeze-section__grid-item-text {
  letter-spacing: .08px;
  margin-bottom: 40px;
}

@media (min-width: 576px) {
  #how-cs-works .freeze-section__grid-item-text {
    font-size: 18px;
    line-height: 29px;
  }
}

@media (min-width: 768px) {
  #how-cs-works .freeze-section__grid-item-text {
    margin-bottom: 93px;
    font-size: 28px;
    line-height: 42px;
  }
}

@media (min-width: 1025px) {
  #how-cs-works .freeze-section__grid-item-text {
    font-size: var(--emu-common-font-sizes-wide-medium);
    margin-bottom: 13px;
    line-height: 26px;
  }
}

#how-cs-works .freeze-section__grid-item-text * {
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

#how-cs-works .freeze-section__grid-item-text sup {
  font-size: 60%;
  line-height: 0;
}

@media (min-width: 1025px) {
  #how-cs-works .freeze-section__mini-banner.intro-block {
    max-width: 910px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
  #how-cs-works .freeze-section__mini-banner.intro-block {
    max-width: 1090px;
  }
}

#how-cs-works .discover-cs {
  height: 500px;
  position: relative;
  overflow: hidden;
}

@media (min-width: 321px) {
  #how-cs-works .discover-cs {
    height: 600px;
  }
}

@media (min-width: 576px) {
  #how-cs-works .discover-cs {
    height: 795px;
  }
}

@media (min-width: 768px) {
  #how-cs-works .discover-cs {
    height: var(--emu-common-sizing-desktop);
  }
}

@media (min-width: 1025px) {
  #how-cs-works .discover-cs {
    height: auto;
    margin-top: -150px;
    padding-top: 60px;
  }
}

#how-cs-works .discover-cs__teaser {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#how-cs-works .discover-cs__inner {
  flex-wrap: wrap;
  max-width: none;
  margin-top: 3px;
  margin-left: -30px;
  margin-right: -30px;
  display: flex;
}

@media (min-width: 576px) {
  #how-cs-works .discover-cs__inner {
    margin-top: 5px;
  }
}

@media (min-width: 768px) {
  #how-cs-works .discover-cs__inner {
    margin: var(--emu-common-spacing-none);
    justify-content: center;
  }
}

@media (min-width: 1025px) {
  #how-cs-works .discover-cs__inner {
    max-width: var(--emu-common-sizing-tablet);
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
  #how-cs-works .discover-cs__inner {
    max-width: var(--emu-common-sizing-large-tablet);
  }
}

#how-cs-works .discover-cs__title {
  z-index: var(--emu-common-other-z-index-layer);
  margin: 70% auto auto;
  position: relative;
}

@media (min-width: 414px) {
  #how-cs-works .discover-cs__title {
    margin-top: 65%;
  }
}

@media (min-width: 576px) {
  #how-cs-works .discover-cs__title {
    margin-top: 70%;
  }
}

@media (min-width: 768px) {
  #how-cs-works .discover-cs__title {
    max-width: 500px;
    margin-top: 655px;
  }
}

@media (min-width: 1025px) {
  #how-cs-works .discover-cs__title {
    max-width: none;
    margin-top: -220px;
    margin-left: -10px;
    margin-right: -10px;
  }
}

#how-cs-works .discover-cs__title .cmp-title__text {
  font-size: 28px;
  line-height: 34px;
}

@media (min-width: 321px) {
  #how-cs-works .discover-cs__title .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-narrow-large);
    line-height: var(--emu-semantic-line-heights-narrow-large);
    padding-left: 20%;
    padding-right: 20%;
  }
}

@media (min-width: 414px) {
  #how-cs-works .discover-cs__title .cmp-title__text {
    font-size: 33px;
    line-height: var(--emu-common-line-heights-narrow-xl);
  }
}

@media (min-width: 576px) {
  #how-cs-works .discover-cs__title .cmp-title__text {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    font-size: 46px;
    line-height: 50px;
  }
}

@media (min-width: 768px) {
  #how-cs-works .discover-cs__title .cmp-title__text {
    font-size: 64px;
    line-height: 69px;
  }
}

@media (min-width: 1025px) {
  #how-cs-works .discover-cs__title .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
    line-height: var(--emu-semantic-line-heights-wide-xl);
  }
}

#how-cs-works .discover-cs__img {
  display: none;
}

@media (min-width: 1025px) {
  #how-cs-works .discover-cs__img {
    display: block;
    position: relative;
  }
}

#how-cs-works .cs-tech-cards__container {
  padding: 54px 15px 20px;
}

@media (min-width: 1025px) {
  #how-cs-works .cs-tech-cards__container {
    padding-bottom: 54px;
  }
}

#how-cs-works .cs-tech-cards__container-inner {
  max-width: none;
  margin-top: -190px;
  margin-left: -15px;
  margin-right: -15px;
}

@media (min-width: 414px) {
  #how-cs-works .cs-tech-cards__container-inner {
    margin-top: -200px;
  }
}

@media (min-width: 768px) {
  #how-cs-works .cs-tech-cards__container-inner {
    margin-top: -350px;
  }
}

@media (min-width: 1024px) {
  #how-cs-works .cs-tech-cards__container-inner {
    max-width: var(--emu-common-sizing-tablet);
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1025px) {
  #how-cs-works .cs-tech-cards__container-inner {
    justify-content: space-evenly;
    margin-top: -100px;
    display: flex;
  }
}

@media (min-width: 1200px) {
  #how-cs-works .cs-tech-cards__container-inner {
    max-width: var(--emu-common-sizing-large-tablet);
  }
}

@media (min-width: 1367px) {
  #how-cs-works .cs-tech-cards__container-inner {
    margin-top: -190px;
  }
}

#how-cs-works .cs-tech-cards__container-inner > .container:not(:first-child) {
  margin-top: 30px;
}

@media (min-width: 1025px) {
  #how-cs-works .cs-tech-cards__container-inner > .container:not(:first-child) {
    margin-top: var(--emu-common-spacing-none);
  }

  #how-cs-works .cs-tech-cards__container-inner > .container {
    max-width: 45%;
  }
}

#how-cs-works .cs-tech-cards__item {
  background-color: var(--emu-semantic-colors-secondary-100);
  max-width: 90%;
  margin: 15px auto;
  padding: 83px 1% 80px;
  position: relative;
}

@media (min-width: 321px) {
  #how-cs-works .cs-tech-cards__item {
    padding-left: 2%;
    padding-right: 2%;
  }
}

@media (min-width: 414px) {
  #how-cs-works .cs-tech-cards__item {
    padding: 84px 5% 78px;
  }
}

@media (min-width: 576px) {
  #how-cs-works .cs-tech-cards__item {
    padding-left: 10%;
    padding-right: 10%;
  }
}

@media (min-width: 768px) {
  #how-cs-works .cs-tech-cards__item {
    padding: 85px 60px 57px;
  }
}

@media (min-width: 1025px) {
  #how-cs-works .cs-tech-cards__item {
    margin: var(--emu-common-spacing-none);
    max-width: none;
    height: 100%;
    padding: 44px 40px 38px;
  }
}

@media (min-width: 1367px) {
  #how-cs-works .cs-tech-cards__item {
    padding-top: 42px;
  }
}

#how-cs-works .cs-tech-cards__item-title .cmp-title__text {
  color: var(--emu-semantic-colors-secondary-600);
  margin-bottom: 18px;
  font-size: 28px;
  line-height: 34px;
}

@media (min-width: 321px) {
  #how-cs-works .cs-tech-cards__item-title .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-narrow-large);
    line-height: var(--emu-semantic-line-heights-narrow-large);
  }
}

@media (min-width: 414px) {
  #how-cs-works .cs-tech-cards__item-title .cmp-title__text {
    font-size: 33px;
    line-height: var(--emu-common-line-heights-narrow-xl);
  }
}

@media (min-width: 576px) {
  #how-cs-works .cs-tech-cards__item-title .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-narrow-xxl);
    line-height: 40px;
  }
}

@media (min-width: 768px) {
  #how-cs-works .cs-tech-cards__item-title .cmp-title__text {
    font-size: 50px;
    line-height: 108.5%;
  }
}

@media (min-width: 1025px) {
  #how-cs-works .cs-tech-cards__item-title .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-wide-large);
    line-height: var(--emu-semantic-line-heights-wide-xl);
  }
}

@media (min-width: 1367px) {
  #how-cs-works .cs-tech-cards__item-title .cmp-title__text {
    margin-bottom: 20px;
  }
}

#how-cs-works .cs-tech-cards__item-content {
  color: var(--emu-semantic-colors-secondary-600);
  padding-bottom: 50px;
  font-size: 14px;
  line-height: 26.5px;
}

@media (min-width: 414px) {
  #how-cs-works .cs-tech-cards__item-content {
    font-size: var(--emu-semantic-font-sizes-narrow-medium);
    line-height: 29.5px;
  }
}

@media (min-width: 576px) {
  #how-cs-works .cs-tech-cards__item-content {
    font-size: 18px;
  }
}

@media (min-width: 768px) {
  #how-cs-works .cs-tech-cards__item-content {
    font-size: 24px;
    line-height: 49px;
  }
}

@media (min-width: 1025px) {
  #how-cs-works .cs-tech-cards__item-content {
    font-size: 16px;
    line-height: 31.5px;
  }
}

#how-cs-works .cs-tech-cards__item-content ul {
  margin-top: var(--emu-common-spacing-none);
  padding: var(--emu-common-spacing-none);
  margin-bottom: 40px;
  margin-left: 40px;
  margin-right: 40px;
}

#how-cs-works .cs-tech-cards__item-content p, #how-cs-works .cs-tech-cards__item-content li {
  font-size: inherit;
  line-height: inherit;
}

#how-cs-works .cs-tech-cards__item-disclaimer {
  color: var(--emu-semantic-colors-secondary-600);
  margin-left: 40px;
  margin-right: 40px;
  font-size: 10px;
  line-height: 135%;
  position: absolute;
  bottom: 25px;
}

@media (min-width: 768px) {
  #how-cs-works .cs-tech-cards__item-disclaimer {
    margin: var(--emu-common-spacing-none);
    bottom: 8%;
  }
}

@media (min-width: 1025px) {
  #how-cs-works .cs-tech-cards__item-disclaimer {
    margin-left: 40px;
    margin-right: 40px;
    bottom: 25px;
  }
}

#how-cs-works .cs-tech-cards__item-disclaimer p, #how-cs-works .cs-tech-cards__item-disclaimer li {
  font-size: inherit;
  line-height: inherit;
}

#how-cs-works .banner.banner--redirection {
  overflow: hidden;
}

#how-cs-works .banner.banner--redirection .banner__teaser {
  background-position: center;
}

@media (min-width: 576px) and (max-width: 767px) {
  #how-cs-works .banner.banner--redirection .banner__teaser {
    height: 1174px;
  }
}

@media (min-width: 768px) {
  #how-cs-works .banner.banner--redirection .banner__teaser {
    height: 1700px;
  }
}

@media (min-width: 1024px) {
  #how-cs-works .banner.banner--redirection .banner__teaser {
    height: 2130px;
  }
}

@media (min-width: 1200px) {
  #how-cs-works .banner.banner--redirection .banner__teaser {
    height: 850px;
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media (min-width: 1401px) {
  #how-cs-works .banner.banner--redirection .banner__teaser {
    height: 1100px;
  }
}

@media (min-width: 2001px) {
  #how-cs-works .banner.banner--redirection .banner__teaser {
    height: 1500px;
  }
}

#how-cs-works .banner.banner--redirection .banner__teaser .disclaimer-bottom-right .emu-teaser__disclaimer {
  padding-bottom: 25px;
  padding-left: 30px;
  padding-right: 30px;
  bottom: 20px;
}

@media (min-width: 321px) {
  #how-cs-works .banner.banner--redirection .banner__teaser .disclaimer-bottom-right .emu-teaser__disclaimer {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 768px) {
  #how-cs-works .banner.banner--redirection .banner__teaser .disclaimer-bottom-right .emu-teaser__disclaimer {
    bottom: 2%;
  }
}

@media (min-width: 1025px) {
  #how-cs-works .banner.banner--redirection .banner__teaser .disclaimer-bottom-right .emu-teaser__disclaimer {
    bottom: 0;
  }
}

@media (min-width: 1200px) {
  #how-cs-works .banner.banner--redirection .banner__teaser .disclaimer-bottom-right .emu-teaser__disclaimer {
    padding-left: 75px;
    padding-right: 45px;
    bottom: 0;
  }
}

@media (min-width: 1367px) {
  #how-cs-works .banner.banner--redirection .banner__teaser .disclaimer-bottom-right .emu-teaser__disclaimer {
    padding-left: var(--emu-common-spacing-none);
    padding-right: 30px;
  }
}

#how-cs-works .banner.banner--redirection .banner__content {
  padding-top: var(--emu-common-spacing-none);
  align-items: center;
  height: 100%;
  display: flex;
  top: 0;
}

@media (min-width: 768px) {
  #how-cs-works .banner.banner--redirection .banner__content {
    padding-top: 2px;
  }
}

#how-cs-works .banner.banner--redirection .banner__content-title {
  max-width: none;
}

@media (min-width: 576px) and (max-width: 767px) {
  #how-cs-works .banner.banner--redirection .banner__content-title .cmp-title__text {
    font-size: 46px;
    line-height: 50px;
  }
}

#how-cs-works .banner.banner--redirection .banner__content-text {
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
  width: 90%;
  margin-top: 7px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) and (max-width: 767px) {
  #how-cs-works .banner.banner--redirection .banner__content-text {
    font-size: 22px;
    line-height: var(--emu-semantic-font-sizes-narrow-xxl);
  }
}

@media (min-width: 768px) {
  #how-cs-works .banner.banner--redirection .banner__content-text {
    width: 100%;
    max-width: 680px;
    margin-top: 6px;
  }
}

@media (min-width: 1025px) {
  #how-cs-works .banner.banner--redirection .banner__content-text {
    max-width: 575px;
    line-height: 38px;
  }
}

@media (min-width: 768px) {
  #how-cs-works .banner.banner--redirection .banner__content-text-container {
    padding-left: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1199px) {
  #how-cs-works .banner.banner--redirection .banner__content-text-container {
    padding-right: var(--emu-common-spacing-none);
  }
}

#how-cs-works .banner.banner--redirection .banner__content-cta-container {
  margin-top: 30px;
}

@media (min-width: 414px) {
  #how-cs-works .banner.banner--redirection .banner__content-cta-container {
    margin-top: 28px;
  }
}

@media (min-width: 768px) {
  #how-cs-works .banner.banner--redirection .banner__content-cta-container {
    margin-top: 25px;
  }
}

#how-cs-works .banner.banner--fap {
  margin-top: -35px;
  overflow: hidden;
}

@media (min-width: 1025px) {
  #how-cs-works .banner.banner--fap {
    margin-top: var(--emu-common-spacing-none);
  }
}

#how-cs-works .banner.banner--fap .banner__teaser {
  height: 565px;
}

@media (min-width: 321px) {
  #how-cs-works .banner.banner--fap .banner__teaser {
    height: 900px;
  }
}

@media (min-width: 768px) {
  #how-cs-works .banner.banner--fap .banner__teaser {
    height: 1560px;
  }
}

@media (min-width: 1024px) {
  #how-cs-works .banner.banner--fap .banner__teaser {
    height: 2130px;
  }
}

@media (min-width: 1200px) {
  #how-cs-works .banner.banner--fap .banner__teaser {
    height: 900px;
  }
}

@media (min-width: 1401px) {
  #how-cs-works .banner.banner--fap .banner__teaser {
    height: 1000px;
  }
}

@media (min-width: 1601px) {
  #how-cs-works .banner.banner--fap .banner__teaser {
    height: 1250px;
  }
}

@media (min-width: 2001px) {
  #how-cs-works .banner.banner--fap .banner__teaser {
    height: 1500px;
  }
}

#how-cs-works .banner.banner--fap .banner__teaser .disclaimer-bottom-right .emu-teaser__disclaimer {
  padding-left: calc(4% + 15px);
  padding-right: calc(4% + 15px);
}

@media (min-width: 1024px) {
  #how-cs-works .banner.banner--fap .banner__content {
    padding-top: var(--emu-common-spacing-small);
    padding-left: var(--emu-common-spacing-none);
    width: auto;
    max-width: none;
    margin-left: -15px;
    margin-right: -15px;
    top: 0;
  }
}

@media (min-width: 1025px) {
  #how-cs-works .banner.banner--fap .banner__content {
    padding-top: var(--emu-common-spacing-none);
    max-width: var(--emu-semantic-sizing-breakpoints-large-plus);
    width: 100%;
    margin-top: -30px;
    margin-left: auto;
    margin-right: auto;
    top: 15%;
  }
}

@media (min-width: 1199px) {
  #how-cs-works .banner.banner--fap .banner__content {
    padding-right: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1401px) {
  #how-cs-works .banner.banner--fap .banner__content {
    margin-top: -28px;
  }
}

@media (min-width: 768px) {
  #how-cs-works .banner.banner--fap .banner__content-inner {
    padding-top: 86%;
  }
}

@media (min-width: 1200px) {
  #how-cs-works .banner.banner--fap .banner__content-inner {
    padding-top: 17.8%;
  }
}

@media (min-width: 1401px) {
  #how-cs-works .banner.banner--fap .banner__content-inner {
    padding-top: 23.5%;
  }
}

@media (min-width: 1601px) {
  #how-cs-works .banner.banner--fap .banner__content-inner {
    padding-top: 28%;
  }
}

@media (min-width: 1024px) {
  #how-cs-works .banner.banner--fap .banner__content-text-container {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

.cq-Editable-dom--container .discover-cs__title, .cq-Editable-dom--container .cs-tech-cards__container-inner {
  margin-top: var(--emu-common-spacing-none) !important;
}

@media (min-width: 576px) and (max-width: 767px) {
  #how-cooltone-works .banner.banner--main .banner__teaser {
    height: 1074px;
  }
}

@media (min-width: 1025px) {
  #how-cooltone-works .banner.banner--main .banner__teaser .disclaimer-bottom-right .emu-teaser__disclaimer {
    padding-bottom: 25px;
  }
}

@media (min-width: 1366px) {
  #how-cooltone-works .banner.banner--main .banner__teaser .disclaimer-bottom-right .emu-teaser__disclaimer {
    padding-bottom: 10px;
  }
}

@media (min-width: 768px) {
  #how-cooltone-works .banner.banner--main .banner__img--sm img, #how-cooltone-works .banner.banner--main .banner__img--md img {
    object-position: 50% 0;
  }
}

#how-cooltone-works .banner.banner--main .banner__content {
  padding-top: 75%;
}

@media (min-width: 321px) {
  #how-cooltone-works .banner.banner--main .banner__content {
    padding-top: 90%;
  }
}

@media (min-width: 414px) {
  #how-cooltone-works .banner.banner--main .banner__content {
    padding-top: 75%;
  }
}

@media (min-width: 576px) {
  #how-cooltone-works .banner.banner--main .banner__content {
    padding-top: 103%;
  }
}

@media (min-width: 768px) {
  #how-cooltone-works .banner.banner--main .banner__content {
    align-items: center;
    height: calc(100% - 83.5px);
    padding-top: 73%;
    display: flex;
  }
}

@media (min-width: 1024px) {
  #how-cooltone-works .banner.banner--main .banner__content {
    padding-top: 80%;
  }
}

@media (min-width: 1025px) {
  #how-cooltone-works .banner.banner--main .banner__content {
    padding-left: var(--emu-common-spacing-none);
    padding-top: var(--emu-common-spacing-none);
    display: block;
    top: 0;
  }
}

@media (min-width: 768px) {
  #how-cooltone-works .banner.banner--main .banner__content-inner {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 100px;
    padding-right: 40px;
  }
}

@media (min-width: 1025px) {
  #how-cooltone-works .banner.banner--main .banner__content-inner {
    padding-left: 15px;
    padding-right: var(--emu-common-spacing-none);
    max-width: var(--emu-common-sizing-desktop);
    width: 100%;
    padding-top: 69px;
    position: absolute;
    top: 10%;
  }
}

@media (min-width: 1199px) {
  #how-cooltone-works .banner.banner--main .banner__content-inner {
    padding-left: 106px;
  }
}

@media (min-width: 1200px) {
  #how-cooltone-works .banner.banner--main .banner__content-inner {
    max-width: var(--emu-common-sizing-desktop);
    margin-left: auto;
    margin-right: auto;
    padding-left: 91px;
    left: 0;
    right: 0;
  }
}

#how-cooltone-works .banner.banner--main .banner__content-img img {
  padding: var(--emu-common-spacing-none);
  max-width: 40%;
  margin-left: 10px;
}

@media (min-width: 414px) {
  #how-cooltone-works .banner.banner--main .banner__content-img img {
    max-width: 50%;
  }
}

@media (min-width: 576px) {
  #how-cooltone-works .banner.banner--main .banner__content-img img {
    max-width: 40%;
    margin-left: 20px;
  }
}

@media (min-width: 768px) {
  #how-cooltone-works .banner.banner--main .banner__content-img img {
    width: 300px;
    max-width: none;
    height: auto;
    margin-left: -25px;
  }
}

@media (min-width: 1025px) {
  #how-cooltone-works .banner.banner--main .banner__content-img img {
    margin-left: var(--emu-common-spacing-none);
    width: 356px;
  }
}

#how-cooltone-works .banner.banner--main .banner__content-title {
  margin-top: -8px;
  padding-left: 25px;
  padding-right: 25px;
}

@media (min-width: 321px) {
  #how-cooltone-works .banner.banner--main .banner__content-title {
    margin-top: -17px;
    padding-left: 40px;
  }
}

@media (min-width: 414px) {
  #how-cooltone-works .banner.banner--main .banner__content-title {
    margin-top: -36px;
  }
}

@media (min-width: 576px) {
  #how-cooltone-works .banner.banner--main .banner__content-title {
    margin-top: -37px;
  }
}

@media (min-width: 768px) {
  #how-cooltone-works .banner.banner--main .banner__content-title {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    margin-top: -40px;
    margin-left: var(--emu-common-spacing-none);
    max-width: 500px;
  }
}

@media (min-width: 1025px) {
  #how-cooltone-works .banner.banner--main .banner__content-title {
    padding-left: 17px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  #how-cooltone-works .banner.banner--main .banner__content-title .cmp-title__text {
    font-size: 46px;
    line-height: 50px;
  }
}

#how-cooltone-works .banner.banner--main .banner__content-title br {
  display: none;
}

@media (min-width: 1024px) {
  #how-cooltone-works .banner.banner--main .banner__content-title br {
    display: block;
  }
}

#how-cooltone-works .banner.banner--main .banner__content-text {
  padding-left: 25px;
  padding-right: 40px;
}

@media (min-width: 321px) {
  #how-cooltone-works .banner.banner--main .banner__content-text {
    padding-left: 40px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  #how-cooltone-works .banner.banner--main .banner__content-text {
    font-size: 22px;
    line-height: 40px;
  }
}

@media (min-width: 768px) {
  #how-cooltone-works .banner.banner--main .banner__content-text {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    margin-left: var(--emu-common-spacing-none);
    max-width: 620px;
  }
}

@media (min-width: 1024px) {
  #how-cooltone-works .banner.banner--main .banner__content-text {
    margin-top: 12px;
  }
}

@media (min-width: 1025px) {
  #how-cooltone-works .banner.banner--main .banner__content-text {
    padding-left: 17px;
  }
}

#how-cooltone-works .banner.banner--main .banner__content-text sup {
  top: .2em;
}

@media (min-width: 1024px) {
  #how-cooltone-works .banner.banner--main .banner__content-text br {
    display: none;
  }
}

#how-cooltone-works .about-cooltone__container {
  padding: 76px 15px 30px;
}

@media (min-width: 414px) {
  #how-cooltone-works .about-cooltone__container {
    padding-top: 78px;
    padding-bottom: 25px;
  }
}

@media (min-width: 768px) {
  #how-cooltone-works .about-cooltone__container {
    padding-top: 120px;
    padding-bottom: 50px;
  }
}

@media (min-width: 1024px) {
  #how-cooltone-works .about-cooltone__container {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 120px;
    padding-bottom: 52px;
  }
}

@media (min-width: 1025px) {
  #how-cooltone-works .about-cooltone__container {
    padding-top: 75px;
    padding-bottom: 65px;
  }
}

@media (min-width: 1200px) {
  #how-cooltone-works .about-cooltone__container {
    max-width: var(--emu-semantic-sizing-breakpoints-large-plus);
  }
}

#how-cooltone-works .about-cooltone__container > .container {
  margin-left: -15px;
  margin-right: -15px;
}

#how-cooltone-works .about-cooltone__container-inner {
  flex-direction: column-reverse;
  display: flex;
}

@media (min-width: 1024px) {
  #how-cooltone-works .about-cooltone__container-inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1025px) {
  #how-cooltone-works .about-cooltone__container-inner {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    flex-flow: wrap;
  }

  #how-cooltone-works .about-cooltone__container-inner > .container:first-child {
    width: 50%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1024px) {
  #how-cooltone-works .about-cooltone__container-inner > .container:last-child {
    width: 100%;
  }
}

@media (min-width: 1025px) {
  #how-cooltone-works .about-cooltone__container-inner > .container:last-child {
    width: 50%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

#how-cooltone-works .about-cooltone__teaser {
  justify-content: center;
  display: flex;
  position: relative;
}

@media (min-width: 768px) {
  #how-cooltone-works .about-cooltone__teaser {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

@media (min-width: 1025px) {
  #how-cooltone-works .about-cooltone__teaser {
    width: 100%;
    margin-bottom: 191px;
  }
}

@media (min-width: 1024px) {
  #how-cooltone-works .about-cooltone__teaser {
    margin-bottom: var(--emu-common-spacing-none);
  }

  #how-cooltone-works .about-cooltone__img {
    justify-content: center;
    display: flex;
  }
}

@media (min-width: 768px) {
  #how-cooltone-works .about-cooltone__img img {
    width: 100%;
  }
}

@media (min-width: 1025px) {
  #how-cooltone-works .about-cooltone__img img {
    width: auto;
  }
}

#how-cooltone-works .about-cooltone__disclaimer {
  text-shadow: -1px 1px 9px #000000bf;
  text-align: center;
  letter-spacing: .15px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 9.75px;
  line-height: 140%;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (min-width: 375px) {
  #how-cooltone-works .about-cooltone__disclaimer {
    padding-bottom: var(--emu-common-spacing-none);
  }
}

@media (min-width: 576px) {
  #how-cooltone-works .about-cooltone__disclaimer {
    padding-bottom: 15px;
  }
}

@media (min-width: 768px) {
  #how-cooltone-works .about-cooltone__disclaimer {
    padding-bottom: 25px;
    padding-left: 3px;
    padding-right: 3px;
  }
}

@media (min-width: 1024px) {
  #how-cooltone-works .about-cooltone__disclaimer {
    max-width: 73%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 10px;
    right: 0;
  }
}

#how-cooltone-works .about-cooltone__disclaimer p, #how-cooltone-works .about-cooltone__disclaimer li {
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

#how-cooltone-works .about-cooltone__content {
  padding-left: 20px;
}

@media (min-width: 321px) {
  #how-cooltone-works .about-cooltone__content {
    padding-left: 25px;
  }
}

@media (min-width: 768px) {
  #how-cooltone-works .about-cooltone__content {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (min-width: 1024px) {
  #how-cooltone-works .about-cooltone__content {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (min-width: 1025px) {
  #how-cooltone-works .about-cooltone__content {
    padding-left: 55px;
    padding-right: var(--emu-common-spacing-none);
  }
}

#how-cooltone-works .about-cooltone__title {
  margin-bottom: 34px;
  margin-right: 10px;
}

@media (min-width: 414px) {
  #how-cooltone-works .about-cooltone__title {
    margin-bottom: var(--emu-common-spacing-large);
  }
}

@media (min-width: 768px) {
  #how-cooltone-works .about-cooltone__title {
    margin-bottom: 12px;
  }
}

@media (min-width: 1024px) {
  #how-cooltone-works .about-cooltone__title {
    margin-bottom: 11px;
  }
}

@media (min-width: 1025px) {
  #how-cooltone-works .about-cooltone__title {
    margin-bottom: var(--emu-common-spacing-large);
  }
}

@media (min-width: 1024px) {
  #how-cooltone-works .about-cooltone__title {
    margin-top: 5px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  #how-cooltone-works .about-cooltone__title .cmp-title__text {
    font-size: 46px;
    line-height: 50px;
  }
}

@media (min-width: 1025px) {
  #how-cooltone-works .about-cooltone__title .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
    line-height: var(--emu-semantic-line-heights-wide-xl);
  }
}

#how-cooltone-works .about-cooltone__text {
  color: var(--emu-semantic-colors-secondary-600);
}

@media (min-width: 576px) and (max-width: 767px) {
  #how-cooltone-works .about-cooltone__text {
    font-size: 22px;
    line-height: var(--emu-semantic-font-sizes-narrow-xxl);
  }
}

@media (min-width: 1025px) {
  #how-cooltone-works .about-cooltone__text {
    font-size: 23px;
    line-height: 38px;
  }
}

#how-cooltone-works .about-cooltone__text p, #how-cooltone-works .about-cooltone__text li {
  color: inherit;
}

@media (min-width: 768px) {
  #how-cooltone-works .about-cooltone__text p {
    max-width: 620px;
  }
}

@media (min-width: 1025px) {
  #how-cooltone-works .about-cooltone__text p {
    max-width: none;
  }
}

#how-cooltone-works .about-cooltone__text ul {
  padding-left: var(--emu-common-spacing-none);
  margin: 34px 10px 5px 30px;
}

@media (min-width: 414px) {
  #how-cooltone-works .about-cooltone__text ul {
    margin-top: 35px;
  }
}

@media (min-width: 768px) {
  #how-cooltone-works .about-cooltone__text ul {
    margin-bottom: 59px;
    margin-right: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1025px) {
  #how-cooltone-works .about-cooltone__text ul {
    margin-bottom: var(--emu-common-spacing-none);
    line-height: 37px;
  }

  #how-cooltone-works .cooltone-fda .fda-tabs-wrapper {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#how-cooltone-works .cooltone-fda .fda-tabs-container {
  padding-bottom: 39px;
}

@media (min-width: 768px) {
  #how-cooltone-works .cooltone-fda .fda-tabs-container {
    padding-top: 106px;
    padding-bottom: 49px;
  }
}

@media (min-width: 1025px) {
  #how-cooltone-works .cooltone-fda .fda-tabs-container {
    padding-top: 55px;
    padding-bottom: 40px;
  }
}

@media (min-width: 768px) {
  #how-cooltone-works .cooltone-fda .fda-tabs__title p {
    margin-bottom: 12px;
  }
}

#how-cooltone-works .cooltone-fda .fda-tabs__text p {
  padding-left: 25px;
  padding-right: 25px;
}

@media (min-width: 768px) {
  #how-cooltone-works .cooltone-fda .fda-tabs__text p {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    max-width: 645px;
  }
}

@media (min-width: 1025px) {
  #how-cooltone-works .cooltone-fda .fda-tabs__text p {
    width: auto;
    max-width: none;
    line-height: 31px;
  }
}

#how-cooltone-works .cooltone-fda .fda-tabs__item--desktop > .aaaem-tabs__tablist .aaaem-tabs__tab {
  width: 40%;
}

@media (min-width: 768px) {
  #how-cooltone-works .cooltone-fda .fda-tabs__item--desktop > .aaaem-tabs__tablist .aaaem-tabs__tab {
    width: auto;
    min-width: 245px;
  }
}

#how-cooltone-works .cooltone-fda .fda-tabs__item--desktop > .aaaem-tabs__tablist .aaaem-tabs__tab:not(:last-child) {
  margin-right: 14px;
}

@media (min-width: 1081px) {
  #how-cooltone-works .cooltone-fda .fda-tabs__item--desktop > .aaaem-tabs__tablist .aaaem-tabs__tab:not(:last-child) {
    margin-right: 24px;
  }
}

@media (min-width: 576px) {
  #how-cooltone-works .cooltone-fda .fda-tabs__item--mobile .aaaem-tabs__tabpanels {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#how-cooltone-works .cooltone-fda .fda-tabs__img--mobile img {
  margin-bottom: var(--emu-common-spacing-none);
  height: auto;
  max-height: 972px;
}

@media (min-width: 1025px) {
  #how-cooltone-works .cooltone-fda .fda-tabs__img--desktop {
    height: auto;
  }

  #how-cooltone-works .cooltone-fda .fda-tabs__img--desktop img {
    width: auto;
    height: 548px;
  }
}

#how-cooltone-works .cooltone-intro {
  overflow: hidden;
}

#how-cooltone-works .cooltone-intro .intro-block {
  max-width: none;
  margin-left: -25px;
  margin-right: -25px;
  padding: 15% 10%;
}

@media (min-width: 768px) {
  #how-cooltone-works .cooltone-intro .intro-block {
    margin-left: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-none);
    padding: 92px 70px 87px;
  }
}

@media (min-width: 1025px) {
  #how-cooltone-works .cooltone-intro .intro-block {
    padding: 91px 80px 88px;
  }
}

#how-cooltone-works .cooltone-intro .intro-block__inner-container {
  margin-left: auto;
  margin-right: auto;
  padding: 20px 30px;
}

@media (min-width: 1025px) {
  #how-cooltone-works .cooltone-intro .intro-block__inner-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

#how-cooltone-works .cooltone-intro .intro-block__title {
  margin-top: 2px;
  margin-bottom: 18px;
}

@media (min-width: 768px) {
  #how-cooltone-works .cooltone-intro .intro-block__title {
    max-width: 600px;
    margin-bottom: 19px;
  }
}

@media (min-width: 1025px) {
  #how-cooltone-works .cooltone-intro .intro-block__title {
    max-width: none;
  }
}

@media (min-width: 768px) {
  #how-cooltone-works .cooltone-intro .intro-block__text {
    max-width: 560px;
  }
}

@media (min-width: 1025px) {
  #how-cooltone-works .cooltone-intro .intro-block__text {
    max-width: none;
    line-height: 31px;
  }
}

@media (min-width: 321px) {
  #how-cooltone-works .banner.banner--redirection .banner__teaser {
    background-position: center;
  }
}

@media (min-width: 576px) {
  #how-cooltone-works .banner.banner--redirection .banner__teaser {
    height: 1174px;
  }
}

@media (min-width: 768px) {
  #how-cooltone-works .banner.banner--redirection .banner__teaser {
    height: 1700px;
  }
}

@media (min-width: 1024px) {
  #how-cooltone-works .banner.banner--redirection .banner__teaser {
    height: 2130px;
  }
}

@media (min-width: 1200px) {
  #how-cooltone-works .banner.banner--redirection .banner__teaser {
    height: 770px;
  }
}

@media (min-width: 1401px) {
  #how-cooltone-works .banner.banner--redirection .banner__teaser {
    height: 790px;
  }
}

@media (min-width: 1501px) {
  #how-cooltone-works .banner.banner--redirection .banner__teaser {
    height: 940px;
  }
}

@media (min-width: 1601px) {
  #how-cooltone-works .banner.banner--redirection .banner__teaser {
    height: 1200px;
  }
}

@media (min-width: 2001px) {
  #how-cooltone-works .banner.banner--redirection .banner__teaser {
    height: 1550px;
  }
}

@media (min-width: 1200px) {
  #how-cooltone-works .banner.banner--redirection .banner__teaser .emu-teaser__disclaimer {
    padding-left: 75px;
  }
}

@media (min-width: 1401px) {
  #how-cooltone-works .banner.banner--redirection .banner__teaser .emu-teaser__disclaimer {
    padding-left: 50px;
  }
}

#how-cooltone-works .banner.banner--redirection .banner__content {
  top: 42%;
}

@media (min-width: 1200px) {
  #how-cooltone-works .banner.banner--redirection .banner__content {
    top: 40%;
  }
}

@media (min-width: 321px) and (max-width: 349px) {
  #how-cooltone-works .banner.banner--redirection .banner__content {
    padding-top: var(--emu-common-spacing-xxs);
  }
}

@media (min-width: 1024px) {
  #how-cooltone-works .banner.banner--redirection .banner__content {
    padding-top: 6px;
  }
}

@media (min-width: 1200px) {
  #how-cooltone-works .banner.banner--redirection .banner__content {
    padding-top: 5px;
  }
}

#how-cooltone-works .banner.banner--redirection .banner__content-title {
  max-width: none;
}

@media (min-width: 321px) and (max-width: 349px) {
  #how-cooltone-works .banner.banner--redirection .banner__content-title .cmp-title__text {
    font-size: 28px;
    line-height: 34px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  #how-cooltone-works .banner.banner--redirection .banner__content-title .cmp-title__text {
    font-size: 46px;
    line-height: 50px;
  }
}

@media (min-width: 1199px) {
  #how-cooltone-works .banner.banner--redirection .banner__content-text-container {
    padding-right: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1200px) {
  #how-cooltone-works .banner.banner--redirection .banner__content-text-container {
    padding-left: var(--emu-common-spacing-none);
  }
}

#how-cooltone-works .banner.banner--redirection .banner__content-text {
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
  width: 90%;
  margin-top: 9px;
}

@media (min-width: 321px) and (max-width: 349px) {
  #how-cooltone-works .banner.banner--redirection .banner__content-text {
    margin-top: var(--emu-common-spacing-small);
  }
}

@media (min-width: 414px) {
  #how-cooltone-works .banner.banner--redirection .banner__content-text {
    margin-top: 7px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  #how-cooltone-works .banner.banner--redirection .banner__content-text {
    font-size: 22px;
    line-height: 40px;
  }
}

@media (min-width: 768px) {
  #how-cooltone-works .banner.banner--redirection .banner__content-text {
    max-width: 640px;
    margin-top: 6px;
  }
}

@media (min-width: 1024px) {
  #how-cooltone-works .banner.banner--redirection .banner__content-text {
    margin-top: 7px;
  }
}

@media (min-width: 1025px) {
  #how-cooltone-works .banner.banner--redirection .banner__content-text {
    max-width: 575px;
    margin-top: 6px;
  }
}

@media (min-width: 1200px) {
  #how-cooltone-works .banner.banner--redirection .banner__content-text {
    margin-top: 7px;
  }
}

#how-cooltone-works .banner.banner--redirection .banner__content-cta-container {
  margin-top: var(--emu-common-spacing-large);
}

@media (min-width: 768px) {
  #how-cooltone-works .banner.banner--redirection .banner__content-cta-container {
    margin-top: 30px;
  }
}

@media (min-width: 1025px) {
  #how-cooltone-works .banner.banner--redirection .banner__content-cta-container {
    margin-top: 28px;
  }
}

@media (min-width: 1200px) {
  #how-cooltone-works .banner.banner--redirection .banner__content-cta-container {
    margin-top: 29px;
  }
}

#how-cooltone-works .banner.banner--redirection .banner__content-cta {
  padding: 17px 31px;
}

@media (min-width: 576px) {
  #how-cooltone-works .banner.banner--redirection .banner__content-cta {
    padding-left: 76px;
    padding-right: 76px;
  }
}

@media (max-width: 320px) {
  #how-cooltone-works .banner.banner--fap .banner__teaser {
    height: 715px;
  }
}

@media (min-width: 321px) and (max-width: 413px) {
  #how-cooltone-works .banner.banner--fap .banner__teaser {
    height: 825px;
  }
}

@media (min-width: 414px) and (max-width: 767px) {
  #how-cooltone-works .banner.banner--fap .banner__teaser {
    height: 900px;
  }
}

@media (min-width: 768px) {
  #how-cooltone-works .banner.banner--fap .banner__teaser {
    background-position: 50% 0;
  }
}

@media (min-width: 1200px) {
  #how-cooltone-works .banner.banner--fap .banner__teaser {
    background-position: center;
  }
}

@media (min-width: 768px) {
  #how-cooltone-works .banner.banner--fap .banner__teaser .emu-teaser__disclaimer {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (min-width: 1024px) {
  #how-cooltone-works .banner.banner--fap .banner__teaser .emu-teaser__disclaimer {
    padding-left: 75px;
    padding-right: 75px;
  }

  #how-cooltone-works .banner.banner--fap .banner__content {
    padding-top: 7px;
    top: 22%;
  }
}

@media (min-width: 1025px) {
  #how-cooltone-works .banner.banner--fap .banner__content {
    padding-top: var(--emu-common-spacing-xs);
  }
}

@media (min-width: 1199px) {
  #how-cooltone-works .banner.banner--fap .banner__content {
    padding-top: 6px;
    padding-right: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1200px) {
  #how-cooltone-works .banner.banner--fap .banner__content {
    padding-left: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1401px) {
  #how-cooltone-works .banner.banner--fap .banner__content {
    padding-top: 5px;
    top: 25%;
  }
}

@media (min-width: 1601px) {
  #how-cooltone-works .banner.banner--fap .banner__content {
    top: 22%;
  }
}

@media (min-width: 768px) {
  #how-cooltone-works .banner.banner--fap .banner__content-inner {
    padding-top: 87%;
  }
}

@media (min-width: 1024px) {
  #how-cooltone-works .banner.banner--fap .banner__content-inner {
    padding-top: 40%;
  }
}

@media (min-width: 1200px) {
  #how-cooltone-works .banner.banner--fap .banner__content-inner {
    padding-top: 12.5%;
  }
}

@media (min-width: 1401px) {
  #how-cooltone-works .banner.banner--fap .banner__content-inner {
    padding-top: 14%;
  }
}

@media (min-width: 1601px) {
  #how-cooltone-works .banner.banner--fap .banner__content-inner {
    padding-top: 28%;
  }
}

@media (min-width: 768px) {
  #how-cooltone-works .banner.banner--fap .banner__content-title {
    margin-top: 1px;
  }
}

@media (min-width: 1199px) {
  #how-cooltone-works .banner.banner--fap .banner__content-title {
    margin-top: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1025px) {
  #how-cooltone-works .banner.banner--fap .banner__content-text {
    margin-top: 12px;
  }
}

@media (min-width: 1200px) {
  #how-cooltone-works .banner.banner--fap .banner__content-text {
    margin-top: 13px;
  }
}

@media (min-width: 1024px) {
  #how-cooltone-works .banner.banner--fap .banner__content-cta-container {
    margin-top: 23px;
  }
}

#tm-see-results .banner.banner--main .banner__teaser {
  background-position: top;
  top: 1px;
}

@media (max-width: 321px) {
  #tm-see-results .banner.banner--main .banner__teaser {
    height: 104vh;
  }
}

@media (min-width: 321px) and (max-width: 575px) {
  #tm-see-results .banner.banner--main .banner__teaser {
    height: 105vh;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  #tm-see-results .banner.banner--main .banner__teaser {
    height: 99vh;
  }
}

@media (min-width: 768px) {
  #tm-see-results .banner.banner--main .banner__teaser {
    background-position: center;
  }
}

#tm-see-results .banner.banner--main .banner__teaser .emu-teaser__disclaimer {
  padding-left: 30px;
  padding-right: 30px;
}

@media (min-width: 321px) {
  #tm-see-results .banner.banner--main .banner__teaser .emu-teaser__disclaimer {
    padding-bottom: 20px;
  }
}

@media (min-width: 576px) {
  #tm-see-results .banner.banner--main .banner__teaser .emu-teaser__disclaimer {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 768px) {
  #tm-see-results .banner.banner--main .banner__teaser .emu-teaser__disclaimer {
    padding-left: 90px;
    padding-right: 90px;
  }
}

@media (min-width: 1024px) {
  #tm-see-results .banner.banner--main .banner__teaser .emu-teaser__disclaimer {
    padding-left: 84px;
    padding-right: 84px;
  }
}

@media (min-width: 1200px) {
  #tm-see-results .banner.banner--main .banner__teaser .emu-teaser__disclaimer {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#tm-see-results .banner.banner--main .banner__teaser .emu-teaser__disclaimer sup {
  top: .5em;
}

#tm-see-results .banner.banner--main .banner__content {
  align-items: flex-end;
  height: calc(100% - 121px);
  padding: 10px 37px 12px 30px;
  display: flex;
}

@media (min-width: 321px) {
  #tm-see-results .banner.banner--main .banner__content {
    top: 3px;
  }
}

@media (min-width: 414px) {
  #tm-see-results .banner.banner--main .banner__content {
    padding-top: 20px;
    padding-bottom: 22px;
    padding-right: 30px;
    top: 13px;
  }
}

@media (min-width: 576px) {
  #tm-see-results .banner.banner--main .banner__content {
    height: calc(100% - 134px);
    padding-left: 40px;
    padding-right: 40px;
    top: 0;
  }
}

@media (min-width: 768px) {
  #tm-see-results .banner.banner--main .banner__content {
    padding-left: 85px;
    padding-right: 85px;
    padding-bottom: var(--emu-common-spacing-none);
    height: 90%;
    display: block;
  }
}

@media (min-width: 1024px) {
  #tm-see-results .banner.banner--main .banner__content {
    padding-left: 84px;
    padding-right: 84px;
    top: 0;
  }
}

@media (min-width: 1200px) {
  #tm-see-results .banner.banner--main .banner__content {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    top: -3px;
  }
}

@media (min-width: 768px) {
  #tm-see-results .banner.banner--main .banner__content > .container {
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-top: 55%;
    display: flex;
  }
}

@media (min-width: 1024px) {
  #tm-see-results .banner.banner--main .banner__content > .container {
    padding-top: 25%;
  }
}

@media (min-width: 1199px) {
  #tm-see-results .banner.banner--main .banner__content > .container {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 108px;
    padding-right: 108px;
  }
}

@media (min-width: 1200px) {
  #tm-see-results .banner.banner--main .banner__content > .container {
    padding-top: var(--emu-common-spacing-none);
  }
}

@media (min-width: 768px) {
  #tm-see-results .banner.banner--main .banner__content-inner {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1024px) {
  #tm-see-results .banner.banner--main .banner__content-inner {
    margin: var(--emu-common-spacing-none);
  }
}

#tm-see-results .banner.banner--main .banner__content-img img {
  padding: var(--emu-common-spacing-none);
  width: auto;
  max-width: none;
  height: 105px;
  position: relative;
  left: -4%;
}

@media (min-width: 321px) {
  #tm-see-results .banner.banner--main .banner__content-img img {
    height: 110px;
    left: -3%;
  }
}

@media (min-width: 414px) {
  #tm-see-results .banner.banner--main .banner__content-img img {
    height: 120px;
  }
}

@media (min-width: 576px) {
  #tm-see-results .banner.banner--main .banner__content-img img {
    height: 179px;
    left: -20px;
  }
}

@media (min-width: 768px) {
  #tm-see-results .banner.banner--main .banner__content-img img {
    margin-left: var(--emu-common-spacing-none);
    height: 215px;
    margin-top: 70px;
    left: -45px;
  }
}

@media (min-width: 1024px) {
  #tm-see-results .banner.banner--main .banner__content-img img {
    height: 270px;
  }
}

@media (min-width: 1200px) {
  #tm-see-results .banner.banner--main .banner__content-img img {
    margin-top: var(--emu-common-spacing-none);
    height: 240px;
    left: -100px;
  }
}

#tm-see-results .banner.banner--main .banner__content-title {
  padding: var(--emu-common-spacing-none);
  margin-top: -13px;
  position: relative;
}

@media (min-width: 321px) {
  #tm-see-results .banner.banner--main .banner__content-title {
    margin-top: -12px;
  }
}

@media (min-width: 576px) {
  #tm-see-results .banner.banner--main .banner__content-title {
    margin-top: 14px;
  }
}

@media (min-width: 768px) {
  #tm-see-results .banner.banner--main .banner__content-title {
    margin-top: -36px;
  }
}

@media (min-width: 1024px) {
  #tm-see-results .banner.banner--main .banner__content-title {
    margin-top: -49px;
  }
}

@media (min-width: 1200px) {
  #tm-see-results .banner.banner--main .banner__content-title {
    margin-top: -30px;
  }
}

@media (min-width: 321px) {
  #tm-see-results .banner.banner--main .banner__content-title .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-narrow-large);
    line-height: var(--emu-semantic-line-heights-narrow-large);
  }
}

@media (min-width: 414px) {
  #tm-see-results .banner.banner--main .banner__content-title .cmp-title__text {
    font-size: 33px;
    line-height: var(--emu-common-line-heights-narrow-xl);
  }
}

@media (min-width: 576px) {
  #tm-see-results .banner.banner--main .banner__content-title .cmp-title__text {
    font-size: 46px;
    line-height: 50px;
  }
}

@media (min-width: 768px) {
  #tm-see-results .banner.banner--main .banner__content-title .cmp-title__text {
    font-size: 64px;
    line-height: 69px;
  }
}

@media (min-width: 1200px) {
  #tm-see-results .banner.banner--main .banner__content-title .cmp-title__text {
    font-size: 54px;
    line-height: 58px;
  }
}

#tm-see-results .banner.banner--main .banner__content-text {
  padding: var(--emu-common-spacing-none);
  margin-top: 12px;
}

@media (min-width: 321px) {
  #tm-see-results .banner.banner--main .banner__content-text {
    margin-top: 17px;
    margin-left: var(--emu-common-spacing-none);
    width: 95%;
  }
}

@media (min-width: 414px) {
  #tm-see-results .banner.banner--main .banner__content-text {
    width: 90%;
    margin-top: 7px;
    font-size: 16px;
    line-height: 29px;
  }
}

@media (min-width: 576px) {
  #tm-see-results .banner.banner--main .banner__content-text {
    font-size: 22px;
    line-height: var(--emu-semantic-font-sizes-narrow-xxl);
    width: 78%;
    margin-top: 21px;
  }
}

@media (min-width: 768px) {
  #tm-see-results .banner.banner--main .banner__content-text {
    margin-top: 22px;
    margin-bottom: var(--emu-common-spacing-medium);
    width: 84%;
    font-size: 28px;
    line-height: 42px;
  }
}

@media (min-width: 1024px) {
  #tm-see-results .banner.banner--main .banner__content-text {
    margin-top: 22px;
    margin-bottom: var(--emu-common-spacing-none);
    width: 570px;
  }
}

@media (min-width: 1200px) {
  #tm-see-results .banner.banner--main .banner__content-text {
    width: 45%;
    margin-top: 17px;
    font-size: 24px;
    line-height: 31px;
  }
}

@media (min-width: 1199px) {
  #tm-see-results .banner.banner--main .banner__content-text-container {
    padding-right: var(--emu-common-spacing-none);
  }
}

#tm-see-results .banner.banner--main .banner__content-text sup {
  top: .2em;
}

@media (max-width: 320px) {
  #tm-see-results .banner.banner--fap .banner__teaser {
    height: 715px;
  }
}

@media (min-width: 321px) and (max-width: 413px) {
  #tm-see-results .banner.banner--fap .banner__teaser {
    height: 825px;
  }
}

@media (min-width: 414px) and (max-width: 767px) {
  #tm-see-results .banner.banner--fap .banner__teaser {
    height: 900px;
  }
}

#tm-see-results .banner.banner--fap .banner__teaser .emu-teaser__disclaimer {
  padding-left: calc(15px + 4%);
  padding-right: calc(15px + 4%);
  font-size: 12px;
}

@media (min-width: 1024px) {
  #tm-see-results .banner.banner--fap .banner__content {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    width: auto;
    margin-left: -15px;
    margin-right: -15px;
    padding-top: 6px;
    top: 0;
  }
}

@media (min-width: 1025px) {
  #tm-see-results .banner.banner--fap .banner__content {
    max-width: var(--emu-common-sizing-large-tablet);
    margin-left: auto;
    margin-right: auto;
    top: 15%;
  }
}

@media (min-width: 1401px) {
  #tm-see-results .banner.banner--fap .banner__content {
    padding-top: 10px;
  }
}

@media (min-width: 1601px) {
  #tm-see-results .banner.banner--fap .banner__content {
    padding-top: 7px;
  }
}

@media (min-width: 2001px) {
  #tm-see-results .banner.banner--fap .banner__content {
    padding-top: var(--emu-common-spacing-small);
  }
}

@media (min-width: 768px) {
  #tm-see-results .banner.banner--fap .banner__content-inner {
    padding-top: 90%;
  }
}

@media (min-width: 1024px) {
  #tm-see-results .banner.banner--fap .banner__content-inner {
    padding-top: 85%;
  }
}

@media (min-width: 1200px) {
  #tm-see-results .banner.banner--fap .banner__content-inner {
    padding-top: 17.7%;
  }
}

@media (min-width: 1401px) {
  #tm-see-results .banner.banner--fap .banner__content-inner {
    padding-top: 23.57%;
  }
}

@media (min-width: 1601px) {
  #tm-see-results .banner.banner--fap .banner__content-inner {
    padding-top: 28%;
  }
}

@media (min-width: 1025px) {
  #tm-see-results .banner.banner--fap .banner__content-title .cmp-title__text {
    font-size: 64px;
    line-height: 69px;
  }
}

@media (min-width: 1200px) {
  #tm-see-results .banner.banner--fap .banner__content-title .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
    line-height: var(--emu-semantic-line-heights-wide-xl);
  }
}

@media (min-width: 1024px) {
  #tm-see-results .banner.banner--fap .banner__content-text {
    max-width: 640px;
    margin-top: 12px;
  }
}

@media (min-width: 1025px) {
  #tm-see-results .banner.banner--fap .banner__content-text {
    font-size: 28px;
    line-height: 42px;
  }
}

@media (min-width: 1200px) {
  #tm-see-results .banner.banner--fap .banner__content-text {
    max-width: 700px;
    margin-top: 11px;
    font-size: 24px;
    line-height: 31px;
  }
}

@media (min-width: 1601px) {
  #tm-see-results .banner.banner--fap .banner__content-text {
    margin-top: 12px;
  }
}

@media (min-width: 768px) {
  #tm-see-results .banner.banner--fap .banner__content-cta-container {
    margin-top: 23px;
  }
}

@media (max-width: 320px) {
  #tm-what-to-expect .banner.banner--main .banner__teaser {
    height: 630px;
  }
}

@media (min-width: 768px) {
  #tm-what-to-expect .banner.banner--main .banner__teaser {
    background-position: 50% 65%;
  }
}

@media (min-width: 1200px) {
  #tm-what-to-expect .banner.banner--main .banner__teaser {
    background-position: 50%;
  }
}

@media (min-width: 1601px) {
  #tm-what-to-expect .banner.banner--main .banner__teaser {
    height: 755px;
  }
}

@media (min-width: 2001px) {
  #tm-what-to-expect .banner.banner--main .banner__teaser {
    height: 900px;
  }
}

@media (min-width: 768px) {
  #tm-what-to-expect .banner.banner--main .banner__teaser .disclaimer-bottom-right .emu-teaser__disclaimer {
    padding-right: var(--emu-common-spacing-none);
    padding-bottom: 15px;
  }
}

@media (min-width: 1024px) {
  #tm-what-to-expect .banner.banner--main .banner__teaser .disclaimer-bottom-right .emu-teaser__disclaimer {
    padding-bottom: 20px;
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (min-width: 1200px) {
  #tm-what-to-expect .banner.banner--main .banner__teaser .disclaimer-bottom-right .emu-teaser__disclaimer {
    padding-bottom: 10px;
  }
}

#tm-what-to-expect .banner.banner--main .banner__content {
  padding-top: 80%;
}

@media (min-width: 350px) {
  #tm-what-to-expect .banner.banner--main .banner__content {
    padding-top: 90%;
  }
}

@media (min-width: 576px) {
  #tm-what-to-expect .banner.banner--main .banner__content {
    padding-top: 100%;
  }
}

@media (min-width: 768px) {
  #tm-what-to-expect .banner.banner--main .banner__content {
    padding-bottom: 10px;
    padding-top: var(--emu-common-spacing-none);
    padding-left: var(--emu-common-spacing-none);
    max-width: var(--emu-semantic-sizing-breakpoints-large-plus);
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media (min-width: 1200px) {
  #tm-what-to-expect .banner.banner--main .banner__content {
    padding-bottom: var(--emu-common-spacing-none);
  }
}

@media (min-width: 768px) {
  #tm-what-to-expect .banner.banner--main .banner__content > .container {
    position: relative;
    top: 58%;
  }
}

@media (min-width: 1024px) {
  #tm-what-to-expect .banner.banner--main .banner__content > .container {
    top: 60%;
  }
}

@media (min-width: 1200px) {
  #tm-what-to-expect .banner.banner--main .banner__content > .container {
    top: 25%;
  }
}

@media (min-width: 1600px) {
  #tm-what-to-expect .banner.banner--main .banner__content > .container {
    top: 40%;
  }
}

@media (min-width: 768px) {
  #tm-what-to-expect .banner.banner--main .banner__content-inner {
    padding-left: 85px;
    padding-right: 52px;
  }
}

@media (min-width: 1024px) {
  #tm-what-to-expect .banner.banner--main .banner__content-inner {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (min-width: 1200px) {
  #tm-what-to-expect .banner.banner--main .banner__content-inner {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    padding-top: var(--emu-common-spacing-medium);
  }
}

#tm-what-to-expect .banner.banner--main .banner__content-img img {
  padding: var(--emu-common-spacing-none);
  max-width: 75%;
  margin-left: 20px;
}

@media (min-width: 350px) {
  #tm-what-to-expect .banner.banner--main .banner__content-img img {
    max-width: 70%;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  #tm-what-to-expect .banner.banner--main .banner__content-img img {
    max-width: 65%;
    margin-left: 15px;
  }
}

@media (min-width: 768px) {
  #tm-what-to-expect .banner.banner--main .banner__content-img img {
    width: 560px;
    max-width: none;
    height: auto;
    margin-left: -40px;
  }
}

@media (min-width: 1200px) {
  #tm-what-to-expect .banner.banner--main .banner__content-img img {
    margin-left: var(--emu-common-spacing-none);
    width: auto;
  }
}

#tm-what-to-expect .banner.banner--main .banner__content-title {
  margin-top: -21px;
}

@media (min-width: 350px) {
  #tm-what-to-expect .banner.banner--main .banner__content-title {
    margin-top: -22px;
    padding-left: 30px;
    padding-right: 5%;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  #tm-what-to-expect .banner.banner--main .banner__content-title {
    margin-top: -7px;
    padding-left: 78px;
  }
}

@media (min-width: 768px) {
  #tm-what-to-expect .banner.banner--main .banner__content-title {
    padding: var(--emu-common-spacing-none);
    margin-top: -69px;
  }
}

@media (min-width: 1024px) {
  #tm-what-to-expect .banner.banner--main .banner__content-title {
    margin-left: var(--emu-common-spacing-none);
    max-width: 660px;
  }
}

@media (min-width: 1200px) {
  #tm-what-to-expect .banner.banner--main .banner__content-title {
    max-width: none;
    margin-top: -55px;
    padding-left: 78px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  #tm-what-to-expect .banner.banner--main .banner__content-title .cmp-title__text {
    font-size: 46px;
    line-height: 50px;
  }
}

@media (min-width: 1025px) and (max-width: 1199px) {
  #tm-what-to-expect .banner.banner--main .banner__content-title .cmp-title__text {
    font-size: 64px;
    line-height: 69px;
  }
}

#tm-what-to-expect .banner.banner--main .banner__content-text {
  padding-right: 30px;
}

@media (min-width: 350px) {
  #tm-what-to-expect .banner.banner--main .banner__content-text {
    padding-left: 30px;
    padding-right: 40px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  #tm-what-to-expect .banner.banner--main .banner__content-text {
    padding-left: 78px;
    padding-right: 35px;
    font-size: 22px;
    line-height: 40px;
  }
}

@media (min-width: 768px) {
  #tm-what-to-expect .banner.banner--main .banner__content-text {
    padding: var(--emu-common-spacing-none);
    margin-top: 12px;
  }
}

@media (min-width: 1024px) {
  #tm-what-to-expect .banner.banner--main .banner__content-text {
    margin-left: var(--emu-common-spacing-none);
    max-width: 635px;
    margin-top: 28px;
  }
}

@media (min-width: 1025px) and (max-width: 1199px) {
  #tm-what-to-expect .banner.banner--main .banner__content-text {
    font-size: 28px;
    line-height: 42px;
  }
}

@media (min-width: 1200px) {
  #tm-what-to-expect .banner.banner--main .banner__content-text {
    max-width: 720px;
    margin-top: 12px;
    padding-left: 78px;
  }
}

#tm-what-to-expect .treatment-plan__wrapper {
  padding-bottom: 18px;
}

@media (min-width: 321px) {
  #tm-what-to-expect .treatment-plan__wrapper {
    padding-bottom: 17px;
  }
}

@media (min-width: 768px) {
  #tm-what-to-expect .treatment-plan__wrapper {
    padding-top: 102px;
    padding-bottom: 15px;
  }
}

@media (min-width: 1024px) {
  #tm-what-to-expect .treatment-plan__wrapper {
    padding-bottom: var(--emu-common-spacing-medium);
  }
}

@media (min-width: 1200px) {
  #tm-what-to-expect .treatment-plan__wrapper {
    padding-top: 111px;
    padding-bottom: 98px;
  }
}

@media (min-width: 768px) {
  #tm-what-to-expect .treatment-plan__wrapper.relative-modal__wrapper--hide {
    padding-bottom: 19px;
  }
}

@media (min-width: 1200px) {
  #tm-what-to-expect .treatment-plan__wrapper.relative-modal__wrapper--hide {
    padding-bottom: 410px;
  }
}

@media (min-width: 768px) {
  #tm-what-to-expect .treatment-plan__title {
    margin-right: var(--emu-common-spacing-none);
    margin-bottom: 15px;
  }
}

@media (min-width: 1200px) {
  #tm-what-to-expect .treatment-plan__title {
    margin-bottom: 33px;
    margin-right: 10px;
  }
}

@media (min-width: 768px) {
  #tm-what-to-expect .treatment-plan__title-container {
    margin-bottom: 55px;
  }
}

@media (min-width: 1024px) {
  #tm-what-to-expect .treatment-plan__title-container {
    width: 53%;
    margin-bottom: 40px;
  }
}

@media (min-width: 1200px) {
  #tm-what-to-expect .treatment-plan__title-container {
    width: 100%;
    margin-bottom: 28px;
  }

  #tm-what-to-expect .treatment-plan__scroll-wrapper {
    max-height: 800px;
  }
}

@media (min-width: 768px) {
  #tm-what-to-expect .treatment-plan__scroll-content-text {
    padding-right: 38px;
  }
}

@media (min-width: 1200px) {
  #tm-what-to-expect .treatment-plan__scroll-content-text {
    padding-right: var(--emu-common-spacing-none);
  }
}

@media (min-width: 768px) {
  #tm-what-to-expect .treatment-plan__scroll-content-text li {
    line-height: 42.5px;
  }
}

@media (min-width: 1024px) {
  #tm-what-to-expect .treatment-plan__scroll-content-text li {
    line-height: 42px;
  }
}

@media (min-width: 1200px) {
  #tm-what-to-expect .treatment-plan__scroll-content-text li {
    font-size: 24px;
    line-height: 31.5px;
  }

  #tm-what-to-expect .treatment-plan__scroll-content .button {
    margin-top: -15px;
  }
}

#tm-what-to-expect .treatment-plan__scroll-menu-text ul li > a:not(.active) {
  opacity: .4;
}

@media (min-width: 1024px) {
  #tm-what-to-expect .treatment-plan__accordion-wrapper .aaaem-accordion__button {
    align-items: center;
  }

  #tm-what-to-expect .treatment-plan__accordion-wrapper .aaaem-accordion__header.-ba-is-active .aaaem-accordion__button.caret-icon .aaaem-accordion__icon {
    transform: rotate(-180deg)translateY(22px);
  }

  #tm-what-to-expect .treatment-plan__accordion-wrapper .aaaem-accordion__icon {
    width: 47px;
    height: 47px;
    margin-top: 12px;
  }

  #tm-what-to-expect .treatment-plan__accordion-wrapper .aaaem-accordion__icon:after, #tm-what-to-expect .treatment-plan__accordion-wrapper .aaaem-accordion__icon:before {
    width: 4.5px;
  }

  #tm-what-to-expect .treatment-plan__accordion-wrapper .aaaem-accordion__icon:after {
    transform: rotate(-48deg)translateX(1px);
  }

  #tm-what-to-expect .treatment-plan__accordion-wrapper .aaaem-accordion__icon:before {
    transform: rotate(48deg)translateX(-1px);
  }
}

#tm-what-to-expect .banner.banner--redirection .banner__teaser {
  height: 825px;
}

@media (min-width: 414px) {
  #tm-what-to-expect .banner.banner--redirection .banner__teaser {
    height: 990px;
  }
}

@media (min-width: 576px) {
  #tm-what-to-expect .banner.banner--redirection .banner__teaser {
    height: 755px;
  }
}

@media (min-width: 768px) {
  #tm-what-to-expect .banner.banner--redirection .banner__teaser {
    background-position: center;
    height: 1566px;
  }
}

@media (min-width: 1024px) {
  #tm-what-to-expect .banner.banner--redirection .banner__teaser {
    height: 2130px;
  }
}

@media (min-width: 1025px) {
  #tm-what-to-expect .banner.banner--redirection .banner__teaser {
    background-position: initial;
  }
}

@media (min-width: 1200px) {
  #tm-what-to-expect .banner.banner--redirection .banner__teaser {
    height: 750px;
  }
}

@media (min-width: 1401px) {
  #tm-what-to-expect .banner.banner--redirection .banner__teaser {
    height: 900px;
  }
}

@media (min-width: 1601px) {
  #tm-what-to-expect .banner.banner--redirection .banner__teaser {
    height: 1175px;
  }
}

@media (min-width: 2001px) {
  #tm-what-to-expect .banner.banner--redirection .banner__teaser {
    height: 1600px;
  }
}

#tm-what-to-expect .banner.banner--redirection .banner__content {
  align-items: center;
  height: 100%;
  padding-top: calc(19.5% - 22px);
  display: flex;
  top: 0;
}

@media (min-width: 375px) {
  #tm-what-to-expect .banner.banner--redirection .banner__content {
    padding-top: calc(17.25% + 8px);
  }
}

@media (min-width: 576px) {
  #tm-what-to-expect .banner.banner--redirection .banner__content {
    padding-top: var(--emu-common-spacing-none);
    margin-top: -12px;
  }
}

@media (min-width: 768px) {
  #tm-what-to-expect .banner.banner--redirection .banner__content {
    margin-top: var(--emu-common-spacing-none);
    padding-top: calc(11% - 78px);
  }
}

@media (min-width: 1024px) {
  #tm-what-to-expect .banner.banner--redirection .banner__content {
    padding-top: var(--emu-common-spacing-none);
    margin-top: -11px;
  }
}

@media (min-width: 1200px) {
  #tm-what-to-expect .banner.banner--redirection .banner__content {
    margin-top: -12px;
  }
}

@media (min-width: 2001px) {
  #tm-what-to-expect .banner.banner--redirection .banner__content {
    margin-top: -9px;
  }
}

@media (min-width: 768px) {
  #tm-what-to-expect .banner.banner--redirection .banner__content-inner {
    max-width: 674px;
    margin-left: auto;
    margin-right: auto;
  }
}

#tm-what-to-expect .banner.banner--redirection .banner__content-title {
  max-width: none;
}

@media (min-width: 576px) and (max-width: 767px) {
  #tm-what-to-expect .banner.banner--redirection .banner__content-title .cmp-title__text {
    font-size: 54px;
    line-height: 58px;
  }
}

@media (min-width: 1024px) {
  #tm-what-to-expect .banner.banner--redirection .banner__content-title .cmp-title__text {
    font-size: 54px;
    line-height: 58px;
  }
}

#tm-what-to-expect .banner.banner--redirection .banner__content-text {
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
  margin-top: -2px;
}

@media (min-width: 576px) and (max-width: 767px) {
  #tm-what-to-expect .banner.banner--redirection .banner__content-text {
    font-size: 24px;
    line-height: 31px;
  }
}

@media (min-width: 768px) {
  #tm-what-to-expect .banner.banner--redirection .banner__content-text {
    padding: var(--emu-common-spacing-none);
    max-width: none;
    margin-top: -3px;
  }
}

@media (min-width: 1024px) {
  #tm-what-to-expect .banner.banner--redirection .banner__content-text {
    margin-top: 12px;
  }
}

@media (min-width: 1200px) {
  #tm-what-to-expect .banner.banner--redirection .banner__content-text {
    font-size: 24px;
    line-height: 31px;
  }
}

@media (min-width: 768px) {
  #tm-what-to-expect .banner.banner--redirection .banner__content-text-container {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#tm-what-to-expect .banner.banner--redirection .banner__content-text br {
  display: block;
}

#tm-what-to-expect .banner.banner--redirection .banner__content-cta-container {
  margin-top: 25px;
}

@media (min-width: 768px) {
  #tm-what-to-expect .banner.banner--redirection .banner__content-cta-container {
    margin-top: 24px;
  }
}

#tm-what-to-expect .banner.banner--redirection .banner__content-cta {
  height: 63px;
}

@media (min-width: 576px) and (max-width: 767px) {
  #tm-what-to-expect .banner.banner--redirection .banner__content-cta {
    font-size: 24px;
  }
}

@media (min-width: 768px) {
  #tm-what-to-expect .banner.banner--redirection .banner__content-cta {
    font-size: 24px;
  }

  #tm-what-to-expect .banner.banner--fap .banner__content {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    top: 41%;
  }
}

@media (min-width: 1024px) {
  #tm-what-to-expect .banner.banner--fap .banner__content {
    max-width: var(--emu-semantic-sizing-breakpoints-large-plus);
    padding-top: 7px;
    top: 42.5%;
  }
}

@media (min-width: 1200px) {
  #tm-what-to-expect .banner.banner--fap .banner__content {
    padding-top: 5px;
    top: 15%;
  }
}

@media (min-width: 1401px) {
  #tm-what-to-expect .banner.banner--fap .banner__content {
    padding-top: 7px;
  }
}

@media (min-width: 768px) {
  #tm-what-to-expect .banner.banner--fap .banner__content-inner {
    padding-top: var(--emu-common-spacing-none);
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (min-width: 1024px) {
  #tm-what-to-expect .banner.banner--fap .banner__content-inner {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1025px) {
  #tm-what-to-expect .banner.banner--fap .banner__content-inner {
    padding-top: 28%;
  }
}

@media (min-width: 1200px) {
  #tm-what-to-expect .banner.banner--fap .banner__content-inner {
    padding-top: 14.7%;
  }
}

@media (min-width: 1401px) {
  #tm-what-to-expect .banner.banner--fap .banner__content-inner {
    padding-top: 20.6%;
  }
}

@media (min-width: 1601px) {
  #tm-what-to-expect .banner.banner--fap .banner__content-inner {
    padding-top: 28%;
  }
}

@media (min-width: 2001px) {
  #tm-what-to-expect .banner.banner--fap .banner__content-inner {
    padding-top: 35%;
  }
}

@media (min-width: 321px) {
  #tm-what-to-expect .banner.banner--fap .banner__content-text {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    max-width: 85%;
  }
}

@media (min-width: 414px) {
  #tm-what-to-expect .banner.banner--fap .banner__content-text {
    max-width: 90%;
  }
}

@media (min-width: 768px) {
  #tm-what-to-expect .banner.banner--fap .banner__content-text {
    max-width: 675px;
  }
}

@media (min-width: 1024px) {
  #tm-what-to-expect .banner.banner--fap .banner__content-text {
    margin-top: 12px;
  }
}

@media (min-width: 1025px) {
  #tm-what-to-expect .banner.banner--fap .banner__content-text {
    max-width: 700px;
  }
}

@media (min-width: 1024px) {
  #tm-what-to-expect .banner.banner--fap .banner__content-cta-container {
    margin-top: 23px;
  }
}

@media (min-width: 768px) {
  #tm-what-to-expect .emu-modal.relative-modal.checklist-modal {
    margin-top: -42px;
  }
}

@media (min-width: 1024px) {
  #tm-what-to-expect .emu-modal.relative-modal.checklist-modal {
    margin-top: -28px;
  }
}

@media (min-width: 1200px) {
  #tm-what-to-expect .emu-modal.relative-modal.checklist-modal {
    margin-top: -35px;
  }

  #tm-what-to-expect .emu-modal.relative-modal.checklist-modal .checklist-content {
    padding-bottom: var(--emu-common-spacing-none);
  }
}

@media (min-width: 768px) {
  #tm-what-to-expect .emu-modal.relative-modal.checklist-modal .checklist-content__title {
    margin-top: 26px;
    margin-bottom: var(--emu-common-spacing-xs);
  }
}

@media (min-width: 1200px) {
  #tm-what-to-expect .emu-modal.relative-modal.checklist-modal .checklist-content__title {
    margin-top: -3px;
    margin-bottom: 6px;
  }
}

@media (min-width: 768px) {
  #tm-what-to-expect .emu-modal.relative-modal.checklist-modal .checklist-content__title .cmp-title__text {
    line-height: 69px;
  }
}

@media (min-width: 1200px) {
  #tm-what-to-expect .emu-modal.relative-modal.checklist-modal .checklist-content__title .cmp-title__text {
    font-size: 44px;
    line-height: 48px;
  }
}

@media (min-width: 768px) {
  #tm-what-to-expect .emu-modal.relative-modal.checklist-modal .checklist-content__description {
    margin-bottom: 18px;
  }

  #tm-what-to-expect .emu-modal.relative-modal.checklist-modal .checklist-content__list {
    padding-bottom: 30px;
    line-height: 40px;
  }
}

@media (min-width: 1200px) {
  #tm-what-to-expect .emu-modal.relative-modal.checklist-modal .checklist-content__list {
    padding-bottom: 34px;
    line-height: 20px;
  }
}

@media (min-width: 768px) {
  #tm-what-to-expect .emu-modal.relative-modal.checklist-modal .checklist-content__list li {
    margin-bottom: 29px;
  }
}

@media (min-width: 1200px) {
  #tm-what-to-expect .emu-modal.relative-modal.checklist-modal .checklist-content__list li {
    margin-bottom: 15px;
  }
}

@media (min-width: 768px) {
  #tm-what-to-expect .emu-modal.relative-modal.checklist-modal .modal-content {
    padding: 38px;
  }
}

@media (min-width: 1200px) {
  #tm-what-to-expect .emu-modal.relative-modal.checklist-modal .modal-content {
    padding: 20px;
  }
}

@media (min-width: 768px) {
  #tm-what-to-expect .emu-modal.relative-modal.checklist-modal .modal-content .close {
    width: var(--emu-common-sizing-medium);
    height: var(--emu-common-sizing-medium);
  }
}

@media (min-width: 1200px) {
  #tm-what-to-expect .emu-modal.relative-modal.checklist-modal .modal-content .close {
    width: 24px;
    height: 27px;
  }
}

#site-map-page .site-map__content {
  padding-bottom: 13px;
  padding-left: 34px;
}

@media (min-width: 768px) {
  #site-map-page .site-map__content {
    padding-left: 45px;
  }
}

@media (min-width: 1200px) {
  #site-map-page .site-map__content {
    padding-bottom: 11px;
  }
}

#site-map-page .site-map__content p {
  font-size: 20px;
  line-height: var(--emu-common-line-heights-wide-medium);
  margin-bottom: 9px;
}

#site-map-page .site-map__content ul {
  margin: var(--emu-common-spacing-none);
  padding: var(--emu-common-spacing-none);
  margin-bottom: 20px;
  list-style-type: none;
}

#site-map-page .site-map__content ul:last-child {
  margin-top: 29px;
}

#site-map-page .site-map__content ul:last-child li {
  padding-left: var(--emu-common-spacing-none);
}

#site-map-page .site-map__content ul li {
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  line-height: var(--emu-common-line-heights-wide-medium);
  padding-left: 20px;
}

#site-map-page .site-map__content ul li sup {
  font-size: 100%;
  top: 6px;
}

#site-map-page .site-map__content a {
  text-decoration: none;
}

#site-map-page .site-map__content a:hover, #site-map-page .site-map__content a:focus {
  text-decoration: underline;
}

#california-supply-chain-act .csc-act__content > p {
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  color: var(--emu-semantic-colors-primary-950);
  line-height: var(--emu-common-line-heights-wide-large);
  padding-left: var(--emu-common-spacing-xs);
  margin-bottom: 37px;
}

@media (min-width: 768px) {
  #california-supply-chain-act .csc-act__content > p {
    padding-left: 15px;
    padding-right: 15px;
  }
}

#california-supply-chain-act .csc-act__content-wrapper {
  padding-bottom: 60px;
}

.aaaem-accordion {
  padding-top: var(--emu-common-spacing-none);
  padding-bottom: var(--emu-common-spacing-none);
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .aaaem-accordion {
    width: 100%;
    padding: 5%;
  }
}

.aaaem-accordion .button .emu-button.emu-button-v2 .cmp-button__text {
  font-size: 15px;
}

@media (min-width: 321px) {
  .aaaem-accordion .button .emu-button.emu-button-v2 .cmp-button__text {
    font-size: 18px;
  }
}

@media (min-width: 414px) {
  .aaaem-accordion .button .emu-button.emu-button-v2 .cmp-button__text {
    font-size: 20px;
  }
}

@media (min-width: 768px) {
  .aaaem-accordion .button .emu-button.emu-button-v2 .cmp-button__text {
    font-size: 28px;
  }
}

.aaaem-accordion__item {
  counter-increment: section;
  padding-left: 6px;
  padding-right: 6px;
}

.aaaem-accordion__header {
  align-items: center;
  margin-bottom: 40px;
  margin-left: 50px;
  padding-top: 1px;
  padding-bottom: 1px;
  display: flex;
  position: relative;
}

@media (min-width: 768px) {
  .aaaem-accordion__header {
    align-items: center;
    min-height: 72px;
    margin-bottom: 70px;
    margin-left: 102px;
  }
}

.aaaem-accordion__header:before, .aaaem-accordion__header:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%)translateX(-100%)translateY(-3px)translateX(-15px);
}

@media (min-width: 768px) {
  .aaaem-accordion__header:before, .aaaem-accordion__header:after {
    transform: translateY(-50%)translateX(-100%)translateY(-5px)translateX(-32px);
  }
}

.aaaem-accordion__header:after {
  content: counter(section);
  font-family: var(--emu-semantic-font-families-heading);
  color: var(--emu-semantic-colors-primary-100);
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  text-align: center;
  padding-top: var(--emu-common-spacing-xs);
  width: 35px;
  display: block;
}

@media (min-width: 414px) {
  .aaaem-accordion__header:after {
    font-size: 21px;
  }
}

@media (min-width: 768px) {
  .aaaem-accordion__header:after {
    width: 70px;
    padding-top: 10px;
    font-size: 50px;
  }
}

.aaaem-accordion__header:before {
  background-color: var(--emu-common-colors-white);
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

@media (min-width: 768px) {
  .aaaem-accordion__header:before {
    width: 70px;
    height: 70px;
  }
}

.aaaem-accordion__header.-ba-is-active .aaaem-accordion__button.caret-icon .aaaem-accordion__icon {
  transform: rotate(-180deg)translateY(12px);
}

@media (min-width: 768px) {
  .aaaem-accordion__header.-ba-is-active .aaaem-accordion__button.caret-icon .aaaem-accordion__icon {
    transform: rotate(-180deg)translateY(24px);
  }
}

@media (min-width: 1024px) {
  .aaaem-accordion__header.-ba-is-active .aaaem-accordion__button.caret-icon .aaaem-accordion__icon {
    transform: rotate(-180deg)translateY(12px);
  }
}

.aaaem-accordion__button {
  justify-content: space-between;
}

.aaaem-accordion__button.caret-icon .aaaem-accordion__icon {
  transform-origin: center;
  transition: all .2s linear;
}

.aaaem-accordion__button.caret-icon .aaaem-accordion__icon:after {
  transform: rotate(-48deg)translateX(1px);
}

@media (min-width: 1024px) {
  .aaaem-accordion__button.caret-icon .aaaem-accordion__icon:after {
    transform: rotate(-48deg);
  }
}

.aaaem-accordion__button.caret-icon .aaaem-accordion__icon:before {
  transform: rotate(48deg)translateX(-1px);
}

@media (min-width: 1024px) {
  .aaaem-accordion__button.caret-icon .aaaem-accordion__icon:before {
    transform: rotate(48deg);
  }
}

.aaaem-accordion__title {
  font-size: 28px;
  line-height: 34px;
}

@media (min-width: 321px) {
  .aaaem-accordion__title {
    font: var(--emu-component-lists-accordion-item-header-text-typography-narrow);
  }
}

@media (min-width: 414px) {
  .aaaem-accordion__title {
    font-size: 33px;
    line-height: 36px;
  }
}

@media (min-width: 576px) {
  .aaaem-accordion__title {
    max-width: 65%;
  }
}

@media (min-width: 768px) {
  .aaaem-accordion__title {
    max-width: 80%;
    font-size: 50px;
    line-height: 54.25px;
  }
}

@media (min-width: 992px) {
  .aaaem-accordion__title {
    max-width: 65%;
  }
}

.aaaem-accordion__icon {
  margin-top: var(--emu-common-spacing-xs);
  flex-shrink: 0;
  width: 23px;
  height: 23px;
}

@media (min-width: 768px) {
  .aaaem-accordion__icon {
    width: 45px;
    height: 45px;
    margin-bottom: -10px;
  }
}

@media (min-width: 1024px) {
  .aaaem-accordion__icon {
    margin-bottom: var(--emu-common-spacing-none);
    width: 24px;
    height: 24px;
  }
}

.aaaem-accordion__icon:before, .aaaem-accordion__icon:after {
  width: 2.5px;
}

@media (min-width: 576px) {
  .aaaem-accordion__icon:before, .aaaem-accordion__icon:after {
    width: 4.5px;
  }
}

@media (min-width: 1024px) {
  .aaaem-accordion__icon:before, .aaaem-accordion__icon:after {
    width: var(--emu-common-border-width-medium);
  }
}

@media (min-width: 768px) {
  .aaaem-accordion dd .js-badger-accordion-panel-inner {
    padding-top: 6px;
  }
}

@media (min-width: 1024px) {
  .aaaem-accordion dd .js-badger-accordion-panel-inner {
    padding-bottom: 65px;
  }
}

.alle-banner-container {
  background-position: center;
}

.alle-banner-content-wrapper {
  padding: 100px 30px 90px;
}

@media (min-width: 768px) {
  .alle-banner-content-wrapper {
    max-width: var(--emu-common-sizing-tablet);
    padding-bottom: 88px;
  }
}

@media (min-width: 1200px) {
  .alle-banner-content-wrapper {
    max-width: var(--emu-common-sizing-large-tablet);
  }
}

.alle-banner-content-wrapper__img img {
  margin: auto;
  margin-bottom: var(--emu-common-spacing-large);
  width: 135px;
}

@media (min-width: 321px) {
  .alle-banner-content-wrapper__img img {
    width: 158px;
  }
}

@media (min-width: 414px) {
  .alle-banner-content-wrapper__img img {
    width: 174px;
    margin-bottom: 34px;
  }
}

@media (min-width: 768px) {
  .alle-banner-content-wrapper__img img {
    width: 279px;
    margin-bottom: 35px;
  }
}

.alle-banner-content-wrapper__title .cmp-title__text {
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  line-height: var(--emu-semantic-line-heights-narrow-large);
  text-align: center;
  max-width: 100%;
  margin-bottom: 17px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 320px) {
  .alle-banner-content-wrapper__title .cmp-title__text {
    font-size: 28px;
    line-height: 34px;
  }
}

@media (min-width: 414px) {
  .alle-banner-content-wrapper__title .cmp-title__text {
    font-size: 33px;
    line-height: var(--emu-common-line-heights-narrow-xl);
    margin-bottom: 18px;
  }
}

@media (min-width: 768px) {
  .alle-banner-content-wrapper__title .cmp-title__text {
    margin-bottom: var(--emu-common-spacing-medium);
    max-width: 620px;
    font-size: 64px;
    line-height: 70px;
  }
}

@media (min-width: 1024px) {
  .alle-banner-content-wrapper__title .cmp-title__text {
    margin-bottom: 12px;
  }
}

@media (min-width: 1025px) {
  .alle-banner-content-wrapper__title .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
    line-height: var(--emu-semantic-line-heights-wide-xl);
    max-width: 70%;
    margin-bottom: 12px;
  }
}

.alle-banner-content-wrapper__text {
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  line-height: var(--emu-semantic-line-heights-narrow-small);
  margin-bottom: 24px;
}

@media (min-width: 414px) {
  .alle-banner-content-wrapper__text {
    font-size: var(--emu-semantic-font-sizes-narrow-medium);
    line-height: 29px;
  }
}

@media (min-width: 768px) {
  .alle-banner-content-wrapper__text {
    max-width: 700px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 28px;
    line-height: 42px;
  }
}

@media (min-width: 1025px) {
  .alle-banner-content-wrapper__text {
    font-size: var(--emu-semantic-font-sizes-wide-small);
    line-height: var(--emu-semantic-line-heights-wide-small);
    max-width: 100%;
    padding-left: 110px;
    padding-right: 110px;
  }
}

.alle-banner-content-wrapper__text * {
  font-size: inherit;
  line-height: inherit;
}

.alle-banner-content-wrapper__text sup {
  font-size: 60%;
  line-height: 0;
  top: 2px;
}

.alle-banner-content-wrapper .button {
  text-align: center;
}

.alle-banner-content-wrapper .button .emu-button {
  padding-left: 49px;
  padding-right: 49px;
}

@media (min-width: 348px) {
  .alle-banner-content-wrapper .button .emu-button {
    padding-left: 74px;
    padding-right: 74px;
  }
}

#esf-see-results .gallery .emu-mapping-marker[aria-label*="female-thighs"] {
  min-width: 87px;
  min-height: 87px;
}

@media (min-width: 768px) {
  #esf-see-results .gallery .emu-mapping-marker[aria-label*="female-thighs"] {
    min-width: 150px;
    min-height: 140px;
  }
}

#esf-see-results .gallery .emu-mapping-marker[aria-label*="-male-thighs"] {
  min-width: 83px;
  min-height: 78px;
}

@media (min-width: 768px) {
  #esf-see-results .gallery .emu-mapping-marker[aria-label*="-male-thighs"] {
    min-width: 147px;
    min-height: 138px;
  }
}

#esf-see-results .gallery .gallery__filter-img--back .emu-mapping-marker[aria-label*="female-flanks"] {
  min-height: var(--emu-common-sizing-medium);
  min-width: 75px;
}

@media (min-width: 768px) {
  #esf-see-results .gallery .gallery__filter-img--back .emu-mapping-marker[aria-label*="female-flanks"] {
    min-width: 124px;
    min-height: 59px;
  }
}

#esf-see-results .gallery .gallery__filter-img--back .emu-mapping-marker[aria-label*="female-thighs"] {
  min-width: 45px;
  min-height: 28px;
}

@media (min-width: 768px) {
  #esf-see-results .gallery .gallery__filter-img--back .emu-mapping-marker[aria-label*="female-thighs"] {
    min-width: 55px;
    min-height: 49px;
  }
}

#esf-see-results .gallery .gallery__filter-img--back .emu-mapping-marker[aria-label*="-male-flanks"] {
  min-width: 73px;
  min-height: 40px;
}

@media (min-width: 768px) {
  #esf-see-results .gallery .gallery__filter-img--back .emu-mapping-marker[aria-label*="-male-flanks"] {
    min-width: 128px;
    min-height: 67px;
  }
}

#esf-see-results .gallery .gallery__filter-img--back .emu-mapping-marker[aria-label*="-male-thighs"] {
  min-width: 34px;
  min-height: 25px;
}

@media (min-width: 768px) {
  #esf-see-results .gallery .gallery__filter-img--back .emu-mapping-marker[aria-label*="-male-thighs"] {
    min-width: 40px;
    min-height: 44px;
  }
}

.gallery .emu-mapping-marker {
  border-radius: var(--emu-common-border-radius-none);
  opacity: 0;
  min-width: 20px;
  min-height: 20px;
  transform: translateX(-50%)translateY(-50%);
}

@media (min-width: 1200px) {
  .gallery .emu-mapping-marker {
    pointer-events: none;
  }
}

.gallery .emu-mapping-marker[aria-label*="female-abdomen"] {
  min-width: 31px;
  min-height: 56px;
}

@media (min-width: 768px) {
  .gallery .emu-mapping-marker[aria-label*="female-abdomen"] {
    min-width: 52px;
    min-height: 98px;
  }
}

.gallery .emu-mapping-marker[aria-label*="female-backbrafat"] {
  min-width: 58px;
  min-height: 26px;
}

@media (min-width: 768px) {
  .gallery .emu-mapping-marker[aria-label*="female-backbrafat"] {
    min-width: 100px;
    min-height: 38px;
  }
}

.gallery .emu-mapping-marker[aria-label*="female-bananaroll"] {
  min-width: 76px;
  min-height: 23px;
}

@media (min-width: 768px) {
  .gallery .emu-mapping-marker[aria-label*="female-bananaroll"] {
    min-width: 128px;
    min-height: 35px;
  }
}

.gallery .emu-mapping-marker[aria-label*="female-chin"] {
  min-width: 42px;
  min-height: 24px;
}

@media (min-width: 768px) {
  .gallery .emu-mapping-marker[aria-label*="female-chin"] {
    min-width: 65px;
    min-height: 40px;
  }
}

.gallery .emu-mapping-marker[aria-label*="female-flanks"] {
  min-width: 24px;
  min-height: 35px;
}

@media (min-width: 768px) {
  .gallery .emu-mapping-marker[aria-label*="female-flanks"] {
    min-width: 26px;
    min-height: 54px;
  }
}

.gallery .emu-mapping-marker[aria-label*="female-glutes"] {
  min-width: 75px;
  min-height: 23px;
}

@media (min-width: 768px) {
  .gallery .emu-mapping-marker[aria-label*="female-glutes"] {
    min-width: 130px;
    min-height: 40px;
  }
}

.gallery .emu-mapping-marker[aria-label*="female-thighs"] {
  min-width: 60px;
  min-height: 38px;
}

@media (min-width: 768px) {
  .gallery .emu-mapping-marker[aria-label*="female-thighs"] {
    min-width: 103px;
    min-height: 66px;
  }
}

.gallery .emu-mapping-marker[aria-label*="female-upperarms"] {
  min-width: 21px;
  min-height: 28px;
}

@media (min-width: 768px) {
  .gallery .emu-mapping-marker[aria-label*="female-upperarms"] {
    min-width: 30px;
    min-height: 45px;
  }
}

.gallery .emu-mapping-marker[aria-label*="-male-abdomen"] {
  min-width: 40px;
  min-height: 56px;
}

@media (min-width: 768px) {
  .gallery .emu-mapping-marker[aria-label*="-male-abdomen"] {
    min-width: 72px;
    min-height: 94px;
  }
}

.gallery .emu-mapping-marker[aria-label*="-male-backbrafat"] {
  min-width: 62px;
  min-height: 22px;
}

@media (min-width: 768px) {
  .gallery .emu-mapping-marker[aria-label*="-male-backbrafat"] {
    min-width: 107px;
    min-height: 40px;
  }
}

.gallery .emu-mapping-marker[aria-label*="-male-bananaroll"] {
  min-width: 69px;
  min-height: 21px;
}

@media (min-width: 768px) {
  .gallery .emu-mapping-marker[aria-label*="-male-bananaroll"] {
    min-width: 120px;
    min-height: 36px;
  }
}

.gallery .emu-mapping-marker[aria-label*="-male-chin"] {
  min-width: 36px;
  min-height: 24px;
}

@media (min-width: 768px) {
  .gallery .emu-mapping-marker[aria-label*="-male-chin"] {
    min-width: 65px;
    min-height: 40px;
  }
}

.gallery .emu-mapping-marker[aria-label*="-male-flanks"] {
  min-width: 20px;
  min-height: 42px;
}

@media (min-width: 768px) {
  .gallery .emu-mapping-marker[aria-label*="-male-flanks"] {
    min-width: 23px;
    min-height: 76px;
  }
}

.gallery .emu-mapping-marker[aria-label*="-male-glutes"] {
  min-width: 71px;
  min-height: 22px;
}

@media (min-width: 768px) {
  .gallery .emu-mapping-marker[aria-label*="-male-glutes"] {
    min-width: 122px;
    min-height: 38px;
  }
}

.gallery .emu-mapping-marker[aria-label*="-male-thighs"] {
  min-width: 59px;
  min-height: 38px;
}

@media (min-width: 768px) {
  .gallery .emu-mapping-marker[aria-label*="-male-thighs"] {
    min-width: 103px;
    min-height: 65px;
  }
}

.gallery .emu-mapping-marker[aria-label*="-male-upperarms"] {
  min-width: 22px;
  min-height: 38px;
}

@media (min-width: 768px) {
  .gallery .emu-mapping-marker[aria-label*="-male-upperarms"] {
    min-width: 30px;
    min-height: 45px;
  }
}

.gallery.cs-loader__wrapper--show .gallery__carousel {
  visibility: hidden;
}

.gallery--tabs-ready.gallery .gallery__filters-img-tabs {
  display: block;
}

.gallery--tabs-ready.gallery .gallery__label-container {
  display: flex;
}

@media (min-width: 1200px) {
  .gallery--tabs-ready.gallery .gallery__label-container {
    display: none;
  }
}

.gallery--has-error .gallery__carousel-wrapper {
  display: none;
}

.gallery__inner {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 76px 13px 71px;
}

@media (min-width: 414px) {
  .gallery__inner {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (min-width: 576px) {
  .gallery__inner {
    padding-left: 13px;
    padding-right: 13px;
  }
}

@media (min-width: 768px) {
  .gallery__inner {
    padding: 123px 84px 126px;
  }
}

@media (min-width: 1024px) {
  .gallery__inner {
    padding-bottom: 114px;
  }
}

@media (min-width: 1200px) {
  .gallery__inner {
    padding-top: 90px;
    padding-right: 80px;
    padding-bottom: var(--emu-common-spacing-large);
    padding-left: 80px;
  }
}

.gallery__title {
  margin-top: 3px;
  margin-bottom: 6px;
  margin-right: 10px;
  padding-left: 30px;
  padding-right: 30px;
}

@media (min-width: 321px) {
  .gallery__title {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 414px) {
  .gallery__title {
    margin-top: var(--emu-common-spacing-xs);
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

@media (min-width: 768px) {
  .gallery__title {
    margin-top: 6px;
    margin-bottom: 5px;
  }
}

@media (min-width: 1200px) {
  .gallery__title {
    margin-top: 5px;
    margin-bottom: 6px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .gallery__title .cmp-title__text {
    font-size: 46px;
    line-height: 50px;
  }
}

@media (min-width: 1200px) {
  .gallery__title .cmp-title__text {
    text-align: center;
  }
}

.gallery__desc {
  margin-bottom: 50px;
  padding-left: 30px;
  padding-right: 30px;
}

@media (min-width: 321px) {
  .gallery__desc {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 414px) {
  .gallery__desc {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .gallery__desc {
    font-size: 22px;
    line-height: 40px;
  }
}

@media (min-width: 768px) {
  .gallery__desc {
    margin-bottom: 76px;
  }
}

@media (min-width: 1025px) {
  .gallery__desc {
    font-size: 28px;
    line-height: 42px;
  }
}

@media (min-width: 1200px) {
  .gallery__desc {
    text-align: center;
    font-size: var(--emu-semantic-font-sizes-wide-small);
    line-height: var(--emu-semantic-line-heights-wide-small);
    margin-bottom: 54px;
    padding-left: 18%;
    padding-right: 18%;
  }

  .gallery__filters {
    align-items: center;
    margin-bottom: 70px;
    display: flex;
  }

  .cq-Editable-dom--container .gallery__filters {
    display: block;
  }

  .gallery__filters > .container:first-child {
    width: 70%;
  }
}

.cq-Editable-dom--container .gallery__filters > .container:first-child {
  width: 100%;
}

@media (min-width: 1200px) {
  .gallery__filters > .container:last-child {
    justify-self: flex-end;
    width: 180px;
    margin-left: auto;
  }
}

.cq-Editable-dom--container .gallery__filters > .container:last-child {
  width: 100%;
}

@media (min-width: 1200px) {
  .gallery__filters-section--left {
    display: flex;
  }
}

.cq-Editable-dom--container .gallery__filters-section--left {
  display: block;
}

.gallery__filters-cta .emu-button-v2.emu-button.emu-button__primary-filled.emu-button-v2.emu-button.emu-button__primary-filled .cmp-button__text {
  top: 1px;
}

.gallery__filters-cta.emu-button.emu-button-v2.emu-button-v2 {
  border-radius: var(--emu-common-border-radius-none);
  padding: var(--emu-common-spacing-none);
  width: 100%;
  height: 40px;
  font-size: 12px;
  line-height: 40px;
}

@media (min-width: 768px) {
  .gallery__filters-cta.emu-button.emu-button-v2.emu-button-v2 {
    height: var(--emu-common-sizing-large);
    font-size: 28px;
    line-height: 64px;
  }
}

@media (min-width: 1200px) {
  .gallery__filters-cta.emu-button.emu-button-v2.emu-button-v2 {
    height: 50px;
    font-size: 18px;
    line-height: 50px;
  }
}

.gallery__filters-cta--inactive {
  opacity: .25;
}

.gallery__filters-cta-container {
  border-radius: 12px;
  margin-bottom: 25px;
  display: flex;
  overflow: hidden;
}

@media (min-width: 768px) {
  .gallery__filters-cta-container {
    margin-bottom: 45px;
  }
}

@media (min-width: 1200px) {
  .gallery__filters-cta-container {
    margin-bottom: 22px;
    margin-left: 20px;
    margin-right: 20px;
  }
}

.gallery__filters-cta-container .button {
  width: 50%;
}

@media (min-width: 1200px) {
  .gallery__filters-cta-container .button {
    width: 194px;
  }
}

.gallery__filters-dropdown {
  width: 90%;
}

@media (min-width: 576px) {
  .gallery__filters-dropdown {
    width: auto;
  }
}

@media (min-width: 768px) {
  .gallery__filters-dropdown {
    width: 270px;
  }
}

@media (min-width: 1024px) {
  .gallery__filters-dropdown {
    width: 385px;
  }
}

.gallery__filters-dropdown--gender [aria-selected="true"] {
  display: none;
}

.gallery__filters-dropdown-container {
  justify-content: center;
  margin-bottom: 70px;
  display: flex;
}

@media (min-width: 768px) {
  .gallery__filters-dropdown-container {
    margin-bottom: 90px;
  }
}

@media (min-width: 1200px) {
  .gallery__filters-dropdown-container {
    margin-bottom: var(--emu-common-spacing-none);
  }
}

.gallery__filters-dropdown-container > .options {
  width: 50%;
  display: flex;
}

@media (min-width: 1200px) {
  .gallery__filters-dropdown-container > .options {
    width: 172px;
    margin-bottom: 22px;
    margin-left: 20px;
    margin-right: 20px;
  }
}

.gallery__filters-dropdown-container > .options:last-child {
  justify-content: flex-end;
}

.gallery__filters-img-tabs {
  min-height: 410px;
  margin-bottom: 52px;
  display: none;
}

@media (min-width: 768px) {
  .gallery__filters-img-tabs {
    min-height: 710px;
  }
}

@media (min-width: 1200px) {
  .gallery__filters-img-tabs {
    margin-bottom: var(--emu-common-spacing-none);
    min-height: 170px;
  }
}

.cq-Editable-dom--container .gallery__filters-img-tabs, .gallery__filters-img-tabs .aaaem-tabs__tablist {
  display: none;
}

.cq-Editable-dom--container .gallery__filters-img-tabs .aaaem-tabs__tablist {
  display: block;
}

.gallery__filters-img-tabs img {
  aspect-ratio: 165 / 410;
  object-fit: contain;
  width: 165px;
  height: 410px;
}

@media (min-width: 768px) {
  .gallery__filters-img-tabs img {
    width: 286px;
    height: 710px;
  }
}

@media (min-width: 1200px) {
  .gallery__filters-img-tabs img {
    width: 68px;
    height: 170px;
  }
}

.cq-Editable-dom--container .gallery__filters-img-tabs img {
  height: 400px;
}

.gallery__filters-img-container.aaaem-container {
  justify-content: center;
  display: flex;
}

@media (min-width: 1200px) {
  .gallery__filters-img-container.aaaem-container {
    justify-content: flex-end;
    margin-right: 20px;
  }

  .gallery__filters-img-container > .image:not(:last-child) {
    margin-right: var(--emu-common-spacing-xs);
  }
}

.gallery__label {
  padding-top: var(--emu-common-spacing-small);
  padding-bottom: var(--emu-common-spacing-small);
}

@media (min-width: 576px) {
  .gallery__label {
    padding-top: 12px;
  }
}

@media (min-width: 768px) {
  .gallery__label {
    padding-top: var(--emu-common-spacing-xs);
  }
}

@media (min-width: 1024px) {
  .gallery__label {
    padding-top: var(--emu-common-spacing-none);
  }
}

.gallery__label .cmp-title__text {
  letter-spacing: .65px;
  font-size: 11px;
}

@media (min-width: 414px) {
  .gallery__label .cmp-title__text {
    font-size: 13px;
  }
}

@media (min-width: 576px) {
  .gallery__label .cmp-title__text {
    font-size: 19px;
  }
}

@media (min-width: 768px) {
  .gallery__label .cmp-title__text {
    font-size: 27px;
  }
}

.gallery__label-container {
  justify-content: center;
  margin-bottom: 60px;
  display: none;
}

@media (min-width: 414px) {
  .gallery__label-container {
    margin-bottom: 63px;
  }
}

@media (min-width: 576px) {
  .gallery__label-container {
    margin-bottom: 72px;
  }
}

@media (min-width: 768px) {
  .gallery__label-container {
    margin-bottom: 66px;
  }
}

@media (min-width: 1024px) {
  .gallery__label-container {
    margin-bottom: 57px;
  }
}

@media (min-width: 1200px) {
  .gallery__label-container {
    display: none;
  }
}

.cq-Editable-dom--container .gallery__label-container {
  display: flex !important;
}

.gallery__label-container > .title {
  width: 50%;
}

@media (min-width: 1024px) {
  .gallery__label-container > .title {
    max-width: 288px;
  }
}

.gallery__carousel-label {
  margin-bottom: 22px;
  display: none;
}

@media (min-width: 768px) {
  .gallery__carousel-label {
    margin-bottom: 25px;
  }
}

@media (min-width: 1200px) {
  .gallery__carousel-label {
    margin-bottom: 19px;
  }
}

.cq-Editable-dom--container .gallery__carousel-label, .gallery__carousel-label--show {
  display: block;
}

.gallery__carousel-label .cmp-title__text {
  font-size: 14px;
  line-height: 1;
}

@media (min-width: 768px) {
  .gallery__carousel-label .cmp-title__text {
    font-size: 30px;
  }
}

@media (min-width: 1200px) {
  .gallery__carousel-label .cmp-title__text {
    padding-left: 10px;
    font-size: 16px;
  }
}

.gallery__carousel .aaaem-carousel__action {
  height: 41px;
  top: 50%;
  transform: translateY(-50%)translateY(-73px);
}

@media (min-width: 768px) {
  .gallery__carousel .aaaem-carousel__action {
    transform: translateY(-50%)translateY(-79px);
  }
}

@media (min-width: 1200px) {
  .gallery__carousel .aaaem-carousel__action {
    top: 54%;
    transform: translateY(-50%)translateY(-81px);
  }
}

.gallery__carousel .aaaem-carousel__action:before {
  background-image: url("resources/images/gallery-next-arrow.png");
  width: 18px;
  height: 25px;
}

.gallery__carousel .aaaem-carousel__action-next {
  right: -6px;
}

@media (min-width: 768px) {
  .gallery__carousel .aaaem-carousel__action-next {
    right: -26px;
  }
}

.gallery__carousel .aaaem-carousel__action-prev {
  left: -6px;
}

@media (min-width: 768px) {
  .gallery__carousel .aaaem-carousel__action-prev {
    left: -26px;
  }
}

.gallery__carousel-slide-main {
  margin-left: 20px;
  margin-right: 20px;
}

@media (min-width: 1200px) {
  .gallery__carousel-slide-main {
    justify-content: center;
    display: flex;
  }

  .gallery__carousel-slide-main:has(.container:nth-child(2):last-child) {
    justify-content: space-between;
  }

  .gallery__carousel-slide-main > .container {
    flex: 0 0 320px;
  }

  .gallery__carousel-slide-main > .container:not(:last-child) {
    margin-right: 10px;
  }
}

.gallery__carousel-ba-container {
  margin-bottom: 23px;
}

@media (min-width: 768px) {
  .gallery__carousel-ba-container {
    margin-bottom: 39px;
  }
}

@media (min-width: 1200px) {
  .gallery__carousel-ba-container {
    margin-bottom: var(--emu-common-spacing-none);
  }
}

.gallery__carousel-ba-img, .gallery__carousel-ba-img img {
  aspect-ratio: 419 / 290;
}

.gallery__carousel-ba-img img {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .gallery__carousel-ba-img img {
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .gallery__carousel-ba-img img {
    max-width: 90%;
  }
}

@media (min-width: 1200px) {
  .gallery__carousel-ba-img img {
    max-width: 100%;
  }
}

.gallery__carousel-ba-img img.cmp-image__image--is-loading {
  max-height: var(--emu-common-sizing-none);
}

.gallery__carousel-ba-img .cmp-image__title {
  text-align: center;
  font-family: var(--emu-semantic-font-families-heading);
  margin-top: 27px;
  font-size: 13px;
  line-height: 1;
  display: block;
}

@media (min-width: 414px) {
  .gallery__carousel-ba-img .cmp-image__title {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .gallery__carousel-ba-img .cmp-image__title {
    margin-top: 20px;
    font-size: 30px;
  }
}

@media (min-width: 1200px) {
  .gallery__carousel-ba-img .cmp-image__title {
    margin-top: 27px;
    font-size: 14px;
  }
}

.gallery__carousel-ba-disc {
  margin-top: var(--emu-common-spacing-xxs);
  letter-spacing: .1px;
  font-size: 12px;
}

@media (min-width: 768px) {
  .gallery__carousel-ba-disc {
    font-size: 20px;
    font-family: var(--emu-semantic-font-families-heading);
    margin-top: 53px;
  }
}

@media (min-width: 1024px) {
  .gallery__carousel-ba-disc {
    margin-top: 50px;
  }
}

@media (min-width: 1200px) {
  .gallery__carousel-ba-disc {
    font-size: 11px;
    font-family: var(--emu-semantic-font-families-body);
    margin-top: -2px;
  }
}

@media (min-width: 768px) {
  .gallery__carousel-ba-disc sup {
    font-size: 72%;
    top: -.1em;
  }
}

@media (min-width: 1024px) {
  .gallery__carousel-ba-disc sup {
    top: .15em;
  }
}

@media (min-width: 1200px) {
  .gallery__carousel-ba-disc sup {
    top: 0;
  }
}

.gallery__carousel-ba-disc p, .gallery__carousel-ba-disc li {
  font-size: inherit;
  letter-spacing: inherit;
  font-family: inherit;
}

.gallery__carousel-disclaimer {
  margin-top: 23px;
  font-size: 12px;
}

@media (min-width: 768px) {
  .gallery__carousel-disclaimer {
    margin-top: -25px;
    font-size: 20px;
  }
}

@media (min-width: 1024px) {
  .gallery__carousel-disclaimer {
    margin-top: -30px;
  }
}

@media (min-width: 1200px) {
  .gallery__carousel-disclaimer {
    margin-top: 25px;
    font-size: 12px;
  }
}

@media (min-width: 768px) {
  .gallery__carousel-disclaimer h5 {
    line-height: 1.5;
  }
}

.gallery__carousel-disclaimer h5 .emphasis {
  display: inline-block;
}

.gallery__carousel-disclaimer p:has(.emphasis) {
  margin-top: var(--emu-common-spacing-xxs);
}

@media (min-width: 768px) {
  .gallery__carousel-disclaimer p:has(.emphasis) {
    margin-top: var(--emu-common-spacing-medium);
  }
}

@media (min-width: 1024px) {
  .gallery__carousel-disclaimer p:has(.emphasis) {
    margin-top: 12px;
  }
}

@media (min-width: 1200px) {
  .gallery__carousel-disclaimer p:has(.emphasis) {
    margin-top: -4px;
  }
}

.gallery__carousel-disclaimer p, .gallery__carousel-disclaimer li, .gallery__carousel-disclaimer h5 {
  font-size: inherit;
  line-height: inherit;
}

.gallery__error {
  font-size: 16px;
  line-height: 24px;
  font-family: var(--emu-semantic-font-families-heading);
  color: red;
  margin-top: -11px;
  margin-bottom: 20px;
  padding-left: 10px;
  font-weight: 600;
  display: none;
}

@media (min-width: 768px) {
  .gallery__error {
    margin-top: -7px;
    margin-bottom: 13px;
  }
}

@media (min-width: 1024px) {
  .gallery__error {
    margin-top: -6px;
    margin-bottom: 24px;
  }
}

@media (min-width: 1200px) {
  .gallery__error {
    margin-top: -4px;
    margin-bottom: 23px;
  }
}

.cq-Editable-dom--container .gallery__error, .gallery__error--show {
  display: block;
}

.gallery__error p {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  font-family: inherit;
}

.banner {
  z-index: var(--emu-common-other-z-index-layer);
  position: relative;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .banner--has-additional-img.banner--has-sm-img .banner__teaser:not(.cq-Editable-dom--container .banner--has-additional-img.banner--has-sm-img .banner__teaser) {
    background-image: none !important;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .banner--has-additional-img.banner--has-md-img .banner__teaser:not(.cq-Editable-dom--container .banner--has-additional-img.banner--has-md-img .banner__teaser) {
    background-image: none !important;
  }
}

@media (min-width: 1401px) {
  .banner--has-additional-img.banner--has-1401-img .banner__teaser:not(.cq-Editable-dom--container .banner--has-additional-img.banner--has-1401-img .banner__teaser) {
    background-image: none !important;
  }

  .banner--has-additional-img .banner__img.banner__img--1401 img {
    object-position: center center;
    display: block;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .banner--has-additional-img .banner__img.banner__img--sm img {
    display: block;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .banner--has-additional-img .banner__img.banner__img--md img {
    display: block;
  }
}

.banner--has-additional-img .banner__img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.cq-Editable-dom--container .banner--has-additional-img .banner__img img {
  display: block;
  position: static;
  object-fit: contain !important;
  object-position: center !important;
  max-height: 600px !important;
}

.banner__teaser {
  background-repeat: no-repeat;
  background-size: cover;
  height: 650px;
}

.cq-Editable-dom--container .banner__teaser {
  background-position: center !important;
}

@media (min-width: 321px) {
  .banner__teaser {
    height: 765px;
  }
}

@media (min-width: 414px) {
  .banner__teaser {
    height: 845px;
  }
}

@media (min-width: 768px) {
  .banner__teaser {
    background-position: bottom;
    height: 1565px;
  }
}

@media (min-width: 1024px) {
  .banner__teaser {
    height: 2080px;
  }
}

@media (min-width: 1200px) {
  .banner__teaser {
    background-position: initial;
    height: 800px;
  }
}

@media (min-width: 1401px) {
  .banner__teaser {
    height: 850px;
  }
}

@media (min-width: 1601px) {
  .banner__teaser {
    height: 1100px;
  }
}

@media (min-width: 2001px) {
  .banner__teaser {
    height: 1400px;
  }
}

.banner__teaser .content-container {
  position: static;
}

.banner__teaser .disclaimer-bottom-right .emu-teaser__disclaimer {
  text-align: center;
  font-size: 10px;
  font-weight: var(--emu-semantic-font-weight-500);
  text-shadow: -1px 2px 9px #000c;
  letter-spacing: .025px;
  width: 100%;
  line-height: 135%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.banner__teaser .disclaimer-bottom-right .emu-teaser__disclaimer p {
  font-size: inherit;
  line-height: inherit;
  text-shadow: inherit;
  letter-spacing: inherit;
}

.banner__teaser .disclaimer-bottom-right .emu-teaser__disclaimer p sup {
  font-size: 60%;
  top: 1px;
}

@media (min-width: 1200px) {
  .banner__teaser .disclaimer-bottom-right .emu-teaser__disclaimer p sup {
    top: 2px;
  }
}

.banner__content {
  width: 100%;
  padding-top: 3px;
  position: absolute;
  top: 40%;
  left: 0;
}

.cq-Editable-dom--container .banner__content {
  padding-top: var(--emu-common-spacing-none) !important;
  position: static !important;
}

@media (min-width: 414px) {
  .banner__content {
    padding-top: var(--emu-common-spacing-xs);
  }
}

@media (min-width: 768px) {
  .banner__content {
    padding-top: 6px;
  }
}

@media (min-width: 1025px) {
  .banner__content {
    top: 35%;
  }
}

@media (min-width: 1024px) {
  .banner__content {
    padding-top: 5px;
  }
}

.cq-Editable-dom--container .banner__content {
  background-color: var(--emu-semantic-colors-primary-100);
  position: static;
}

.banner__content > .container {
  width: 100%;
}

.cq-Editable-dom--container .banner__content > .container, .cq-Editable-dom--container .banner__content-inner {
  padding-top: var(--emu-common-spacing-none) !important;
  position: static !important;
}

.banner__content-title {
  max-width: 475px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .banner__content-title {
    max-width: 550px;
  }
}

.banner__content-title .cmp-title__text {
  letter-spacing: 0;
  font-size: 28px;
  line-height: 34px;
  font-weight: var(--emu-common-font-weight-black);
}

@media (min-width: 321px) {
  .banner__content-title .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-narrow-large);
    line-height: var(--emu-semantic-line-heights-narrow-large);
  }
}

@media (min-width: 414px) {
  .banner__content-title .cmp-title__text {
    font-size: 33px;
    line-height: var(--emu-common-line-heights-narrow-xl);
  }
}

@media (min-width: 768px) {
  .banner__content-title .cmp-title__text {
    font-size: 64px;
    line-height: 69px;
  }
}

@media (min-width: 1025px) {
  .banner__content-title .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
    line-height: var(--emu-semantic-font-sizes-wide-xxl);
  }
}

.banner__content-text {
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  line-height: var(--emu-semantic-line-heights-narrow-small);
  max-width: 575px;
  margin-top: 12px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 25px;
  padding-right: 25px;
}

@media (min-width: 768px) {
  .banner__content-text {
    max-width: 730px;
  }
}

@media (min-width: 414px) {
  .banner__content-text {
    font-size: var(--emu-semantic-font-sizes-narrow-medium);
    line-height: 29px;
  }
}

@media (min-width: 768px) {
  .banner__content-text {
    margin-top: 15px;
    font-size: 28px;
    line-height: 42px;
  }
}

@media (min-width: 1025px) {
  .banner__content-text {
    font-size: var(--emu-semantic-font-sizes-wide-small);
    line-height: var(--emu-semantic-line-heights-wide-small);
  }
}

.banner__content-text > :not(sup) {
  font-size: inherit;
  line-height: inherit;
}

.banner__content-text br {
  display: none;
}

@media (min-width: 768px) {
  .banner__content-text br {
    display: block;
  }
}

.banner__content-text sup {
  font-size: 60%;
  top: 1px;
}

@media (min-width: 768px) {
  .banner__content-text sup {
    top: 2px;
  }
}

@media (min-width: 1024px) {
  .banner__content-text-container {
    padding-left: 15px;
  }
}

@media (min-width: 1199px) {
  .banner__content-text-container {
    padding-right: 73px;
  }
}

@media (min-width: 1200px) {
  .banner__content-text-container {
    padding-left: 73px;
  }
}

.banner__content-cta-container {
  justify-content: center;
  margin-top: 14px;
  display: flex;
}

.cq-Editable-dom--container .banner__content-cta-container {
  display: block !important;
}

.cq-Editable-dom--container .banner__content-cta-container > .button {
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 414px) {
  .banner__content-cta-container {
    margin-top: 13px;
  }
}

@media (min-width: 768px) {
  .banner__content-cta-container {
    margin-top: 25px;
  }
}

@media (min-width: 1025px) {
  .banner__content-cta-container {
    margin-top: 10px;
  }
}

.banner.banner--fap {
  overflow: hidden;
}

.banner.banner--fap .banner__teaser {
  background-position: center;
  height: 690px;
  margin-left: -15px;
  margin-right: -15px;
}

@media (min-width: 321px) {
  .banner.banner--fap .banner__teaser {
    height: 840px;
  }
}

@media (min-width: 414px) {
  .banner.banner--fap .banner__teaser {
    height: 890px;
  }
}

@media (min-width: 768px) {
  .banner.banner--fap .banner__teaser {
    height: 1560px;
  }
}

@media (min-width: 1024px) {
  .banner.banner--fap .banner__teaser {
    height: 2130px;
  }
}

@media (min-width: 1200px) {
  .banner.banner--fap .banner__teaser {
    height: 755px;
  }
}

@media (min-width: 1401px) {
  .banner.banner--fap .banner__teaser {
    height: 1000px;
  }
}

@media (min-width: 1601px) {
  .banner.banner--fap .banner__teaser {
    height: 1250px;
  }
}

@media (min-width: 2001px) {
  .banner.banner--fap .banner__teaser {
    height: 1500px;
  }
}

.banner.banner--fap .banner__teaser .emu-teaser__disclaimer {
  padding-left: 65px;
  padding-right: 65px;
}

.banner.banner--fap .banner__content {
  padding-left: 15px;
  padding-right: 15px;
  top: 0;
}

@media (min-width: 350px) {
  .banner.banner--fap .banner__content {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

@media (min-width: 577px) {
  .banner.banner--fap .banner__content {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1024px) {
  .banner.banner--fap .banner__content {
    padding-right: var(--emu-common-spacing-none);
    padding-top: var(--emu-common-spacing-small);
    max-width: var(--emu-common-sizing-desktop);
    margin-left: auto;
    margin-right: auto;
    padding-top: 5px;
    top: 15%;
    left: 0;
    right: 0;
  }
}

@media (min-width: 1199px) {
  .banner.banner--fap .banner__content {
    padding-top: 6px;
    padding-right: 73px;
  }
}

@media (min-width: 1200px) {
  .banner.banner--fap .banner__content {
    padding-top: 5px;
    padding-left: 73px;
  }
}

@media (min-width: 1401px) {
  .banner.banner--fap .banner__content {
    padding-top: 6px;
  }
}

@media (min-width: 1601px) {
  .banner.banner--fap .banner__content {
    padding-top: 5px;
  }
}

.banner.banner--fap .banner__content-inner {
  padding-top: 70%;
}

@media (min-width: 321px) {
  .banner.banner--fap .banner__content-inner {
    padding-top: 80%;
  }
}

@media (min-width: 768px) {
  .banner.banner--fap .banner__content-inner {
    padding-top: 84%;
  }
}

@media (min-width: 1024px) {
  .banner.banner--fap .banner__content-inner {
    padding-top: 54%;
  }
}

@media (min-width: 1200px) {
  .banner.banner--fap .banner__content-inner {
    padding-top: 16%;
  }
}

@media (min-width: 1401px) {
  .banner.banner--fap .banner__content-inner {
    padding-top: 22.23%;
  }
}

@media (min-width: 1601px) {
  .banner.banner--fap .banner__content-inner {
    padding-top: 28%;
  }
}

.banner.banner--fap .banner__content-title {
  max-width: none;
}

@media (min-width: 1401px) {
  .banner.banner--fap .banner__content-title {
    margin-top: -2px;
  }
}

.banner.banner--fap .banner__content-text {
  max-width: none;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 414px) {
  .banner.banner--fap .banner__content-text {
    margin-top: 13px;
  }
}

@media (min-width: 577px) {
  .banner.banner--fap .banner__content-text {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 768px) {
  .banner.banner--fap .banner__content-text {
    max-width: 675px;
    margin-top: 11px;
  }
}

@media (min-width: 1024px) {
  .banner.banner--fap .banner__content-text {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    max-width: 675px;
  }
}

@media (min-width: 1025px) {
  .banner.banner--fap .banner__content-text {
    max-width: 700px;
    margin-top: 13px;
  }
}

@media (min-width: 1601px) {
  .banner.banner--fap .banner__content-text {
    margin-top: 12px;
  }
}

@media (min-width: 1024px) {
  .banner.banner--fap .banner__content-text-container {
    padding-left: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1199px) {
  .banner.banner--fap .banner__content-text-container {
    padding-right: var(--emu-common-spacing-none);
  }
}

.banner.banner--fap .banner__content-cta-container {
  margin-top: 25px;
}

@media (min-width: 414px) {
  .banner.banner--fap .banner__content-cta-container {
    margin-top: 23px;
  }
}

@media (min-width: 768px) {
  .banner.banner--fap .banner__content-cta-container {
    margin-top: 22px;
  }
}

.banner.banner--main {
  overflow: hidden;
}

.banner.banner--main .banner__teaser {
  background-position: center;
  height: 565px;
}

@media (min-width: 321px) {
  .banner.banner--main .banner__teaser {
    height: 700px;
  }
}

@media (min-width: 414px) {
  .banner.banner--main .banner__teaser {
    height: 775px;
  }
}

@media (min-width: 768px) {
  .banner.banner--main .banner__teaser {
    background-position: 50% 0;
    height: 1432px;
  }
}

@media (min-width: 1024px) {
  .banner.banner--main .banner__teaser {
    height: 1910px;
  }
}

@media (min-width: 1200px) {
  .banner.banner--main .banner__teaser {
    background-position: center;
    height: 656px;
  }
}

@media (min-width: 1401px) {
  .banner.banner--main .banner__teaser {
    height: 660px;
  }
}

@media (min-width: 1601px) {
  .banner.banner--main .banner__teaser {
    height: 750px;
  }
}

@media (min-width: 2001px) {
  .banner.banner--main .banner__teaser {
    height: 900px;
  }
}

@media (min-width: 768px) {
  .banner.banner--main .banner__teaser .disclaimer-bottom-right .emu-teaser__disclaimer {
    padding-bottom: 20px;
  }
}

@media (min-width: 1025px) {
  .banner.banner--main .banner__teaser .disclaimer-bottom-right .emu-teaser__disclaimer {
    padding-bottom: 10px;
  }
}

.banner.banner--main .banner__content {
  padding-top: 42%;
  top: 0;
}

@media (min-width: 350px) {
  .banner.banner--main .banner__content {
    padding-top: 70%;
  }
}

@media (min-width: 768px) {
  .banner.banner--main .banner__content {
    padding-top: 48%;
  }
}

@media (min-width: 1024px) {
  .banner.banner--main .banner__content {
    padding-top: 72%;
  }
}

@media (min-width: 1025px) {
  .banner.banner--main .banner__content {
    padding-left: 15px;
    padding-top: var(--emu-common-spacing-medium);
    width: 100%;
    position: absolute;
    top: 5%;
    left: 0;
  }
}

@media (min-width: 1200px) {
  .banner.banner--main .banner__content {
    padding-left: var(--emu-common-spacing-none);
  }
}

@media (min-width: 768px) {
  .banner.banner--main .banner__content-inner {
    padding-left: 90px;
    padding-right: 90px;
  }
}

@media (min-width: 1025px) {
  .banner.banner--main .banner__content-inner {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    max-width: var(--emu-common-sizing-desktop);
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
  .banner.banner--main .banner__content-inner {
    padding-left: 73px;
    padding-right: 73px;
  }
}

.banner.banner--main .banner__content-img-container {
  padding: 15px 10px;
}

@media (min-width: 576px) {
  .banner.banner--main .banner__content-img-container {
    padding-bottom: var(--emu-common-spacing-none);
    padding-top: var(--emu-common-spacing-none);
  }
}

@media (min-width: 768px) {
  .banner.banner--main .banner__content-img-container {
    justify-content: center;
    align-items: center;
    padding-left: 10%;
    padding-right: 10%;
    display: flex;
  }
}

.banner.banner--main .banner__content-img-container > .image {
  vertical-align: middle;
  display: inline-block;
}

.banner.banner--main .banner__content-img img {
  max-width: 65%;
  padding-left: 30px;
  padding-right: 15px;
}

@media (min-width: 350px) {
  .banner.banner--main .banner__content-img img {
    padding-left: 45px;
    padding-right: var(--emu-common-spacing-none);
    max-width: 60%;
  }
}

@media (min-width: 414px) {
  .banner.banner--main .banner__content-img img {
    max-width: 65%;
  }
}

@media (min-width: 768px) {
  .banner.banner--main .banner__content-img img {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    width: auto;
    max-width: none;
    height: 296px;
    margin-left: -25px;
  }
}

@media (min-width: 1025px) {
  .banner.banner--main .banner__content-img img {
    margin-left: var(--emu-common-spacing-none);
    max-width: 600px;
    height: auto;
  }
}

.banner.banner--main .banner__content-title {
  max-width: none;
  margin-top: -28px;
  padding-left: 30px;
  padding-right: 10px;
}

@media (min-width: 350px) {
  .banner.banner--main .banner__content-title {
    margin-top: -23px;
    padding-left: 45px;
  }
}

@media (min-width: 414px) {
  .banner.banner--main .banner__content-title {
    margin-top: -26px;
  }
}

@media (min-width: 768px) {
  .banner.banner--main .banner__content-title {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    margin-top: -18px;
  }
}

@media (min-width: 1025px) {
  .banner.banner--main .banner__content-title {
    margin-top: -95px;
    padding-left: 35px;
  }
}

.cq-Editable-dom--container .banner.banner--main .banner__content-title {
  margin-top: var(--emu-common-spacing-none) !important;
}

@media (min-width: 321px) and (max-width: 349px) {
  .banner.banner--main .banner__content-title .cmp-title__text {
    font-size: 28px;
    line-height: 34px;
  }
}

@media (min-width: 768px) {
  .banner.banner--main .banner__content-title .cmp-title__text {
    margin-top: var(--emu-common-spacing-none);
  }
}

@media (min-width: 767px) {
  .banner.banner--main .banner__content-title br:nth-child(2) {
    display: none;
  }
}

.banner.banner--main .banner__content-text {
  max-width: none;
  margin-top: 7px;
  padding-left: 30px;
  padding-right: 15px;
}

@media (min-width: 350px) {
  .banner.banner--main .banner__content-text {
    padding-left: 45px;
  }
}

@media (min-width: 768px) {
  .banner.banner--main .banner__content-text {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    margin-top: 13px;
  }
}

@media (min-width: 1025px) {
  .banner.banner--main .banner__content-text {
    padding-left: 35px;
  }
}

.banner.banner--main .banner__content-text-container {
  padding-left: var(--emu-common-spacing-none);
}

.banner.banner--main .banner__content-text br {
  display: none;
}

@media (min-width: 1024px) {
  .banner.banner--main .banner__content-text br {
    display: block;
  }
}

.banner.banner--main .banner__content-text sup {
  top: 2px;
}

.banner.banner--main .banner__content-cta-container {
  justify-content: flex-start;
  margin-top: 15px;
  padding-left: 35px;
}

@media (min-width: 321px) {
  .banner.banner--main .banner__content-cta-container {
    padding-left: 45px;
  }
}

@media (min-width: 768px) {
  .banner.banner--main .banner__content-cta-container {
    padding-left: var(--emu-common-spacing-none);
    margin-top: 22px;
  }
}

@media (min-width: 1025px) {
  .banner.banner--main .banner__content-cta-container {
    margin-top: 13px;
    padding-left: 35px;
  }
}

.banner.banner--main .banner__content-cta.emu-button-v2.emu-button.emu-button-v2.emu-button {
  letter-spacing: .1px;
  padding-top: var(--emu-common-spacing-none);
  padding-bottom: var(--emu-common-spacing-none);
  height: 59px;
  padding-left: 39px;
  padding-right: 39px;
  font-size: 114%;
  line-height: 59px;
}

@media (min-width: 321px) {
  .banner.banner--main .banner__content-cta.emu-button-v2.emu-button.emu-button-v2.emu-button {
    line-height: var(--emu-common-line-heights-narrow-xxxl);
    height: 54px;
  }
}

@media (min-width: 414px) {
  .banner.banner--main .banner__content-cta.emu-button-v2.emu-button.emu-button-v2.emu-button {
    height: 59px;
    line-height: 59px;
  }
}

@media (min-width: 768px) {
  .banner.banner--main .banner__content-cta.emu-button-v2.emu-button.emu-button-v2.emu-button {
    padding-left: 43px;
    padding-right: 43px;
    font-size: 24px;
  }
}

.banner.banner--main .banner__content-cta.emu-button-v2.emu-button.emu-button-v2.emu-button .cmp-button__text {
  top: 0;
}

@media (pointer: fine) {
  .button .emu-button-v2.emu-button.emu-button__primary-filled:not(.u-no-gradient-btn):hover .cmp-button__text {
    background: linear-gradient(270.32deg, #82d4f899 21.95%, #05325d00 68.41%, #09406699 99.32%), linear-gradient(0deg, var(--emu-semantic-colors-primary-200), var(--emu-semantic-colors-primary-200));
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  }
}

.button .emu-button-v2.emu-button.emu-button__primary-filled, .button .emu-button-v2.emu-button.emu-button__secondary-filled {
  justify-content: center;
  align-items: center;
}

.button .emu-button-v2.emu-button.emu-button__primary-filled .cmp-button__text, .button .emu-button-v2.emu-button.emu-button__secondary-filled .cmp-button__text {
  position: relative;
  top: 2px;
}

.button .emu-button-v2.emu-button.emu-button__primary-filled:focus .cmp-button__text, .button .emu-button-v2.emu-button.emu-button__secondary-filled:focus .cmp-button__text {
  text-decoration: underline;
}

.button .emu-button-v2.emu-button.emu-button__primary-filled:focus {
  outline: var(--emu-common-border-width-medium) solid var(--emu-semantic-colors-primary-100);
  outline-offset: -4px;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.button .emu-button-v2.emu-button.emu-button__primary-filled:focus .cmp-button__text {
  -webkit-text-decoration-color: var(--emu-semantic-colors-primary-100);
  text-decoration-color: var(--emu-semantic-colors-primary-100);
}

.button .emu-button-v2.emu-button.emu-button__secondary-filled:focus .cmp-button__text {
  -webkit-text-decoration-color: var(--emu-common-colors-white);
  text-decoration-color: var(--emu-common-colors-white);
}

.aaaem-carousel__action {
  background-color: var(--emu-common-colors-transparent);
  border: var(--emu-common-border-width-none);
  padding: var(--emu-common-spacing-none);
  height: 40px;
  display: flex;
  top: calc(19% + 3px);
  bottom: auto;
}

@media (min-width: 321px) {
  .aaaem-carousel__action {
    top: calc(19% + 2px);
  }
}

@media (min-width: 768px) {
  .aaaem-carousel__action {
    top: calc(23% - 7px);
  }
}

@media (min-width: 1025px) {
  .aaaem-carousel__action {
    top: calc(50% - 6px);
  }
}

.aaaem-carousel__action-icon {
  display: none;
}

.aaaem-carousel__action:before {
  content: "";
  background-repeat: no-repeat;
  background-size: contain;
  width: 12px;
  height: 21px;
  display: inline-block;
}

@media (min-width: 321px) {
  .aaaem-carousel__action:before {
    width: var(--emu-common-sizing-small);
    height: 28px;
  }
}

@media (min-width: 768px) {
  .aaaem-carousel__action:before {
    width: 22px;
    height: 39px;
  }
}

.aaaem-carousel__action-prev {
  left: 25px;
}

@media (min-width: 768px) {
  .aaaem-carousel__action-prev {
    left: -11px;
  }
}

.aaaem-carousel__action-prev:before {
  background-image: url("resources/images/next-arrow.png");
  transform: rotate(180deg);
}

.aaaem-carousel__action-next {
  right: 25px;
}

@media (min-width: 768px) {
  .aaaem-carousel__action-next {
    right: -11px;
  }
}

.aaaem-carousel__action-next:before {
  background-image: url("resources/images/next-arrow.png");
}

.emu-modal.relative-modal.checklist-modal {
  margin-top: -3px;
}

@media (min-width: 768px) {
  .emu-modal.relative-modal.checklist-modal {
    margin-top: -5px;
  }
}

@media (min-width: 1200px) {
  .emu-modal.relative-modal.checklist-modal .checklist-content {
    padding-top: 19px;
    padding-bottom: 33px;
    padding-right: 26px;
    display: flex;
  }

  .emu-modal.relative-modal.checklist-modal .checklist-content > .container {
    width: 50%;
  }

  .emu-modal.relative-modal.checklist-modal .checklist-content__section--left {
    flex-direction: column;
    align-items: center;
    display: flex;
  }
}

.emu-modal.relative-modal.checklist-modal .checklist-content__title {
  color: #265bb3;
  margin-top: 3px;
  margin-bottom: 7px;
}

@media (min-width: 414px) {
  .emu-modal.relative-modal.checklist-modal .checklist-content__title {
    margin-bottom: 5px;
  }
}

@media (min-width: 768px) {
  .emu-modal.relative-modal.checklist-modal .checklist-content__title {
    margin-top: 6px;
    margin-bottom: 9px;
  }
}

@media (min-width: 1200px) {
  .emu-modal.relative-modal.checklist-modal .checklist-content__title {
    margin-top: var(--emu-common-spacing-none);
    margin-bottom: var(--emu-common-spacing-xs);
  }
}

@media (max-width: 413px) {
  .emu-modal.relative-modal.checklist-modal .checklist-content__title .cmp-title__text {
    font-size: 35px;
    line-height: 38px;
  }
}

@media (min-width: 768px) {
  .emu-modal.relative-modal.checklist-modal .checklist-content__title .cmp-title__text {
    line-height: 1;
  }
}

@media (min-width: 1200px) {
  .emu-modal.relative-modal.checklist-modal .checklist-content__title .cmp-title__text {
    line-height: var(--emu-semantic-line-heights-wide-xl);
  }
}

.emu-modal.relative-modal.checklist-modal .checklist-content__description {
  color: #7e7e7e;
  margin-bottom: 15px;
  font-size: 13px;
  line-height: 26px;
}

@media (min-width: 414px) {
  .emu-modal.relative-modal.checklist-modal .checklist-content__description {
    font-size: 12px;
    line-height: 22px;
  }
}

@media (min-width: 768px) {
  .emu-modal.relative-modal.checklist-modal .checklist-content__description {
    font-size: 20px;
    line-height: 34.6px;
    font-weight: var(--emu-common-font-weight-bold);
    margin-bottom: 43px;
    padding-right: 40%;
  }
}

@media (min-width: 1200px) {
  .emu-modal.relative-modal.checklist-modal .checklist-content__description {
    font-weight: var(--emu-common-font-weight-regular);
    padding-right: var(--emu-common-spacing-none);
    font-size: 15px;
    line-height: 33px;
  }
}

.emu-modal.relative-modal.checklist-modal .checklist-content__description br {
  display: none;
}

@media (min-width: 768px) {
  .emu-modal.relative-modal.checklist-modal .checklist-content__description br {
    display: block;
  }
}

@media (min-width: 1200px) {
  .emu-modal.relative-modal.checklist-modal .checklist-content__description br {
    display: none;
  }
}

.emu-modal.relative-modal.checklist-modal .checklist-content__description p, .emu-modal.relative-modal.checklist-modal .checklist-content__description li {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  font-weight: inherit;
}

.emu-modal.relative-modal.checklist-modal .checklist-content__description a, .emu-modal.relative-modal.checklist-modal .checklist-content__description a:focus, .emu-modal.relative-modal.checklist-modal .checklist-content__description a:active, .emu-modal.relative-modal.checklist-modal .checklist-content__description a:hover {
  color: var(--emu-semantic-colors-primary-950);
  text-decoration: none;
}

.emu-modal.relative-modal.checklist-modal .checklist-content__list {
  color: var(--emu-semantic-colors-secondary-600);
  font-size: 13px;
  line-height: 18px;
}

@media (min-width: 414px) {
  .emu-modal.relative-modal.checklist-modal .checklist-content__list {
    font-size: 12px;
    line-height: 22px;
  }
}

@media (min-width: 768px) {
  .emu-modal.relative-modal.checklist-modal .checklist-content__list {
    font-size: 24px;
    line-height: 48.36px;
    font-weight: var(--emu-common-font-weight-bold);
  }
}

@media (min-width: 1200px) {
  .emu-modal.relative-modal.checklist-modal .checklist-content__list {
    font-size: 15px;
    line-height: 21px;
    font-weight: var(--emu-common-font-weight-regular);
    margin-top: var(--emu-common-spacing-xxs);
    letter-spacing: .085px;
  }

  .emu-modal.relative-modal.checklist-modal .checklist-content__list sup {
    top: 3px;
  }
}

.emu-modal.relative-modal.checklist-modal .checklist-content__list li, .emu-modal.relative-modal.checklist-modal .checklist-content__list p {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
}

.emu-modal.relative-modal.checklist-modal .checklist-content__list ul {
  padding: var(--emu-common-spacing-none);
  margin: var(--emu-common-spacing-none);
  list-style: none;
}

.emu-modal.relative-modal.checklist-modal .checklist-content__list li {
  margin-bottom: 29px;
  padding-left: 48px;
  position: relative;
}

@media (min-width: 768px) {
  .emu-modal.relative-modal.checklist-modal .checklist-content__list li {
    margin-bottom: 20px;
    padding-left: 60px;
  }
}

@media (min-width: 1200px) {
  .emu-modal.relative-modal.checklist-modal .checklist-content__list li {
    margin-bottom: 14.4px;
    padding-left: 48px;
  }
}

.emu-modal.relative-modal.checklist-modal .checklist-content__list li:before {
  content: "";
  background-image: url("resources/images/checked-icon.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 28px;
  height: 24px;
  position: absolute;
  top: -1px;
  left: 0;
}

@media (min-width: 768px) {
  .emu-modal.relative-modal.checklist-modal .checklist-content__list li:before {
    width: 40px;
    height: 35px;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media (min-width: 1200px) {
  .emu-modal.relative-modal.checklist-modal .checklist-content__list li:before {
    width: 28px;
    height: 24px;
    top: -1px;
    transform: none;
  }
}

[data-component="container-isi-v2"] .aaaem-isi-banner {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  padding-top: var(--emu-common-spacing-xxs);
  max-width: var(--emu-semantic-sizing-breakpoints-large-plus);
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 3px;
  display: flex;
}

@media (min-width: 768px) {
  [data-component="container-isi-v2"] .aaaem-isi-banner {
    padding-top: 3px;
    padding-bottom: 1px;
  }
}

@media (min-width: 1142px) {
  [data-component="container-isi-v2"] .aaaem-isi-banner {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

[data-component="container-isi-v2"] .aaaem-isi-banner-content p {
  font-size: var(--emu-common-font-sizes-narrow-medium);
  line-height: var(--emu-common-spacing-medium);
  color: var(--emu-common-colors-white);
  font-family: var(--emu-semantic-font-families-avenir-next-bold);
}

[data-component="container-isi-v2"] .aaaem-isi-banner__button-wrapper {
  line-height: var(--emu-semantic-sizing-one-line-height-narrow);
  padding-bottom: var(--emu-common-spacing-none);
  padding-top: var(--emu-common-spacing-none);
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
  font-size: var(--emu-common-font-sizes-narrow-medium);
  font-family: var(--emu-semantic-font-families-heading);
}

[data-component="container-isi-v2"] .aaaem-isi-banner__button-wrapper span {
  color: var(--emu-common-colors-white);
}

[data-component="container-isi-v2"] .aaaem-isi-banner__button-wrapper svg {
  width: 10px;
  height: 10px;
}

[data-component="container-isi-v2"] .aaaem-isi-banner__button-wrapper svg path {
  stroke: var(--emu-common-colors-white);
  stroke-width: 35px;
  fill: var(--emu-common-colors-white);
}

[data-component="container-isi-v2"] .aaaem-isi-container {
  background: linear-gradient(180deg, var(--emu-semantic-colors-primary-100) 0, var(--emu-semantic-colors-primary-800) 24px);
  border-top: var(--emu-common-border-width-none);
}

[data-component="container-isi-v2"] .aaaem-isi-container__header-wrapper {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  color: var(--emu-semantic-colors-text-dark);
  background: linear-gradient(180deg, var(--emu-semantic-colors-primary-100) 0, var(--emu-semantic-colors-primary-800) 100%);
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1092px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

[data-component="container-isi-v2"] .aaaem-isi-container__header-wrapper p {
  font-size: var(--emu-common-font-sizes-narrow-medium);
  padding-top: 11px;
  padding-bottom: 12px;
  line-height: 16px;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .aaaem-isi-container__header-wrapper p {
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
    line-height: 20px;
  }

  [data-component="container-isi-v2"] .aaaem-isi-container__header-wrapper br {
    display: none;
  }
}

[data-component="container-isi-v2"] .aaaem-isi-container__header-wrapper b {
  font-size: var(--emu-common-font-sizes-narrow-medium);
  font-family: var(--emu-semantic-font-families-heading);
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content {
  padding: 15px 15px 50px;
}

@media (min-width: 768px) {
  [data-component="container-isi-v2"] .aaaem-isi-container__isi-content {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 1200px) {
  [data-component="container-isi-v2"] .aaaem-isi-container__isi-content {
    padding-bottom: 35px;
  }
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content-wrapper {
  color: var(--emu-semantic-colors-secondary-500);
  font-family: var(--emu-semantic-font-families-body);
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  width: 100%;
  max-width: 1092px;
  padding-top: 20px;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .aaaem-isi-container__isi-content-wrapper {
    gap: 50px;
    padding-top: 60px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
  }

  [data-component="container-isi-v2"] .aaaem-isi-container__isi-content-wrapper .container {
    width: 50%;
  }
}

@media (min-width: 1440px) {
  [data-component="container-isi-v2"] .aaaem-isi-container__isi-content-wrapper {
    gap: 35px;
    padding-top: 30px;
    padding-left: 55px;
    padding-right: 40px;
  }
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content-wrapper-right .text-top {
  padding-top: 20px;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .aaaem-isi-container__isi-content-wrapper-right .text-top {
    padding-top: var(--emu-common-spacing-none);
  }
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content-wrapper-right .text-bottom a {
  font-family: var(--emu-semantic-font-families-body);
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content-wrapper-left .text-bottom a {
  font-family: var(--emu-semantic-font-families-heading);
  color: var(--emu-semantic-colors-secondary-500);
}

@media (min-width: 1440px) {
  [data-component="container-isi-v2"] .aaaem-isi-container__isi-content-wrapper-left {
    padding-right: 20px;
  }
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content-wrapper .text-top p {
  padding-top: 20px;
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content-wrapper .text-bottom p {
  padding-top: 5px;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .aaaem-isi-container__isi-content-wrapper .text-bottom p {
    padding-top: 10px;
  }
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content-wrapper .text-top p, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content-wrapper .text-bottom p {
  font-size: 13px;
  line-height: 19.5px;
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content-wrapper .text-top p b, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content-wrapper .text-bottom p b {
  font-family: var(--emu-semantic-font-families-heading);
  color: var(--emu-semantic-colors-secondary-500);
}

[data-component="container-isi-v2"] .aaaem-isi-container__button-wrapper {
  align-self: center;
}

.isi-temp-content {
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: var(--emu-common-spacing-xs);
  background-color: var(--emu-common-colors-white);
  padding-top: var(--emu-common-spacing-medium);
  max-height: 225px;
  display: none;
}

.isi-temp-content__inner {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.isi-temp-content__inner > .container {
  width: 45%;
  padding-right: 20px;
}

.isi-temp-content .aaaem-isi-container__isi-content-wrapper-right, .isi-temp-content .aaaem-isi-container__isi-content-wrapper-left {
  padding-right: var(--emu-common-spacing-none);
}

.isi-temp-content .aaaem-isi-container__isi-content-wrapper-right .text-top, .isi-temp-content .aaaem-isi-container__isi-content-wrapper-left .text-top {
  padding-top: var(--emu-common-spacing-none);
}

.isi-temp-content .aaaem-isi-container__isi-content-wrapper-right .text-top p:nth-child(3), .isi-temp-content .aaaem-isi-container__isi-content-wrapper-right .text-top p:nth-child(4), .isi-temp-content .aaaem-isi-container__isi-content-wrapper-left .text-top p:nth-child(3), .isi-temp-content .aaaem-isi-container__isi-content-wrapper-left .text-top p:nth-child(4), .isi-temp-content .aaaem-isi-container__isi-content-wrapper-right .text-bottom, .isi-temp-content .aaaem-isi-container__isi-content-wrapper-left .text-bottom {
  display: none;
}

.isi-temp-content .cmp-text p {
  color: var(--emu-semantic-colors-secondary-500);
  font-family: var(--emu-semantic-font-families-body);
  font-size: 12px;
  line-height: 16px;
}

.isi-temp-content .cmp-text p:not(:first-child) {
  margin-top: 20px;
}

@media (min-width: 576px) {
  .has-isi-temp-content .isi-temp-content {
    display: block;
  }
}

@media (min-width: 1025px) {
  .fda-tabs-wrapper {
    max-width: var(--emu-common-sizing-tablet);
    margin-left: auto;
    margin-right: auto;
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (min-width: 1200px) {
  .fda-tabs-wrapper {
    max-width: var(--emu-common-sizing-large-tablet);
  }
}

.fda-tabs-container {
  background-color: var(--emu-semantic-colors-secondary-50);
  padding-top: 53px;
  padding-bottom: 26px;
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
  overflow: hidden;
}

@media (min-width: 768px) {
  .fda-tabs-container {
    padding-top: 85px;
  }
}

@media (min-width: 1025px) {
  .fda-tabs-container {
    padding-top: 54px;
  }
}

.fda-tabs-container__inner {
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  .fda-tabs-container__inner {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (min-width: 1025px) {
  .fda-tabs-container__inner {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

.fda-tabs__title p {
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  line-height: var(--emu-semantic-line-heights-narrow-large);
  font-family: var(--emu-semantic-font-families-heading);
  color: var(--emu-semantic-colors-primary-100);
  font-weight: var(--emu-common-font-weight-black);
  margin-bottom: 12px;
  padding-left: 25px;
  padding-right: 25px;
}

@media (max-width: 320px) {
  .fda-tabs__title p {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 28px;
    line-height: 34px;
  }
}

@media (min-width: 414px) and (max-width: 575px) {
  .fda-tabs__title p {
    font-size: 33px;
    line-height: var(--emu-common-line-heights-narrow-xl);
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .fda-tabs__title p {
    font-size: 46px;
    line-height: 50px;
  }
}

@media (min-width: 768px) {
  .fda-tabs__title p {
    margin-bottom: var(--emu-common-spacing-medium);
    font-size: 64px;
    line-height: 69px;
  }
}

@media (min-width: 1024px) {
  .fda-tabs__title p {
    margin-bottom: 17px;
  }
}

@media (min-width: 1025px) {
  .fda-tabs__title p {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
    line-height: var(--emu-semantic-line-heights-wide-xl);
    margin-bottom: 12px;
  }
}

.fda-tabs__title p br {
  display: block;
}

@media (min-width: 1200px) {
  .fda-tabs__title p br {
    display: none;
  }
}

@media (min-width: 1025px) {
  .fda-tabs__text {
    width: 82%;
    margin-left: auto;
    margin-right: auto;
  }
}

.fda-tabs__text p {
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  line-height: var(--emu-semantic-line-heights-narrow-small);
  color: var(--emu-semantic-colors-secondary-600);
  margin: auto auto 35px;
  padding-left: 25px;
  padding-right: 25px;
}

@media (max-width: 320px) {
  .fda-tabs__text p {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 414px) and (max-width: 575px) {
  .fda-tabs__text p {
    font-size: var(--emu-semantic-font-sizes-narrow-medium);
    line-height: 29px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .fda-tabs__text p {
    font-size: 22px;
    line-height: 40px;
  }
}

@media (min-width: 768px) {
  .fda-tabs__text p {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    max-width: 620px;
    margin-bottom: 33px;
    margin-left: auto;
    margin-right: auto;
    font-size: 28px;
    line-height: 42px;
  }
}

@media (min-width: 1025px) {
  .fda-tabs__text p {
    font-size: var(--emu-semantic-font-sizes-wide-small);
    width: 85%;
    max-width: none;
    margin-left: auto;
    margin-right: auto;
    line-height: 38px;
  }
}

@media (min-width: 1024px) {
  .fda-tabs__text p {
    max-width: 90%;
  }

  .fda-tabs__text--gray p {
    max-width: 620px;
  }
}

@media (min-width: 1025px) {
  .fda-tabs__text--gray p {
    width: 85%;
    max-width: none;
    line-height: 38px;
  }
}

.fda-tabs__item .aaaem-tabs__tablist {
  justify-content: center;
  margin-bottom: 70px;
}

.fda-tabs__item li.aaaem-tabs__tab {
  width: 140px;
}

@media (min-width: 321px) {
  .fda-tabs__item li.aaaem-tabs__tab {
    width: auto;
    min-width: 40%;
  }
}

@media (min-width: 768px) {
  .fda-tabs__item li.aaaem-tabs__tab {
    font-size: var(--emu-semantic-font-sizes-wide-small);
    padding: var(--emu-common-spacing-none);
    min-width: 245px;
  }
}

.fda-tabs__item li.aaaem-tabs__tab:not(:last-child) {
  margin-right: 10px;
}

@media (min-width: 1025px) {
  .fda-tabs__item li.aaaem-tabs__tab:not(:last-child) {
    margin-right: 20px;
  }
}

.fda-tabs__item li.aaaem-tabs__tab a {
  font-family: var(--emu-semantic-font-families-heading);
  border: var(--emu-common-border-width-medium) solid var(--emu-semantic-colors-primary-200);
  background: var(--emu-common-colors-transparent);
  vertical-align: middle;
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  line-height: 21px;
  font-weight: var(--emu-common-font-weight-black);
  color: var(--emu-semantic-colors-primary-200);
  text-align: center;
  border-radius: 58px;
  padding: 10px;
  text-decoration: none;
}

@media (min-width: 321px) {
  .fda-tabs__item li.aaaem-tabs__tab a {
    padding-left: 35px;
    padding-right: 35px;
  }
}

@media (min-width: 768px) {
  .fda-tabs__item li.aaaem-tabs__tab a {
    font-size: var(--emu-semantic-font-sizes-wide-small);
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .fda-tabs__item li.aaaem-tabs__tab a span {
    position: relative;
    top: 2px;
  }
}

.fda-tabs__item li.aaaem-tabs__tab a[aria-selected="true"], .fda-tabs__item li.aaaem-tabs__tab a[aria-selected="true"]:hover, .fda-tabs__item li.aaaem-tabs__tab a[aria-selected="true"]:active, .fda-tabs__item li.aaaem-tabs__tab a[aria-selected="true"]:focus {
  background: var(--emu-semantic-colors-primary-200);
  color: var(--emu-common-colors-white);
}

.fda-tabs__item--mobile {
  flex-direction: column-reverse;
  display: flex;
}

@media (min-width: 1025px) {
  .fda-tabs__item--mobile {
    display: none;
  }
}

.cq-Editable-dom--container .fda-tabs__item--mobile {
  display: flex !important;
}

.fda-tabs__item--mobile .aaaem-tabs__tablist {
  margin-bottom: var(--emu-common-spacing-none);
  column-gap: var(--emu-common-spacing-none);
}

.fda-tabs__item--mobile .aaaem-tabs__tablist li.aaaem-tabs__tab {
  width: auto;
  min-width: 0;
  margin-right: 5px;
}

.fda-tabs__item--mobile .aaaem-tabs__tablist li.aaaem-tabs__tab a {
  background-color: var(--emu-common-colors-transparent);
  color: var(--emu-semantic-colors-secondary-600);
  opacity: .4;
  border-radius: var(--emu-common-border-radius-xxs);
  font-size: 13px;
  line-height: 13px;
  font-weight: var(--emu-semantic-font-weight-600);
  letter-spacing: 1px;
  border: none;
  padding: 13px 25px;
}

.fda-tabs__item--mobile .aaaem-tabs__tablist li.aaaem-tabs__tab a[aria-selected="true"], .fda-tabs__item--mobile .aaaem-tabs__tablist li.aaaem-tabs__tab a[aria-selected="true"]:focus {
  color: var(--emu-semantic-colors-primary-100);
  background: var(--emu-common-colors-transparent);
  opacity: 1;
}

.fda-tabs__item--mobile .aaaem-tabs__tablist li.aaaem-tabs__tab a[aria-selected="true"]:focus {
  box-shadow: 0 0 0 .2rem #007bff80;
}

@media (min-width: 576px) {
  .fda-tabs__item--mobile .aaaem-tabs__tabpanels {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.fda-tabs__img--mobile img {
  max-width: 100%;
  height: 417px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 320px) {
  .fda-tabs__img--mobile img {
    width: 100%;
    height: 408px;
  }
}

@media (min-width: 321px) {
  .fda-tabs__img--mobile img {
    margin-bottom: 20px;
  }
}

@media (min-width: 414px) and (max-width: 575px) {
  .fda-tabs__img--mobile img {
    margin-bottom: var(--emu-common-spacing-none);
    height: 486px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .fda-tabs__img--mobile img {
    width: 100%;
    height: auto;
  }
}

@media (min-width: 768px) {
  .fda-tabs__img--mobile img {
    margin-bottom: var(--emu-common-spacing-none);
    max-width: 100%;
    height: 899px;
  }
}

.fda-tabs__img--desktop {
  display: none;
}

.cq-Editable-dom--container .fda-tabs__img--desktop {
  display: block !important;
}

@media (min-width: 1025px) {
  .fda-tabs__img--desktop {
    width: 950px;
    height: 450px;
    margin: auto;
    display: block;
  }
}

@media (min-width: 1366px) {
  .fda-tabs__img--desktop {
    height: auto;
  }
}

@media (min-width: 1025px) {
  .fda-tabs__img--desktop img {
    max-width: none;
    height: 433px;
    margin: auto;
  }
}

@media (min-width: 1366px) {
  .fda-tabs__img--desktop img {
    width: 100%;
    height: auto;
  }
}

.fap__form > .text:last-child {
  display: none;
}

.cq-Editable-dom--container .fap__form > .text:last-child {
  display: block;
}

.fap__input-container {
  align-items: center;
  display: flex;
}

.fap__input {
  position: relative;
}

.fap__input input {
  border: var(--emu-common-border-width-none);
  padding: var(--emu-common-spacing-none);
  font-family: var(--emu-semantic-font-families-heading);
  font-size: var(--emu-semantic-font-sizes-wide-small);
  line-height: 33px;
  font-weight: var(--emu-common-font-weight-black);
  color: var(--emu-semantic-colors-primary-100);
  background-color: var(--emu-common-colors-white);
  border-radius: 100px 0 0 100px;
  width: 200px;
  height: 59px;
  padding-top: 6px;
  padding-left: 30px;
}

@media (min-width: 350px) {
  .fap__input input {
    width: 250px;
    height: 54px;
    padding-left: 20px;
  }
}

@media (min-width: 577px) {
  .fap__input input {
    height: 59px;
  }
}

@media (min-width: 768px) {
  .fap__input input {
    width: 350px;
  }
}

@media (min-width: 1025px) {
  .fap__input input {
    width: 380px;
  }
}

.fap__input input:focus {
  box-shadow: 0 0 10px #00a4e4b3;
}

.fap__input input::placeholder {
  color: currentColor;
  text-align: center;
  font-size: 15px;
}

@media (min-width: 321px) {
  .fap__input input::placeholder {
    font-size: 18px;
  }
}

@media (min-width: 768px) {
  .fap__input input::placeholder {
    font-size: 24px;
  }
}

.fap__submit {
  background-color: var(--emu-common-colors-white);
  border-radius: 0 100px 100px 0;
  width: 70px;
  height: 59px;
  margin-left: -1px;
  padding: 10px 35px 10px 20px;
  position: relative;
}

@media (min-width: 350px) {
  .fap__submit {
    width: 80px;
    height: 54px;
  }
}

@media (min-width: 577px) {
  .fap__submit {
    height: 59px;
  }
}

.fap__submit img {
  width: 15px;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}

@media (min-width: 350px) {
  .fap__submit img {
    width: 24px;
  }
}

.footer footer {
  width: 100%;
  margin-bottom: 25px;
  padding-top: 25px;
  position: relative;
}

@media (min-width: 1024px) {
  .footer footer {
    margin-bottom: 40px;
    padding-top: 65px;
  }
}

@media (min-width: 1200px) {
  .footer footer {
    max-width: 1092px;
    margin: auto auto 78px;
  }

  .footer footer:after {
    content: "";
    height: var(--emu-common-border-width-thin);
    background-color: var(--emu-semantic-colors-secondary-200);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.footer footer:before {
  content: "";
  height: var(--emu-common-border-width-thin);
  background-color: var(--emu-semantic-colors-secondary-200);
  position: absolute;
  top: 0;
  left: 30px;
  right: 30px;
}

@media (min-width: 768px) {
  .footer footer:before {
    left: 55px;
    right: 55px;
  }
}

@media (min-width: 1200px) {
  .footer footer:before {
    left: 0;
    right: 0;
  }
}

.footer__top-container {
  padding-top: var(--emu-common-spacing-medium);
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 25px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
}

@media (min-width: 768px) {
  .footer__top-container {
    padding-left: 55px;
    padding-right: 55px;
  }
}

@media (min-width: 1024px) {
  .footer__top-container {
    flex-direction: row;
    padding-bottom: 36px;
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 1200px) {
  .footer__top-container {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    padding-top: var(--emu-common-spacing-none);
  }
}

.footer__top-container > div {
  flex: 1;
}

@media (min-width: 1024px) {
  .footer__top-container > div:first-child {
    padding-right: 30px;
  }

  .footer__top-container > div:last-child {
    padding-left: 20px;
  }
}

@media (min-width: 1200px) {
  .footer__logo-container {
    padding-left: 55px;
    padding-right: 30px;
  }
}

.footer__logo-container .text p {
  color: var(--emu-semantic-colors-secondary-500);
  font-size: 15px;
  line-height: 24px;
  font-family: var(--emu-semantic-font-families-mono);
  font-weight: var(--emu-semantic-font-weight-500);
  margin-bottom: 12px;
}

@media (min-width: 1024px) {
  .footer__logo-container .text p {
    padding-top: var(--emu-common-spacing-none);
  }
}

.footer__logo {
  width: 100%;
  max-width: 125px;
}

.footer__logo img {
  padding-bottom: 20px;
}

.footer__navigation-container {
  gap: 10px;
  padding-top: 37px;
  display: flex;
}

@media (min-width: 768px) {
  .footer__navigation-container {
    gap: 13px;
  }
}

@media (min-width: 1024px) {
  .footer__navigation-container {
    padding-top: var(--emu-common-spacing-none);
    gap: var(--emu-common-spacing-xs);
  }
}

.footer__navigation-container > .navigationlist:first-child {
  width: 46%;
}

@media (min-width: 1024px) {
  .footer__navigation-container > .navigationlist:first-child {
    width: 50%;
  }
}

@media (min-width: 1140px) {
  .footer__navigation-container > .navigationlist:first-child {
    width: 53%;
  }
}

@media (min-width: 1024px) {
  .footer__navigation-container > .navigationlist:first-child ul {
    width: 185px;
  }
}

@media (min-width: 1140px) {
  .footer__navigation-container > .navigationlist:first-child ul {
    width: 260px;
  }
}

.footer__navigation-container > .navigationlist:last-child {
  width: 48%;
}

@media (min-width: 1140px) {
  .footer__navigation-container > .navigationlist:last-child {
    width: 45%;
  }
}

@media (min-width: 1024px) {
  .footer__navigation-container > .navigationlist:last-child ul {
    width: 230px;
  }
}

.footer__navigation-container .emu-navigation {
  background-color: var(--emu-common-colors-transparent);
}

.footer__navigation-container .emu-navigation__item, .footer__navigation-container .emu-navigation__item a {
  letter-spacing: 0;
}

.footer__navigation-container .emu-navigation__item a {
  display: inline;
}

.footer__navigation-container .emu-navigation .emu-navigation__menu--level-0 {
  gap: var(--emu-common-spacing-none);
}

.footer__navigation-container .emu-navigation .emu-navigation__menu--level-0 > .emu-navigation__item {
  padding-top: var(--emu-common-spacing-none);
  padding-bottom: var(--emu-common-spacing-none);
  font-weight: var(--emu-common-font-weight-black);
  font-family: var(--emu-semantic-font-families-heading);
  margin-bottom: 20px;
  margin-left: var(--emu-common-spacing-none);
  margin-right: var(--emu-common-spacing-none);
  width: 100%;
  font-size: 13px;
  line-height: 21px;
}

@media (min-width: 1024px) {
  .footer__navigation-container .emu-navigation .emu-navigation__menu--level-0 > .emu-navigation__item {
    margin-bottom: 10px;
  }
}

@media (min-width: 1200px) {
  .footer__navigation-container .emu-navigation .emu-navigation__menu--level-0 > .emu-navigation__item:nth-child(4), .footer__navigation-container .emu-navigation .emu-navigation__menu--level-0 > .emu-navigation__item:nth-child(3) {
    margin-right: var(--emu-common-spacing-none);
  }
}

.footer__navigation-container .emu-navigation .emu-navigation__menu--level-0 > .emu-navigation__item img {
  vertical-align: middle;
}

.footer__navigation-container .emu-navigation .emu-navigation__menu--level-0 > .emu-navigation__item a {
  color: inherit;
  font-weight: var(--emu-common-font-weight-black);
  font-family: var(--emu-semantic-font-families-heading);
  overflow-wrap: break-word;
  white-space: normal;
  border: none;
  max-width: 100%;
  font-size: 13px;
  line-height: 21px;
}

.footer__navigation-container .emu-navigation .emu-navigation__menu--level-0 > .emu-navigation__item a:hover, .footer__navigation-container .emu-navigation .emu-navigation__menu--level-0 > .emu-navigation__item a:active, .footer__navigation-container .emu-navigation .emu-navigation__menu--level-0 > .emu-navigation__item a:focus {
  color: var(--emu-semantic-colors-primary-500);
  background: var(--emu-common-colors-transparent);
}

.footer__navigation-container .emu-navigation .emu-navigation__menu--level-0 > .emu-navigation__item a:active, .footer__navigation-container .emu-navigation .emu-navigation__menu--level-0 > .emu-navigation__item a:focus {
  text-decoration: underline;
}

.footer__navigation-container .emu-navigation .emu-navigation__menu--level-0 > .emu-navigation__item:last-child a {
  display: inline;
}

.footer__navigation-container .emu-navigation .emu-navigation__menu--level-0 > .emu-navigation__item:last-child a:before {
  content: none;
}

.footer__navigation-container .emu-navigation ul {
  padding-right: var(--emu-common-spacing-none);
  padding-left: var(--emu-common-spacing-none);
  margin-top: var(--emu-common-spacing-none);
  flex-direction: column;
  display: flex;
}

.footer__navigation-container .emu-navigation__content-wrapper {
  position: relative;
}

.footer__bottom-container {
  padding: 15px 30px 65px;
}

@media (min-width: 768px) {
  .footer__bottom-container {
    padding-left: 55px;
    padding-right: 55px;
  }
}

@media (min-width: 1024px) {
  .footer__bottom-container {
    padding-top: 45px;
    padding-bottom: 52px;
    padding-left: 55px;
  }
}

@media (min-width: 1200px) {
  .footer__bottom-container {
    padding-top: 29px;
  }
}

.footer__bottom-container p {
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  line-height: 21px;
  font-weight: var(--emu-semantic-font-weight-500);
  color: var(--emu-semantic-colors-primary-950);
  padding-top: 15px;
}

@media (min-width: 1200px) {
  .footer__bottom-container p {
    color: var(--emu-semantic-colors-secondary-500);
    font-size: 15px;
    line-height: 20px;
  }
}

.emu-form-dropdown {
  position: relative;
}

.emu-form-dropdown.custom-hide-dropdown .emu-dropdown-menu__group {
  opacity: 0;
  height: 0;
  display: none;
  overflow: hidden;
}

.emu-form-dropdown.custom-hide-dropdown .emu-dropdown-menu__button {
  border-radius: var(--emu-component-ingredients-select-input-border-radius-narrow);
}

.emu-form-dropdown.custom-hide-dropdown .emu-dropdown-menu__item {
  visibility: hidden;
}

.emu-form-dropdown.custom-hide-dropdown.custom-hide-dropdown--show .emu-dropdown-menu__group {
  opacity: 1;
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  height: auto;
  display: block;
}

.emu-form-dropdown.custom-hide-dropdown.custom-hide-dropdown--show .emu-dropdown-menu__button {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.emu-form-dropdown.custom-hide-dropdown.custom-hide-dropdown--show .emu-dropdown-menu__item {
  visibility: visible;
}

.emu-form-dropdown .emu-dropdown-menu__item {
  color: var(--emu-component-ingredients-select-input-option-text-color-filled-active-light);
  text-align: center;
  text-transform: uppercase;
  border: none;
  margin-top: -1px;
  font-size: 11px;
  line-height: 1.5;
}

@media (min-width: 576px) {
  .emu-form-dropdown .emu-dropdown-menu__item {
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .emu-form-dropdown .emu-dropdown-menu__item {
    padding-top: 13px;
    padding-bottom: 11px;
    font-size: 20px;
  }
}

@media (min-width: 1200px) {
  .emu-form-dropdown .emu-dropdown-menu__item {
    padding-top: 18px;
    padding-bottom: 14px;
    font-size: 15px;
  }
}

.emu-form-dropdown .emu-dropdown-menu__item[data-disabled="true"] {
  display: none;
}

.emu-form-dropdown .emu-dropdown-menu__button {
  font-size: 14px;
  line-height: 40px;
  font-family: var(--emu-semantic-font-families-heading);
  justify-content: center;
  height: 40px;
  width: 100% !important;
}

@media (min-width: 576px) {
  .emu-form-dropdown .emu-dropdown-menu__button {
    height: var(--emu-common-sizing-large);
    padding: var(--emu-component-ingredients-select-input-padding-wide);
    font-size: 20px;
    line-height: 64px;
  }
}

@media (min-width: 768px) {
  .emu-form-dropdown .emu-dropdown-menu__button {
    font-size: 28px;
  }
}

@media (min-width: 1200px) {
  .emu-form-dropdown .emu-dropdown-menu__button {
    height: 50px;
    padding: 10px 30px 10px 15px;
    font-size: 18px;
    line-height: 50px;
    position: relative;
  }
}

.emu-form-dropdown .emu-dropdown-menu__button span {
  margin-right: 6px;
  position: relative;
  top: 1px;
}

@media (min-width: 576px) {
  .emu-form-dropdown .emu-dropdown-menu__button span {
    margin-right: var(--emu-common-spacing-xs);
  }
}

@media (min-width: 768px) {
  .emu-form-dropdown .emu-dropdown-menu__button span {
    margin-right: 13px;
    top: 3px;
    left: -2px;
  }
}

@media (min-width: 1200px) {
  .emu-form-dropdown .emu-dropdown-menu__button span {
    margin-right: var(--emu-common-spacing-none);
    top: 1px;
    left: 0;
  }
}

.emu-form-dropdown .emu-dropdown-menu__button.emu-dropdown-menu__button svg {
  width: 11px;
  transform: rotate(0);
}

@media (min-width: 576px) {
  .emu-form-dropdown .emu-dropdown-menu__button.emu-dropdown-menu__button svg {
    width: 18px;
  }
}

@media (min-width: 768px) {
  .emu-form-dropdown .emu-dropdown-menu__button.emu-dropdown-menu__button svg {
    transform: rotate(0)scale(1.5);
  }
}

@media (min-width: 1200px) {
  .emu-form-dropdown .emu-dropdown-menu__button.emu-dropdown-menu__button svg {
    position: absolute;
    top: 17px;
    right: 19px;
    transform: rotate(0);
  }
}

.emu-form-dropdown .emu-dropdown-menu__button.emu-dropdown-menu__button svg path {
  stroke: var(--emu-component-ingredients-select-input-icon-color-fill-filled-default-light);
  stroke-width: 30px;
}

@media (min-width: 576px) {
  .emu-form-dropdown .emu-dropdown-menu__button.emu-dropdown-menu__button svg path {
    stroke-width: 45px;
  }
}

.emu-form-dropdown .emu-dropdown-menu__group {
  padding-top: var(--emu-common-spacing-xxs);
  width: 100% !important;
  top: 100% !important;
  left: 0 !important;
}

@media (min-width: 576px) {
  .emu-form-dropdown .emu-dropdown-menu__group {
    padding-top: var(--emu-common-spacing-none);
    transform: translateY(-3px);
  }
}

header.experiencefragment {
  z-index: var(--emu-common-other-z-index-header);
  position: relative;
}

@media (min-width: 1024px) {
  header.experiencefragment div.header {
    padding-top: 11px;
  }
}

@media (min-width: 1200px) {
  header.experiencefragment div.header {
    max-width: var(--emu-semantic-sizing-breakpoints-large-plus);
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1024px) {
  header.experiencefragment div.header .skip-menu {
    display: none;
  }
}

header.experiencefragment div.header .header__content {
  padding: 10px 15px 6px;
}

@media (min-width: 414px) {
  header.experiencefragment div.header .header__content {
    padding-bottom: 7px;
  }
}

@media (min-width: 768px) {
  header.experiencefragment div.header .header__content {
    padding-bottom: 17px;
  }
}

@media (min-width: 1024px) {
  header.experiencefragment div.header .header__content {
    padding-bottom: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1200px) {
  header.experiencefragment div.header .header__content {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

header.experiencefragment div.header .header__menu-mobile {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (min-width: 1024px) {
  header.experiencefragment div.header .header__menu-mobile {
    display: none;
  }
}

header.experiencefragment div.header .header__menu-mobile > .image {
  max-width: 60%;
}

header.experiencefragment div.header .header__eyebrow-text p {
  color: var(--emu-semantic-colors-secondary-500);
  font-size: 7px;
  line-height: 8px;
  font-family: var(--emu-semantic-font-families-heading);
  font-weight: var(--emu-semantic-font-weight-regular);
  margin-top: 21px;
}

@media (min-width: 414px) {
  header.experiencefragment div.header .header__eyebrow-text p {
    font-size: 8px;
  }
}

@media (min-width: 1024px) {
  header.experiencefragment div.header .header__eyebrow-text p {
    margin-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-small);
    flex-direction: row;
    justify-content: flex-end;
    font-size: 8px;
    line-height: 9px;
    display: flex;
  }
}

header.experiencefragment div.header .header__eyebrow-text p .emphasis {
  letter-spacing: -.7px;
}

@media (min-width: 414px) {
  header.experiencefragment div.header .header__eyebrow-text p .emphasis {
    letter-spacing: -.8px;
  }
}

@media (min-width: 768px) {
  header.experiencefragment div.header .header__eyebrow-text p .emphasis {
    letter-spacing: .16px;
  }
}

header.experiencefragment div.header .header__eyebrow-text p .emphasis:first-child {
  margin-right: 5px;
}

@media (min-width: 768px) {
  header.experiencefragment div.header .header__eyebrow-text p .emphasis:first-child {
    margin-right: 10px;
  }
}

header.experiencefragment div.header .header__eyebrow-text p .emphasis:last-child {
  margin: var(--emu-common-spacing-none);
  margin-left: var(--emu-common-spacing-xs);
}

@media (min-width: 768px) {
  header.experiencefragment div.header .header__eyebrow-text p .emphasis:last-child {
    margin-left: 10px;
  }
}

header.experiencefragment div.header .header .header__menu-trigger {
  padding: var(--emu-common-spacing-none);
  background-color: var(--emu-common-colors-transparent);
  padding-top: var(--emu-common-spacing-small);
  padding-bottom: var(--emu-common-spacing-small);
  border: var(--emu-common-border-width-none);
  outline: var(--emu-common-outline-width-unset);
  position: relative;
  left: 1px;
}

header.experiencefragment div.header .header .header__menu-trigger:hover, header.experiencefragment div.header .header .header__menu-trigger:focus, header.experiencefragment div.header .header .header__menu-trigger:active {
  background-color: var(--emu-common-colors-transparent);
}

header.experiencefragment div.header .header .header__menu-trigger:active {
  outline: var(--emu-common-outline-width-unset);
}

header.experiencefragment div.header .header .header__menu-trigger span {
  background: var(--emu-semantic-colors-secondary-300);
  border-radius: var(--emu-common-border-radius-xxs);
  width: 28px;
  height: 2px;
}

header.experiencefragment div.header .header .header__menu-trigger span:before, header.experiencefragment div.header .header .header__menu-trigger span:after {
  content: "";
  background: inherit;
  height: inherit;
  width: inherit;
  border-radius: 1px;
  position: absolute;
  right: 0;
}

header.experiencefragment div.header .header .header__menu-trigger span:before {
  top: 1px;
}

header.experiencefragment div.header .header .header__menu-trigger span:after {
  bottom: 1px;
}

header.experiencefragment div.header .header .header__menu-trigger .cmp-button__text, header.experiencefragment div.header .header .aaaem-navigation {
  display: none;
}

header.experiencefragment div.header .header .aaaem-navigation__global-navigation-close-button .header__menu-close {
  padding: var(--emu-common-spacing-none);
  border-width: var(--emu-common-border-width-none);
  background-color: var(--emu-common-colors-transparent);
}

header.experiencefragment div.header .header .aaaem-navigation__global-navigation-close-button .header__menu-close:hover, header.experiencefragment div.header .header .aaaem-navigation__global-navigation-close-button .header__menu-close:focus, header.experiencefragment div.header .header .aaaem-navigation__global-navigation-close-button .header__menu-close:active {
  border-width: var(--emu-common-border-width-none);
  background-color: var(--emu-common-colors-transparent);
  outline: var(--emu-common-outline-width-unset);
}

header.experiencefragment div.header .header .aaaem-navigation__global-navigation-close-button .header__menu-close span {
  width: 35px;
  height: 35px;
  display: block;
  position: relative;
}

header.experiencefragment div.header .header .aaaem-navigation__global-navigation-close-button .header__menu-close span:before, header.experiencefragment div.header .header .aaaem-navigation__global-navigation-close-button .header__menu-close span:after {
  content: "";
  background-color: var(--emu-common-colors-white);
  height: var(--emu-common-border-width-medium);
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(45deg);
}

header.experiencefragment div.header .header .aaaem-navigation__global-navigation-close-button .header__menu-close span:after {
  transform: translate(-50%, -50%)rotate(-45deg);
}

header.experiencefragment div.header .header .aaaem-navigation__global-navigation-close-button .header__menu-close .cmp-button__text {
  display: none;
}

@media (min-width: 1024px) {
  header.experiencefragment div.header .header .aaaem-navigation__content-wrapper {
    display: block;
  }
}

header.experiencefragment div.header .header .aaaem-navigation__content-wrapper.js-is-open {
  z-index: var(--emu-common-other-z-index-layer);
  background-color: var(--emu-semantic-colors-primary-900);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
}

@media (min-width: 1024px) {
  header.experiencefragment div.header .header .aaaem-navigation__content-wrapper.js-is-open {
    background-color: var(--emu-semantic-colors-none);
    position: relative;
    overflow: visible;
  }
}

header.experiencefragment div.header .header .aaaem-navigation__content-wrapper.js-is-open .header__eyebrow-text p {
  color: var(--emu-common-colors-white);
}

header.experiencefragment div.header .header .aaaem-navigation__content-wrapper.js-is-open .header__menu-mobile-dropdown .aaaem-image img {
  filter: brightness(0) invert();
}

@media (min-width: 1024px) {
  header.experiencefragment div.header .header .aaaem-navigation__content-wrapper.js-is-open .header__menu-mobile-dropdown .aaaem-image img {
    filter: none;
  }
}

header.experiencefragment div.header .header .aaaem-navigation__content-wrapper.js-is-open .aaaem-navigation__global-navigation-close-button .aaaem-button {
  position: absolute;
  top: 9px;
  right: 9px;
}

@media (min-width: 1024px) {
  header.experiencefragment div.header .header .aaaem-navigation__global-navigation-close-button {
    display: none;
  }
}

header.experiencefragment div.header .header .aaaem-navigation__prenav-content {
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 1024px) {
  header.experiencefragment div.header .header .aaaem-navigation__prenav-content {
    padding-top: var(--emu-common-spacing-none);
    flex-direction: row;
    justify-content: space-between;
    display: flex;
  }
}

@media (min-width: 1200px) {
  header.experiencefragment div.header .header .aaaem-navigation__prenav-content {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1024px) {
  header.experiencefragment div.header .header .aaaem-navigation__prenav-content > .container {
    width: 16.667%;
    padding-right: 25px;
  }
}

@media (min-width: 1200px) {
  header.experiencefragment div.header .header .aaaem-navigation__prenav-content > .container {
    width: 25%;
    margin-top: -2px;
    padding-right: 25px;
  }
}

@media (min-width: 1024px) {
  header.experiencefragment div.header .header .aaaem-navigation__prenav-content .header__eyebrow-text {
    display: none;
  }
}

.navigationlist .emu-navigation__content-wrapper {
  position: relative;
}

.navigationlist .emu-navigation__content-wrapper nav span, .navigationlist .emu-navigation__content-wrapper nav a {
  font-weight: var(--emu-common-font-weight-regular);
}

@media (min-width: 1024px) {
  .navigationlist .emu-navigation__content-wrapper nav ul li span, .navigationlist .emu-navigation__content-wrapper nav ul li > a {
    color: var(--emu-semantic-colors-secondary-400);
  }

  .navigationlist .emu-navigation__content-wrapper nav ul li span:hover, .navigationlist .emu-navigation__content-wrapper nav ul li span:focus, .navigationlist .emu-navigation__content-wrapper nav ul li > a:hover, .navigationlist .emu-navigation__content-wrapper nav ul li > a:focus {
    color: var(--emu-semantic-colors-primary-100);
  }
}

.navigationlist .emu-navigation__content-wrapper nav ul li.emu-navigation__item--active > a, .navigationlist .emu-navigation__content-wrapper nav ul li.emu-navigation__item--active > span a {
  color: var(--emu-semantic-colors-primary-50);
}

@media (min-width: 1024px) {
  .navigationlist .emu-navigation__content-wrapper nav ul li.emu-navigation__item--active > a, .navigationlist .emu-navigation__content-wrapper nav ul li.emu-navigation__item--active > span a {
    color: var(--emu-semantic-colors-primary-100);
  }
}

.emu-navigation__item {
  letter-spacing: .6px;
  font-size: 19px;
  line-height: 24px;
}

@media (min-width: 1024px) {
  .emu-navigation__item {
    font-size: 10px;
    line-height: var(--emu-semantic-sizing-one-line-height-wide);
    letter-spacing: .2px;
  }
}

@media (min-width: 1200px) {
  .emu-navigation__item {
    letter-spacing: .24px;
    white-space: nowrap;
    font-size: 12px;
  }
}

.emu-navigation .emu-navigation__menu--level-0 {
  flex-direction: column;
  margin-top: 75px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
}

@media (min-width: 1024px) {
  .emu-navigation .emu-navigation__menu--level-0 {
    padding: var(--emu-common-spacing-none);
    margin: var(--emu-common-spacing-none);
    font-family: var(--emu-semantic-font-families-heading);
    font-weight: var(--emu-semantic-font-weight-900);
    flex-direction: row;
  }
}

.emu-navigation .emu-navigation__menu--level-0 > .emu-navigation__item {
  text-align: center;
  padding-top: 12px;
  padding-bottom: 20px;
}

@media (min-width: 1024px) {
  .emu-navigation .emu-navigation__menu--level-0 > .emu-navigation__item {
    text-align: left;
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 6px;
    padding-bottom: 23px;
    position: relative;
  }
}

@media (min-width: 1200px) {
  .emu-navigation .emu-navigation__menu--level-0 > .emu-navigation__item {
    margin-left: 14px;
    margin-right: 14px;
  }

  .emu-navigation .emu-navigation__menu--level-0 > .emu-navigation__item.emu-navigation__item-parent {
    margin-left: var(--emu-common-spacing-small);
    margin-right: 9px;
  }

  .emu-navigation .emu-navigation__menu--level-0 > .emu-navigation__item:nth-child(4), .emu-navigation .emu-navigation__menu--level-0 > .emu-navigation__item:nth-child(3) {
    margin-right: 16px;
  }
}

@media (min-width: 1024px) {
  .emu-navigation .emu-navigation__menu--level-0 > .emu-navigation__item:last-child {
    margin-right: var(--emu-common-spacing-none);
  }
}

.emu-navigation .emu-navigation__menu--level-0 > .emu-navigation__item:last-child a {
  flex-direction: row;
  justify-content: center;
  display: flex;
}

@media (min-width: 1024px) {
  .emu-navigation .emu-navigation__menu--level-0 > .emu-navigation__item:last-child a {
    color: var(--emu-semantic-colors-primary-100);
  }
}

.emu-navigation .emu-navigation__menu--level-0 > .emu-navigation__item:last-child a:before {
  content: "";
  width: var(--emu-common-sizing-small);
  background-image: url("resources/images/location-icon.png");
  background-size: cover;
  height: 23px;
  margin-right: 20px;
  display: inline-block;
  position: relative;
  left: 10px;
}

@media (min-width: 1024px) {
  .emu-navigation .emu-navigation__menu--level-0 > .emu-navigation__item:last-child a:before {
    height: var(--emu-common-sizing-small);
    margin-right: var(--emu-common-spacing-xs);
    left: var(--emu-common-spacing-none);
    width: 11px;
    top: -2px;
  }
}

@media (min-width: 1200px) {
  .emu-navigation .emu-navigation__menu--level-0 > .emu-navigation__item-parent .nav-item.parent {
    padding-right: var(--emu-common-spacing-xs);
  }
}

.emu-navigation .emu-navigation__menu--level-0 > .emu-navigation__item-parent .nav-item.parent:after {
  content: "";
  color: var(--emu-semantic-colors-secondary-200);
  border-width: var(--emu-common-border-width-none);
  border-style: solid;
  border-top-width: 3.5px;
  border-right-width: 3.5px;
  width: 13px;
  height: 13px;
  margin-left: 10px;
  display: inline-block;
  position: relative;
  top: -2px;
  left: 2px;
  transform: rotate(135deg);
}

@media (min-width: 1024px) {
  .emu-navigation .emu-navigation__menu--level-0 > .emu-navigation__item-parent .nav-item.parent:after {
    border-top-width: var(--emu-common-border-width-medium);
    border-right-width: var(--emu-common-border-width-medium);
    background-attachment: fixed;
    width: 8px;
    height: 8px;
  }
}

.emu-navigation .emu-navigation__menu--level-0 > .emu-navigation__item-parent .emu-navigation__menu--level-1 {
  width: 100%;
  display: none;
  position: relative;
}

@media (min-width: 1024px) {
  .emu-navigation .emu-navigation__menu--level-0 > .emu-navigation__item-parent .emu-navigation__menu--level-1 {
    width: auto;
    position: absolute;
    left: -10px;
  }

  .emu-navigation .emu-navigation__menu--level-0 > .emu-navigation__item-parent.js-toggle-dropdown .nav-item.parent {
    color: var(--emu-semantic-colors-primary-100);
  }
}

.emu-navigation .emu-navigation__menu--level-0 > .emu-navigation__item-parent.js-toggle-dropdown .nav-item.parent:after {
  top: var(--emu-common-sizing-xs);
  transform: rotate(-45deg);
}

@media (min-width: 1024px) {
  .emu-navigation .emu-navigation__menu--level-0 > .emu-navigation__item-parent.js-toggle-dropdown .nav-item.parent:after {
    top: 2px;
  }
}

.emu-navigation .emu-navigation__menu--level-0 > .emu-navigation__item-parent.js-toggle-dropdown .emu-navigation__menu--level-1 {
  display: block;
}

.emu-navigation .emu-navigation__menu--level-1 {
  margin-top: 12px;
  margin-bottom: var(--emu-common-spacing-small);
}

@media (min-width: 1024px) {
  .emu-navigation .emu-navigation__menu--level-1 {
    margin: var(--emu-common-spacing-none);
    background-color: var(--emu-common-colors-white);
    border-style: solid;
    border-top-width: 8px;
    border-color: var(--emu-semantic-colors-primary-100);
    min-width: 180px;
    margin-top: 23px;
    padding-top: 30px;
    padding-bottom: 20px;
  }
}

.emu-navigation .emu-navigation__menu--level-1 > .emu-navigation__item span, .emu-navigation .emu-navigation__menu--level-1 > .emu-navigation__item a {
  letter-spacing: .5px;
  font-family: var(--emu-semantic-font-families-heading);
  font-weight: var(--emu-semantic-font-weight-900);
  padding: 11px 15px 20px 25px;
  transition: all .3s ease-out;
}

@media (min-width: 1024px) {
  .emu-navigation .emu-navigation__menu--level-1 > .emu-navigation__item span, .emu-navigation .emu-navigation__menu--level-1 > .emu-navigation__item a {
    letter-spacing: .24px;
    padding-top: var(--emu-common-spacing-small);
    padding-bottom: 11px;
    padding-left: 17px;
    padding-right: 17px;
    line-height: 16px;
  }
}

.emu-navigation .emu-navigation__menu--level-1 > .emu-navigation__item span sup, .emu-navigation .emu-navigation__menu--level-1 > .emu-navigation__item a sup {
  font-size: 97%;
  font-family: var(--emu-semantic-font-families-body);
  top: 7px;
}

@media (min-width: 1024px) {
  .emu-navigation .emu-navigation__menu--level-1 > .emu-navigation__item span sup, .emu-navigation .emu-navigation__menu--level-1 > .emu-navigation__item a sup {
    top: 4px;
  }
}

.emu-navigation .emu-navigation__menu--level-1 > .emu-navigation__item span:hover, .emu-navigation .emu-navigation__menu--level-1 > .emu-navigation__item a:hover {
  font-size: var(--emu-common-font-sizes-narrow-medium);
  letter-spacing: .24px;
}

.emu-navigation .emu-navigation__menu--level-1 > .emu-navigation__item span:hover sup, .emu-navigation .emu-navigation__menu--level-1 > .emu-navigation__item a:hover sup {
  top: 4px;
}

.intro-block__inner-container {
  max-width: var(--emu-common-sizing-tablet);
  margin-left: auto;
  margin-right: auto;
  padding: 103px 30px 95px;
}

@media (min-width: 414px) {
  .intro-block__inner-container {
    padding-top: 105px;
    padding-bottom: 93px;
  }
}

@media (min-width: 768px) {
  .intro-block__inner-container {
    padding: 150px 65px 143px;
  }
}

@media (min-width: 1025px) {
  .intro-block__inner-container {
    padding: 102px 30px 93px;
  }
}

@media (min-width: 1200px) {
  .intro-block__inner-container {
    max-width: var(--emu-common-sizing-large-tablet);
  }
}

.intro-block__title {
  margin-bottom: 17px;
}

@media (min-width: 768px) {
  .intro-block__title {
    margin-bottom: var(--emu-common-spacing-xxs);
  }
}

@media (min-width: 1025px) {
  .intro-block__title {
    margin-bottom: 12px;
  }
}

.intro-block__title .cmp-title__text {
  font-size: 28px;
  line-height: 34px;
  font-weight: var(--emu-common-font-weight-black);
  font-family: var(--emu-semantic-font-families-heading);
}

@media (min-width: 321px) {
  .intro-block__title .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-narrow-large);
    line-height: var(--emu-semantic-line-heights-narrow-large);
  }
}

@media (min-width: 414px) {
  .intro-block__title .cmp-title__text {
    font-size: 33px;
    line-height: var(--emu-common-line-heights-narrow-xl);
  }
}

@media (min-width: 576px) {
  .intro-block__title .cmp-title__text {
    font-size: 46px;
    line-height: 50px;
  }
}

@media (min-width: 768px) {
  .intro-block__title .cmp-title__text {
    font-size: 64px;
    line-height: 77px;
  }
}

@media (min-width: 1025px) {
  .intro-block__title .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
    text-align: center;
    line-height: 65px;
  }
}

@media (min-width: 1200px) {
  .intro-block__title .cmp-title__text br {
    display: none;
  }
}

.intro-block__text {
  max-width: 670px;
}

@media (min-width: 414px) {
  .intro-block__text {
    font-size: var(--emu-semantic-font-sizes-narrow-medium);
    line-height: 29px;
  }
}

@media (min-width: 576px) {
  .intro-block__text {
    font-size: 22px;
    line-height: 40px;
  }
}

@media (min-width: 768px) {
  .intro-block__text {
    font-size: 28px;
    line-height: 42px;
  }
}

@media (min-width: 1025px) {
  .intro-block__text {
    text-align: center;
    max-width: none;
    padding-left: 65px;
    padding-right: 65px;
    font-size: 24px;
    line-height: 31px;
  }
}

.intro-block__text * {
  font-size: inherit;
  line-height: inherit;
}

.intro-block__text sup {
  font-size: 60%;
  line-height: 0;
}

.intro-block.intro-block--blue-bg .intro-block__inner-container {
  margin-left: -25px;
  margin-right: -25px;
  padding: 16.5% 10%;
}

@media (min-width: 321px) {
  .intro-block.intro-block--blue-bg .intro-block__inner-container {
    margin-left: -10px;
    margin-right: -10px;
    padding-top: 15%;
    padding-bottom: 15%;
  }
}

@media (min-width: 768px) {
  .intro-block.intro-block--blue-bg .intro-block__inner-container {
    margin-left: auto;
    margin-right: auto;
    padding: 105px 50px 97px;
  }
}

@media (min-width: 1025px) {
  .intro-block.intro-block--blue-bg .intro-block__inner-container {
    padding-top: 93px;
    padding-bottom: 89px;
  }
}

@media (min-width: 1200px) {
  .intro-block.intro-block--blue-bg .intro-block__inner-container {
    padding: 93px 80px 89px;
  }
}

.intro-block.intro-block--blue-bg .intro-block__title {
  margin-top: 1px;
  margin-bottom: 43px;
}

@media (min-width: 321px) {
  .intro-block.intro-block--blue-bg .intro-block__title {
    margin-top: var(--emu-common-spacing-xxs);
  }
}

@media (min-width: 575px) {
  .intro-block.intro-block--blue-bg .intro-block__title {
    margin-bottom: 44px;
  }
}

@media (min-width: 768px) {
  .intro-block.intro-block--blue-bg .intro-block__title {
    margin-bottom: 18px;
    margin-left: auto;
    margin-right: auto;
    margin-top: var(--emu-common-spacing-none);
    max-width: 640px;
  }
}

@media (min-width: 1025px) {
  .intro-block.intro-block--blue-bg .intro-block__title {
    max-width: 750px;
  }
}

@media (min-width: 1200px) {
  .intro-block.intro-block--blue-bg .intro-block__title {
    max-width: none;
  }
}

.intro-block.intro-block--blue-bg .intro-block__title .cmp-title__text {
  font-family: var(--emu-semantic-font-families-body);
  font-weight: var(--emu-common-font-weight-regular);
}

@media (min-width: 575px) and (max-width: 767px) {
  .intro-block.intro-block--blue-bg .intro-block__title .cmp-title__text {
    font-size: 46px;
    line-height: 50px;
  }

  .intro-block.intro-block--blue-bg .intro-block__text {
    font-size: 22px;
    line-height: 40px;
  }
}

@media (min-width: 768px) {
  .intro-block.intro-block--blue-bg .intro-block__text {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1025px) {
  .intro-block.intro-block--blue-bg .intro-block__text {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    max-width: 750px;
    line-height: 38px;
  }
}

@media (min-width: 1200px) {
  .intro-block.intro-block--blue-bg .intro-block__text {
    max-width: none;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(1turn);
  }
}

.cs-loader {
  z-index: var(--emu-common-other-z-index-layer);
  animation: 1s linear infinite spin;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
}

.cs-loader path {
  stroke: var(--emu-common-colors-white);
}

.cs-loader__wrapper {
  position: relative;
}

.cs-loader__wrapper:after {
  content: "";
  background-color: var(--emu-common-colors-transparent);
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.cs-loader__wrapper--show {
  pointer-events: none;
}

.cs-loader__wrapper--show .cs-loader, .cs-loader__wrapper--show:after {
  display: block;
}

[data-component="modal"].relative-modal .modal-wrapper {
  top: var(--emu-common-spacing-none);
  background-color: var(--emu-semantic-colors-none);
  position: relative;
}

[data-component="modal"].relative-modal .modal-content {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: 20px;
}

[data-component="modal"] .modal-content {
  flex-direction: column;
  display: flex;
  box-shadow: 8px 12px 14px #00000059;
}

@media (min-width: 1200px) {
  [data-component="modal"] .modal-content {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
  }
}

@media (min-width: 1440px) {
  [data-component="modal"] .modal-content {
    width: 1200px;
  }
}

[data-component="modal"] .modal-content > .container {
  padding-top: 20px;
}

@media (min-width: 414px) {
  [data-component="modal"] .modal-content > .container {
    padding-top: var(--emu-common-spacing-none);
  }
}

[data-component="modal"] .close {
  width: var(--emu-common-sizing-medium);
  height: var(--emu-common-sizing-medium);
  background-image: url("resources/images/close-icon.png");
  background-repeat: no-repeat;
  background-size: cover;
  align-self: flex-end;
  position: static;
}

@media (min-width: 414px) {
  [data-component="modal"] .close {
    width: 28px;
    height: 28px;
  }
}

@media (min-width: 1200px) {
  [data-component="modal"] .close {
    background-position: bottom;
    background-size: contain;
    width: 24px;
    height: 27px;
  }
}

[data-component="modal"] .close, [data-component="modal"] .close:active, [data-component="modal"] .close:focus {
  opacity: 1;
}

[data-component="modal"] .close span {
  display: none;
}

.aaaem-pf {
  position: relative;
}

.aaaem-pf__loader {
  display: none;
}

.page-title-banner__title {
  padding: var(--emu-common-spacing-none);
}

.page-title-banner__title .emu-title__text {
  font-size: 33px;
  line-height: var(--emu-common-line-heights-narrow-xl);
}

@media (min-width: 768px) {
  .page-title-banner__title .emu-title__text {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
    line-height: var(--emu-semantic-line-heights-wide-xl);
  }
}

.page-title-banner__title-wrapper {
  background-color: var(--emu-semantic-colors-secondary-800);
  margin-bottom: var(--emu-common-spacing-large);
  padding: 29px 30px 21px;
}

@media (min-width: 768px) {
  .page-title-banner__title-wrapper {
    margin-bottom: 33px;
  }
}

@media (min-width: 1200px) {
  .page-title-banner__title-wrapper {
    padding-left: 60px;
  }
}

@media (min-width: 1440px) {
  .page-title-banner__title-wrapper {
    padding-left: 90px;
  }
}

.pre-footer {
  border-top-style: solid;
  border-top-width: 13px;
  border-top-color: var(--emu-semantic-colors-primary-100);
  margin-top: 63px;
  margin-bottom: var(--emu-common-spacing-none);
  padding-top: var(--emu-common-spacing-none);
  padding-bottom: var(--emu-common-spacing-none);
  margin-left: 15px;
  margin-right: 15px;
}

@media (min-width: 768px) {
  .pre-footer {
    margin-bottom: 5px;
    margin-left: 40px;
    margin-right: 40px;
  }
}

@media (min-width: 1024px) {
  .pre-footer {
    border-top-width: 15px;
    margin-bottom: 10px;
    padding-top: 39px;
    padding-bottom: 59px;
  }
}

@media (min-width: 1140px) {
  .pre-footer {
    margin-top: 50px;
    padding-bottom: 45px;
  }
}

@media (min-width: 1200px) {
  .pre-footer {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: var(--emu-common-spacing-none);
    max-width: 1092px;
  }
}

.pre-footer > div {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: auto;
  display: flex;
}

@media (min-width: 1024px) {
  .pre-footer > div {
    flex-direction: row;
    height: 40px;
  }
}

.pre-footer__logo {
  padding-top: 35px;
}

@media (min-width: 1024px) {
  .pre-footer__logo {
    padding-top: 9px;
  }
}

.pre-footer__logo img {
  min-height: 50px;
}

@media (min-width: 1024px) {
  .pre-footer__logo img {
    min-height: 40px;
  }
}

.pre-footer__social {
  justify-content: space-between;
  align-items: baseline;
  gap: 46px;
  margin-top: 25px;
  margin-bottom: 15px;
  margin-left: 15px;
  display: flex;
}

@media (min-width: 1024px) {
  .pre-footer__social {
    margin: var(--emu-common-spacing-none);
    justify-content: right;
    align-items: center;
    gap: 51px;
  }
}

.pre-footer__social .cmp-text a {
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  line-height: 33px;
  font-family: var(--emu-semantic-font-families-heading);
  letter-spacing: .84px;
  text-decoration: none;
}

.pre-footer__social .cmp-text a:hover {
  color: var(--emu-semantic-colors-primary-500);
}

.pre-footer__icons {
  display: flex;
}

.pre-footer__icons [data-component="image"] {
  cursor: pointer;
  margin-right: 34.5px;
}

@media (min-width: 1024px) {
  .pre-footer__icons [data-component="image"] {
    margin-left: 24.5px;
    margin-right: var(--emu-common-spacing-none);
  }
}

.pre-footer__icons [data-component="image"] svg {
  fill: var(--emu-semantic-colors-secondary-700);
}

.pre-footer__icons > div:first-child svg, .pre-footer__icons > div:nth-child(2) svg {
  width: 15.75px;
}

.pre-footer__icons > div:nth-child(3) svg {
  width: 20.25px;
}

.product-switch__wrapper {
  background-position: top;
  position: relative;
}

.product-switch__title .cmp-title__text {
  font-family: var(--emu-semantic-font-families-heading);
  font-weight: var(--emu-common-font-weight-black);
  margin-top: 38px;
  margin-bottom: 28px;
  padding-left: 10px;
  padding-right: 10px;
}

@media (max-width: 349px) {
  .product-switch__title .cmp-title__text {
    margin-top: 40px;
    margin-bottom: 27px;
    font-size: 28px;
    line-height: 41px;
  }
}

@media (min-width: 414px) {
  .product-switch__title .cmp-title__text {
    font-size: 33px;
    line-height: var(--emu-common-line-heights-narrow-xl);
    margin-top: 40px;
    margin-bottom: 26px;
  }
}

@media (min-width: 768px) {
  .product-switch__title .cmp-title__text {
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    font-size: 64px;
    line-height: 70px;
  }
}

@media (min-width: 1025px) {
  .product-switch__title .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
    line-height: var(--emu-semantic-line-heights-wide-xl);
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1200px) {
  .product-switch__title .cmp-title__text {
    margin-top: 45px;
    margin-bottom: 35px;
  }
}

.product-switch__container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

@media (min-width: 1024px) {
  .product-switch__container {
    max-width: var(--emu-common-sizing-tablet);
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
  .product-switch__container {
    max-width: var(--emu-semantic-sizing-breakpoints-large-plus);
  }
}

.product-switch__container > .container {
  flex-shrink: 0;
  flex-basis: 40%;
  align-self: stretch;
}

.product-switch__container-inner {
  padding-top: var(--emu-common-spacing-none);
  padding-bottom: var(--emu-common-spacing-none);
  position: relative;
}

@media (min-width: 1200px) {
  .product-switch__container-inner {
    justify-content: center;
    gap: 30px;
    display: flex;
  }

  .product-switch__container-inner > .container {
    width: 540px;
  }
}

.product-switch__img-container {
  position: relative;
}

.product-switch__img-container > .button {
  position: static;
}

.product-switch__image {
  position: relative;
}

.product-switch__image--desktop {
  display: none;
}

@media (min-width: 1200px) {
  .product-switch__image--desktop {
    display: block;
  }

  .product-switch__image--mobile {
    display: none;
  }
}

.product-switch__image > img {
  object-fit: cover;
  width: 100%;
  height: 350px;
  margin: auto;
}

@media (min-width: 321px) {
  .product-switch__image > img {
    height: 400px;
  }
}

@media (min-width: 414px) {
  .product-switch__image > img {
    height: 440px;
  }
}

@media (min-width: 768px) {
  .product-switch__image > img {
    height: 850px;
  }
}

@media (min-width: 1024px) {
  .product-switch__image > img {
    height: 1080px;
  }
}

@media (min-width: 1200px) {
  .product-switch__image > img {
    height: 561px;
  }
}

.product-switch__image-button.emu-button.emu-button-v2.emu-button-v2 {
  white-space: nowrap;
  min-width: 250px;
  padding-left: 34px;
  padding-right: 34px;
  position: absolute;
  bottom: 31px;
  left: 50%;
  transform: translateX(-50%);
}

@media (min-width: 768px) {
  .product-switch__image-button.emu-button.emu-button-v2.emu-button-v2 {
    bottom: 25px;
  }
}

@media (min-width: 1024px) {
  .product-switch__image-button.emu-button.emu-button-v2.emu-button-v2 {
    bottom: 26px;
  }
}

.product-switch__image-button.emu-button.emu-button-v2.emu-button-v2 span.cmp-button__text {
  top: 1px;
}

.product-switch__description {
  margin-top: 10px;
  margin-bottom: 75px;
  padding-left: 5px;
  padding-right: 5px;
}

.product-switch__description * {
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  line-height: 15px;
}

@media (min-width: 414px) {
  .product-switch__description {
    margin-bottom: 85px;
  }
}

@media (min-width: 768px) {
  .product-switch__description {
    margin-top: 45px;
    margin-bottom: 92px;
  }
}

@media (min-width: 1200px) {
  .product-switch__description {
    max-width: 540px;
    margin-top: 36px;
    margin-bottom: 40px;
  }
}

.product-switch__description sup {
  font-size: 6px;
  top: 1px;
}



.testimonial-carousel__container {
  padding: 50px 15px 57px;
  overflow: hidden;
}

@media (min-width: 321px) {
  .testimonial-carousel__container {
    padding-top: 53px;
    padding-bottom: 51px;
  }
}

@media (min-width: 768px) {
  .testimonial-carousel__container {
    padding: 95px 60px 60px;
  }
}

@media (min-width: 1024px) {
  .testimonial-carousel__container {
    padding-top: 96px;
  }
}

@media (min-width: 1025px) {
  .testimonial-carousel__container {
    padding-top: 105px;
    padding-bottom: 101px;
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

.testimonial-carousel__container .emphasis {
  display: block;
}

@media (min-width: 1025px) {
  .testimonial-carousel__container .emphasis {
    display: inline;
  }
}

.testimonial-carousel__container-inner {
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
  margin-left: -15px;
  margin-right: -15px;
}

.testimonial-carousel__block {
  max-width: none;
  margin-left: -15px;
  margin-right: -15px;
}

@media (min-width: 1025px) {
  .testimonial-carousel__block {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1200px) {
  .testimonial-carousel__block {
    max-width: var(--emu-semantic-sizing-breakpoints-large-plus);
  }
}

.testimonial-carousel__title {
  margin-bottom: 7px;
}

@media (min-width: 1024px) {
  .testimonial-carousel__title {
    margin-bottom: 5px;
  }
}

@media (min-width: 1025px) {
  .testimonial-carousel__title {
    margin-bottom: 6px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .testimonial-carousel__title .cmp-title__text {
    font-size: 46px;
    line-height: 50px;
  }
}

@media (min-width: 1025px) {
  .testimonial-carousel__title .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
    line-height: var(--emu-semantic-line-heights-wide-xl);
  }

  .testimonial-carousel__title .cmp-title__text br {
    display: none;
  }
}

.testimonial-carousel__text {
  margin-bottom: 37px;
}

@media (min-width: 321px) {
  .testimonial-carousel__text {
    margin-bottom: 36px;
  }
}

@media (min-width: 768px) {
  .testimonial-carousel__text {
    margin-bottom: var(--emu-common-spacing-xl);
    padding-left: 20px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .testimonial-carousel__text {
    font-size: 22px;
    line-height: 40px;
  }
}

@media (min-width: 1025px) {
  .testimonial-carousel__text {
    font-size: var(--emu-semantic-font-sizes-wide-small);
    line-height: var(--emu-semantic-line-heights-wide-small);
    margin-bottom: 80px;
  }
}

.testimonial-carousel__text br {
  display: block;
}

@media (min-width: 768px) {
  .testimonial-carousel__text br {
    display: none;
  }
}

.testimonial-carousel__slider {
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

@media (min-width: 321px) {
  .testimonial-carousel__slider {
    width: 72%;
  }
}

@media (min-width: 768px) {
  .testimonial-carousel__slider {
    align-items: normal;
    width: 90%;
  }
}

@media (min-width: 1025px) {
  .testimonial-carousel__slider {
    flex-direction: row;
    width: 100%;
  }
}

.testimonial-carousel__slider .embed {
  width: 100%;
  margin-bottom: 36px;
}

@media (min-width: 768px) {
  .testimonial-carousel__slider .embed {
    margin-bottom: var(--emu-common-spacing-xl);
  }
}

@media (min-width: 1025px) {
  .testimonial-carousel__slider .embed {
    margin-bottom: var(--emu-common-spacing-none);
    width: 55%;
  }
}

@media (min-width: 321px) {
  .testimonial-carousel__slider > .container {
    padding-right: 19%;
  }
}

@media (min-width: 768px) {
  .testimonial-carousel__slider > .container {
    padding-right: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1025px) {
  .testimonial-carousel__slider > .container {
    width: 35%;
    padding-top: 36px;
    padding-left: 20px;
  }
}

.testimonial-carousel__slider-title {
  margin-bottom: 14px;
}

@media (min-width: 768px) {
  .testimonial-carousel__slider-title {
    margin-bottom: 10px;
  }
}

.testimonial-carousel__slider-title .cmp-title__text {
  font-size: var(--emu-common-font-sizes-wide-medium);
  line-height: var(--emu-semantic-line-heights-narrow-medium);
}

@media (min-width: 768px) {
  .testimonial-carousel__slider-title .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-narrow-large);
    line-height: var(--emu-semantic-line-heights-wide-large);
  }
}

@media (min-width: 1025px) {
  .testimonial-carousel__slider-title .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    line-height: var(--emu-semantic-line-heights-wide-medium);
  }
}

.testimonial-carousel__slider-subtitle {
  font-size: 20px;
  line-height: var(--emu-common-line-heights-narrow-large);
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .testimonial-carousel__slider-subtitle {
    margin-bottom: 6px;
    font-size: 36px;
    line-height: 49px;
  }
}

@media (min-width: 1025px) {
  .testimonial-carousel__slider-subtitle {
    margin-bottom: var(--emu-common-spacing-small);
  }
}

.testimonial-carousel__slider-subtitle p, .testimonial-carousel__slider-subtitle li {
  font-size: inherit;
  line-height: inherit;
}

.testimonial-carousel__slider-text {
  font-size: var(--emu-common-font-sizes-wide-medium);
  line-height: var(--emu-semantic-line-heights-narrow-medium);
  margin-bottom: 1px;
}

@media (min-width: 768px) {
  .testimonial-carousel__slider-text {
    margin-bottom: 6px;
    padding-right: 10px;
    font-size: 28px;
    line-height: 42px;
  }
}

@media (min-width: 1025px) {
  .testimonial-carousel__slider-text {
    font-size: 15px;
    line-height: 32px;
  }
}

.testimonial-carousel__slider-text p, .testimonial-carousel__slider-text li {
  font-size: inherit;
  line-height: inherit;
}

.timeline-item {
  margin-top: 40px;
  margin-bottom: 40px;
}

@media (min-width: 767px) {
  .timeline-item {
    padding-left: 45px;
    padding-right: 45px;
  }
}

@media (min-width: 768px) {
  .timeline-item {
    margin-top: 88px;
    margin-bottom: 88px;
  }
}

@media (min-width: 1200px) {
  .timeline-item {
    padding-right: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
    padding-left: var(--emu-common-spacing-none);
    justify-content: space-between;
    margin-top: 3%;
    margin-bottom: 55px;
    display: flex;
    position: relative;
  }
}

.timeline-item--no-title {
  margin-top: 70px;
}

@media (min-width: 414px) {
  .timeline-item--no-title {
    margin-top: 72px;
  }
}

@media (min-width: 768px) {
  .timeline-item--no-title {
    margin-top: 118px;
  }
}

@media (min-width: 1200px) {
  .timeline-item--no-title {
    justify-content: flex-end;
    margin-top: 3%;
  }

  .timeline-item > .title {
    width: 42%;
  }

  .timeline-item > .container {
    justify-self: flex-end;
    width: 42%;
  }
}

.timeline-item__title {
  margin-bottom: 36px;
  padding-top: 3px;
}

@media (min-width: 414px) {
  .timeline-item__title {
    padding-top: 5px;
  }
}

@media (min-width: 767px) {
  .timeline-item__title {
    margin-bottom: 100px;
  }
}

@media (min-width: 1024px) {
  .timeline-item__title {
    padding-right: 20%;
  }
}

@media (min-width: 1200px) {
  .timeline-item__title {
    padding-right: var(--emu-common-spacing-none);
    margin-bottom: var(--emu-common-spacing-none);
  }
}

.timeline-item__title--mb-lg {
  margin-bottom: 42px;
}

@media (min-width: 767px) {
  .timeline-item__title--mb-lg {
    margin-bottom: 74px;
  }
}

@media (min-width: 1200px) {
  .timeline-item__title--mb-lg {
    margin-bottom: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1024px) {
  .timeline-item__title--pr-lg {
    padding-right: 40%;
  }
}

@media (min-width: 1200px) {
  .timeline-item__title--pr-lg {
    padding-right: var(--emu-common-spacing-none);
  }
}

.timeline-item__title br {
  display: none;
}

.timeline-item__title br:nth-child(2) {
  display: block;
}

@media (min-width: 768px) {
  .timeline-item__title br:nth-child(2) {
    display: none;
  }

  .timeline-item__title br:first-child, .timeline-item__title br:nth-child(3) {
    display: block;
  }
}

@media (min-width: 1200px) {
  .timeline-item__title br:first-child, .timeline-item__title br:nth-child(3) {
    display: none;
  }

  .timeline-item__title br:nth-child(2) {
    display: block;
  }
}

.timeline-item__content {
  justify-content: space-between;
  display: flex;
  position: relative;
}

@media (min-width: 1200px) {
  .timeline-item__content {
    position: static;
  }
}

.timeline-item__content > .container {
  width: 22%;
}

@media (min-width: 321px) {
  .timeline-item__content > .container {
    width: 18%;
  }
}

@media (min-width: 414px) {
  .timeline-item__content > .container {
    width: 20%;
    margin-top: -1px;
  }
}

@media (min-width: 1200px) {
  .timeline-item__content > .container {
    margin: var(--emu-common-spacing-none);
    width: 7%;
    height: calc(100% - 2px);
    position: absolute;
    top: 2px;
    left: 43%;
  }
}

.cq-Editable-dom--container .timeline-item__content > .container {
  width: 20%;
  position: static;
}

.timeline-item__content > .text {
  width: 70%;
}

@media (min-width: 321px) {
  .timeline-item__content > .text {
    width: 75%;
  }
}

@media (min-width: 1200px) {
  .timeline-item__content > .text {
    width: 100%;
  }
}

.timeline-item__content-separator {
  background-color: var(--emu-common-colors-white);
  width: 2px;
  height: 100%;
  position: relative;
}

.timeline-item__content-separator p, .timeline-item__content-separator ul {
  display: none;
}

.timeline-item__content-separator:before, .timeline-item__content-separator:after {
  content: "";
  background-color: var(--emu-semantic-colors-primary-50);
  z-index: var(--emu-common-other-z-index-layer);
  border-radius: 100%;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-50%)translateX(1px);
}

.timeline-item__content-separator:after {
  top: auto;
  bottom: 0;
}

.timeline-item__content-date-container {
  justify-content: space-between;
  height: 100%;
  display: flex;
}

@media (min-width: 768px) {
  .timeline-item__content-date-container {
    margin-top: -1px;
  }
}

@media (min-width: 1024px) {
  .timeline-item__content-date-container {
    margin-top: -2px;
  }
}

.timeline-item__content-date .cmp-title__text {
  font-size: 15px;
  line-height: var(--emu-semantic-line-heights-narrow-small);
  margin-top: var(--emu-common-spacing-xxs);
}

@media (min-width: 321px) {
  .timeline-item__content-date .cmp-title__text {
    margin-top: 18px;
  }
}

@media (min-width: 414px) {
  .timeline-item__content-date .cmp-title__text {
    font-size: 20px;
  }
}

@media (min-width: 576px) {
  .timeline-item__content-date .cmp-title__text {
    margin-top: 5px;
    font-size: 28px;
  }
}

@media (min-width: 1200px) {
  .timeline-item__content-date .cmp-title__text {
    margin-top: 17px;
    font-size: 18px;
  }
}

@media (min-width: 414px) {
  .timeline-item__content-desc {
    font-size: 16px;
    line-height: 29px;
  }
}

@media (min-width: 576px) {
  .timeline-item__content-desc {
    font-size: 22px;
    line-height: 40px;
  }
}

@media (min-width: 768px) {
  .timeline-item__content-desc {
    font-size: 28px;
    line-height: 42px;
  }
}

@media (min-width: 1200px) {
  .timeline-item__content-desc {
    padding-top: var(--emu-common-spacing-xxs);
    font-size: 24px;
    line-height: 31px;
  }
}

.timeline-item__content-desc p, .timeline-item__content-desc li {
  font-size: inherit;
  line-height: inherit;
}

.timeline-item__content-desc p {
  margin-bottom: 15px;
}

@media (min-width: 414px) {
  .timeline-item__content-desc p {
    margin-bottom: 13px;
  }
}

@media (min-width: 768px) {
  .timeline-item__content-desc p {
    margin-bottom: 15px;
  }
}

@media (min-width: 1200px) {
  .timeline-item__content-desc p {
    margin-bottom: 14px;
  }
}

.timeline-item__content-desc sup {
  top: .1em;
}

.timeline-item__content-desc h4 {
  font-size: 15px;
  line-height: 26px;
  font-weight: var(--emu-common-font-weight-bold);
  margin-top: var(--emu-common-spacing-xxs);
  margin-bottom: 7px;
}

@media (min-width: 414px) {
  .timeline-item__content-desc h4 {
    margin-top: var(--emu-common-spacing-none);
    margin-bottom: 9px;
    font-size: 17px;
    line-height: 135%;
  }
}

@media (min-width: 576px) {
  .timeline-item__content-desc h4 {
    margin-top: 1px;
    margin-bottom: var(--emu-common-spacing-small);
    font-size: 24px;
    line-height: 32.78px;
  }
}

@media (min-width: 767px) {
  .timeline-item__content-desc h4 {
    margin-top: var(--emu-common-spacing-none);
    font-size: 30px;
    line-height: 40.98px;
  }
}

@media (min-width: 1200px) {
  .timeline-item__content-desc h4 {
    font-size: var(--emu-semantic-font-sizes-wide-small);
    line-height: 33px;
  }
}

.treatment-plan__wrapper {
  padding-top: 68px;
  padding-bottom: 20px;
  position: relative;
}

@media (min-width: 768px) {
  .treatment-plan__wrapper {
    padding-top: 73px;
    padding-bottom: 105px;
  }
}

@media (min-width: 1200px) {
  .treatment-plan__wrapper {
    padding-top: 111px;
    padding-bottom: 100px;
  }
}

@media (min-width: 768px) {
  .treatment-plan__wrapper.relative-modal__wrapper--hide {
    padding-bottom: 20px;
  }
}

@media (min-width: 1200px) {
  .treatment-plan__wrapper.relative-modal__wrapper--hide {
    padding-top: 80px;
    padding-bottom: 411px;
  }
}

.treatment-plan__wrapper.relative-modal__wrapper--hide .treatment-plan__scroll-wrapper, .treatment-plan__wrapper.relative-modal__wrapper--hide .treatment-plan__accordion-wrapper {
  display: none;
}

@media (min-width: 1200px) {
  .treatment-plan__wrapper.relative-modal__wrapper--hide .treatment-plan__title-container {
    display: none;
  }
}

.treatment-plan__title-container {
  width: 82%;
  margin: auto auto 53px;
}

@media (min-width: 321px) {
  .treatment-plan__title-container {
    width: 75%;
    margin-bottom: 73px;
  }
}

@media (min-width: 414px) {
  .treatment-plan__title-container {
    width: 70%;
  }
}

@media (min-width: 768px) {
  .treatment-plan__title-container {
    margin-bottom: 18px;
  }
}

@media (min-width: 1200px) {
  .treatment-plan__title-container {
    width: 100%;
    padding-bottom: 150px;
  }
}

.treatment-plan__title {
  margin-top: -4px;
  margin-bottom: 7px;
}

@media (min-width: 768px) {
  .treatment-plan__title {
    margin-top: -6px;
    margin-bottom: 33px;
    margin-right: 10px;
  }
}

@media (min-width: 1024px) {
  .treatment-plan__title {
    margin-bottom: var(--emu-common-spacing-large);
  }
}

@media (min-width: 1200px) {
  .treatment-plan__title {
    margin-bottom: 33px;
    margin-right: 10px;
  }
}

@media (min-width: 1025px) and (max-width: 1199px) {
  .treatment-plan__sub-title {
    font-size: 28px;
    line-height: 42px;
  }
}

.treatment-plan__sub-title br {
  display: block;
}

@media (min-width: 768px) {
  .treatment-plan__sub-title br {
    display: none;
  }
}

@media (min-width: 1200px) {
  .treatment-plan__sub-title br {
    display: block;
  }
}

.treatment-plan__accordion-wrapper {
  display: block;
}

@media (min-width: 1200px) {
  .treatment-plan__accordion-wrapper {
    display: none;
  }
}

.cq-Editable-dom--container .treatment-plan__accordion-wrapper {
  display: block;
}

.treatment-plan__scroll-wrapper {
  display: none;
}

@media (min-width: 1200px) {
  .treatment-plan__scroll-wrapper {
    justify-content: center;
    max-height: 700px;
    margin-top: 35px;
    margin-bottom: -15px;
    padding-left: 63px;
    padding-right: 63px;
    display: flex;
    overflow: auto;
  }

  .cq-Editable-dom--container .treatment-plan__scroll-wrapper {
    max-height: none !important;
  }

  .treatment-plan__scroll-wrapper::-webkit-scrollbar {
    display: none;
  }

  .treatment-plan__scroll-wrapper > div:first-child {
    position: sticky;
    top: 0;
    overflow: hidden;
  }
}

.cq-Editable-dom--container .treatment-plan__scroll-wrapper {
  max-height: none;
  display: flex;
}

.treatment-plan__scroll-menu {
  justify-content: flex-end;
  width: 100%;
  max-width: 580px;
  height: 100%;
  display: flex;
}

@media (min-width: 1300px) {
  .treatment-plan__scroll-menu {
    max-width: 600px;
  }
}

.treatment-plan__scroll-menu-text {
  z-index: var(--emu-common-other-z-index-base);
  border-right-color: var(--emu-common-colors-white);
  border-right-width: var(--emu-common-border-width-thin);
  border-right-style: solid;
  height: 100%;
  margin-right: 30px;
  padding-right: 30px;
  display: flex;
  position: relative;
}

.treatment-plan__scroll-menu-text:before, .treatment-plan__scroll-menu-text:after {
  content: "";
  z-index: var(--emu-common-other-z-index-layer);
  background-color: var(--emu-semantic-colors-primary-50);
  border-radius: 50%;
  width: 12px;
  height: 12px;
  position: absolute;
  right: -6px;
  transform: translateY(-50%);
}

.treatment-plan__scroll-menu-text:before {
  top: 6px;
}

.treatment-plan__scroll-menu-text:after {
  bottom: -6px;
}

.treatment-plan__scroll-menu-text ul {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  padding-left: 10px;
  padding-right: 10px;
  list-style: none;
}

.treatment-plan__scroll-menu-text ul li {
  font-size: var(--emu-semantic-font-sizes-wide-xl);
  line-height: var(--emu-semantic-line-heights-wide-xl);
  counter-increment: section;
  font-family: var(--emu-semantic-font-families-heading);
  padding-top: 5px;
  padding-bottom: 68px;
  padding-left: 56px;
  display: flex;
  position: relative;
}

.os-windows .treatment-plan__scroll-menu-text ul li {
  padding-top: 13px;
  padding-bottom: 61px;
}

.treatment-plan__scroll-menu-text ul li > a {
  opacity: .1;
  text-decoration: none;
  transition: all .3s ease-out;
}

.treatment-plan__scroll-menu-text ul li > a, .treatment-plan__scroll-menu-text ul li > a:active, .treatment-plan__scroll-menu-text ul li > a:visited {
  color: #0077bf;
}

.treatment-plan__scroll-menu-text ul li > a:hover {
  color: var(--emu-common-colors-white);
  opacity: 1;
}

.treatment-plan__scroll-menu-text ul li > a:after {
  content: counter(section);
  font-family: var(--emu-semantic-font-families-heading);
  color: var(--emu-semantic-colors-primary-100);
  background-color: var(--emu-common-colors-white);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 41px;
  height: 41px;
  font-size: 28px;
  line-height: 15px;
  display: flex;
  position: absolute;
  top: 8px;
  left: 0;
}

.os-windows .treatment-plan__scroll-menu-text ul li > a:after {
  padding-top: 5px;
  top: 10px;
}

.treatment-plan__scroll-menu-text ul li > a.active {
  opacity: 1;
  color: var(--emu-common-colors-white);
}

@media (min-width: 1200px) {
  .treatment-plan__scroll-content {
    padding-top: 60px;
    padding-bottom: 60px;
    scroll-margin-top: 150px;
  }

  .treatment-plan__scroll-content-wrapper {
    max-width: 580px;
  }
}

@media (min-width: 1200px) and (min-width: 1300px) {
  .treatment-plan__scroll-content-wrapper {
    width: 580px;
  }
}

@media (min-width: 1200px) {
  .treatment-plan__scroll-content-wrapper > div:first-child {
    margin-top: -60px;
    margin-bottom: 170px;
  }
}

@media (min-width: 768px) {
  .treatment-plan__scroll-content-text {
    padding-left: 38px;
    padding-right: 30px;
  }
}

@media (min-width: 1200px) {
  .treatment-plan__scroll-content-text {
    padding: var(--emu-common-spacing-none);
  }
}

.treatment-plan__scroll-content-text ul {
  margin: var(--emu-common-spacing-none);
  padding-left: 35px;
  padding-right: 20px;
}

@media (min-width: 1200px) {
  .treatment-plan__scroll-content-text ul {
    padding-left: 25px;
    padding-right: var(--emu-common-spacing-none);
  }
}

.treatment-plan__scroll-content-text ul li {
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  margin-bottom: 19px;
  line-height: 21px;
}

@media (min-width: 414px) {
  .treatment-plan__scroll-content-text ul li {
    font-size: 15px;
    line-height: 28px;
  }
}

@media (min-width: 768px) {
  .treatment-plan__scroll-content-text ul li {
    margin-bottom: 29px;
    font-size: 28px;
    line-height: 42px;
  }
}

@media (min-width: 1024px) {
  .treatment-plan__scroll-content-text ul li {
    margin-bottom: 31px;
    font-size: 28px;
    line-height: 42px;
  }
}

@media (min-width: 1200px) {
  .treatment-plan__scroll-content-text ul li {
    margin-bottom: 30px;
    font-size: 23px;
    line-height: 35px;
  }
}

.treatment-plan__scroll-content-text ul li b {
  font-family: var(--emu-semantic-font-families-heading);
}

.treatment-plan__scroll-content-text ul li b a {
  text-decoration: none;
}

.treatment-plan__scroll-content-cta {
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 619px;
  height: 51px;
}

@media (min-width: 414px) {
  .treatment-plan__scroll-content-cta {
    height: 66px;
  }
}

@media (min-width: 768px) {
  .treatment-plan__scroll-content-cta {
    height: 72px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1024px) {
  .treatment-plan__scroll-content-cta {
    height: 82px;
  }
}

@media (min-width: 1200px) {
  .treatment-plan__scroll-content-cta {
    width: -moz-fit-content;
    width: fit-content;
    height: 59px;
    margin-left: 25px;
  }
}

.treatment-plan__scroll-content .button {
  display: flex;
}

@media (min-width: 768px) {
  .treatment-plan__scroll-content .button {
    margin-top: 20px;
  }
}

@media (min-width: 1024px) {
  .treatment-plan__scroll-content .button {
    margin-top: var(--emu-common-spacing-medium);
    justify-content: center;
  }
}

@media (min-width: 1200px) {
  .treatment-plan__scroll-content .button {
    justify-content: flex-start;
    margin-top: -10px;
  }
}

.treatment-plan__scroll-content .button .emu-button-v2.emu-button {
  font-size: 18px;
  line-height: 33px;
}

@media (min-width: 768px) {
  .treatment-plan__scroll-content .button .emu-button-v2.emu-button {
    font-size: var(--emu-semantic-font-sizes-wide-small);
  }
}

@media (min-width: 1024px) {
  .treatment-plan__scroll-content .button .emu-button-v2.emu-button {
    font-size: 28px;
  }
}

@media (min-width: 1200px) {
  .treatment-plan__scroll-content .button .emu-button-v2.emu-button {
    font-size: var(--emu-semantic-font-sizes-wide-small);
  }
}

.treatment-plan__scroll-content-disclaimer p {
  font-size: var(--emu-common-font-sizes-narrow-medium);
  line-height: var(--emu-common-line-heights-narrow-medium);
}

/*# sourceMappingURL=main.css.map */
