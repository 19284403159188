.testimonial-carousel {
  &__container {
    padding-top: 50px;
    padding-bottom: 57px;
    overflow: hidden;
    padding-left: 15px;
    padding-right: 15px;

    // breakpoint needed to match the live site
    @include mq('321px') {
      padding-top: 53px;
      padding-bottom: 51px;
    }

    @include mq('medium') {
      padding-top: 95px;
      padding-bottom: 60px;
      padding-left: 60px;
      padding-right: 60px;
    }

    @include mq('large') {
      padding-top: 96px;
    }

    // breakpoint needed to match the live site
    @include mq('1025px') {
      padding-top: 105px;
      padding-bottom: 101px;
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
    }

    .emphasis {
      display: block;

      // breakpoint needed to match the live site
      @include mq('1025px') {
        display: inline;
      }
    }

    &-inner {
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  &__block {
    margin-left: -15px;
    margin-right: -15px;
    max-width: none;

    @include mq('1025px') {
      margin-left: auto;
      margin-right: auto;
      max-width: 960px;
      padding-left: 15px;
      padding-right: 15px;
    }

    @include mq('x-large') {
      max-width: var(--emu-semantic-sizing-breakpoints-large-plus);
    }
  }

  &__title {
    margin-bottom: 7px;

    @include mq('large') {
      margin-bottom: 5px;
    }

    // breakpoint needed to match the live site
    @include mq('1025px') {
      margin-bottom: 6px;
    }

    .cmp-title__text {
      // breakpoint needed to match the live site
      @media (min-width: 576px) and (max-width: 767px) {
        font-size: 46px;
        line-height: 50px;
      }

      // breakpoint needed to match the live site
      @include mq('1025px') {
        font-size: var(--emu-semantic-font-sizes-wide-xl);
        line-height: var(--emu-semantic-line-heights-wide-xl);
      }

      br {
        // breakpoint needed to match the live site
        @include mq('1025px') {
          display: none;
        }
      }
    }
  }

  &__text {
    margin-bottom: 37px;

    // breakpoint needed to match the live site
    @include mq('321px') {
      margin-bottom: 36px;
    }

    @include mq('medium') {
      padding-left: 20px;
      margin-bottom: var(--emu-common-spacing-xl);
    }

    // breakpoint needed to match the live site
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 22px;
      line-height: 40px;
    }

    // breakpoint needed to match the live site
    @include mq('1025px') {
      font-size: var(--emu-semantic-font-sizes-wide-small);
      line-height: var(--emu-semantic-line-heights-wide-small);
      margin-bottom: 80px;
    }

    br {
      display: block;

      @include mq('medium') {
        display: none;
      }
    }
  }

  &__slider {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;

    // breakpoint needed to match the live site
    @include mq('321px') {
      width: 72%;
    }

    @include mq('medium') {
      align-items: normal;
      width: 90%;
    }

    // breakpoint needed to match the live site
    @include mq('1025px') {
      flex-direction: row;
      width: 100%;
    }

    .embed {
      width: 100%;
      margin-bottom: 36px;

      @include mq('medium') {
        margin-bottom: var(--emu-common-spacing-xl);
      }

      // breakpoint needed to match the live site
      @include mq('1025px') {
        width: 55%;
        margin-bottom: var(--emu-common-spacing-none);
      }
    }

    > .container {
      // breakpoint needed to match the live site
      @include mq('321px') {
        padding-right: 19%; // needed to match the live site
      }

      @include mq('medium') {
        padding-right: var(--emu-common-spacing-none);
      }

      // breakpoint needed to match the live site
      @include mq('1025px') {
        width: 35%; // As per design
        padding-left: 20px;
        padding-top: 36px;
      }
    }

    &-title {
      margin-bottom: 14px;

      @include mq('medium') {
        margin-bottom: 10px;
      }

      .cmp-title__text {
        font-size: var(--emu-common-font-sizes-wide-medium);
        line-height: var(--emu-semantic-line-heights-narrow-medium);

        @include mq('medium') {
          font-size: var(--emu-semantic-font-sizes-narrow-large);
          line-height: var(--emu-semantic-line-heights-wide-large);
        }

        // breakpoint needed to match the live site
        @include mq('1025px') {
          font-size: var(--emu-semantic-font-sizes-wide-medium);
          line-height: var(--emu-semantic-line-heights-wide-medium);
        }
      }
    }

    &-subtitle {
      font-size: 20px;
      line-height: var(--emu-common-line-heights-narrow-large);
      margin-bottom: 15px;

      @include mq('medium') {
        font-size: 36px;
        line-height: 49px;
        margin-bottom: 6px;
      }

      // breakpoint needed to match the live site
      @include mq('1025px') {
        margin-bottom: var(--emu-common-spacing-small);
      }

      p,
      li {
        font-size: inherit;
        line-height: inherit;
      }
    }

    &-text {
      font-size: var(--emu-common-font-sizes-wide-medium);
      line-height: var(--emu-semantic-line-heights-narrow-medium);
      margin-bottom: 1px;

      @include mq('medium') {
        font-size: 28px;
        line-height: 42px;
        margin-bottom: 6px;
        padding-right: 10px;
      }

      // breakpoint needed to match the live site
      @include mq('1025px') {
        font-size: 15px;
        line-height: 32px;
      }

      p,
      li {
        font-size: inherit;
        line-height: inherit;
      }
    }
  }
}
