#california-supply-chain-act {
  .csc-act {
    &__content {
      > p {
        font-size: var(--emu-semantic-font-sizes-narrow-medium);
        color: var(--emu-semantic-colors-primary-950);
        line-height: var(--emu-common-line-heights-wide-large);
        margin-bottom: 37px;
        padding-left: var(--emu-common-spacing-xs);

        @include mq('medium') {
          padding-right: 15px;
          padding-left: 15px;
        }
      }

      &-wrapper {
        padding-bottom: 60px;
      }
    }
  }
}
