#how-cs-works {
  // main banner styles
  .banner {
    &.banner--main {
      .banner {
        &__teaser {
          height: 594px;

          // breakpoint needed to match the live site
          @include mq('321px') {
            height: 700px;
          }

          // breakpoint needed to match the live site
          @include mq('414px') {
            height: 775px;
          }

          // breakpoint needed to match the live site
          @include mq('576px') {
            height: 1075px;
          }

          @include mq('medium') {
            height: 1432px;
          }

          @include mq('large') {
            height: 1910px;
          }

          @include mq('x-large') {
            height: 656px;
          }

          // breakpoint needed to match the live site
          @include mq('1401px') {
            height: 660px;
          }

          // breakpoint needed to match the live site
          @include mq('1601px') {
            height: 750px;
          }

          // breakpoint needed to match the live site
          @include mq('2001px') {
            height: 900px;
          }

          .disclaimer-bottom-right .emu-teaser__disclaimer {
            padding-bottom: 15px;

            @include mq('medium') {
              padding-bottom: 25px;
            }
          }
        }

        &__content {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          padding-top: var(--emu-common-spacing-none);
          height: calc(100% - 62px); // height of the disclaimer
          padding-bottom: 10%;

          // breakpoint needed to match the live site
          @include mq('451px') {
            top: 1px;
            height: calc(100% - 48.5px); // height of the disclaimer
          }

          @include mq('medium') {
            padding-left: 15px;
            padding-right: 15px;
            max-width: var(--emu-common-sizing-tablet);
            left: 50%;
            transform: translateX(-50%);
            height: 100%;
            padding-top: 105%; // value from live site
            justify-content: flex-start;
            padding-bottom: var(--emu-common-spacing-none);
            top: 0;
          }

          @include mq('large') {
            padding-top: 115%; // value from live site
          }

          // breakpoint needed to match the live site
          @include mq('1025px') {
            justify-content: center;
            height: calc(100% - 58.5px); // height of the disclaimer
            max-width: none;
            left: 0;
            transform: none;
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
          }

          // breakpoint needed to match the live site
          @include mq('1199px') {
            padding-left: 38px;
            max-width: var(--emu-common-sizing-desktop);
            left: 50%;
            transform: translateX(-50%);
          }

          // breakpoint needed to match the live site
          @include mq('x-large') {
            padding-top: var(--emu-common-spacing-none);
          }

          &-inner {
            @include mq('medium') {
              padding-left: 75px;
              padding-right: var(--emu-common-spacing-none);
            }

            // breakpoint needed to match the live site
            @include mq('1025px') {
              padding-left: var(--emu-common-spacing-none);
            }
          }

          &-img {
            img {
              max-width: none;
              width: 100%;
              padding-right: 31%; // value from live site
              padding-left: 1px; // value from live site

              // breakpoint needed to match the live site
              @include mq('321px') {
                padding-right: 38%; // value from live site
                padding-left: var(--emu-common-spacing-none);
              }

              // breakpoint needed to match the live site
              @include mq('414px') {
                padding-right: 38.5%; // value from live site
              }

              // breakpoint needed to match the live site
              @include mq('576px') {
                padding-right: 41.5%; // value from live site
                padding-left: 1px; // value from live site
              }

              @include mq('medium') {
                width: 515px;
                height: auto;
                padding: var(--emu-common-spacing-none);
                margin-left: -85px;
              }

              // breakpoint needed to match the live site
              @include mq('1025px') {
                width: 613px;
                padding-left: 2%; // value from live site
                margin-left: var(--emu-common-spacing-none);
              }

              @include mq('x-large') {
                width: 600px;
                padding-left: 3%; // value from live site
              }
            }
          }

          &-title {
            padding-right: 5%; // value from live site
            padding-left: 30px;
            margin-top: -26px;

            // breakpoint needed to match the live site
            @media (min-width: 321px) and (max-width: 413px) {
              margin-top: -27px;
            }

            // breakpoint needed to match the live site
            @media (min-width: 576px) and (max-width: 767px) {
              margin-top: -41px;
            }

            @include mq('medium') {
              padding: var(--emu-common-spacing-none);
              margin-top: -54px;
            }

            // breakpoint needed to match the live site
            @include mq('1025px') {
              padding-left: 70px;
              padding-right: 40%; // value from live site
              margin-top: -45px;
            }

            .cmp-title__text {
              // breakpoint needed to match the live site
              @media (min-width: 321px) and (max-width: 413px) {
                font-size: var(--emu-semantic-font-sizes-narrow-large);
                line-height: var(--emu-semantic-line-heights-narrow-large);
              }

              // breakpoint needed to match the live site
              @media (min-width: 576px) and (max-width: 767px) {
                font-size: 46px;
                line-height: 50px;
              }
            }
          }

          &-text {
            padding-left: 30px;

            // breakpoint needed to match the live site
            @media (min-width: 576px) and (max-width: 767px) {
              font-size: 22px;
              line-height: 40px;
              padding-left: 70px;
              padding-right: 30px;
            }

            @include mq('medium') {
              padding-left: var(--emu-common-spacing-none);
              margin-top: 15px;
            }

            // breakpoint needed to match the live site
            @include mq('1025px') {
              padding-left: 70px;
              margin-top: 12px;
            }

            br {
              display: none;

              @include mq('x-large') {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  // freeze section
  .freeze-section {
    position: relative;
    overflow: hidden;

    &__title {
      h3 {
        // media query needed to match the live site
        @include mq('320px', 'max-width') {
          font-size: 28px;
          line-height: 34px;
        }

        // media query needed to match the live site
        @media (min-width: 414px) and (max-width: 575px) {
          font-size: 33px;
          line-height: var(--emu-common-line-heights-narrow-xl);
        }

        // media query needed to match the live site
        @media (min-width: 576px) and (max-width: 767px) {
          font-size: 46px;
          line-height: 50px;
        }

        // media query needed to match the live site
        @media (min-width: 768px) and (max-width: 1024px) {
          font-size: 64px;
          line-height: 69px;
        }
      }
    }

    &__bg {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-size: cover;
      background-repeat: no-repeat;

      @include mq('medium') {
        background-position: center center;
      }
    }

    &__container {
      position: relative;
      z-index: var(--emu-common-other-z-index-layer);
      padding-top: 80px;
    }

    &__intro {
      // breakpoint needed to match the live site
      @include mq('321px') {
        padding-left: 15px;
        padding-right: 15px;
      }

      @include mq('medium') {
        padding-left: 50px;
        padding-right: 50px;
      }

      &-img {
        width: 50%;
        margin-left: auto;
        margin-right: auto;

        @include mq('medium') {
          width: 480px;
        }

        // breakpoint needed to match the live site
        @include mq('1025px') {
          width: 600px;
        }
      }

      &-title {
        margin-top: -23px;
        max-width: 100%;

        @include mq('414px') {
          margin-top: -21px;
        }

        @include mq('medium') {
          margin-top: -74px;
        }

        // breakpoint needed to match the live site
        @include mq('1025px') {
          margin-top: -100px;
        }

        .cmp-title__text {
          br {
            display: none;

            &:nth-of-type(2),
            &:nth-of-type(5) {
              display: block;
            }

            @include mq('medium') {
              &:nth-of-type(2),
              &:nth-of-type(5) {
                display: none;
              }

              &:nth-of-type(1),
              &:nth-of-type(3),
              &:nth-of-type(5) {
                display: block;
              }
            }

            @include mq('x-large') {
              &:nth-of-type(1),
              &:nth-of-type(3),
              &:nth-of-type(5) {
                display: none;
              }

              &:nth-of-type(4) {
                display: block;
              }
            }
          }
        }
      }
    }

    &__grid {
      padding-top: 72px;
      padding-bottom: 75px;

      // breakpoint needed to match the live site
      @include mq('321px') {
        padding-left: 15px;
        padding-right: 15px;
      }

      // media query needed to match the live site
      @include mq('414px') {
        padding-top: 71px;
      }

      @include mq('medium') {
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 68px;
      }

      // breakpoint needed to match the live site
      @include mq('1025px') {
        padding-top: 70px;
        max-width: var(--emu-common-sizing-tablet);
        margin-left: auto;
        margin-right: auto;
        padding-left: 40px;
        padding-right: 40px;
      }

      @include mq('x-large') {
        max-width: var(--emu-common-sizing-large-tablet);
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
        display: flex;
      }

      > .container {
        &:last-child {
          .freeze-section__grid-item-text {
            @include mq('medium') {
              margin-bottom: 15px;
            }
          }
        }

        @include mq('x-large') {
          width: 33.33%; // value from live site
          padding-left: 40px;
          padding-right: 40px;
        }
      }

      &-item {
        padding-left: 23px;
        padding-right: 23px;

        // breakpoint needed to match the live site
        @include mq('321px') {
          padding-left: 26px;
          padding-right: 26px;
        }

        @include mq('medium') {
          max-width: 570px;
          margin-left: auto;
          margin-right: auto;
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
        }

        // breakpoint needed to match the live site
        @include mq('1025px') {
          max-width: none;
        }

        &-img {
          display: flex;
          justify-content: center;
          margin-bottom: 13px;

          // media query needed to match the live site
          @include mq(414px) {
            margin-bottom: 14px;
          }

          @include mq('medium') {
            margin-bottom: 40px;
          }

          // breakpoint needed to match the live site
          @include mq('1025px') {
            margin-bottom: 14px;
          }

          img {
            max-width: var(--emu-common-sizing-large);

            @include mq('medium') {
              max-width: none;
              width: 100px;
            }

            // breakpoint needed to match the live site
            @include mq('1025px') {
              width: auto;
            }
          }
        }

        &-title {
          margin-bottom: 6px;

          // media query needed to match the live site
          @include mq('414px') {
            margin-bottom: 5px;
          }

          @include mq('medium') {
            margin-bottom: 7px;
          }

          .cmp-title__text {
            // breakpoint needed to match the live site
            @include mq('1025px') {
              font-size: var(--emu-semantic-font-sizes-wide-large);
              line-height: 1.2;
            }
          }
        }

        &-text {
          margin-bottom: 40px;
          letter-spacing: 0.08px; // to match live site

          // breakpoint needed to match the live site
          @include mq('576px') {
            font-size: 18px;
            line-height: 29px;
          }

          @include mq('medium') {
            font-size: 28px;
            line-height: 42px;
            margin-bottom: 93px;
          }

          // breakpoint needed to match the live site
          @include mq('1025px') {
            font-size: var(--emu-common-font-sizes-wide-medium);
            line-height: 26px;
            margin-bottom: 13px;
          }

          * {
            font-size: inherit;
            line-height: inherit;
            letter-spacing: inherit;
          }

          sup {
            font-size: 60%;
            line-height: 0;
          }
        }
      }
    }

    &__mini-banner.intro-block {
      // breakpoint needed to match the live site
      @include mq('1025px') {
        max-width: 910px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 50px;
      }

      @include mq('x-large') {
        max-width: 1090px;
      }
    }
  }

  // discover title section
  .discover-cs {
    height: 500px; // value from live site
    position: relative;
    overflow: hidden;

    // breakpoint needed to match the live site
    @include mq('321px') {
      height: 600px;
    }

    // breakpoint needed to match the live site
    @include mq('576px') {
      height: 795px;
    }

    @include mq('medium') {
      height: var(--emu-common-sizing-desktop);
    }

    // breakpoint needed to match the live site
    @include mq('1025px') {
      height: auto;
      margin-top: -150px;
      padding-top: 60px;
    }

    &__teaser {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &__inner {
      display: flex;
      flex-wrap: wrap;
      margin-left: -30px;
      margin-right: -30px;
      max-width: none;
      margin-top: 3px;

      // breakpoint needed to match the live site
      @include mq('576px') {
        margin-top: 5px;
      }

      @include mq('medium') {
        margin: var(--emu-common-spacing-none);
        justify-content: center;
      }

      // breakpoint needed to match the live site
      @include mq('1025px') {
        max-width: var(--emu-common-sizing-tablet);
        margin-left: auto;
        margin-right: auto;
      }

      @include mq('x-large') {
        max-width: var(--emu-common-sizing-large-tablet);
      }
    }

    &__title {
      position: relative;
      z-index: var(--emu-common-other-z-index-layer);
      margin-top: 70%;
      margin-right: auto;
      margin-bottom: auto;
      margin-left: auto;

      // breakpoint needed to match the live site
      @include mq('414px') {
        margin-top: 65%; // value from live site
      }

      // breakpoint needed to match the live site
      @include mq('576px') {
        margin-top: 70%; // value from live site
      }

      @include mq('medium') {
        margin-top: 655px;
        max-width: 500px;
      }

      // breakpoint needed to match the live site
      @include mq('1025px') {
        margin-top: -220px;
        margin-left: -10px;
        margin-right: -10px;
        max-width: none;
      }

      .cmp-title__text {
        font-size: 28px;
        line-height: 34px;

        // breakpoint needed to match the live site
        @include mq('321px') {
          font-size: var(--emu-semantic-font-sizes-narrow-large);
          line-height: var(--emu-semantic-line-heights-narrow-large);
          padding-left: 20%; // value from live site
          padding-right: 20%; // value from live site
        }

        // breakpoint needed to match the live site
        @include mq('414px') {
          font-size: 33px;
          line-height: var(--emu-common-line-heights-narrow-xl);
        }

        // breakpoint needed to match the live site
        @include mq('576px') {
          font-size: 46px;
          line-height: 50px;
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
        }

        @include mq('medium') {
          font-size: 64px;
          line-height: 69px;
        }

        // breakpoint needed to match the live site
        @include mq('1025px') {
          font-size: var(--emu-semantic-font-sizes-wide-xl);
          line-height: var(--emu-semantic-line-heights-wide-xl);
        }
      }
    }

    &__img {
      display: none;

      // breakpoint needed to match the live site
      @include mq('1025px') {
        display: block;
        position: relative;
      }
    }
  }

  // technology cards
  .cs-tech-cards {
    &__container {
      padding-top: 54px;
      padding-left: 15px;
      padding-bottom: 20px;
      padding-right: 15px;

      // breakpoint needed to match the live site
      @include mq('1025px') {
        padding-bottom: 54px;
      }

      &-inner {
        margin-left: -15px;
        margin-right: -15px;
        max-width: none;
        margin-top: -190px;

        // breakpoint needed to match the live site
        @include mq('414px') {
          margin-top: -200px;
        }

        @include mq('medium') {
          margin-top: -350px;
        }

        @include mq('large') {
          max-width: var(--emu-common-sizing-tablet);
          margin-left: auto;
          margin-right: auto;
        }

        // breakpoint needed to match the live site
        @include mq('1025px') {
          margin-top: -100px;
          display: flex;
          justify-content: space-evenly;
        }

        @include mq('x-large') {
          max-width: var(--emu-common-sizing-large-tablet);
        }

        // breakpoint needed to match the design
        @include mq('1367px') {
          margin-top: -190px;
        }

        > .container {
          &:not(:first-child) {
            margin-top: 30px;

            // breakpoint needed to match the live site
            @include mq('1025px') {
              margin-top: var(--emu-common-spacing-none);
            }
          }

          // breakpoint needed to match the live site
          @include mq('1025px') {
            max-width: 45%; // value from live site
          }
        }
      }
    }

    &__item {
      margin-top: 15px;
      margin-right: auto;
      margin-bottom: 15px;
      margin-left: auto;
      padding-top: 83px;
      padding-left: 1%; // value from live site
      padding-bottom: 80px;
      padding-right: 1%; // value from live site
      background-color: var(--emu-semantic-colors-secondary-100);
      position: relative;
      max-width: 90%;

      // breakpoint needed to match the live site
      @include mq('321px') {
        padding-left: 2%; // value from live site
        padding-right: 2%; // value from live site
      }

      // breakpoint needed to match the live site
      @include mq('414px') {
        padding-top: 84px;
        padding-left: 5%; // value from live site
        padding-right: 5%; // value from live site
        padding-bottom: 78px;
      }

      // breakpoint needed to match the live site
      @include mq('576px') {
        padding-left: 10%;
        padding-right: 10%;
      }

      @include mq('medium') {
        padding-top: 85px;
        padding-right: 60px;
        padding-bottom: 57px;
        padding-left: 60px;
      }

      // breakpoint needed to match the live site
      @include mq('1025px') {
        max-width: none;
        margin: var(--emu-common-spacing-none);
        padding-top: 44px;
        padding-right: 40px;
        padding-bottom: 38px;
        padding-left: 40px;
        height: 100%;
      }

      // breakpoint needed to match the design
      @include mq('1367px') {
        padding-top: 42px;
      }

      &-title {
        .cmp-title__text {
          font-size: 28px;
          line-height: 34px;
          margin-bottom: 18px;
          color: var(--emu-semantic-colors-secondary-600);

          // breakpoint needed to match the live site
          @include mq('321px') {
            font-size: var(--emu-semantic-font-sizes-narrow-large);
            line-height: var(--emu-semantic-line-heights-narrow-large);
          }

          // breakpoint needed to match the live site
          @include mq('414px') {
            font-size: 33px;
            line-height: var(--emu-common-line-heights-narrow-xl);
          }

          // breakpoint needed to match the live site
          @include mq('576px') {
            font-size: var(--emu-semantic-font-sizes-narrow-xxl);
            line-height: 40px;
          }

          @include mq('medium') {
            font-size: 50px;
            line-height: 108.5%; // value from live site
          }

          // breakpoint needed to match the live site
          @include mq('1025px') {
            font-size: var(--emu-semantic-font-sizes-wide-large);
            line-height: var(--emu-semantic-line-heights-wide-xl);
          }

          // breakpoint needed to match the design
          @include mq('1367px') {
            margin-bottom: 20px;
          }
        }
      }

      &-content {
        font-size: 14px;
        line-height: 26.5px;
        padding-bottom: 50px;
        color: var(--emu-semantic-colors-secondary-600);

        // breakpoint needed to match the live site
        @include mq('414px') {
          font-size: var(--emu-semantic-font-sizes-narrow-medium);
          line-height: 29.5px; // to match live site
        }

        // breakpoint needed to match the live site
        @include mq('576px') {
          font-size: 18px;
        }

        @include mq('medium') {
          font-size: 24px;
          line-height: 49px; // to match live site
        }

        // breakpoint needed to match the live site
        @include mq('1025px') {
          font-size: 16px;
          line-height: 31.5px;
        }

        ul {
          margin-top: var(--emu-common-spacing-none);
          margin-right: 40px;
          margin-bottom: 40px;
          margin-left: 40px;
          padding: var(--emu-common-spacing-none);
        }

        p,
        li {
          font-size: inherit;
          line-height: inherit;
        }
      }

      &-disclaimer {
        font-size: 10px;
        line-height: 135%; // value from live site
        color: var(--emu-semantic-colors-secondary-600);
        margin-left: 40px;
        margin-right: 40px;
        position: absolute;
        bottom: 25px;

        @include mq('medium') {
          margin: var(--emu-common-spacing-none);
          bottom: 8%; // value from live site
        }

        // breakpoint needed to match the live site
        @include mq('1025px') {
          margin-left: 40px;
          margin-right: 40px;
          bottom: 25px;
        }

        p,
        li {
          font-size: inherit;
          line-height: inherit;
        }
      }
    }
  }

  // redirection banner styles
  .banner {
    &.banner--redirection {
      overflow: hidden;

      .banner {
        &__teaser {
          background-position: center;

          // breakpoint needed to match the live site
          @media (min-width: 576px) and (max-width: 767px) {
            height: 1174px;
          }

          @include mq('medium') {
            height: 1700px;
          }

          @include mq('large') {
            height: 2130px;
          }

          @include mq('x-large') {
            height: 850px;
            margin-left: -15px; // value from live site
            margin-right: -15px; // value from live site
          }

          // breakpoint needed to match the live site
          @media (min-width: 1401px) {
            height: 1100px;
          }

          // breakpoint needed to match the live site
          @media (min-width: 2001px) {
            height: 1500px;
          }

          .disclaimer-bottom-right .emu-teaser__disclaimer {
            padding-left: 30px;
            padding-right: 30px;
            padding-bottom: 25px;
            bottom: 20px;

            @include mq('321px') {
              padding-left: 15px;
              padding-right: 15px;
            }

            @include mq('medium') {
              bottom: 2%; // value from live site
            }

            @include mq('1025px') {
              bottom: 0;
            }

            @include mq('x-large') {
              bottom: 0;
              padding-left: 75px;
              padding-right: 45px;
            }

            @media (min-width: 1367px) {
              padding-left: var(--emu-common-spacing-none);
              padding-right: 30px;
            }
          }
        }

        &__content {
          top: 0;
          height: 100%;
          display: flex;
          align-items: center;
          padding-top: var(--emu-common-spacing-none);

          @include mq('medium') {
            padding-top: 6px;
          }

          // breakpoint needed to match the live site
          @include mq('medium') {
            padding-top: 2px;
          }

          &-title {
            max-width: none;

            .cmp-title__text {
              // breakpoint needed to match the live site
              @media (min-width: 576px) and (max-width: 767px) {
                font-size: 46px;
                line-height: 50px;
              }
            }
          }

          &-text {
            width: 90%;
            margin-left: auto;
            margin-right: auto;
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
            margin-top: 7px;

            // breakpoint needed to match the live site
            @media (min-width: 576px) and (max-width: 767px) {
              font-size: 22px;
              line-height: var(--emu-semantic-font-sizes-narrow-xxl);
            }

            @include mq('medium') {
              width: 100%;
              max-width: 680px;
              margin-top: 6px;
            }

            // breakpoint needed to match the live site
            @media (min-width: 1025px) {
              max-width: 575px;
              line-height: 38px;
            }

            &-container {
              @include mq('medium') {
                padding-left: var(--emu-common-spacing-none);
              }

              // breakpoint needed to match the live site
              @media (min-width: 1199px) {
                padding-right: var(--emu-common-spacing-none);
              }
            }
          }

          &-cta-container {
            margin-top: 30px;

            // breakpoint needed to match the live site
            @include mq('414px') {
              margin-top: 28px;
            }

            @include mq('medium') {
              margin-top: 25px;
            }
          }
        }
      }
    }
  }

  // FAP banner styles
  .banner {
    &.banner--fap {
      margin-top: -35px; // value from live site
      overflow: hidden;

      // breakpoint needed to match the live site
      @include mq('1025px') {
        margin-top: var(--emu-common-spacing-none);
      }

      .banner {
        &__teaser {
          height: 565px;

          // breakpoint needed to match the live site
          @include mq('321px') {
            height: 900px;
          }

          @include mq('medium') {
            height: 1560px;
          }

          @include mq('large') {
            height: 2130px;
          }

          @include mq('x-large') {
            height: 900px;
          }

          // breakpoint needed to match the live site
          @include mq('1401px') {
            height: 1000px;
          }

          // breakpoint needed to match the live site
          @include mq('1601px') {
            height: 1250px;
          }

          // breakpoint needed to match the live site
          @include mq('2001px') {
            height: 1500px;
          }

          .disclaimer-bottom-right .emu-teaser__disclaimer {
            padding-left: calc(4% + 15px); // value deduced from live site
            padding-right: calc(4% + 15px); // value deduced from live site
          }
        }

        &__content {
          @include mq('large') {
            top: 0;
            padding-top: var(--emu-common-spacing-small);
            padding-left: var(--emu-common-spacing-none);
            margin-left: -15px;
            margin-right: -15px;
            width: auto;
            max-width: none;
          }

          // breakpoint needed to match the live site
          @include mq('1025px') {
            top: 15%; // value from live site
            margin-top: -30px;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            padding-top: var(--emu-common-spacing-none);
            max-width: var(--emu-semantic-sizing-breakpoints-large-plus);
          }

          // breakpoint needed to match the live site
          @include mq('1199px') {
            padding-right: var(--emu-common-spacing-none);
          }

          // breakpoint needed to match the live site
          @include mq('1401px') {
            margin-top: -28px;
          }

          &-inner {
            @include mq('medium') {
              padding-top: 86%; // value from live site
            }

            @include mq('x-large') {
              padding-top: 17.8%; // value from live site
            }

            // breakpoint needed to match the live site
            @include mq('1401px') {
              padding-top: 23.5%; // value from live site
            }

            // breakpoint needed to match the live site
            @include mq('1601px') {
              padding-top: 28%; // value from live site
            }
          }

          &-text {
            &-container {
              @include mq('large') {
                padding-left: var(--emu-common-spacing-none);
                padding-right: var(--emu-common-spacing-none);
              }
            }
          }
        }
      }
    }
  }
}

// authoring styles
.discover-cs__title,
.cs-tech-cards__container-inner {
  @include aem-editor-view {
    margin-top: var(--emu-common-spacing-none) !important;
  }
}
