#how-cooltone-works {
  // main banner styles
  .banner {
    &.banner--main {
      .banner {
        &__teaser {
          // breakpoint needed to match the live site
          @media (min-width: 576px) and (max-width: 767px) {
            height: 1074px;
          }

          .disclaimer-bottom-right {
            .emu-teaser__disclaimer {
              // breakpoint needed to match the live site
              @include mq('1025px') {
                padding-bottom: 25px;
              }

              // breakpoint needed to match the live site
              @include mq('1366px') {
                padding-bottom: 10px;
              }
            }
          }
        }

        &__img {
          &--sm,
          &--md {
            img {
              @include mq('medium') {
                object-position: 50% 0;
              }
            }
          }
        }

        &__content {
          padding-top: 75%;

          // breakpoint needed to match the live site
          @include mq('321px') {
            padding-top: 90%;
          }

          // breakpoint needed to match the live site
          @include mq('414px') {
            padding-top: 75%;
          }

          // breakpoint needed to match the live site
          @include mq('576px') {
            padding-top: 103%;
          }

          @include mq('medium') {
            padding-top: 73%;
            height: calc(
              100% - 83.5px
            ); // calculated value to reduce disclaimer height
            display: flex;
            align-items: center;
          }

          @include mq('large') {
            padding-top: 80%;
          }

          // breakpoint needed to match the live site
          @include mq('1025px') {
            padding-left: var(--emu-common-spacing-none);
            display: block;
            padding-top: var(--emu-common-spacing-none);
            top: 0;
          }

          &-inner {
            @include mq('medium') {
              padding-left: 85px;
              padding-right: 25px;
              padding-left: 100px;
              padding-right: 40px;
              max-width: 960px;
              margin-left: auto;
              margin-right: auto;
            }

            // breakpoint needed to match the live site
            @include mq('1025px') {
              position: absolute;
              top: 10%;
              padding-left: 15px;
              padding-right: var(--emu-common-spacing-none);
              width: 100%;
              max-width: var(--emu-common-sizing-desktop);
              padding-top: 69px;
            }

            // breakpoint needed to match the live site
            @include mq('1199px') {
              padding-left: 106px;
            }

            @include mq('x-large') {
              padding-left: 91px;
              max-width: var(--emu-common-sizing-desktop);
              margin-left: auto;
              margin-right: auto;
              left: 0;
              right: 0;
            }
          }

          &-img {
            img {
              max-width: 40%;
              padding: var(--emu-common-spacing-none);
              margin-left: 10px;

              // breakpoint needed to match the live site
              @include mq('414px') {
                max-width: 50%;
              }

              // breakpoint needed to match the live site
              @include mq('576px') {
                max-width: 40%;
                margin-left: 20px;
              }

              @include mq('medium') {
                height: auto;
                width: 300px;
                max-width: none;
                margin-left: -25px;
              }

              // breakpoint needed to match the live site
              @include mq('1025px') {
                width: 356px;
                margin-left: var(--emu-common-spacing-none);
              }
            }
          }

          &-title {
            margin-top: -8px;
            padding-left: 25px;
            padding-right: 25px;

            // breakpoint needed to match the live site
            @include mq('321px') {
              margin-top: -17px;
              padding-left: 40px;
            }

            // breakpoint needed to match the live site
            @include mq('414px') {
              margin-top: -36px;
            }

            // breakpoint needed to match the live site
            @include mq('576px') {
              margin-top: -37px;
            }

            @include mq('medium') {
              margin-top: -40px;
              padding-left: var(--emu-common-spacing-none);
              padding-right: var(--emu-common-spacing-none);
              max-width: 500px;
              margin-left: var(--emu-common-spacing-none);
            }

            // breakpoint needed to match the live site
            @include mq('1025px') {
              padding-left: 17px;
            }

            .cmp-title__text {
              // breakpoint needed to match the live site
              @media (min-width: 576px) and (max-width: 767px) {
                font-size: 46px;
                line-height: 50px;
              }
            }

            br {
              display: none;

              @include mq('large') {
                display: block;
              }
            }
          }

          &-text {
            padding-left: 25px;
            padding-right: 40px;

            // breakpoint needed to match the live site
            @include mq('321px') {
              padding-left: 40px;
            }

            // breakpoint needed to match the live site
            @media (min-width: 576px) and (max-width: 767px) {
              font-size: 22px;
              line-height: 40px;
            }

            @include mq('medium') {
              padding-left: var(--emu-common-spacing-none);
              padding-right: var(--emu-common-spacing-none);
              max-width: 620px;
              margin-left: var(--emu-common-spacing-none);
            }

            @include mq('large') {
              margin-top: 12px;
            }

            // breakpoint needed to match the live site
            @include mq('1025px') {
              padding-left: 17px;
            }

            sup {
              top: 0.2em;
            }

            br {
              @include mq('large') {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  // About Component Styles
  .about-cooltone {
    &__container {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 76px;
      padding-bottom: 30px;

      // breakpoint needed to match the live site
      @include mq('414px') {
        padding-top: 78px;
        padding-bottom: 25px;
      }

      @include mq('medium') {
        padding-top: 120px;
        padding-bottom: 50px;
      }

      @include mq('large') {
        padding-bottom: 52px;
        padding-top: 120px;
        max-width: 960px;
        margin-left: auto;
        margin-right: auto;
      }

      // breakpoint needed to match the live site
      @include mq('1025px') {
        padding-top: 126px;
        padding-top: 75px;
        padding-bottom: 65px;
      }

      @include mq('x-large') {
        max-width: var(--emu-semantic-sizing-breakpoints-large-plus);
      }

      > .container {
        margin-left: -15px;
        margin-right: -15px;
      }

      &-inner {
        display: flex;
        flex-direction: column-reverse;

        @include mq('large') {
          padding-left: 15px;
          padding-right: 15px;
        }

        // breakpoint needed to match the live site
        @include mq('1025px') {
          flex-direction: row;
          flex-wrap: wrap;
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
        }

        > .container {
          &:first-child {
            // breakpoint needed to match the live site
            @include mq('1025px') {
              width: 50%;
              padding-left: 15px;
              padding-right: 15px;
            }
          }

          &:last-child {
            @include mq('large') {
              width: 100%;
            }

            // breakpoint needed to match the live site
            @include mq('1025px') {
              width: 50%;
              padding-left: 15px;
              padding-right: 15px;
            }
          }
        }
      }
    }

    &__teaser {
      position: relative;
      display: flex;
      justify-content: center;

      @include mq('medium') {
        display: block;
        width: 95%;
        margin-left: auto;
        margin-right: auto;
      }

      // breakpoint needed to match the live site
      @include mq('1025px') {
        width: 100%;
        margin-bottom: 191px;
      }

      @include mq('large') {
        margin-bottom: var(--emu-common-spacing-none);
      }
    }

    &__img {
      @include mq('large') {
        display: flex;
        justify-content: center;
      }

      img {
        @include mq('medium') {
          width: 100%;
        }

        // breakpoint needed to match the live site
        @include mq('1025px') {
          width: auto;
        }
      }
    }

    &__disclaimer {
      position: absolute;
      bottom: 0;
      left: 0;
      padding-bottom: 10px;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 9.75px;
      line-height: 140%; // value to match the live site
      text-shadow: -1px 1px 9px rgba(0, 0, 0, 0.75);
      text-align: center;
      letter-spacing: 0.15px;

      // breakpoint needed to match the live site
      @include mq('375px') {
        padding-bottom: var(--emu-common-spacing-none);
      }

      // breakpoint needed to match the live site
      @include mq('576px') {
        padding-bottom: 15px;
      }

      @include mq('medium') {
        padding-bottom: 25px;
        padding-left: 3px;
        padding-right: 3px;
      }

      @include mq('large') {
        max-width: 73%;
        margin-left: auto;
        margin-right: auto;
        right: 0;
        padding-bottom: 10px;
      }

      p,
      li {
        font-size: inherit;
        line-height: inherit;
        letter-spacing: inherit;
      }
    }

    &__content {
      padding-left: 20px;

      // breakpoint needed to match the live site
      @include mq('321px') {
        padding-left: 25px;
      }

      @include mq('medium') {
        padding-left: 50px;
        padding-right: 50px;
      }

      @include mq('large') {
        padding-left: 50px;
        padding-right: 50px;
      }

      @include mq('1025px') {
        padding-left: 55px;
        padding-right: var(--emu-common-spacing-none);
      }
    }

    &__title {
      margin-right: 10px;
      margin-bottom: 34px;

      // breakpoint needed to match the live site
      @include mq('414px') {
        margin-bottom: var(--emu-common-spacing-large);
      }

      @include mq('medium') {
        margin-bottom: 12px;
      }

      @include mq('large') {
        margin-bottom: 11px;
      }

      // breakpoint needed to match the live site
      @include mq('1025px') {
        margin-bottom: var(--emu-common-spacing-large);
      }

      @include mq('large') {
        margin-top: 5px;
      }

      .cmp-title__text {
        // media query needed to match the live site
        @media (min-width: 576px) and (max-width: 767px) {
          font-size: 46px;
          line-height: 50px;
        }

        // breakpoint needed to match the live site
        @include mq('1025px') {
          font-size: var(--emu-semantic-font-sizes-wide-xl);
          line-height: var(--emu-semantic-line-heights-wide-xl);
        }
      }
    }

    &__text {
      color: var(--emu-semantic-colors-secondary-600);

      // media query needed to match the live site
      @media (min-width: 576px) and (max-width: 767px) {
        font-size: 22px;
        line-height: var(--emu-semantic-font-sizes-narrow-xxl);
      }

      // breakpoint needed to match the live site
      @include mq('1025px') {
        font-size: 23px;
        line-height: 38px;
      }

      p,
      li {
        color: inherit;
      }

      p {
        @include mq('medium') {
          max-width: 620px;
        }

        // breakpoint needed to match the live site
        @include mq('1025px') {
          max-width: none;
        }
      }

      ul {
        padding-left: var(--emu-common-spacing-none);
        margin-top: 34px;
        margin-right: 10px;
        margin-left: 30px;
        margin-bottom: 5px;

        // breakpoint needed to match the live site
        @include mq('414px') {
          margin-top: 35px;
        }

        @include mq('medium') {
          margin-bottom: 59px;
          margin-right: var(--emu-common-spacing-none);
        }

        // breakpoint needed to match the live site
        @include mq('1025px') {
          line-height: 37px;
          margin-bottom: var(--emu-common-spacing-none);
        }
      }
    }
  }

  // FDA Tabs
  .cooltone-fda {
    .fda-tabs {
      &-wrapper {
        // breakpoint needed ot match the live site
        @include mq('1025px') {
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
        }
      }
      &-container {
        padding-bottom: 39px;

        @include mq('medium') {
          padding-top: 106px;
          padding-bottom: 49px;
        }

        // breakpoint needed to match the live site
        @include mq('1025px') {
          padding-top: 55px;
          padding-bottom: 40px;
        }
      }

      &__title p {
        @include mq('medium') {
          margin-bottom: 12px;
        }
      }

      &__text {
        p {
          padding-left: 25px;
          padding-right: 25px;

          @include mq('medium') {
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
            max-width: 645px;
          }

          // breakpoint needed to match the live site
          @include mq('1025px') {
            max-width: none;
            width: auto;
            line-height: 31px;
          }
        }
      }

      &__item--desktop {
        > .aaaem-tabs__tablist {
          .aaaem-tabs__tab {
            width: 40%;

            @include mq('medium') {
              width: auto;
              min-width: 245px;
            }

            &:not(:last-child) {
              margin-right: 14px;

              // breakpoint needed to match the live site
              @include mq('1081px') {
                margin-right: 24px;
              }
            }
          }
        }
      }

      &__item--mobile {
        .aaaem-tabs__tabpanels {
          @include mq('576px') {
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
          }
        }
      }

      &__img {
        &--mobile {
          img {
            height: auto;
            margin-bottom: var(--emu-common-spacing-none);
            max-height: 972px; // value from live site
          }
        }

        &--desktop {
          // breakpoint needed to match the live site
          @include mq('1025px') {
            height: auto;
          }

          img {
            // breakpoint needed to match the live site
            @include mq('1025px') {
              height: 548px; // value from live site
              width: auto;
            }
          }
        }
      }
    }
  }

  // Blue banner intro section styles
  .cooltone-intro {
    overflow: hidden;

    .intro-block {
      margin-left: -25px;
      margin-right: -25px;
      padding-top: 15%; // value from live site
      padding-right: 10%; // value from live site
      padding-bottom: 15%; // value from live site
      padding-left: 10%; // value from live site
      max-width: none;

      @include mq('medium') {
        padding-top: 92px;
        padding-right: 70px;
        padding-bottom: 87px;
        padding-left: 70px;
        margin-left: var(--emu-common-spacing-none);
        margin-right: var(--emu-common-spacing-none);
      }

      // breakpoint needed to match the live site
      @include mq('1025px') {
        padding-top: 91px;
        padding-right: 80px;
        padding-bottom: 88px;
        padding-left: 80px;
      }

      &__inner-container {
        padding-top: 20px;
        padding-right: 30px;
        padding-bottom: 20px;
        padding-left: 30px;
        margin-left: auto;
        margin-right: auto;

        // breakpoint needed to match the live site
        @include mq('1025px') {
          padding-left: 15px;
          padding-right: 15px;
        }
      }

      &__title {
        margin-top: 2px;
        margin-bottom: 18px;

        @include mq('medium') {
          margin-bottom: 19px;
          max-width: 600px;
        }

        // breakpoint needed to match the live site
        @include mq('1025px') {
          max-width: none;
        }
      }

      &__text {
        @include mq('medium') {
          max-width: 560px;
        }

        // breakpoint needed to match the live site
        @include mq('1025px') {
          max-width: none;
          line-height: 31px;
        }
      }
    }
  }

  // redirection banner
  .banner {
    &.banner--redirection {
      .banner {
        &__teaser {
          // breakpoint needed to match the live site
          @include mq('321px') {
            background-position: center center;
          }

          // breakpoint needed to match the live site
          @include mq('576px') {
            height: 1174px;
          }

          @include mq('medium') {
            height: 1700px;
          }

          @include mq('large') {
            height: 2130px;
          }

          @include mq('x-large') {
            height: 770px;
          }

          // breakpoint needed to match the live site
          @include mq('1401px') {
            height: 790px;
          }

          // breakpoint needed to match the live site
          @include mq('1501px') {
            height: 940px;
          }

          // breakpoint needed to match the live site
          @include mq('1601px') {
            height: 1200px;
          }

          // breakpoint needed to match the live site
          @include mq('2001px') {
            height: 1550px;
          }

          .emu-teaser__disclaimer {
            @include mq('x-large') {
              padding-left: 75px;
            }

            @include mq('1401px') {
              padding-left: 50px;
            }
          }
        }

        &__content {
          top: 42%;

          @include mq('x-large') {
            top: 40%;
          }

          // breakpoint needed to match the live site
          @media (min-width: 321px) and (max-width: 349px) {
            padding-top: var(--emu-common-spacing-xxs);
          }

          @include mq('large') {
            padding-top: 6px;
          }

          @include mq('x-large') {
            padding-top: 5px;
          }

          &-title {
            max-width: none;

            .cmp-title__text {
              // breakpoint needed to match the live site
              @media (min-width: 321px) and (max-width: 349px) {
                font-size: 28px;
                line-height: 34px;
              }

              // breakpoint needed to match the live site
              @media (min-width: 576px) and (max-width: 767px) {
                font-size: 46px;
                line-height: 50px;
              }
            }
          }

          &-text-container {
            // breakpoint needed to match the live site
            @include mq('1199px') {
              padding-right: var(--emu-common-spacing-none);
            }

            @include mq('x-large') {
              padding-left: var(--emu-common-spacing-none);
            }
          }

          &-text {
            margin-top: 9px;
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
            width: 90%;

            // breakpoint needed to match the live site
            @media (min-width: 321px) and (max-width: 349px) {
              margin-top: var(--emu-common-spacing-small);
            }

            // breakpoint needed to match the live site
            @include mq('414px') {
              margin-top: 7px;
            }

            // breakpoint needed to match the live site
            @media (min-width: 576px) and (max-width: 767px) {
              font-size: 22px;
              line-height: 40px;
            }

            @include mq('medium') {
              max-width: 640px;
              margin-top: 6px;
            }

            @include mq('large') {
              margin-top: 7px;
            }

            // breakpoint needed to match the live site
            @include mq('1025px') {
              max-width: 575px;
              margin-top: 6px;
            }

            @include mq('x-large') {
              margin-top: 7px;
            }
          }

          &-cta-container {
            margin-top: var(--emu-common-spacing-large);

            @include mq('medium') {
              margin-top: 30px;
            }

            // breakpoint needed to match the live site
            @include mq('1025px') {
              margin-top: 28px;
            }

            @include mq('x-large') {
              margin-top: 29px;
            }
          }

          &-cta {
            padding-top: 17px;
            padding-bottom: 17px;
            padding-left: 31px;
            padding-right: 31px;

            @include mq('576px') {
              padding-left: 76px;
              padding-right: 76px;
            }
          }
        }
      }
    }
  }

  // Find a Provider banner
  .banner {
    &.banner--fap {
      .banner {
        &__teaser {
          @include mq('320px', 'max-width') {
            height: 715px;
          }

          // breakpoint needed to match the live site
          @media (min-width: 321px) and (max-width: 413px) {
            height: 825px;
          }

          // breakpoint needed to match the live site
          @media (min-width: 414px) and (max-width: 767px) {
            height: 900px;
          }

          @include mq('medium') {
            background-position: 50% 0;
          }

          @include mq('x-large') {
            background-position: center center;
          }

          .emu-teaser__disclaimer {
            @include mq('medium') {
              padding-left: 50px;
              padding-right: 50px;
            }

            @include mq('large') {
              padding-left: 75px;
              padding-right: 75px;
            }
          }
        }

        &__content {
          @include mq('large') {
            top: 22%;
            padding-top: 7px;
          }

          // breakpoint needed to match the live site
          @include mq('1025px') {
            padding-top: var(--emu-common-spacing-xs);
          }

          // breakpoint needed to match the live site
          @include mq('1199px') {
            padding-top: 6px;
            padding-right: var(--emu-common-spacing-none);
          }

          @include mq('x-large') {
            padding-left: var(--emu-common-spacing-none);
          }

          @include mq('1401px') {
            top: 25%;
            padding-top: 5px;
          }

          // breakpoint needed to match the live site
          @include mq('1601px') {
            top: 22%;
          }

          &-inner {
            @include mq('medium') {
              padding-top: 87%;
            }

            @include mq('large') {
              padding-top: 40%;
            }

            @include mq('x-large') {
              padding-top: 12.5%;
            }

            // breakpoint needed to match the live site
            @include mq('1401px') {
              padding-top: 14%;
            }

            // breakpoint needed to match the live site
            @include mq('1601px') {
              padding-top: 28%;
            }
          }

          &-title {
            @include mq('medium') {
              margin-top: 1px;
            }

            // breakpoint needed to match the live site
            @include mq('1199px') {
              margin-top: var(--emu-common-spacing-none);
            }
          }

          &-text {
            // breakpoint needed to match the live site
            @include mq('1025px') {
              margin-top: 12px;
            }

            @include mq('x-large') {
              margin-top: 13px;
            }
          }

          &-cta-container {
            @include mq('large') {
              margin-top: 23px;
            }
          }
        }
      }
    }
  }
}
