.fap {
  &__form {
    > .text:last-child {
      display: none;

      @include aem-editor-view {
        display: block;
      }
    }
  }

  &__input-container {
    display: flex;
    align-items: center;
  }

  &__input {
    position: relative;

    input {
      border: var(--emu-common-border-width-none);
      padding: var(--emu-common-spacing-none);
      font-family: var(--emu-semantic-font-families-heading);
      font-size: var(--emu-semantic-font-sizes-wide-small);
      line-height: 33px;
      font-weight: var(--emu-common-font-weight-black);
      color: var(--emu-semantic-colors-primary-100);
      width: 200px;
      padding-left: 30px;
      padding-top: 6px;
      height: 59px;
      background-color: var(--emu-common-colors-white);
      border-radius: 100px 0 0 100px;

      // breakpoint needed to match the live site
      @include mq('350px') {
        width: 250px;
        height: 54px;
        padding-left: 20px;
      }

      // breakpoint needed to match the live site
      @include mq('577px') {
        height: 59px;
      }

      @include mq('medium') {
        width: 350px;
      }

      // breakpoint needed to match the live site
      @include mq('1025px') {
        width: 380px;
      }

      &:focus {
        box-shadow: 0 0 10px 0 rgba(0, 164, 228, 0.7);
      }

      &::placeholder {
        color: currentColor;
        text-align: center;
        font-size: 15px;

        // breakpoint needed to match the live site
        @include mq('321px') {
          font-size: 18px;
        }

        // breakpoint needed to match the live site
        @include mq('medium') {
          font-size: 24px;
        }
      }
    }
  }

  &__submit {
    width: 70px;
    height: 59px;
    padding-top: 10px;
    padding-right: 35px;
    padding-bottom: 10px;
    padding-left: 20px;
    position: relative;
    background-color: var(--emu-common-colors-white);
    border-radius: 0 100px 100px 0;
    margin-left: -1px;

    // breakpoint needed to match the live site
    @include mq('350px') {
      width: 80px;
      height: 54px;
    }

    // breakpoint needed to match the live site
    @include mq('577px') {
      height: 59px;
    }

    img {
      width: 15px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 20px;

      // breakpoint needed to match the live site
      @include mq('350px') {
        width: 24px;
      }
    }
  }
}
