.gallery {
  // markers
  .emu-mapping-marker {
    border-radius: var(--emu-common-border-radius-none);
    opacity: 0;
    min-width: 20px;
    min-height: 20px;
    transform: translateX(-50%) translateY(-50%);

    @include mq('x-large') {
      pointer-events: none;
    }

    &[aria-label*='female-abdomen'] {
      min-width: 31px;
      min-height: 56px;

      @include mq('medium') {
        min-width: 52px;
        min-height: 98px;
      }
    }

    &[aria-label*='female-backbrafat'] {
      min-width: 58px;
      min-height: 26px;

      @include mq('medium') {
        min-width: 100px;
        min-height: 38px;
      }
    }

    &[aria-label*='female-bananaroll'] {
      min-width: 76px;
      min-height: 23px;

      @include mq('medium') {
        min-width: 128px;
        min-height: 35px;
      }
    }

    &[aria-label*='female-chin'] {
      min-width: 42px;
      min-height: 24px;

      @include mq('medium') {
        min-width: 65px;
        min-height: 40px;
      }
    }

    &[aria-label*='female-flanks'] {
      min-width: 24px;
      min-height: 35px;

      @include mq('medium') {
        min-width: 26px;
        min-height: 54px;
      }
    }

    &[aria-label*='female-glutes'] {
      min-width: 75px;
      min-height: 23px;

      @include mq('medium') {
        min-width: 130px;
        min-height: 40px;
      }
    }

    &[aria-label*='female-thighs'] {
      min-width: 60px;
      min-height: 38px;

      @include mq('medium') {
        min-width: 103px;
        min-height: 66px;
      }
    }

    &[aria-label*='female-upperarms'] {
      min-height: 28px;
      min-width: 21px;

      @include mq('medium') {
        min-width: 30px;
        min-height: 45px;
      }
    }

    &[aria-label*='-male-abdomen'] {
      min-width: 40px;
      min-height: 56px;

      @include mq('medium') {
        min-width: 72px;
        min-height: 94px;
      }
    }

    &[aria-label*='-male-backbrafat'] {
      min-width: 62px;
      min-height: 22px;

      @include mq('medium') {
        min-width: 107px;
        min-height: 40px;
      }
    }

    &[aria-label*='-male-bananaroll'] {
      min-width: 69px;
      min-height: 21px;

      @include mq('medium') {
        min-width: 120px;
        min-height: 36px;
      }
    }

    &[aria-label*='-male-chin'] {
      min-width: 36px;
      min-height: 24px;

      @include mq('medium') {
        min-width: 65px;
        min-height: 40px;
      }
    }

    &[aria-label*='-male-flanks'] {
      min-width: 20px;
      min-height: 42px;

      @include mq('medium') {
        min-width: 23px;
        min-height: 76px;
      }
    }

    &[aria-label*='-male-glutes'] {
      min-width: 71px;
      min-height: 22px;

      @include mq('medium') {
        min-width: 122px;
        min-height: 38px;
      }
    }

    &[aria-label*='-male-thighs'] {
      min-width: 59px;
      min-height: 38px;

      @include mq('medium') {
        min-width: 103px;
        min-height: 65px;
      }
    }

    &[aria-label*='-male-upperarms'] {
      min-width: 22px;
      min-height: 38px;

      @include mq('medium') {
        min-width: 30px;
        min-height: 45px;
      }
    }
  }
}
