.banner {
  &.banner--fap {
    overflow: hidden;

    .banner {
      &__teaser {
        height: 690px;
        background-position: center;
        margin-left: -15px; // value from live site
        margin-right: -15px; // value from live site

        // breakpoint needed to match the live site
        @include mq('321px') {
          height: 840px;
        }

        // breakpoint needed to match the live site
        @include mq('414px') {
          height: 890px;
        }

        @include mq('medium') {
          height: 1560px;
        }

        @include mq('large') {
          height: 2130px;
        }

        @include mq('x-large') {
          height: 755px;
        }

        // breakpoint needed to match the live site
        @include mq('1401px') {
          height: 1000px;
        }

        // breakpoint needed to match the live site
        @include mq('1601px') {
          height: 1250px;
        }

        // breakpoint needed to match the live site
        @include mq('2001px') {
          height: 1500px;
        }

        .emu-teaser__disclaimer {
          padding-left: 65px;
          padding-right: 65px;
        }
      }

      &__content {
        top: 0;
        padding-left: 15px;
        padding-right: 15px;

        // breakpoint needed to match the live site
        @include mq('350px') {
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
        }

        // breakpoint needed to match the live site
        @include mq('577px') {
          padding-left: 15px;
          padding-right: 15px;
        }

        @include mq('large') {
          top: 15%; // value from live site
          padding-right: var(--emu-common-spacing-none);
          padding-top: var(--emu-common-spacing-small);
          max-width: var(--emu-common-sizing-desktop);
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
        }

        // breakpoint needed to match the live site
        @include mq('large') {
          padding-top: 5px;
        }

        // breakpoint needed to match the live site
        @include mq('1199px') {
          padding-right: 73px;
          padding-top: 6px;
        }

        @include mq('x-large') {
          padding-left: 73px;
          padding-top: 5px;
        }

        // breakpoint needed to match the live site
        @include mq('1401px') {
          padding-top: 6px;
        }

        // breakpoint needed to match the live site
        @include mq('1601px') {
          padding-top: 5px;
        }

        &-inner {
          padding-top: 70%; // value from live site

          // breakpoint needed to match the live site
          @include mq('321px') {
            padding-top: 80%; // value from live site
          }

          @include mq('medium') {
            padding-top: 84%; // value from live site
          }

          @include mq('large') {
            padding-top: 54%; // value from live site
          }

          @include mq('x-large') {
            padding-top: 16%; // value from live site
          }

          // breakpoint needed to match the live site
          @include mq('1401px') {
            padding-top: 22.23%; // value from live site
          }

          // breakpoint needed to match the live site
          @include mq('1601px') {
            padding-top: 28%; // value from live site
          }
        }

        &-title {
          max-width: none;

          // breakpoint needed to match the live site
          @include mq('1401px') {
            margin-top: -2px;
          }
        }

        &-text {
          max-width: none;
          padding-left: 15px;
          padding-right: 15px;

          // breakpoint needed to match the live site
          @include mq('414px') {
            margin-top: 13px;
          }

          // breakpoint needed to match the live site
          @include mq('577px') {
            max-width: 700px;
            margin-left: auto;
            margin-right: auto;
          }

          @include mq('medium') {
            max-width: 675px;
            margin-top: 11px;
          }

          @include mq('large') {
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
            max-width: 675px;
          }

          // breakpoint needed to match design
          @include mq('1025px') {
            max-width: 700px;
            margin-top: 13px;
          }

          // breakpoint needed to match the live site
          @include mq('1601px') {
            margin-top: 12px;
          }

          &-container {
            @include mq('large') {
              padding-left: var(--emu-common-spacing-none);
            }

            // breakpoint needed to match the live site
            @include mq('1199px') {
              padding-right: var(--emu-common-spacing-none);
            }
          }
        }

        &-cta-container {
          margin-top: 25px;

          // breakpoint needed to match the live site
          @include mq('414px') {
            margin-top: 23px;
          }

          @include mq('medium') {
            margin-top: 22px;
          }
        }
      }
    }
  }
}
