.emu-form-dropdown {
  position: relative;

  &.custom-hide-dropdown {
    .emu-dropdown-menu__group {
      display: none;
      height: 0;
      opacity: 0;
      overflow: hidden;
    }

    .emu-dropdown-menu__button {
      border-radius: var(
        --emu-component-ingredients-select-input-border-radius-narrow
      );
    }

    .emu-dropdown-menu__item {
      visibility: hidden;
    }

    &.custom-hide-dropdown--show {
      .emu-dropdown-menu__group {
        display: block;
        height: auto;
        opacity: 1;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top-width: 0;
      }

      .emu-dropdown-menu__button {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      .emu-dropdown-menu__item {
        visibility: visible;
      }
    }
  }

  .emu-dropdown-menu {
    &__item {
      color: var(
        --emu-component-ingredients-select-input-option-text-color-filled-active-light
      );
      text-align: center;
      border: none;
      margin-top: -1px; // needed to remove white blank space
      text-transform: uppercase; // needed to match the live site
      font-size: 11px;
      line-height: 1.5;

      // breakpoint needed to match the live site
      @include mq('576px') {
        font-size: 16px;
      }

      @include mq('medium') {
        font-size: 20px;
        padding-top: 13px;
        padding-bottom: 11px;
      }

      @include mq('x-large') {
        font-size: 15px;
        padding-top: 18px;
        padding-bottom: 14px;
      }

      &[data-disabled='true'] {
        display: none;
      }
    }

    &__button {
      height: 40px; // value from live site
      font-size: 14px;
      line-height: 40px;
      font-family: var(--emu-semantic-font-families-heading);
      justify-content: center;
      width: 100% !important; // needed to override the styles from JS

      // breakpoint needed to match the live site
      @include mq('576px') {
        height: var(--emu-common-sizing-large); // value from live site
        font-size: 20px;
        line-height: 64px;
        padding: var(--emu-component-ingredients-select-input-padding-wide);
      }

      @include mq('medium') {
        font-size: 28px;
      }

      @include mq('x-large') {
        font-size: 18px;
        line-height: 50px;
        height: 50px;
        padding-top: 10px;
        padding-right: 30px;
        padding-bottom: 10px;
        padding-left: 15px;
        position: relative;
      }

      span {
        margin-right: 6px;
        position: relative;
        top: 1px;

        // breakpoint needed to match the live site
        @include mq('576px') {
          margin-right: var(--emu-common-spacing-xs);
        }

        @include mq('medium') {
          margin-right: 13px;
          top: 3px;
          left: -2px;
        }

        @include mq('x-large') {
          margin-right: var(--emu-common-spacing-none);
          top: 1px;
          left: 0;
        }
      }

      // increasing specificity
      &.emu-dropdown-menu__button {
        svg {
          width: 11px;
          transform: rotate(0);

          // breakpoint needed to match the live site
          @include mq('576px') {
            width: 18px;
          }

          @include mq('medium') {
            transform: rotate(0deg) scale(1.5);
          }

          @include mq('x-large') {
            transform: rotate(0deg);
            position: absolute;
            right: 19px;
            top: 17px;
          }

          path {
            stroke: var(
              --emu-component-ingredients-select-input-icon-color-fill-filled-default-light
            );
            stroke-width: 30px;

            // breakpoint needed to match the live site
            @include mq('576px') {
              stroke-width: 45px;
            }
          }
        }
      }
    }

    &__group {
      top: 100% !important; // needed to override the styles from JS
      left: 0 !important; // needed to override the styles from JS
      width: 100% !important; // needed to override the styles from JS
      padding-top: var(--emu-common-spacing-xxs);

      // breakpoint needed to match the live site
      @include mq('576px') {
        padding-top: var(--emu-common-spacing-none);
        transform: translateY(-3px); // calculated value to match the live site
      }
    }
  }
}
