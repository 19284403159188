.navigationlist {
  .emu-navigation__content-wrapper {
    position: relative;

    nav {
      span,
      a {
        font-weight: var(--emu-common-font-weight-regular);
      }
    }

    nav ul li {
      span,
      > a {
        @include mq('large') {
          color: var(--emu-semantic-colors-secondary-400);
        }

        &:hover,
        &:focus {
          @include mq('large') {
            color: var(--emu-semantic-colors-primary-100);
          }
        }
      }

      &.emu-navigation__item--active {
        > a,
        > span a {
          color: var(--emu-semantic-colors-primary-50);

          @include mq('large') {
            color: var(--emu-semantic-colors-primary-100);
          }
        }
      }
    }
  }
}

.emu-navigation {
  &__item {
    font-size: 19px;
    line-height: 24px;
    letter-spacing: 0.6px; // to match live site.

    @include mq('large') {
      font-size: 10px;
      line-height: var(--emu-semantic-sizing-one-line-height-wide);
      letter-spacing: 0.2px; // to match live site.
    }

    @include mq('x-large') {
      font-size: 12px;
      letter-spacing: 0.24px; // to match live site.
      white-space: nowrap;
    }
  }

  .emu-navigation__menu {
    &--level-0 {
      display: flex;
      flex-direction: column;
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 75px;

      @include mq('large') {
        flex-direction: row;
        padding: var(--emu-common-spacing-none);
        margin: var(--emu-common-spacing-none);
        font-family: var(--emu-semantic-font-families-heading);
        font-weight: var(--emu-semantic-font-weight-900);
      }

      > .emu-navigation__item {
        text-align: center;
        padding-top: 12px;
        padding-bottom: 20px;

        @include mq('large') {
          text-align: left;
          padding-top: 6px;
          padding-bottom: 23px;
          margin-left: 10px;
          margin-right: 10px;
          position: relative;
        }

        @include mq('x-large') {
          margin-left: 14px;
          margin-right: 14px;
        }

        &.emu-navigation__item-parent {
          @include mq('x-large') {
            margin-left: var(--emu-common-spacing-small);
            margin-right: 9px;
          }
        }

        // styles for these child items in nav needed special care to match live site
        &:nth-child(4),
        &:nth-child(3) {
          @include mq('x-large') {
            margin-right: 16px;
          }
        }

        &:last-child {
          @include mq('large') {
            margin-right: var(--emu-common-spacing-none);
          }

          a {
            display: flex;
            flex-direction: row;
            justify-content: center;

            @include mq('large') {
              color: var(--emu-semantic-colors-primary-100);
            }

            &::before {
              content: '';
              display: inline-block;
              background-image: url('../../assets/images/location-icon.png');
              background-size: cover;
              position: relative;
              width: var(--emu-common-sizing-small);
              height: 23px;
              margin-right: 20px;
              left: 10px;

              @include mq('large') {
                width: 11px;
                height: var(--emu-common-sizing-small);
                margin-right: var(--emu-common-spacing-xs);
                left: var(--emu-common-spacing-none);
                top: -2px;
              }
            }
          }
        }

        &-parent {
          .nav-item.parent {
            @include mq('x-large') {
              padding-right: var(--emu-common-spacing-xs);
            }

            &::after {
              content: '';
              display: inline-block;
              color: var(--emu-semantic-colors-secondary-200);
              border-width: var(--emu-common-border-width-none);
              border-style: solid;
              border-top-width: 3.5px;
              border-right-width: 3.5px;
              height: 13px;
              width: 13px;
              transform: rotate(135deg);
              position: relative;
              top: -2px;
              left: 2px;
              margin-left: 10px;

              @include mq('large') {
                border-top-width: var(--emu-common-border-width-medium);
                border-right-width: var(--emu-common-border-width-medium);
                background-attachment: fixed;
                width: 8px;
                height: 8px;
              }
            }
          }

          .emu-navigation__menu--level-1 {
            display: none;
            position: relative;
            width: 100%;

            @include mq('large') {
              position: absolute;
              left: -10px;
              width: auto;
            }
          }

          &.js-toggle-dropdown {
            .nav-item.parent {
              @include mq('large') {
                color: var(--emu-semantic-colors-primary-100);
              }

              &::after {
                transform: rotate(-45deg);
                top: var(--emu-common-sizing-xs);

                @include mq('large') {
                  top: 2px;
                }
              }
            }

            .emu-navigation__menu--level-1 {
              display: block;
            }
          }
        }
      }
    }

    &--level-1 {
      margin-top: 12px;
      margin-bottom: var(--emu-common-spacing-small);

      @include mq('large') {
        margin: var(--emu-common-spacing-none);
        margin-top: 23px;
        background-color: var(--emu-common-colors-white);
        border-top-width: 8px;
        border-style: solid;
        border-color: var(--emu-semantic-colors-primary-100);
        padding-top: 30px;
        padding-bottom: 20px;
        min-width: 180px; // to match live site.
      }

      > .emu-navigation__item {
        span,
        a {
          padding-top: 11px;
          padding-right: 15px;
          padding-bottom: 20px;
          padding-left: 25px;
          letter-spacing: 0.5px; // to match live site.
          font-family: var(--emu-semantic-font-families-heading);
          font-weight: var(--emu-semantic-font-weight-900);
          transition: all 0.3s ease-out 0s; // to match live site.

          @include mq('large') {
            line-height: 16px;
            letter-spacing: 0.24px; // to match live site.
            padding-top: var(--emu-common-spacing-small);
            padding-right: 17px;
            padding-bottom: 11px;
            padding-left: 17px;
          }

          sup {
            font-size: 97%; // to match live site.
            top: 7px;
            font-family: var(--emu-semantic-font-families-body);

            @include mq('large') {
              top: 4px;
            }
          }

          &:hover {
            font-size: var(--emu-common-font-sizes-narrow-medium);
            letter-spacing: 0.24px;

            sup {
              top: 4px;
            }
          }
        }
      }
    }
  }
}
