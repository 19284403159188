#site-map-page {
  .site-map {
    &__content {
      padding-left: 34px;
      padding-bottom: 13px;

      @include mq('medium') {
        padding-left: 45px;
      }

      @include mq('x-large') {
        padding-bottom: 11px;
      }

      p {
        font-size: 20px;
        line-height: var(--emu-common-line-heights-wide-medium);
        margin-bottom: 9px;
      }

      ul {
        list-style-type: none;
        margin: var(--emu-common-spacing-none);
        padding: var(--emu-common-spacing-none);
        margin-bottom: 20px;

        &:last-child {
          margin-top: 29px;

          li {
            padding-left: var(--emu-common-spacing-none);
          }
        }

        li {
          font-size: var(--emu-semantic-font-sizes-narrow-medium);
          line-height: var(--emu-common-line-heights-wide-medium);
          padding-left: 20px;

          sup {
            font-size: 100%;
            top: 6px; // to match live site.
          }
        }
      }

      a {
        text-decoration: none;

        &:hover,
        &:focus {
          text-decoration: underline; // to match live site.
        }
      }
    }
  }
}
