.footer {
  footer {
    padding-top: 25px;
    width: 100%;
    position: relative;
    margin-bottom: 25px; // to match the live site.

    @include mq('large') {
      margin-bottom: 40px; // to match the live site.
      padding-top: 65px;
    }

    @include mq('x-large') {
      max-width: 1092px; // to match the live site.
      margin: auto;
      margin-bottom: 78px; // to match the live site.

      // separator above footer
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        height: var(--emu-common-border-width-thin);
        background-color: var(--emu-semantic-colors-secondary-200);
        left: 0;
        right: 0;
      }
    }

    // separator below footer
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 30px;
      right: 30px;
      height: var(--emu-common-border-width-thin);
      background-color: var(--emu-semantic-colors-secondary-200);

      @include mq('medium') {
        left: 55px; // to match the live site.
        right: 55px; // to match the live site.
      }

      @include mq('x-large') {
        left: 0px; // to match the live site.
        right: 0px; // to match the live site.
      }
    }
  }

  &__top-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: var(--emu-common-spacing-medium);
    padding-bottom: 25px;
    padding-left: 30px;
    padding-right: 30px;

    @include mq('medium') {
      padding-right: 55px;
      padding-left: 55px;
    }

    @include mq('large') {
      flex-direction: row;
      padding-bottom: 36px;
      padding-right: 40px;
      padding-left: 40px;
    }

    @include mq('x-large') {
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
      padding-top: var(--emu-common-spacing-none);
    }

    > div {
      flex: 1;
      &:first-child {
        @include mq('large') {
          padding-right: 30px;
        }
      }

      &:last-child {
        @include mq('large') {
          padding-left: 20px;
        }
      }
    }
  }

  &__logo-container {
    @include mq('x-large') {
      padding-left: 55px;
      padding-right: 30px;
    }

    .text {
      p {
        margin-bottom: 12px;
        color: var(--emu-semantic-colors-secondary-500);
        line-height: 24px;
        font-size: 15px;
        font-family: var(--emu-semantic-font-families-mono);
        font-weight: var(--emu-semantic-font-weight-500);

        @include mq('large') {
          padding-top: var(--emu-common-spacing-none);
        }
      }
    }
  }

  &__logo {
    max-width: 125px; // to match the live site.
    width: 100%;

    img {
      padding-bottom: 20px; // to match the live site.
    }
  }

  &__navigation-container {
    padding-top: 37px;
    display: flex;
    gap: 10px;

    @include mq('medium') {
      gap: 13px;
    }

    @include mq('large') {
      padding-top: var(--emu-common-spacing-none);
      gap: var(--emu-common-spacing-xs);
    }

    > .navigationlist {
      &:first-child {
        width: 46%; // from live site

        @include mq('large') {
          width: 50%;
        }

        // breakpoint needed to match live at 1440px
        @include mq('1140px') {
          width: 53%; // as per live site
        }

        ul {
          @include mq('large') {
            width: 185px; // as per live site
          }

          // breakpoint needed to match live at 1440px
          @include mq('1140px') {
            width: 260px; // as per live site
          }
        }
      }

      &:last-child {
        width: 48%; // from live site

        // breakpoint needed to match live at 1440px
        @include mq('1140px') {
          width: 45%; // as per live site
        }

        ul {
          @include mq('large') {
            width: 230px; // as per live site
          }
        }
      }
    }

    .emu-navigation {
      background-color: var(--emu-common-colors-transparent);

      &__item,
      &__item a {
        letter-spacing: 0;
      }

      &__item a {
        display: inline;
      }

      .emu-navigation__menu {
        &--level-0 {
          gap: var(--emu-common-spacing-none);

          > .emu-navigation__item {
            padding-top: var(--emu-common-spacing-none);
            padding-bottom: var(--emu-common-spacing-none);
            width: 100%;
            margin-bottom: 20px;
            font-weight: var(--emu-common-font-weight-black);
            font-family: var(--emu-semantic-font-families-heading);
            font-size: 13px;
            line-height: 21px;
            margin-left: var(--emu-common-spacing-none);
            margin-right: var(--emu-common-spacing-none);

            @include mq('large') {
              margin-bottom: 10px;
            }

            &:nth-child(4),
            &:nth-child(3) {
              @include mq('x-large') {
                margin-right: var(--emu-common-spacing-none);
              }
            }

            img {
              vertical-align: middle;
            }

            a {
              color: inherit;
              border: none;
              font-weight: var(--emu-common-font-weight-black);
              font-family: var(--emu-semantic-font-families-heading);
              font-size: 13px;
              line-height: 21px;
              max-width: 100%;
              overflow-wrap: break-word;
              white-space: normal;

              &:hover,
              &:active,
              &:focus {
                color: var(--emu-semantic-colors-primary-500);
                background: var(--emu-common-colors-transparent);
              }

              &:active,
              &:focus {
                text-decoration: underline;
              }
            }

            &:last-child {
              a {
                display: inline;

                &::before {
                  content: none;
                }
              }
            }
          }
        }
      }

      ul {
        display: flex;
        flex-direction: column;
        padding-right: var(--emu-common-spacing-none);
        padding-left: var(--emu-common-spacing-none);
        margin-top: var(--emu-common-spacing-none);
      }

      &__content-wrapper {
        position: relative;
      }
    }
  }

  &__bottom-container {
    padding-top: 15px;
    padding-bottom: 65px;
    padding-left: 30px;
    padding-right: 30px;

    @include mq('medium') {
      padding-left: 55px;
      padding-right: 55px;
    }

    @include mq('large') {
      padding-top: 45px;
      padding-bottom: 52px;
      padding-left: 55px;
    }

    @include mq('x-large') {
      padding-top: 29px;
    }

    p {
      padding-top: 15px;
      font-size: var(--emu-semantic-font-sizes-narrow-medium);
      line-height: 21px;
      font-weight: var(--emu-semantic-font-weight-500);
      color: var(--emu-semantic-colors-primary-950);

      @include mq('x-large') {
        font-size: 15px;
        line-height: 20px;
        color: var(--emu-semantic-colors-secondary-500);
      }
    }
  }
}
