#home {
}

// ========= Coolmonth Banner Styling ================
.coolmonth-banner-wrapper {
    max-width: unset;
    overflow: hidden;
    position: relative;
    &.hidden {
      display: none;
    }
    > .container {
      display: flex;
    }
    .background-image {
      img {
        bottom: 0;
        left: 50%;
        position: absolute;
        min-height: 100%;
        min-width: 100%;
        right: 0;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
      }
    }
  }
  .coolmonth-banner  {
    align-items: center;
    display: flex;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    @include mq('medium') {
      flex-direction: row;
    }
    > div {
      align-items: center;
      display: flex;
    }
    .logo {
      margin-bottom: -16px;
      margin-top: 32px;
      position: relative;
      width: 150px;
      @include mq('medium') {
        margin-bottom: 0;
        margin-top: 0;
        margin-left: 32px;
      }
    }
    .text {
      position: relative;
    }
    p,
    a {
      font-family: Arial , sans-serif; 
      font-size: 20px; 
      color: #fff; 
      text-align: center;
    }
    p {
      padding: 32.0px; 
    }
  }
// ============================================