[data-component='container-isi-v2'] {
  .aaaem-isi {
    &-banner {
      display: flex;
      gap: 5px;
      justify-content: space-between;
      align-items: center;
      margin-top: var(--emu-common-spacing-none);
      margin-bottom: var(--emu-common-spacing-none);
      margin-left: auto;
      margin-right: auto;
      padding-top: var(--emu-common-spacing-xxs);
      padding-bottom: 3px;
      max-width: var(--emu-semantic-sizing-breakpoints-large-plus);

      @include mq('medium') {
        padding-bottom: 1px;
        padding-top: 3px;
      }

      // breakpoint needed to match the live site
      @include mq('1142px') {
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
      }

      &-content {
        p {
          font-size: var(--emu-common-font-sizes-narrow-medium);
          line-height: var(--emu-common-spacing-medium);
          color: var(--emu-common-colors-white);
          font-family: var(--emu-semantic-font-families-avenir-next-bold);
        }
      }

      &__button-wrapper {
        line-height: var(--emu-semantic-sizing-one-line-height-narrow);
        padding-bottom: var(--emu-common-spacing-none);
        padding-top: var(--emu-common-spacing-none);
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
        font-size: var(--emu-common-font-sizes-narrow-medium);
        font-family: var(--emu-semantic-font-families-heading);

        span {
          color: var(--emu-common-colors-white);
        }

        svg {
          height: 10px; // As per live site
          width: 10px; // As per live site

          path {
            stroke: var(--emu-common-colors-white);
            stroke-width: 35px;
            fill: var(--emu-common-colors-white);
          }
        }
      }
    }

    &-container {
      background: linear-gradient(
        180deg,
        var(--emu-semantic-colors-primary-100) 0,
        var(--emu-semantic-colors-primary-800) 24px
      );
      border-top: var(--emu-common-border-width-none);

      &__header-wrapper {
        max-width: 1092px; // As per live site
        width: 100%;
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-none);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        color: var(--emu-semantic-colors-text-dark);
        background: linear-gradient(
          180deg,
          var(--emu-semantic-colors-primary-100) 0,
          var(--emu-semantic-colors-primary-800) 100%
        );

        p {
          font-size: var(--emu-common-font-sizes-narrow-medium);
          line-height: 16px;
          padding-top: 11px;
          padding-bottom: 12px;

          @include mq('large') {
            line-height: 20px;
            padding-top: var(--emu-common-spacing-none);
            padding-bottom: var(--emu-common-spacing-none);
          }
        }

        br {
          @include mq('large') {
            display: none;
          }
        }

        b {
          font-size: var(--emu-common-font-sizes-narrow-medium);
          font-family: var(--emu-semantic-font-families-heading);
        }
      }

      &__isi-content {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 15px;
        padding-bottom: 50px;

        @include mq('medium') {
          padding-left: 40px;
          padding-right: 40px;
        }

        @include mq('x-large') {
          padding-bottom: 35px;
        }

        &-wrapper {
          color: var(--emu-semantic-colors-secondary-500);
          font-family: var(--emu-semantic-font-families-body);
          max-width: 1092px; // As per live site
          width: 100%; // As per live site
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: var(--emu-common-spacing-none);
          padding-top: 20px;

          @include mq('large') {
            display: flex;
            gap: 50px;
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 60px;

            .container {
              width: 50%;
            }
          }

          @include mq('xx-large') {
            padding-left: 55px;
            padding-right: 40px;
            padding-top: 30px;
            gap: 35px;
          }

          &-right {
            .text {
              &-top {
                padding-top: 20px;

                @include mq('large') {
                  padding-top: var(--emu-common-spacing-none);
                }
              }

              &-bottom a {
                font-family: var(--emu-semantic-font-families-body);
              }
            }
          }

          &-left {
            .text-bottom a {
              font-family: var(--emu-semantic-font-families-heading);
              color: var(--emu-semantic-colors-secondary-500);
            }

            @include mq('xx-large') {
              padding-right: 20px;
            }
          }

          .text {
            &-top p {
              padding-top: 20px;
            }

            &-bottom p {
              padding-top: 5px;

              @include mq('large') {
                padding-top: 10px;
              }
            }

            &-top,
            &-bottom {
              p {
                font-size: 13px;
                line-height: 19.5px;

                b {
                  font-family: var(--emu-semantic-font-families-heading);
                  color: var(--emu-semantic-colors-secondary-500);
                }
              }
            }
          }
        }
      }

      &__button-wrapper {
        align-self: center;
      }
    }
  }
}

.isi-temp-content {
  display: none;
  max-height: 225px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: var(--emu-common-spacing-xs);
  background-color: var(--emu-common-colors-white);
  padding-top: var(--emu-common-spacing-medium);

  &__inner {
    display: flex;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;

    > .container {
      width: 45%;
      padding-right: 20px;
    }
  }

  .aaaem-isi-container__isi-content-wrapper-right,
  .aaaem-isi-container__isi-content-wrapper-left {
    padding-right: var(--emu-common-spacing-none);

    .text-top {
      padding-top: var(--emu-common-spacing-none);

      p {
        &:nth-child(3),
        &:nth-child(4) {
          display: none;
        }
      }
    }

    .text-bottom {
      display: none;
    }
  }

  .cmp-text {
    p {
      color: var(--emu-semantic-colors-secondary-500);
      font-family: var(--emu-semantic-font-families-body);
      font-size: 12px;
      line-height: 16px;

      &:not(:first-child) {
        margin-top: 20px;
      }
    }
  }

  .has-isi-temp-content & {
    @include mq('576px') {
      display: block;
    }
  }
}
