.aaaem-carousel__action {
  background-color: var(--emu-common-colors-transparent);
  border: var(--emu-common-border-width-none);
  padding: var(--emu-common-spacing-none);
  top: calc(19% + 3px); // value from live site
  bottom: auto;
  height: 40px; // value from live site
  display: flex;

  // breakpoint needed to match the live site
  @include mq('321px') {
    top: calc(19% + 2px); // value from live site
  }

  @include mq('medium') {
    top: calc(23% - 7px); // value from live site
  }

  // breakpoint needed to match the live site
  @include mq('1025px') {
    top: calc(50% - 6px);
  }

  &-icon {
    display: none;
  }

  &::before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 21px;
    background-repeat: no-repeat;
    background-size: contain;

    // breakpoint needed to match the live site
    @include mq('321px') {
      width: var(--emu-common-sizing-small);
      height: 28px; // value from live site
    }

    @include mq('medium') {
      width: 22px;
      height: 39px;
    }
  }

  &-prev {
    left: 25px;

    @include mq('medium') {
      left: -11px;
    }

    &::before {
      background-image: url('../../assets/images/next-arrow.png');
      transform: rotate(180deg);
    }
  }

  &-next {
    right: 25px;

    @include mq('medium') {
      right: -11px;
    }

    &::before {
      background-image: url('../../assets/images/next-arrow.png');
    }
  }
}
