.aaaem-accordion {
  padding-top: var(--emu-common-spacing-none);
  padding-bottom: var(--emu-common-spacing-none);
  width: 95%;
  margin-left: auto;
  margin-right: auto;

  @include mq('medium') {
    width: 100%;
    padding: 5%; // value from live site
  }

  // when there is a button inside an accordion
  .button .emu-button.emu-button-v2 .cmp-button__text {
    font-size: 15px;

    // breakpoint needed to match the live site
    @include mq('321px') {
      font-size: 18px;
    }

    // breakpoint needed to match the live site
    @include mq('414px') {
      font-size: 20px;
    }

    @include mq('medium') {
      font-size: 28px;
    }
  }

  &__item {
    padding-left: 6px;
    padding-right: 6px;
    counter-increment: section; /* Increment the counter before applying css */
  }

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 50px;
    margin-bottom: 40px;
    padding-top: 1px;
    padding-bottom: 1px;

    @include mq('medium') {
      align-items: center;
      margin-left: 102px;
      margin-bottom: 70px;
      min-height: 72px;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%) translateX(-100%) translateY(-3px)
        translateX(-15px); // 15 is the space between number and header

      @include mq('medium') {
        transform: translateY(-50%) translateX(-100%) translateY(-5px)
          translateX(-32px); // 32 is the space between number and header
      }
    }

    &::after {
      content: counter(section); /* Display the counter */
      font-family: var(--emu-semantic-font-families-heading);
      color: var(--emu-semantic-colors-primary-100);
      font-size: var(--emu-semantic-font-sizes-narrow-medium);
      width: 35px;
      display: block;
      text-align: center;
      padding-top: var(--emu-common-spacing-xs);

      // breakpoint needed to match the live site
      @include mq('414px') {
        font-size: 21px;
      }

      @include mq('medium') {
        font-size: 50px;
        width: 70px;
        padding-top: 10px;
      }
    }

    &::before {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: var(--emu-common-colors-white);

      @include mq('medium') {
        width: 70px;
        height: 70px;
      }
    }

    &.-ba-is-active {
      .aaaem-accordion__button.caret-icon .aaaem-accordion__icon {
        transform: rotate(-180deg) translateY(12px); // to match live site

        @include mq('medium') {
          transform: rotate(-180deg) translateY(24px);
        }

        @include mq('large') {
          transform: rotate(-180deg) translateY(12px);
        }
      }
    }
  }

  &__button {
    justify-content: space-between;

    &.caret-icon {
      .aaaem-accordion__icon {
        transform-origin: center;
        transition: all 0.2s linear;

        &::after {
          transform: rotate(-48deg) translateX(1px);

          @include mq('large') {
            transform: rotate(-48deg);
          }
        }

        &::before {
          transform: rotate(48deg) translateX(-1px);

          @include mq('large') {
            transform: rotate(48deg);
          }
        }
      }
    }
  }

  &__title {
    font-size: 28px;
    line-height: 34px;

    @include mq('321px') {
      font: var(
        --emu-component-lists-accordion-item-header-text-typography-narrow
      );
    }

    // breakpoint needed to match the live site
    @include mq('414px') {
      font-size: 33px;
      line-height: 36px;
    }

    // breakpoint needed to match the live site
    @include mq('576px') {
      max-width: 65%; // to match live site
    }

    @include mq('medium') {
      max-width: 80%; // to match live site
      font-size: 50px;
      line-height: 54.25px;
    }

    // breakpoint needed to match the live site
    @include mq('992px') {
      max-width: 65%; // to match live site
    }
  }

  &__icon {
    width: 23px; // min width is needed to add
    height: 23px; //to match live site
    margin-top: var(--emu-common-spacing-xs);
    flex-shrink: 0;

    @include mq('medium') {
      margin-bottom: -10px;
      width: 45px;
      height: 45px;
    }

    @include mq('large') {
      width: 24px;
      height: 24px;
      margin-bottom: var(--emu-common-spacing-none);
    }

    &::before,
    &::after {
      width: 2.5px; // to match live site

      @include mq('576px') {
        width: 4.5px; // to match live site
      }

      @include mq('large') {
        width: var(--emu-common-border-width-medium); // to match live site
      }
    }
  }

  dd .js-badger-accordion-panel-inner {
    @include mq('medium') {
      padding-top: 6px;
    }

    @include mq('large') {
      padding-bottom: 65px;
    }
  }
}
